import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { SelectPrettyOption, SelectPrettyValue } from "./SelectPretty";
import {
  Box,
  Button,
  FormLabel,
  Radio,
  RadioGroup,
  Select,
  Sheet,
  Typography,
} from "@mui/joy";
import { useEffect, useMemo, useRef, useState } from "react";
import { LuInfo } from "react-icons/lu";
import * as R from "remeda";
import { useProductsByIds } from "../../curve-management";
import {
  agGridCellAutoPadding,
  changeAllZIndex,
  headerHeight,
} from "../../globals";
import { useUserSubscriptionTier } from "../../market-pages";
import { useUserHasRole } from "../../layout";
import { usePageProductIds, usePagesProductIds } from "../../market-pages";
import { productName } from "../../utils";
import {
  joinProductsWithGridPageProducts,
  knownStatusList,
  type TAffectedProducts,
  type TProductWithGridIdAndStatus,
  type TKnownStatus,
  type TStatus,
} from "./statusLogic";
import { FaArrowRight } from "react-icons/fa";
import {
  knownStatusRules,
  radioSlotProps,
  statusModalMaxWidth,
  StatusOperationSchema,
  type TStatusTransitionEnhanced,
  type TUpdateStatusOperation,
} from "./consts";
import { StatusModalTitle } from "./StatusComponents";
import {
  changeAllHoverAtom,
  useStatusMap,
  useUpdateColumnStatus,
  useWriteStatusInstruments,
  checkCanBroadcast,
  genGlobalDependencyOptions,
  genSelectStatusOptions,
  genStatusTransitionActions,
  verifyKnownStatusRules,
} from "./hooks";
import { useSetAtom } from "jotai";

async function handleUpdateStatus({
  statusOperations,
  isDeniedBroadcast,
  isLiteUser,
  toStatus,
  affectedGridProducts,
  transitionActions,
  updateColumnStatus,
  writeStatusInstruments,
  statusMap,
}: {
  statusOperations: Record<string, TUpdateStatusOperation>;
  isDeniedBroadcast: boolean;
  isLiteUser: boolean;
  toStatus: TStatus;
  affectedGridProducts: TProductWithGridIdAndStatus[] | undefined;
  transitionActions: TStatusTransitionEnhanced[];
  updateColumnStatus: ReturnType<typeof useUpdateColumnStatus>;
  writeStatusInstruments: ReturnType<typeof useWriteStatusInstruments>;
  statusMap: Record<string, TStatus>;
}) {
  const toStatusIsGlobalProduct = !knownStatusList.includes(toStatus);

  const productIds = affectedGridProducts
    ?.map((product) => {
      const gridId = product.gridId;
      const curveId = product.id;
      const fromStatus = statusMap[curveId];
      if (toStatusIsGlobalProduct) {
        const hasGlobalProduct = product.product_global_dependencies.some(
          (globalDep) => globalDep.global_product,
        );
        if (hasGlobalProduct) {
          return product.id;
        }
      } else {
        // casting because we have already ruled out the global products
        const toStatusRules = knownStatusRules[toStatus as TKnownStatus];

        const canEod = !!product?.eod_product_dep;
        const canBroadcast = !!checkCanBroadcast(
          product?.packageByPackage.permissions,
        );

        const resolvedRules = {
          isDeniedBroadcast,
          isLiteUser,
          canEod,
          canBroadcast,
        };

        const canTransitionToStatus = verifyKnownStatusRules(
          toStatusRules,
          resolvedRules,
        );

        if (canTransitionToStatus) {
          console.log("product:", product.name);
          console.log("to:", toStatus);

          const requiredAction = transitionActions.find(
            (action) => action.gridId === gridId,
          );

          if (requiredAction) {
            const action = statusOperations[requiredAction.id];

            if (action === "write") {
              console.log(
                "write",
                requiredAction.from,
                "to",
                toStatus,
                gridId,
                curveId,
                product,
                fromStatus,
              );
              if (gridId && curveId && knownStatusList.includes(toStatus)) {
                writeStatusInstruments({
                  to: toStatus,
                  from: fromStatus,
                  gridId,
                  curveId,
                });
              } else {
                console.error("gridId or curveId is missing");
              }
            }
          }

          return product.id;
        }
      }
    })
    .filter(Boolean);

  if (productIds?.length) {
    await updateColumnStatus(toStatus, productIds);
  }
}

function SelectStatusOnePage(props: TSelectStatusChangeAll) {
  const { data: gridPageProducts } = usePageProductIds();

  const { data: pageProducts } = useProductsByIds(
    gridPageProducts?.map((p) => p.productId).filter(Boolean) || [],
  );

  const statusMap = useStatusMap();

  const statusProducts = useMemo(
    () =>
      joinProductsWithGridPageProducts(
        gridPageProducts,
        pageProducts,
        statusMap,
      ),
    [gridPageProducts, pageProducts, statusMap],
  );

  return (
    <SelectStatusChangeAll {...props} statusProducts={statusProducts || []} />
  );
}

function StatusModalMany({
  setClose,
  anchor,
  open,
}: {
  setClose: () => unknown;
  anchor: HTMLAnchorElement | null;
  open: boolean;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const [toStatus, setToStatus] = useState<TStatus>("listen");
  const [affectedGridProducts, setAffectedGridProducts] =
    useState<TAffectedProducts>({});
  const [transitionActions, setTransitionActions] = useState<
    TStatusTransitionEnhanced[]
  >([]);

  const [statusOperations, setStatusOperations] = useState<
    Record<string, TUpdateStatusOperation>
  >({});

  const [pageScope, setPageScope] = useState<"current-page" | "all-pages">(
    "current-page",
  );

  const distinctTransitions = R.uniqueBy(
    transitionActions,
    (entry) => `${entry?.from}-${entry?.to}`,
  );

  const isDeniedBroadcast = useUserHasRole("no-broadcast");
  const { liteUser } = useUserSubscriptionTier();

  const updateColumnStatus = useUpdateColumnStatus();
  const writeStatusInstruments = useWriteStatusInstruments();
  const statusMap = useStatusMap();
  const isLiteUser = !!liteUser;
  async function onSave() {
    console.log("-- ON SAVE --");
    console.log("");

    setError(undefined);
    setIsLoading(true);

    try {
      await handleUpdateStatus({
        isDeniedBroadcast,
        isLiteUser,
        toStatus,
        affectedGridProducts: affectedGridProducts[pageScope],
        transitionActions,
        statusOperations,
        updateColumnStatus,
        writeStatusInstruments,
        statusMap,
      });

      setClose();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError("Something went wrong!");
    }
  }

  return (
    <ClickAwayListener onClickAway={setClose}>
      <BasePopup
        open={open}
        offset={8}
        anchor={anchor}
        placement="right-start"
        style={{
          width: "100%",
          maxWidth: statusModalMaxWidth,
          zIndex: changeAllZIndex,
        }}
      >
        <Box
          sx={{
            borderRadius: "sm",
            overflow: "hidden",
            background: (theme) => theme.palette.background.surface,
          }}
        >
          <Box>
            <StatusModalTitle label="change all sources" />
            <Box sx={{ p: 2 }}>
              <Box>
                <Box>
                  <FormLabel
                    sx={{
                      mb: 1,
                      fontWeight: "xl",
                      textTransform: "uppercase",
                      fontSize: "xs",
                    }}
                  >
                    Apply to
                  </FormLabel>
                  <RadioGroup
                    size="sm"
                    orientation="horizontal"
                    sx={{ gap: 1.5 }}
                    onChange={(e) => {
                      setPageScope(
                        e.target.value as "current-page" | "all-pages",
                      );

                      setToStatus("listen");
                      setTransitionActions([]);
                      setError(undefined);
                    }}
                  >
                    {[
                      { value: "current-page", label: "Current Page" },
                      { value: "all-pages", label: "All Pages" },
                    ].map((option) => (
                      <Sheet
                        key={option.value}
                        sx={{
                          width: "100%",
                          textAlign: "center",
                          p: 0.5,
                          borderRadius: "md",
                          boxShadow: "sm",
                        }}
                      >
                        <Radio
                          sx={{ textTransform: "capitalize" }}
                          checked={pageScope === option.value}
                          value={option.value}
                          label={option.label}
                          overlay
                          disableIcon
                          slotProps={radioSlotProps}
                        />
                      </Sheet>
                    ))}
                  </RadioGroup>
                </Box>
                {pageScope === "all-pages" && (
                  <Typography sx={{ pt: 1 }} level="body-xs">
                    NB: Each Curve can only be in one status across all of your
                    Pages
                  </Typography>
                )}
                <FormLabel
                  sx={{
                    mt: 2,
                    mb: 1,
                    fontWeight: "xl",
                    textTransform: "uppercase",
                    fontSize: "xs",
                  }}
                >
                  change to
                </FormLabel>
                {pageScope === "current-page" ? (
                  <SelectStatusOnePage
                    toStatus={toStatus}
                    pageScope={pageScope}
                    setToStatus={setToStatus}
                    affectedGridProducts={affectedGridProducts}
                    setAffectedGridProducts={setAffectedGridProducts}
                    transitionActions={transitionActions}
                    setTransitionActions={setTransitionActions}
                  />
                ) : (
                  <SelectStatusAllPages
                    toStatus={toStatus}
                    pageScope={pageScope}
                    setToStatus={setToStatus}
                    affectedGridProducts={affectedGridProducts}
                    setAffectedGridProducts={setAffectedGridProducts}
                    transitionActions={transitionActions}
                    setTransitionActions={setTransitionActions}
                  />
                )}
              </Box>
              {distinctTransitions?.length > 0 ? (
                <Box sx={{ pt: 2 }}>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    {distinctTransitions.map((action) => {
                      const productNamesPerAction = transitionActions
                        .filter((entry) => entry.id === action.id)
                        .map((entry) => entry.productName)
                        .join(", ");

                      return (
                        <RadioGroup
                          key={action.id}
                          size="sm"
                          orientation="vertical"
                          sx={{
                            gap: 1,
                            display: "flex",
                            flexDirection: "column",
                          }}
                          onChange={(e) =>
                            setStatusOperations((prev) => ({
                              ...prev,
                              [e.target.name]: StatusOperationSchema.parse(
                                e.target.value,
                              ),
                            }))
                          }
                        >
                          <Typography
                            endDecorator={
                              <LuInfo title={productNamesPerAction} />
                            }
                            sx={{ textTransform: "capitalize" }}
                            level="body-xs"
                          >
                            {action.label}
                          </Typography>
                          {action?.options.map((option) => {
                            return (
                              <Sheet
                                key={option.value}
                                sx={{
                                  p: 0.5,
                                  borderRadius: "md",
                                  boxShadow: "sm",
                                }}
                              >
                                <Radio
                                  key={`${action.id}${option.value}`}
                                  name={action.id}
                                  sx={{ textTransform: "capitalize" }}
                                  checked={
                                    statusOperations[action.id]
                                      ? statusOperations[action.id] ===
                                        option.value
                                      : option.value === "read"
                                  }
                                  value={option.value}
                                  label={option.label}
                                  overlay
                                  disableIcon
                                  slotProps={radioSlotProps}
                                />
                              </Sheet>
                            );
                          })}
                        </RadioGroup>
                      );
                    })}
                  </Box>
                </Box>
              ) : (
                ""
              )}
              <Box sx={{ pt: 2 }}>
                <Button
                  sx={{ width: "100%" }}
                  size="sm"
                  loading={isLoading}
                  loadingPosition="end"
                  onClick={onSave}
                >
                  Save
                </Button>
                {error && (
                  <Typography color="danger" sx={{ pt: 2 }} level="body-sm">
                    {error}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </BasePopup>
    </ClickAwayListener>
  );
}

export function ChangeAllStatusButton() {
  const [open, setOpen] = useState(false);
  const elRef = useRef<HTMLAnchorElement>(null);

  const setChangeAll = useSetAtom(changeAllHoverAtom);

  return (
    <Box
      sx={{
        height: headerHeight * 2,
        display: "flex",
        alignItems: "center",
      }}
    >
      {open && (
        <StatusModalMany
          setClose={() => setOpen(false)}
          anchor={elRef.current}
          open={open}
        />
      )}
      <Button
        ref={elRef}
        onClick={() => setOpen(true)}
        onMouseEnter={() => {
          setChangeAll(true);
        }}
        onMouseLeave={() => {
          setChangeAll(false);
        }}
        size="sm"
        variant="soft"
        sx={{
          ml: agGridCellAutoPadding,
          fontSize: "0.7rem",
          fontWeight: "400",
          minHeight: 0,
          paddingInline: 0.5,
          paddingBlock: 0.5,
        }}
      >
        SOURCE{" "}
        <FaArrowRight
          style={{
            fontSize: "7px",
            marginLeft: "2px",
          }}
        />
      </Button>
    </Box>
  );
}

type TSelectStatusChangeAll = {
  toStatus: TStatus;
  pageScope: "current-page" | "all-pages";
  setToStatus: React.Dispatch<React.SetStateAction<TStatus>>;
  transitionActions: TStatusTransitionEnhanced[];
  setTransitionActions: React.Dispatch<
    React.SetStateAction<TStatusTransitionEnhanced[]>
  >;
  affectedGridProducts: TAffectedProducts;
  setAffectedGridProducts: React.Dispatch<
    React.SetStateAction<TAffectedProducts>
  >;
};

function SelectStatusChangeAll({
  statusProducts,
  toStatus,
  pageScope,
  setToStatus,
  setTransitionActions,
  affectedGridProducts,
  setAffectedGridProducts,
}: TSelectStatusChangeAll & {
  statusProducts: TProductWithGridIdAndStatus[];
}) {
  // this needs to initialize the affected products when the user
  // changes between current page and all pages
  useEffect(() => {
    if (!affectedGridProducts[pageScope] && statusProducts.length) {
      console.log("set affectedGridProducts in chg all modal");
      setAffectedGridProducts((prev) =>
        R.merge(prev, {
          [pageScope]: statusProducts,
        }),
      );
    }
  }, [
    pageScope,
    statusProducts,
    setAffectedGridProducts,
    affectedGridProducts,
  ]);

  const canAtLeastOneEod = statusProducts?.find(
    (product) => product?.eod_product_dep,
  );
  const canAtLeastOneBroadcast = statusProducts?.find((product) =>
    checkCanBroadcast(product?.packageByPackage.permissions),
  );
  const { liteUser } = useUserSubscriptionTier();
  const resolvedRules = {
    isDeniedBroadcast: useUserHasRole("no-broadcast"),
    isLiteUser: !!liteUser,
    canEod: !!canAtLeastOneEod,
    canBroadcast: !!canAtLeastOneBroadcast,
  };

  const logo = statusProducts?.[0]?.packageByPackage?.sourceBySource?.logo;

  const globalOptions = R.pipe(
    statusProducts || [],
    R.flatMap(genGlobalDependencyOptions),
    R.uniqueBy((product) => product.value),
  );

  const statusOptionsToRender = genSelectStatusOptions({
    resolvedRules,
    globalOptions,
  });

  const allStatusOptions = genSelectStatusOptions({
    resolvedRules,
    globalOptions,
  });

  const statusMap = useStatusMap();
  return (
    <Select
      size="sm"
      value={toStatus}
      indicator={false}
      sx={{
        background: "transparent",
      }}
      onChange={(_e, value) => {
        if (!value) return;

        const toStatus = value;

        const transitionActions = R.pipe(
          statusProducts || [],
          R.map((product) => {
            const transitionAction = genStatusTransitionActions({
              from: statusMap?.[product.id] || "listen",
              to: toStatus,
            });

            return (
              transitionAction && {
                ...transitionAction,
                productId: product.id,
                gridId: product.gridId,
                productName: productName(product),
              }
            );
          }),
        ).filter(Boolean);

        setTransitionActions(transitionActions);
        setToStatus(value);
      }}
      renderValue={(renderValueParams) => {
        return (
          <SelectPrettyValue
            options={statusOptionsToRender}
            renderValueParams={renderValueParams}
            logo={logo}
          />
        );
      }}
    >
      {allStatusOptions.map((option) => {
        return (
          <SelectPrettyOption
            key={option.value}
            {...option}
            statusOptionsToRender={statusOptionsToRender}
            logo={logo}
          />
        );
      })}
    </Select>
  );
}

function SelectStatusAllPages(props: TSelectStatusChangeAll) {
  const { results: gridPagesProductIds } = usePagesProductIds();

  const { data: pageProducts } = useProductsByIds(
    gridPagesProductIds?.map((p) => p.productId).filter(Boolean) || [],
  );
  const statusMap = useStatusMap();
  const statusProducts = useMemo(
    () =>
      joinProductsWithGridPageProducts(
        gridPagesProductIds || [],
        pageProducts,
        statusMap,
      ),
    [gridPagesProductIds, pageProducts, statusMap],
  );

  return (
    <SelectStatusChangeAll {...props} statusProducts={statusProducts || []} />
  );
}
