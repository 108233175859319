import { type ForwardedRef, forwardRef, memo, type ReactNode } from "react";
import type {
  CandlestickSeriesPartialOptions,
  ISeriesApi,
  SeriesMarker,
  Time,
} from "lightweight-charts";

import { SeriesContext } from "./internal/series-context.js";
import { createSeriesHook } from "./internal/create-series-hook.js";
import type { CandlestickSeriesParams } from "../internal/series.js";

const useCandlestickSeriesAction =
  createSeriesHook<CandlestickSeriesParams>("Candlestick");

export interface CandlestickSeriesProps
  extends CandlestickSeriesPartialOptions {
  data: CandlestickSeriesParams["data"];
  markers?: SeriesMarker<Time>[];
  reactive?: boolean;
  children?: ReactNode;
}

export const CandlestickSeries = memo(
  forwardRef(function CandlestickSeries(
    props: CandlestickSeriesProps,
    ref: ForwardedRef<ISeriesApi<"Candlestick">>,
  ) {
    const { children, ...rest } = props;

    const context = useCandlestickSeriesAction(rest, ref);

    return (
      <SeriesContext.Provider value={context.current}>
        {children}
      </SeriesContext.Provider>
    );
  }),
);
