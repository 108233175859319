import { z } from "zod";
import { useAuth0Config } from "../../auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { passwordValidation } from "../../utils/forms";
import { useState } from "react";
import type { TResetPasswordBody } from "../../../api/reset-password.mjs";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalClose,
  Sheet,
  Typography,
} from "@mui/joy";
import { InputField } from "../components/Form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useAuditLoggerFactory } from "../audit";

type ResetPasswordForm = {
  password: string;
  repeatPassword: string;
};

export default function ResetPasswordModal({
  open,
  setOpen,
  userId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  userId: string | undefined;
}) {
  const [showPassword, setShowPassword] = useState(false);

  const { auth0_url, env } = useAuth0Config();

  const passwordValidationObj = z.object({
    password: passwordValidation,
    repeatPassword: passwordValidation,
  });

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<ResetPasswordForm>({
    resolver: zodResolver(passwordValidationObj),
  });

  const auditFactory = useAuditLoggerFactory();

  function onSubmit({ password, repeatPassword }: ResetPasswordForm) {
    if (!userId) return;

    if (password !== repeatPassword) {
      return toast.error("Passwords do not match");
    }

    const audit = auditFactory({
      actionName: "changeUserPassword",
      clearanceLevel: "umi-internal-write",
    });

    audit.start(`Changing password for user: ${userId}`);

    const body = {
      userId,
      password,
      auth0_url,
      connection: "Username-Password-Authentication",
      env,
    } satisfies TResetPasswordBody;

    const resetPasswordReq = fetch("/api/reset-password", {
      method: "POST",
      body: JSON.stringify(body),
    }).then((res) => {
      if (res.status !== 200) {
        audit.error("Error resetting password.");
        return Promise.reject(res);
      }

      audit.success();
      setOpen(false);
    });

    toast.promise(resetPasswordReq, {
      loading: "Resetting password...",
      success: "Password reset successfully!",
      error: "Error resetting password",
    });
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => {
        resetField("password");
        resetField("repeatPassword");
        setOpen(false);
      }}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Sheet
        variant="outlined"
        sx={{
          minWidth: 350,
          maxWidth: 500,
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <Typography component="h2" level="h4" fontWeight="lg" mb={1}>
          Change Password
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            id="modal-desc"
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
              marginTop: 2,
            }}
          >
            <InputField
              {...register("password")}
              error={errors.password}
              label="New Password"
              type={showPassword ? "text" : "password"}
              endDecorator={
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </IconButton>
              }
            />
            <InputField
              {...register("repeatPassword")}
              error={errors.password}
              label="Repeat Password"
              type={showPassword ? "text" : "password"}
              endDecorator={
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </IconButton>
              }
            />
            <Button variant="soft" type="submit">
              Confirm Password
            </Button>
          </Box>
        </form>
      </Sheet>
    </Modal>
  );
}
