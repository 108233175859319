import { headerHeight } from "../globals";
import { useGridSettingsOptions, useVisibleColumns } from "../grid-settings";
import { client } from "../../triplit/triplit";
import { useCssVar } from "../sharedHooks";
import { useState, useEffect } from "react";
import type { TPageSettings } from "../../triplit/schema";
import { useGridApi } from "../../shared/hooks";

async function getExtraHeaderHeightEnabled(pageId: string): Promise<boolean> {
  try {
    const pageSettings: TPageSettings | null = await client.fetchById(
      "pages",
      pageId,
      { policy: "local-only" },
    );
    if (pageSettings === null) {
      return false;
    }
    return pageSettings.extraHeaderHeightEnabled ?? false;
  } catch (error) {
    console.error("Error fetching page settings:", error);
    return false;
  }
}

export function useDynamicCssExtraHeightRow(pageId: string) {
  const { getApi } = useGridApi();
  const api = getApi();
  const visibleColumns = useVisibleColumns();
  const setVar = useCssVar();

  const { hideStatusRow } = useGridSettingsOptions();

  const [extraHeaderHeight, setExtraHeaderHeight] =
    useState<number>(headerHeight);

  getExtraHeaderHeightEnabled(pageId).then((enabled) => {
    const setHeaderHeight = enabled ? 100 : headerHeight;
    const extraHeightChanged = setHeaderHeight !== extraHeaderHeight;
    if (extraHeightChanged) {
      setExtraHeaderHeight(setHeaderHeight);
    }
  });

  useEffect(() => {
    if (!api || api.isDestroyed()) return;

    console.log("useDynamicCssExtraHeightRow");

    console.log("Update grid extra height through grid API");
    console.log("setHeaderHeight", extraHeaderHeight);
    setVar("--extraHeaderHeight", `${extraHeaderHeight}px`);
    setVar("--headerHeight", `${headerHeight}px`);

    const statusColumnHeight = !hideStatusRow ? headerHeight : 0;
    const lastUpdatedColumnHeight = headerHeight;
    const allHeadersMinusNameColumnHeight =
      (visibleColumns.length - 1) * headerHeight;
    const nameColumnHeight = extraHeaderHeight;

    const heightOfAllHeaders =
      statusColumnHeight +
      lastUpdatedColumnHeight +
      allHeadersMinusNameColumnHeight +
      nameColumnHeight;

    api.setGridOption("headerHeight", heightOfAllHeaders);
  }, [api, extraHeaderHeight, visibleColumns, setVar, hideStatusRow]);

  return null;
}
