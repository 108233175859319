import type {
  CustomSeriesPartialOptions,
  ISeriesApi,
  SeriesMarker,
  Time,
} from "lightweight-charts";
import { type ForwardedRef, type ReactNode, forwardRef, memo } from "react";

import type {
  CandlestickSeriesParams,
  CustomSeriesParams,
} from "../internal/series.js";
import { createSeriesHook } from "./internal/create-series-hook.js";
import { SeriesContext } from "./internal/series-context.js";

const useCandlestickRoundedSeriesAction =
  createSeriesHook<CustomSeriesParams>("Custom");

export interface CandlestickRoundedSeriesProps
  extends CustomSeriesPartialOptions {
  data: CandlestickSeriesParams["data"];
  markers?: SeriesMarker<Time>[];
  reactive?: boolean;
  children?: ReactNode;
  upColor?: string;
  downColor?: string;
  onSeriesReady?: (api: ISeriesApi<"Custom">) => void;
  view: CustomSeriesParams["view"];
}

export const CandlestickRoundedSeries = memo(
  forwardRef(function CandlestickRoundedSeries(
    props: CandlestickRoundedSeriesProps,
    ref: ForwardedRef<ISeriesApi<"Custom">>,
  ) {
    const { children, ...rest } = props;

    const context = useCandlestickRoundedSeriesAction(rest, ref);

    return (
      <SeriesContext.Provider value={context.current}>
        {children}
      </SeriesContext.Provider>
    );
  }),
);
