import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import * as marky from "marky";
import { z } from "zod";
import { objectKeys } from "./shared/utils";
dayjs.extend(relativeTime);
dayjs.extend(utc);

export const zDateString = z
  .string()
  .refine((value) => value === null || !Number.isNaN(Date.parse(value)), {
    message: "Invalid date string",
  });

export function formatDateForTable(date: string | null | undefined) {
  if (date) {
    const dateObj = new Date(date);
    const formattedDate = new Intl.DateTimeFormat("en-UK", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(dateObj);
    const timeAgo = dayjs(date).fromNow();

    return `${formattedDate} (${timeAgo})`;
  }
  return "-";
}

export function yesterdayDate() {
  const currentDate = new Date();
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  const formattedYesterday = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(yesterday);

  return formattedYesterday;
}

export function timeAgoShort(date: Date, now: Date = new Date()) {
  const diff = now.getTime() - date.getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (seconds < 60) return "<1m";
  if (minutes < 60) return `${minutes}m`;
  if (hours < 24) return `${hours}h`;
  if (days < 30) return `${days}d`;
  if (months < 12) return `${months}M`;
  return `${years}Y`;
}

export function mergeData<T extends RequiredTableFields>(
  serverRowData: Readonly<T[]>,
  localEditedData: Record<string, Partial<T>>,
) {
  return serverRowData.map((serverRow) => {
    const localEdited = localEditedData[serverRow.id];
    if (localEdited) {
      return {
        ...serverRow,
        ...localEdited,
      };
    }
    return serverRow;
  });
}

export function editableKeys<
  T extends Record<
    string,
    Partial<{ type: string; editable?: boolean | null }>
  >,
>(config: T) {
  return objectKeys(config)
    .filter(
      (
        key,
      ): key is {
        [K in keyof T]: T[K] extends { editable: true } ? K : never;
      }[keyof T] => "editable" in config[key],
    )
    .map((key) => key);
}

export function editableColDefs<
  T extends Array<Partial<{ field: string; editable?: boolean | null }>>,
>(config: T) {
  return config.filter(
    (obj): obj is Extract<T[number], { editable: true }> =>
      "editable" in obj && !!obj?.editable,
  );
}

export type RequiredTableFields = {
  id: string | number;
};

export const ukDateFormatter = new Intl.DateTimeFormat("en-GB", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

export function setDateToMidnightUTC(inputDate: string | number | Date) {
  try {
    if (!inputDate) {
      throw new Error("Input date is required.");
    }

    const newDate = new Date(inputDate);

    if (Number.isNaN(newDate.getTime())) {
      throw new Error("Invalid date.");
    }

    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const isoDateString = `${formattedDate}T00:00:00.000Z`;
    return isoDateString;
  } catch (error) {
    console.error("Error in setDateToMidnightUTC:", error);
    return null;
  }
}

const enableProfiling = false;

export function profileStart(tag: string) {
  if (enableProfiling) marky.mark(tag);
}

export function profileEnd(tag: string) {
  if (enableProfiling) marky.stop(tag);
}

type WithProperty<T, P extends PropertyKey> = {
  [K in P]: T;
};

export function memoize0<
  TResult,
  TObject extends object,
  TTag extends keyof TObject,
>(
  obj: TObject & WithProperty<TResult, TTag>,
  tag: TTag,
  func: () => TResult,
): TResult {
  if (!Object.prototype.hasOwnProperty.call(obj, tag)) {
    Object.defineProperty(obj, tag, {
      enumerable: false,
      writable: false,
      value: func(),
    });
  }

  return obj[tag];
}

/**
 * Capitalizes the first letter of a string. Will only work for roman characters.
 * @param string - The string to capitalize.
 * @returns The string with the first letter capitalized.
 **/
export function capitalizeFirstLetter(string: string) {
  return string[0].toUpperCase() + string.slice(1);
}

export function formatKebabCase(string: string) {
  return string
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function throttle<T extends (...args: Parameters<T>) => ReturnType<T>>(
  fn: T,
  delay: number,
): T {
  let lastCall = 0;
  let lastResult: ReturnType<T>;

  return ((...args: Parameters<T>): ReturnType<T> => {
    const now = Date.now();

    if (now - lastCall >= delay) {
      lastCall = now;
      lastResult = fn(...args);
    }

    return lastResult;
  }) as T;
}
