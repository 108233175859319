import {
  Box,
  Button,
  Input,
  LinearProgress,
  Option,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/joy";
import { useState, useEffect } from "react";
import { client } from "../../../triplit/triplit";
import type { TPageProduct } from "../../../triplit/schema";
import { useEntity } from "@triplit/react";
import { ColumnWidthSetting } from "./SetAllColumnWidths";
import { defaultColumnSettings, validColumnSettings } from "../columnDefs";
import { validColumnWidth } from "./columnSettingsHelpers";
import { ModalButtons } from "./Buttons";
import { GridModalContainer } from "./GridModal";
import { useAtom, useAtomValue } from "jotai";
import {
  cancelCellFormatting,
  initialFormattingAndSettingsAtom,
} from "./formatCellHelpers";
import { store } from "../../sharedHooks";
import { gridModalDockviewAtom } from "../../calculations-worker/sharedStores";
import { GridModalSelectionInfo } from "./modalComponents";
import { useGridApi } from "../../../shared/hooks";
import { colParams } from "../../utils";
import { gridSettingsOptions } from "../../grid-settings";
import { userDetailsAtom } from "../../../auth";

export type ContextMenuColumnSettings = {
  show: boolean;
  columnId?: string;
};

type InitialColumnSettings = {
  columnWidth?: number;
  decimalPlaces?: number;
  thousandsSeparator?: boolean;
  increment?: number;
};

export async function saveColumnSettings(changes: {
  decimalPlaces?: number;
  thousandsSeparator?: boolean;
  columnWidth?: number;
  increment?: number;
}) {
  const userId = store.get(userDetailsAtom).id;
  const incrementMap = store.get(gridSettingsOptions)?.incrementMap || {};

  const modalDockview = store.get(gridModalDockviewAtom);
  const ranges = modalDockview?.formatting?.selectedRange;

  if (!ranges) return;

  const { columnIds, productIds } = ranges.reduce(
    (acc: { columnIds: string[]; productIds: string[] }, range) => {
      range.map((cell) => {
        if (!acc.columnIds.includes(cell.columnId)) {
          acc.columnIds.push(cell.columnId);
        }

        if (cell?.productId && !acc.productIds.includes(cell.productId)) {
          acc.productIds.push(cell.productId);
        }
      });

      return acc;
    },
    { columnIds: [], productIds: [] },
  );

  if (!columnIds.length) return;

  if (productIds.length && userId) {
    for (const productId of productIds) {
      if (changes.increment) {
        incrementMap[productId] = changes.increment;
      }
    }

    await client.transact(async (tx) => {
      await tx.insert("gridSettings", {
        id: userId,
        incrementMap: JSON.stringify(incrementMap),
      });
    });
  }

  const updates = columnIds.map((columnId) =>
    client.update("pageProducts", columnId, (current) => {
      if (changes.decimalPlaces !== undefined)
        current.decimalPlaces = changes.decimalPlaces;

      if (changes.thousandsSeparator !== undefined)
        current.thousandsSeparator = changes.thousandsSeparator;

      if (changes.columnWidth !== undefined)
        current.columnWidth = changes.columnWidth;
    }),
  );

  await Promise.all(updates);
}

function ColumnSettingsModalWithState({
  pageId,
  product,
  initialSettings,
}: {
  pageId: string;
  product?: TPageProduct;
  initialSettings: InitialColumnSettings;
}) {
  const { getApi } = useGridApi();
  const api = getApi();

  const dockviewValue = useAtomValue(gridModalDockviewAtom);
  const rangeSelection = dockviewValue?.formatting?.selectedRange || [];

  const editable = rangeSelection
    ?.flatMap((range) => {
      return range.flatMap((cell) => {
        const params = colParams(api?.getColumn(cell.columnId));
        return params?.hasSharedCell;
      });
    })
    .some((c) => c);

  const [columnWidth, setColumnWidth] = useState<number>(
    product?.columnWidth || defaultColumnSettings.columnWidth,
  );

  const initialFormatting = useAtomValue(
    initialFormattingAndSettingsAtom,
  )?.formatting;

  const [incrementValue, setIncrementValue] = useState<number | undefined>(
    initialSettings?.increment,
  );

  if (!product) return <LinearProgress />;

  const { decimalPlaces, thousandsSeparator, increment } = validColumnSettings({
    decimalPlaces: product.decimalPlaces,
    thousandsSeparator: product.thousandsSeparator,
  });

  return (
    <GridModalContainer
      panel="formatting"
      body={
        <>
          <GridModalSelectionInfo selectedRange={rangeSelection} />
          <Stack padding={2}>
            <Box>
              <Box>
                <Box overflow={"hidden"}>
                  <Typography>Number of decimal places to show</Typography>
                  <Select
                    sx={{ width: 120, mt: 1 }}
                    variant="outlined"
                    defaultValue={decimalPlaces}
                    onChange={(_, value) =>
                      saveColumnSettings({
                        decimalPlaces: Number(value),
                      })
                    }
                  >
                    {Array.from({ length: 10 }, (_, i) => (
                      <Option key={i.toString()} value={i}>
                        {i}
                      </Option>
                    ))}
                  </Select>
                </Box>
                {editable && (
                  <Box mt={1} overflow={"hidden"}>
                    <Typography>Increment</Typography>
                    <Stack
                      flexDirection={"row"}
                      gap={1}
                      mt={1}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      height={27}
                    >
                      <Input
                        type="number"
                        value={incrementValue ?? increment}
                        onChange={(e) =>
                          setIncrementValue(Number(e.target.value))
                        }
                        sx={{
                          width: 120,
                        }}
                      />
                      <Button
                        variant="solid"
                        color="neutral"
                        onClick={() => {
                          setIncrementValue(defaultColumnSettings.increment);
                        }}
                      >
                        Set to Default
                      </Button>
                    </Stack>
                  </Box>
                )}
                <Box mt={1} overflow={"hidden"}>
                  <Typography>Thousands separator</Typography>
                  <Switch
                    sx={{ mt: 1 }}
                    size="lg"
                    checked={thousandsSeparator}
                    onChange={() =>
                      saveColumnSettings({
                        thousandsSeparator: !product.thousandsSeparator,
                      })
                    }
                  />
                </Box>
                <Box mt={1} overflow={"hidden"}>
                  <Typography>Column Width</Typography>
                  <ColumnWidthSetting
                    columnWidth={columnWidth}
                    setColumnWidth={setColumnWidth}
                  />
                </Box>
              </Box>
            </Box>
          </Stack>
        </>
      }
      buttons={
        <ModalButtons
          parentPanel="formatting"
          onCancel={() => {
            saveColumnSettings({ ...initialSettings });

            if (initialFormatting) {
              cancelCellFormatting(pageId, initialFormatting);
            }
          }}
          onSave={() => {
            saveColumnSettings({
              columnWidth: validColumnWidth(columnWidth),
              increment: incrementValue,
            });
          }}
        />
      }
    />
  );
}

export type TColumnSettingsModalProps = {
  columnId: string;
  initialColumnWidth: number;
  initialThousandsSeparator: boolean;
  initialDecimalPlaces: number;
  initialIncrement?: number;
  pageId: string;
};

export default function ColumnSettingsModal({
  pageId,
  columnId,
  initialColumnWidth,
  initialThousandsSeparator,
  initialDecimalPlaces,
}: TColumnSettingsModalProps) {
  const { incrementMap } = useAtomValue(gridSettingsOptions);

  const { result: product } = useEntity(client, "pageProducts", columnId, {
    localOnly: true,
  });

  const productId = product?.productId || "";

  const [initialFormattingAtom, setInitialFormattingAtom] = useAtom(
    initialFormattingAndSettingsAtom,
  );

  useEffect(() => {
    if (product && !initialFormattingAtom.settings) {
      const initialSettings = validColumnSettings({
        columnWidth: initialColumnWidth,
        decimalPlaces: initialDecimalPlaces,
        thousandsSeparator: initialThousandsSeparator,
        increment: incrementMap?.[productId],
      });

      setInitialFormattingAtom({
        ...initialFormattingAtom,
        settings: { ...initialSettings },
      });
    }
  }, [
    product,
    initialFormattingAtom,
    initialColumnWidth,
    initialThousandsSeparator,
    initialDecimalPlaces,
    incrementMap,
    productId,
    setInitialFormattingAtom,
  ]);

  if (!product) return <LinearProgress />;

  const initialSettings = validColumnSettings({
    columnWidth: initialColumnWidth,
    decimalPlaces: initialDecimalPlaces,
    thousandsSeparator: initialThousandsSeparator,
    increment: incrementMap?.[productId],
  });

  return (
    <ColumnSettingsModalWithState
      pageId={pageId}
      product={product}
      initialSettings={initialSettings}
    />
  );
}
