function Icon({ selected }: { selected: boolean }) {
  return (
    <svg
      className={selected ? "nav-icon selected" : "nav-icon"}
      width="42"
      height="42"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{selected ? "Market Selected" : "Market"}</title>
      <rect
        className="background"
        opacity="0.15"
        width="42"
        height="42"
        rx="2"
        fill="white"
      />
      <rect
        x="10"
        y="11"
        width="18"
        height="17"
        rx="1"
        stroke="white"
        strokeWidth="2"
      />
      <rect
        x="13.5"
        y="14.5"
        width="4"
        height="1"
        fill="#141528"
        className="orange"
      />
      <rect
        x="20.5"
        y="14.5"
        width="4"
        height="1"
        fill="#141528"
        stroke="white"
      />
      <rect
        x="13.5"
        y="17.5"
        width="4"
        height="1"
        fill="#141528"
        className="orange"
      />
      <rect
        x="20.5"
        y="17.5"
        width="4"
        height="1"
        fill="#141528"
        stroke="white"
      />
      <rect
        x="13.5"
        y="20.5"
        width="4"
        height="1"
        fill="#141528"
        className="orange"
      />
      <rect
        x="20.5"
        y="20.5"
        width="4"
        height="1"
        fill="#141528"
        stroke="white"
      />
      <rect
        x="13.5"
        y="23.5"
        width="4"
        height="1"
        fill="#141528"
        className="orange"
      />
      <rect
        x="20.5"
        y="23.5"
        width="4"
        height="1"
        fill="#141528"
        stroke="white"
      />
    </svg>
  );
}

export function MarketIcon({ selected = false }: { selected?: boolean }) {
  return <Icon selected={selected} />;
}
