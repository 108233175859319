import {
  faCircleCheck,
  faExclamationCircle,
  faExclamationTriangle,
  faHexagonExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Typography, type ColorPaletteProp } from "@mui/joy";

type TSeverity = "success" | "warning" | "danger" | "info";

const items: Record<
  TSeverity,
  {
    color: ColorPaletteProp;
    icon: React.ReactElement;
  }
> = {
  success: { color: "success", icon: <FontAwesomeIcon icon={faCircleCheck} /> },
  warning: {
    color: "warning",
    icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
  },
  danger: {
    color: "danger",
    icon: <FontAwesomeIcon icon={faHexagonExclamation} />,
  },
  info: {
    color: "neutral",
    icon: <FontAwesomeIcon icon={faExclamationCircle} />,
  },
};

export function ErrorMessage({
  title,
  message,
  severity,
}: {
  title: string;
  message: string;
  severity: TSeverity;
}) {
  const { color, icon } = items[severity];
  return (
    <Alert
      key={title}
      sx={{
        alignItems: "flex-start",
        maxWidth: "400px",
      }}
      startDecorator={icon}
      variant="soft"
      color={color}
    >
      <div>
        <Box
          sx={{
            mt: -0.2,
            pb: 0.5,
          }}
        >
          {title}
        </Box>
        <Typography level="body-sm" color={color}>
          {message}
        </Typography>
      </div>
    </Alert>
  );
}
