import type { CreatePriceLineOptions, IPriceLine } from "lightweight-charts";
import {
  type ForwardedRef,
  forwardRef,
  memo,
  type MutableRefObject,
  type ReactNode,
  useContext,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from "react";

import { createLazyValue, type LazyValue } from "../internal/lazy-value.js";
import {
  priceLine,
  type PriceLineActionResult,
} from "../internal/price-line.js";
import { SeriesContext } from "./internal/series-context.js";
import { useDeepCompareMemo } from "@react-hookz/web";

export interface PriceLineProps extends CreatePriceLineOptions {
  children?: ReactNode;
}

function usePriceLineAction(
  props: PriceLineProps,
  ref: ForwardedRef<IPriceLine>,
): MutableRefObject<LazyValue<PriceLineActionResult>> {
  const { children, ...rest } = props;

  const series = useContext(SeriesContext);

  const context = useRef(
    createLazyValue(
      () => {
        if (!series) throw new Error("Series is not defined");
        return priceLine(series(), rest);
      },
      (value: PriceLineActionResult) => value.destroy(),
    ),
  );

  useLayoutEffect(() => {
    const { current } = context;
    current();

    return () => {
      current.reset();
    };
  }, []);

  const propsWithNoChildren = useDeepCompareMemo(() => {
    return rest;
  }, [rest]);

  useLayoutEffect(() => {
    context.current().update(propsWithNoChildren);
  }, [propsWithNoChildren]);

  useImperativeHandle(ref, () => context.current().subject(), []);

  return context;
}

export const PriceLine = memo(
  forwardRef((props: PriceLineProps, ref: ForwardedRef<IPriceLine>) => {
    usePriceLineAction(props, ref);

    return null;
  }),
);
