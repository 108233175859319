import { useOrgId } from "../orgs/hooks";
import * as R from "remeda";
import { type ResultOf, readFragment } from "../../graphql";
import { useMemo } from "react";
import { useParams } from "@tanstack/react-router";
import { useQuerySub } from "../../utils/useQuerySub";
import { type TEnv, type TServerResponse, getHasuraUrl } from "../../globals";
import { mergeData } from "../../utils";
import type {
  TUsersRoles,
  TUserRolesBody,
} from "../../../api/update-roles.mjs";
import type { TBlockUserBody } from "../../../api/block-user.mjs";
import toast from "react-hot-toast";
import { graphql } from "../../graphql";
import { useQuery } from "@triplit/react";
import { client } from "../../triplit/triplit";
import { parseRoles, parseSubscriptionTier } from "../../utils/users";
import { useLazyQuery, useQuery as useQueryApollo } from "@apollo/client";
import {
  objectKeys,
  objectFromEntries,
  objectEntries,
  includes,
} from "../../shared/utils";
import { useApi } from "../hooks/useApi";
import type { TPendingEditUser } from "../../triplit/schema";
import { useUserId } from "../../auth";

export const liveUsersSub = graphql(`
  subscription LiveUsers {
    live_users_new {
      folio_user
    }
  }
`);

export const activeUsersLast24Sub = graphql(`
  subscription ActiveUsersLast24($org: Int!) {
    users_active_last_day(where: { organisation: { _eq: $org } }) {
      folio_user
    }
  }
`);

export const UserFragment = graphql(`
  fragment UserFragment on folio_user {
    __typename
    id
    firstname
    lastname
    username
    created
    email
    roles
    blocked
    subscription_tier
    sessions(order_by: { last_seen: desc }, limit: 1) {
      id
      last_seen
      folio_user
      is_mobile
    }
    permissionCount: permissions_aggregate {
      aggregate {
        count(columns: id)
      }
    }
    broadcastPermissions: permissions_aggregate(
      where: { permission: { _eq: broadcast } }
    ) {
      aggregate {
        count(columns: id)
      }
    }
    readPermissions: permissions_aggregate(
      where: { permission: { _eq: read } }
    ) {
      aggregate {
        count(columns: id)
      }
    }
    writePermissions: permissions_aggregate(
      where: { permission: { _eq: write } }
    ) {
      aggregate {
        count(columns: id)
      }
    }

    listPermissions: permissions_aggregate(
      where: { permission: { _eq: list } }
    ) {
      aggregate {
        count(columns: id)
      }
    }
    organisationByOrganisation {
      sourceBySource {
        name
      }
    }
    created
    logout_requested_at
    licence_agreements
    userpackageByUser {
      ...PackageFragment
    }
  }
`);

export const UserDetailsFragment = graphql(`
  fragment UserDetailsFragment on folio_user {
    __typename
    id
    firstname
    lastname
    username
    email
    organisation
    roles
    subscription_tier
  }
`);

export const mutateUser = graphql(`
  mutation mutateUser($id: String!, $user: folio_user_set_input!) {
    update_folio_user_by_pk(pk_columns: { id: $id }, _set: $user) {
      ...UserFragment
    }
  }
`);

export const mutateUsersBulk = graphql(`
  mutation bulkUpdateUsers($user: folio_user_set_input!, $ids: [String!]!) {
    update_folio_user(where: { id: { _in: $ids } }, _set: $user) {
      affected_rows
      returning {
        ...UserFragment
      }
    }
  }
`);

const userTableQuery = graphql(`
  subscription UsersByOrgQuery($org: Int!) {
    folio_user(
      where: { organisation: { _eq: $org } }
      order_by: { firstname: asc, lastname: asc }
    ) {
      ...UserFragment
    }
  }
`);

const singleUserQuery = graphql(`
  subscription SingleUser($id: String!) {
    folio_user_by_pk(id: $id) {
      ...UserFragment
    }
  }
`);

const userByUserIdQuery = graphql(`
  subscription UserByUserId($id: String!) {
    folio_user_by_pk(id: $id) {
      ...UserDetailsFragment
    }
  }
`);

export const logoutUsersMutation = graphql(`
  mutation LogoutUsers($logoutUsers: [logout_request_insert_input!]!) {
    insert_logout_request(
      objects: $logoutUsers
      on_conflict: {
        constraint: logout_request_user_context_unique
        update_columns: logout_requested_at
      }
    ) {
      affected_rows
    }
  }
`);

export const createUserMutation = graphql(`
  mutation createUser(
    $organisation: Int
    $lastname: String
    $id: String!
    $firstname: String
    $username: citext
    $email: citext
    $pageSettings: String
  ) {
    insert_folio_user_one(
      object: {
        id: $id
        email: $email
        firstname: $firstname
        username: $username
        lastname: $lastname
        organisation: $organisation
        roles: ["user"]
        blocked: true
      }
    ) {
      id
    }
    insert_page_settings_one(
      object: { folio_user: $id, settings_blob: $pageSettings }
    ) {
      folio_user
    }
  }
`);

export function useSingleUser(id: string) {
  return useQuerySub({
    query: singleUserQuery,
    pause: !id,
    variables: { id },
  });
}

export function useSingleUserPermissions(id: string) {
  return useQuerySub({
    query: singleUserQuery,
    pause: !id,
    variables: { id },
  });
}

export function useSingleUserById(id: string) {
  const res = useSingleUser(id);
  return readFragment(UserFragment, res.data?.folio_user_by_pk);
}

export function useUserRoles(id: string) {
  const res = useSingleUser(id);
  const user = readFragment(UserFragment, res.data?.folio_user_by_pk);

  return parseRoles(user?.roles);
}

export function useUserByUserIdQuery(id: string) {
  return useQuerySub({
    query: userByUserIdQuery,
    pause: !id,
    variables: { id },
  });
}

export function useUserByUserId(id: string) {
  const res = useUserByUserIdQuery(id);
  const user = readFragment(UserDetailsFragment, res.data?.folio_user_by_pk);

  return { user, res };
}

export function useUser() {
  const { org, ...rest } = useParams({
    from: "/admin/orgs/$org/users/$userId",
  });

  const userId = decodeURIComponent(rest.userId ?? "");

  const res = useSingleUser(userId);
  const user = readFragment(UserFragment, res.data?.folio_user_by_pk);

  const userKeys = user && objectKeys(user);

  if (!userKeys || !user || !userId) {
    return {
      isLoading: true,
      user,
      userKeys,
      userId,
      org,
      res,
    };
  }

  return { isLoading: false, res, user, org, userId };
}

export function useUsers() {
  const orgId = useOrgId();
  const { fetching, error, data } = useQuerySub({
    query: userTableQuery,
    pause: !orgId,
    variables: { org: orgId ?? 7 },
  });

  const { results } = useQuery(client, client.query("pendingEditUser"));
  const editedData = results || [];

  const parsedEditedData = Object.fromEntries(
    editedData?.map((value) => {
      const key = value.id;
      if (typeof value === "object" && value !== null) {
        const newValue = Object.fromEntries(
          Object.entries(value)
            .map(([innerKey, innerValue]) => {
              if (!innerValue) return;

              if (innerKey === "roles") {
                const roles = parseRoles(innerValue);
                return [innerKey, Array.from(roles)];
              }
              return [innerKey, innerValue];
            })
            .filter(Boolean),
        );
        return [key, newValue];
      }
      return [key, value];
    }),
  );

  const serverData = readFragment(UserFragment, data?.folio_user ?? []);

  const groupsQuery = useQuery(client, client.query("userGroups"));
  const groups = groupsQuery.results || [];
  const userData = useMemo(() => {
    if (!serverData.length) {
      return [];
    }
    const mergedData = mergeData(serverData, parsedEditedData);

    const dataWithGroups = mergedData.map((user) => {
      const userGroups = groups
        .filter((group) => Array.from(group.users || [])?.includes(user.id))
        .map((g) => g.id)
        .filter(Boolean);

      return {
        ...user,
        roles: user.roles ? Array.from(parseRoles(user.roles)) : ["user"],
        groups: userGroups || [],
      };
    });
    return dataWithGroups;
  }, [serverData, parsedEditedData, groups]);

  return {
    orgId,
    users: {
      fetching,
      error,
      serverData,
      pendingUsers: results,
      data: userData,
    },
  };
}

export type TUserRow = ReturnType<typeof useUsers>["users"]["data"][number];

export function useLiveUsers() {
  const res = useQuerySub({ query: liveUsersSub });
  return res;
}
export function useActiveUsersLast24() {
  const orgId = useOrgId();

  const res = useQuerySub({
    query: activeUsersLast24Sub,
    variables: { org: orgId ?? 7 },
  });
  return res;
}

export type TUser = ResultOf<typeof UserFragment>;

export type TGenRolesRow = {
  id: string;
  user: Partial<TPendingEditUser>;
};

export function genRolesForServer(
  row: TGenRolesRow,
  originalRoles: Set<string>,
) {
  const newRoles = row.user.roles;
  console.log("originalRoles", originalRoles, newRoles);
  return {
    userId: row.id,
    newRoles: Array.from(newRoles ?? []),
    originalRoles: Array.from(originalRoles),
    hasChanged: !R.isDeepEqual(newRoles, originalRoles),
  } as const;
}

export type UpdateRolesAuth0Props = {
  roles: TUsersRoles[] | [];
  env: TEnv;
  auth0_url: string;
  auth0_org_id: string;
};

export async function updateUsersRolesAuth0({
  roles,
  env,
  auth0_url,
  auth0_org_id,
}: UpdateRolesAuth0Props) {
  const enabled = roles.some((r) => r.hasChanged);
  if (!enabled) return Promise.resolve([]);
  const body = {
    roles,
    env,
    auth0_url,
    orgId: auth0_org_id,
  } satisfies TUserRolesBody;

  const fetchRes = fetch("/api/update-roles", {
    method: "POST",
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      if (res.status !== 200) {
        console.error("error updating roles", await res.text());
        return Promise.reject("Error updating roles");
      }
      return await (res.json() as Promise<TServerResponse>);
    })
    .catch((e) => {
      console.error("error updating roles", e);
      return Promise.reject(e);
    });
  return await toast.promise(fetchRes, {
    loading: "Updating roles...",
    success: "Roles updated",
    error: (msg: TServerResponse<{ message: string }> | undefined) => {
      console.error("error updating roles", msg);
      const errors = msg?.filter((m) => m.error?.message);
      const errorString = errors?.map((m) => m.error?.message).join("\n");
      return `Error updating roles:\n\n${errorString}`;
    },
  });
}

export type BlockedSuccessProps = {
  userIds: string[];
  shouldBlock: boolean | undefined | null;
};

type UpdateBlockedAuth0PropsBase = {
  env: TEnv;
  auth0_url: string;
  enabled: boolean;
  onSuccess?: ({ userIds, shouldBlock }: BlockedSuccessProps) => Promise<void>;
};

type WithUserUpdateBlockedAuth0Props = {
  userIds: string[];
  shouldBlock: boolean | undefined | null;
};

type WithoutUserUpdateBlockedAuth0Props = {
  userIds: undefined;
  shouldBlock: undefined | null;
};

type UpdateBlockedAuth0Props = UpdateBlockedAuth0PropsBase &
  (WithUserUpdateBlockedAuth0Props | WithoutUserUpdateBlockedAuth0Props);

export function useSetBlocked() {
  const makeApiRequest = useApi();

  const handleSetBlocked = async ({
    userIds,
    enabled = true,
    shouldBlock,
    env,
    auth0_url,
    onSuccess,
  }: UpdateBlockedAuth0Props) => {
    if (!enabled || !userIds || shouldBlock === undefined)
      return Promise.resolve([]);

    const hasuraUrl = getHasuraUrl(env);

    const body: TBlockUserBody = {
      userIds,
      env,
      auth0_url,
      shouldBlock,
      hasuraUrl,
    };

    const action = shouldBlock ? "Blocking" : "Unblocking";
    const result = shouldBlock ? "Blocked" : "Unblocked";
    const userText = `User${userIds.length > 1 ? "s" : ""}`;

    const auth0Mutate = async (block = shouldBlock) => {
      return await makeApiRequest(
        "/api/block-user",
        "POST",
        JSON.stringify({ ...body, shouldBlock: block }),
      ).then(async (res) => {
        if (res.status !== 200) {
          const resErrors = (await res.json()) as TServerResponse;
          console.error(`error ${action}`, resErrors);
          return Promise.reject(resErrors);
        }
        await onSuccess?.({
          userIds,
          shouldBlock,
        });
        return await (res.json() as Promise<TServerResponse>);
      });
    };

    const mutateRes = auth0Mutate();

    return await toast.promise(mutateRes, {
      loading: `${action} ${userText}...`,
      success: `${userText} ${result}`,
      error: `Error ${action} ${userText}`,
    });
  };

  return {
    handleSetBlocked,
  };
}

export function usePendingUser() {
  const { org, id } = useParams({ from: "/admin/orgs/$org/users/new/$id" });

  const { results, fetching, fetchingLocal } = useQuery(
    client,
    client.query("pendingUser"),
  );

  const pendingUserForm = results?.find((user) => user.id === id);
  return {
    pendingUser: pendingUserForm,
    orgId: org,
    tempUserId: id,
    fetching,
    fetchingLocal,
  };
}

export function usePendingEditUser() {
  const params = useParams({ from: "/admin/orgs/$org/users/$userId" });
  const userId = decodeURIComponent(params.userId ?? "");
  const res = useSingleUser(userId);
  const serverUser = readFragment(UserFragment, res.data?.folio_user_by_pk);
  const { results, fetchingLocal } = useQuery(
    client,
    client.query("pendingEditUser"),
  );

  const pendingEditUser = results?.find((user) => user.id === userId);

  return useMemo(() => {
    const nonServerFields = ["updatedAt", "updatedBy", "groups", "id"] as const;

    const changedFields =
      pendingEditUser &&
      objectFromEntries(
        objectEntries(pendingEditUser).filter(([k, v]) => {
          if (k === "roles") {
            const serverRoles = parseRoles(serverUser?.roles);
            const newRoles = parseRoles(v);
            return !R.isDeepEqual(serverRoles, newRoles);
          }

          return (
            !includes(nonServerFields, k) &&
            serverUser?.[k] !== v &&
            v !== null &&
            v !== undefined
          );
        }),
      );

    return {
      pendingEditUser: pendingEditUser
        ? {
            ...pendingEditUser,
            subscription_tier: parseSubscriptionTier(
              pendingEditUser?.subscription_tier,
            ),
          }
        : undefined,
      pendingEditUserForm: {
        ...serverUser,
        ...changedFields,
        roles: parseRoles(pendingEditUser?.roles || serverUser?.roles),
        subscription_tier: parseSubscriptionTier(
          pendingEditUser?.subscription_tier || serverUser?.subscription_tier,
        ),
      },
      changedFields,
      serverUser,
      userId,
      fetching: fetchingLocal || res.fetching,
    };
  }, [pendingEditUser, serverUser, userId, fetchingLocal, res.fetching]);
}

export const userPageSettings = graphql(`
  query UserPageSettings($folio_user: String!) {
    page_settings_by_pk(folio_user: $folio_user) {
      settings_blob
    }
  }
`);

export const mutateUserPageSettings = graphql(`
  mutation UpdateUserPageSettings(
    $folio_user: String!
    $settings_blob: String!
  ) {
    update_page_settings_by_pk(
      pk_columns: { folio_user: $folio_user }
      _set: { settings_blob: $settings_blob }
    ) {
      folio_user
    }
  }
`);

const usersWithPageSettings = graphql(`
  query OrganisationPageSettings($org: Int!) {
    folio_user(
      where: {
        _and: [
          { organisation: { _eq: $org } }
          { page_settings: { settings_blob: { _is_null: false } } }
        ]
      }
      order_by: { firstname: asc }
    ) {
      id
      email
      firstname
      lastname
      username
    }
  }
`);

export function useUsersWithPageSettings() {
  const { org } = useParams({ from: "/admin/orgs/$org/users/$userId" });
  const parsedOrg = org ? Number.parseInt(org) : undefined;
  const { data, ...rest } = useQueryApollo(usersWithPageSettings, {
    skip: !parsedOrg,
    variables: { org: parsedOrg as number },
  });

  return { data: data?.folio_user, ...rest };
}

const pageSettingsToCopyFrom = graphql(`
  query PageSettingsToCopyFromTo($fromUserId: String!) {
    page_settings_by_pk(folio_user: $fromUserId) {
      folio_user
      settings_blob
    }
  }
`);

export function usePageSettingsToCopyFrom() {
  const [fetchPageSettingsToCopyFrom, { loading }] = useLazyQuery(
    pageSettingsToCopyFrom,
  );

  return {
    fetchPageSettingsToCopyFrom,
    loading,
  };
}

const shadowCurvesUser = graphql(`
  query ShadowCurvesUser($folio_user: String!) {
    folio_shadow_curves(where: { folio_user: { _eq: $folio_user } }) {
      folio_user
      state
    }
  }
`);

export function useShadowCurvesUser(userId: string | undefined) {
  const { data, ...rest } = useQueryApollo(shadowCurvesUser, {
    skip: !userId,
    variables: { folio_user: userId as string },
  });

  const state = data?.folio_shadow_curves[0]?.state;

  return { data: state, ...rest };
}

export function useMyPageSettings() {
  const userId = useUserId();
  const { data, loading, error } = useQueryApollo(userPageSettings, {
    skip: !userId,
    variables: { folio_user: userId as string },
  });

  const {
    data: shadows,
    loading: shadowLoading,
    error: shadowError,
  } = useShadowCurvesUser(userId);

  return {
    data: {
      ...data?.page_settings_by_pk,
      shadowCurves: shadows,
    },
    loading: loading || shadowLoading,
    error: error || shadowError,
  };
}
