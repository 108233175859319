import type {
  ColDef,
  EditableCallbackParams,
  GetMainMenuItems,
  ITooltipParams,
  ICellRendererParams,
} from "ag-grid-community";
import { isNullish } from "remeda";
import type { TCell, TData } from "../calculations-worker/sharedStores";
import type { useProductsByIds } from "../curve-management";
import { columnWidth } from "../globals";
import type { useCurrentPageProducts } from "../market-pages/pageProductHooks";
import { formatGridNumber, parse } from "../numbers";
import { isListOnlyPermissions } from "../utils";
import type { TTooltipCell, TColumnHeaderProps } from "./AgGridComponents";
import { findRangeSelectionCell, processCellStyle } from "./classRules";
import {
  formatAdhocText,
  formatMonthColumnText,
  monthTimespreadValueGetter,
  timespreadData,
} from "./periodHelpers";
import type { TStatusMap } from "../../triplit/schema";
import { throttle } from "../../utils";
import { store } from "../sharedHooks";
import { adhocSpreadsAtom } from "../grid-settings/atomStore";
import { suppressKeyboardEvent } from "./keyboardShortcuts";
import { isDev } from "../../globals";

export const curveColumnStack = [
  {
    label: "Parent",
    name: "commodityParentGroup",
    defaultVisible: true,
    groupable: true,
    required: false,
    hoverPosition: null,
    showOnMobile: false,
  },
  {
    label: "Region",
    name: "geographicalRegion",
    hoverPosition: 4,
    defaultVisible: true,
    groupable: true,
    required: false,
    showOnMobile: false,
  },
  {
    label: "Commodity",
    name: "commodityGroup",
    hoverPosition: 3,
    defaultVisible: true,
    groupable: true,
    required: false,
    showOnMobile: false,
  },
  {
    label: "Package",
    hoverPosition: 2,
    name: "package",
    required: false,
    defaultVisible: false,
    showOnMobile: false,
    groupable: false,
  },
  {
    name: "name",
    label: "Display Name",
    showOnMobile: true,
    required: true,
    groupable: false,
    defaultVisible: false,
    hoverPosition: null,
  },
  {
    label: "Curve Name",
    name: "description",
    hoverPosition: 1,
    required: false,
    defaultVisible: false,
    showOnMobile: false,
    groupable: false,
  },
  {
    label: "Source",
    name: "source",
    hoverPosition: 5,
    required: false,
    defaultVisible: false,
    showOnMobile: false,
    groupable: false,
  },
  {
    label: "Field Name",
    name: "fieldName",
    required: false,
    defaultVisible: false,
    hoverPosition: null,
    showOnMobile: false,
    groupable: false,
  },
  {
    label: "Price UOM",
    showOnMobile: true,
    name: "uom",
    defaultVisible: true,
    required: true,
    hoverPosition: null,
    groupable: false,
  },
] as const;

export type CurveColumnStackName = (typeof curveColumnStack)[number]["name"];

type RequiredCurveColumnStack = Exclude<
  (typeof curveColumnStack)[number],
  { required: false }
>;

type OptionalCurveColumnStack = Exclude<
  (typeof curveColumnStack)[number],
  { required: true }
>;

export const requiredColumnStack = curveColumnStack.filter(
  (column): column is RequiredCurveColumnStack => {
    return column.required;
  },
);

export const potentiallyInvisibleColumns = curveColumnStack.filter(
  (column): column is OptionalCurveColumnStack => {
    return !column.required;
  },
);

export const mainMonthColumnId = "period";

// If a field is provided, it's a shadow column.
// width only is used for the main column period
function monthColumnDefs({
  field,
  width,
}: { field?: string; width?: number } = {}) {
  return {
    field: mainMonthColumnId,
    headerComponentParams: {
      gridId: field,
    },
    headerName: "Month",
    width: width ? width : 70,
    minWidth: 70,
    lockPinned: !field,
    pinned: field ? undefined : "left",
    lockPosition: !field,
    headerComponent: "agColumnMonths",
    resizable: true,
    suppressNavigable: true,
    suppressHeaderContextMenu: true,
    suppressHeaderMenuButton: true,
    valueFormatter: (params) => {
      if (params.data?.rowType === "adhoc") {
        const spread = store
          .get(adhocSpreadsAtom)
          ?.find((s) => s.rowId === params?.data?.id);

        return formatAdhocText({
          from: spread?.from?.periodValue,
          to: spread?.to?.periodValue,
        });
      }

      const str = params.value?.toString();
      return formatMonthColumnText(str);
    },
    tooltipValueGetter: (params) => {
      const code = params.node?.data?.code;
      if (isDev) {
        return `${code} / M${params.node?.rowIndex}`;
      }
      if (code) {
        return code;
      }
    },
    cellStyle: field
      ? processCellStyle
      : (params) => {
          const api = params?.api;
          if (!api) return null;

          const columns = api.getColumns();
          if (!columns) return null;

          const cellIndex = params.node.rowIndex;

          const rangeSelection = api.getCellRanges();

          const inRange = rangeSelection?.some((range) => {
            const start = range?.startRow?.rowIndex;
            const end = range?.endRow?.rowIndex;
            if (!start || !end || !cellIndex) return false;
            return cellIndex >= start && cellIndex <= end;
          });

          const isAdhocSpread = params.data?.rowType === "adhoc";

          const borderRight = inRange
            ? "4px solid var(--indicatorColour)"
            : "var(--ag-borders-critical) var(--ag-border-color)";

          const fontSize = isAdhocSpread ? 10 : "inherit";

          return {
            borderRight,
            fontSize,
          };
        },
  } satisfies ColDef<TData, TCell>;
}

function monthTimespreadColumnDefs({
  field,
  width,
}: {
  field?: string;
  width?: number;
} = {}) {
  return {
    field: "month-timespread",
    headerComponentParams: {
      gridId: field,
      shadowType: "month-timespread",
    },
    headerName: "Spread",
    width: field && width ? width : 70,
    cellStyle: processCellStyle,
    lockPinned: false,
    lockPosition: false,
    resizable: true,
    headerComponent: "agColumnMonthTimespread",
    valueGetter: (params) => {
      if (timespreadData) {
        return monthTimespreadValueGetter(params, timespreadData);
      }
    },
    suppressNavigable: true,
    suppressHeaderContextMenu: true,
    suppressHeaderMenuButton: true,
  } satisfies ColDef;
}

function blankColumnDefs({
  field,
  width,
}: { field?: string; width?: number } = {}) {
  return {
    field: "blank",
    headerName: "",
    headerComponentParams: {
      gridId: field,
      shadowType: "blank",
    },
    cellClass: "blank-cell",
    valueGetter: () => null,
    width: field && width ? width : 70,
    lockPinned: false,
    lockPosition: false,
    resizable: true,
    headerComponent: null,
    enableValue: false,
    suppressNavigable: true,
    suppressHeaderContextMenu: true,
    suppressHeaderMenuButton: true,
  } satisfies ColDef;
}

export const defaultColumnSettings = {
  decimalPlaces: 2,
  thousandsSeparator: false,
  columnWidth,
  increment: 0.25,
};

export function validColumnSettings({
  decimalPlaces,
  thousandsSeparator,
  columnWidth,
  increment,
}: {
  decimalPlaces?: number | null;
  thousandsSeparator?: boolean | null;
  columnWidth?: number | null;
  increment?: number | null;
}) {
  return {
    decimalPlaces: isNullish(decimalPlaces)
      ? defaultColumnSettings.decimalPlaces
      : decimalPlaces,
    thousandsSeparator: isNullish(thousandsSeparator)
      ? defaultColumnSettings.thousandsSeparator
      : thousandsSeparator,
    columnWidth: isNullish(columnWidth)
      ? defaultColumnSettings.columnWidth
      : columnWidth,
    increment: isNullish(increment)
      ? defaultColumnSettings.increment
      : increment,
  };
}

export const sharedCellOffsetsArr: Record<string, string[]> = {};

export function genColumnDefs({
  currentPageProducts,
  products,
  mainMenuItems,
  mainMonthColumnWidth,
  statusMap,
  flashCellUpdates,
}: {
  currentPageProducts: ReturnType<typeof useCurrentPageProducts>["results"];
  products: ReturnType<typeof useProductsByIds>["data"];
  mainMenuItems: GetMainMenuItems;
  mainMonthColumnWidth: number | undefined;
  statusMap: TStatusMap;
  flashCellUpdates: boolean;
}) {
  const orderedProducts = currentPageProducts?.sort((a, b) => {
    const aIdx = a.idx || "0";
    const bIdx = b.idx || "0";
    if (aIdx === bIdx) return 0;
    return aIdx < bIdx ? -1 : 1;
  });
  const productColDefs: ColDef<TData, TCell>[] = orderedProducts
    ?.map((pageProduct) => {
      const {
        id,
        columnFieldSelector,
        productId,
        columnType,
        columnWidth,
        thousandsSeparator: thousandsSeparatorSetting,
        decimalPlaces: decimalPlacesSetting,
        idx,
      } = pageProduct;

      // Shadow columns.
      if (columnType !== "product") {
        switch (columnType) {
          case "month":
            return monthColumnDefs({ field: id, width: columnWidth });
          case "month-timespread":
            return monthTimespreadColumnDefs({
              field: id,
              width: columnWidth,
            });
          case "blank":
            return blankColumnDefs({ field: id, width: columnWidth });
        }
      }

      const product = products?.find((p) => p.id === productId);
      const permissions = product?.packageByPackage.permissions;

      const sharedCellOffsets = product?.has_shared_cell
        ? product.product_configs
            .map((config) =>
              config.formula === null ? config.relative_month.toString() : null,
            )
            .filter(Boolean)
        : [];

      if (product?.id) sharedCellOffsetsArr[product.id] = sharedCellOffsets;

      const isListOnly = isListOnlyPermissions(permissions);

      const status = productId ? statusMap?.[productId] || "listen" : "listen";

      const isEditable = (
        params:
          | EditableCallbackParams<TData, TCell>
          | ITooltipParams<TData, TCell, unknown>,
        customStatusCheck = false,
      ) => {
        const rowIndex = params?.node?.rowIndex?.toString();

        const editable =
          params?.data?.rowType === "mth" &&
          sharedCellOffsets &&
          rowIndex &&
          (status === "broadcast" ||
            status === "hybrid_broadcast" ||
            status === "private" ||
            customStatusCheck)
            ? sharedCellOffsets.includes(rowIndex)
            : false;
        return editable;
      };

      // Define the tooltip value getter function
      const tooltipValueGetter = (params: ITooltipParams) =>
        ({
          status,
          productId,
          isEditable: Boolean(
            params.data?.rowType === "mth" && product?.has_shared_cell,
          ),
        }) satisfies TTooltipCell;

      // Use the throttled version of the tooltip value getter function
      const throttledTooltipValueGetter = throttle(tooltipValueGetter, 1000);

      return {
        field: id,
        headerName: product?.name,
        suppressFillHandle: !product?.has_shared_cell,
        ...(!isListOnly &&
          permissions && {
            tooltipComponent: "agTooltipProduct",
            // params for header tooltip
            headerTooltip: productId,
            // params for row cell tooltip
            tooltipValueGetter: throttledTooltipValueGetter,
            editable: isEditable,
          }),
        cellEditor: "agNumberCellEditor",
        headerComponent: "agColumnHeaderProducts",
        cellRendererSelector: (params: ICellRendererParams) => {
          const productId = params.colDef?.headerComponentParams.productId;
          const sharedCells = sharedCellOffsetsArr[productId];
          const rowIndex = params.node.rowIndex?.toString();
          if (
            flashCellUpdates &&
            rowIndex &&
            productId &&
            sharedCells?.includes(rowIndex)
          ) {
            return {
              component: "agAnimateShowChangeCellRenderer",
            };
          }
          return undefined;
        },
        // anything changing in header params (local or remote changes),
        // will lead to the column headers being re-rendered.
        headerComponentParams: {
          gridId: id,
          productId,
          settings: validColumnSettings({
            thousandsSeparator: thousandsSeparatorSetting,
            decimalPlaces: decimalPlacesSetting,
          }),

          status: status || "listen",
          artisType: product?.artis_type,
          sharedCellOffsets,
          hasSharedCell: Boolean(product?.has_shared_cell),
          permissions,
          exchange: product?.packageByPackage.sourceBySource?.exchange?.code,
          maturity: product?.maturity,
          columnLabels: {
            commodityParentGroup:
              product?.commodityGroupByCommodityGroup?.commodity_parent_group
                ?.commodity_parent_group,
            commodityGroup: product?.commodity_group,
            geographicalRegion: product?.geographical_region,
            package: product?.packageByPackage?.name,
            uom: product?.uom,
          },
          hasFormula:
            (
              product?.product_configs
                ?.map((config) => config?.formula)
                .filter(Boolean) || []
            )?.length > 0,
        } satisfies TColumnHeaderProps,
        suppressHeaderMenuButton: true,
        suppressKeyboardEvent,
        mainMenuItems,
        filter: false,
        sortable: false,
        cellClass: "ag-right-aligned-cell",
        cellStyle: (params) => processCellStyle(params, product),
        cellClassRules: {
          rangeSelectionGroupTwo: (params) => findRangeSelectionCell(params, 1),
        },
        valueFormatter: (params) => {
          const { thousandsSeparator, decimalPlaces } = validColumnSettings({
            thousandsSeparator: thousandsSeparatorSetting,
            decimalPlaces: decimalPlacesSetting,
          });

          const cellValue = params.value;

          if (cellValue === null || cellValue === undefined) {
            return "";
          }
          if (columnFieldSelector === "lasttime") {
            const date = new Date(cellValue);
            const formattedTime = date.toTimeString().split(" ")[0];
            return formattedTime;
          }

          if (typeof cellValue === "string") return cellValue;

          const number = parse(cellValue);
          if (number === null) return "";

          const formatted = formatGridNumber(
            number,
            decimalPlaces,
            thousandsSeparator,
          );

          return formatted;
        },

        width: columnWidth || 150,
      } satisfies ColDef<TData, TCell>;
    })
    .filter(Boolean) as ColDef<TData, TCell>[];

  return currentPageProducts
    ? [monthColumnDefs({ width: mainMonthColumnWidth }), ...productColDefs]
    : [];
}
