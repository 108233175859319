import { Checkbox } from "@mui/joy";

export default function ({
  checked,
  onChange,
  label,
}: {
  checked: boolean | null | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}) {
  return (
    <Checkbox
      color="neutral"
      label={label}
      checked={Boolean(checked)}
      onChange={onChange}
      sx={{
        py: 1,
        flexDirection: "row-reverse",
        color: "text.secondary",
        maxWidth: "175px",
      }}
    />
  );
}
