import type { GridApi, IMenuActionParams } from "ag-grid-community";
import { getCurrentCellValue, type TRangeCell } from "../../tableUtils";
import html2canvas from "html2canvas";
import {
  formatMonthColumnText,
  monthCodeToShortMonthAndYear,
} from "./periodHelpers";
import toast from "react-hot-toast";

function getColWidth(cell: TRangeCell, api: GridApi) {
  const column = api.getColumn(cell.columnId);
  return (column?.getActualWidth() || 70) + 10;
}

const createDiv = (
  styles: Partial<CSSStyleDeclaration>,
  innerText?: string,
): HTMLDivElement => {
  const div = document.createElement("div");
  Object.assign(div.style, styles);
  if (innerText) div.innerText = innerText;
  return div;
};

const copyImageToClipboard = async (dataURL: string): Promise<void> => {
  try {
    const imgBlob = await (await fetch(dataURL)).blob();
    await navigator.clipboard.write([
      new ClipboardItem({ "image/png": imgBlob }),
    ]);
  } catch (error) {
    console.error("Failed to copy image to clipboard:", error);
  }
};

type TCell = TRangeCell & {
  headerName: string;
  period: string;
};

export const copyAsPng = async (
  rangeSelection: TRangeCell[],
  params: IMenuActionParams,
  toastId: string,
): Promise<void> => {
  if (rangeSelection.length === 0) {
    console.error("No cells selected");
    return;
  }

  const rows = rangeSelection.reduce<{ [key: string]: TCell[] }>(
    (acc, cell) => {
      const rowId = cell.rowId || "";
      const period = formatMonthColumnText(monthCodeToShortMonthAndYear(rowId));
      const colDef = params.api.getColumnDef(cell.columnId);
      const name = colDef?.headerName;
      if (!name) return acc;
      acc[rowId] = acc[rowId] ?? [];
      acc[rowId].push({
        ...cell,
        headerName: name,
        period,
      });
      return acc;
    },
    {},
  );
  const firstRow = rows[Object.keys(rows)[0]];
  if (!firstRow) return;

  const container = createDiv({
    position: "absolute",
    top: "0",
    left: "0",
    backgroundColor: "#fff",
    padding: "10px",
    zIndex: "-1",
  });

  const headerRowDiv = createDiv({
    display: "flex",
    border: "1px solid #ddd",
  });
  const imageDiv = document.getElementById("artisLogoSmall");
  if (!imageDiv) throw new Error("artis logo not found");
  const imageDivClone = imageDiv.cloneNode(true) as SVGElement;
  imageDivClone.style.width = "75px";

  const monthColStyles = {
    width: "95px",
    borderRight: "1px solid #ddd",
    marginLeft: "5px",
    display: "flex",
    alignItems: "center",
  };

  const imageContainer = createDiv(monthColStyles);

  imageContainer.appendChild(imageDivClone);
  headerRowDiv.appendChild(imageContainer);
  for (const cell of firstRow) {
    const width = getColWidth(cell, params.api);
    headerRowDiv.appendChild(
      createDiv(
        {
          width: `${width}px`,
          fontWeight: "bold",
          paddingTop: "22px",
          textAlign: "center",
          borderRight: "1px solid #ddd",
        },
        cell.headerName,
      ),
    );
  }

  container.appendChild(headerRowDiv);
  Object.entries(rows).forEach(([, cells], rowIndex) => {
    const rowDiv = createDiv({
      display: "flex",
      border: "1px solid #ddd",
      backgroundColor: rowIndex % 2 === 1 ? "#f0f0f0" : "",
    });
    rowDiv.appendChild(createDiv(monthColStyles, cells[0].period));

    for (const cell of cells) {
      const cellDiv = document.createElement("div");
      const width = getColWidth(cell, params.api);
      cellDiv.style.width = `${width}px`;
      cellDiv.style.textAlign = "right";
      cellDiv.style.paddingRight = "15px";
      let cellValue = getCurrentCellValue(cell, params.api);
      const colDef = params.api.getColumnDef(cell.columnId);
      const rowNode = params.api.getRowNode(cell.rowId);
      if (colDef && rowNode && typeof colDef.valueFormatter === "function") {
        cellValue = colDef.valueFormatter({
          value: cellValue,
          data: rowNode.data,
          node: rowNode,
          colDef,
          // this stuff is just to keep ts happy and isn't actually used by valueFormatter
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          column: params.column!,
          api: params.api,
          context: params.context,
        });
      }
      cellDiv.innerText = cellValue?.toString() || "";
      rowDiv.appendChild(
        createDiv(
          {
            width: `${width}px`,
            textAlign: "left",
            paddingLeft: "5px",
          },
          cellValue?.toString() || "",
        ),
      );
    }

    container.appendChild(rowDiv);
  });

  document.body.appendChild(container);

  await new Promise((resolve) => setTimeout(resolve, 100));
  try {
    const canvas = await html2canvas(container, { useCORS: true });
    const dataURL = canvas.toDataURL("image/png");
    await copyImageToClipboard(dataURL);
    toast.success("Copied!", { id: toastId });
  } catch (err) {
    toast.error("Copy as PNG failed", { id: toastId });
    console.error("Error capturing the element:", err);
  } finally {
    document.body.removeChild(container);
  }
};
