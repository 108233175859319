import type { GridApi } from "ag-grid-community";
import { atom, getDefaultStore, useAtom } from "jotai";
import { useCallback, useEffect, useMemo } from "react";
import type { TRangeCell } from "../tableUtils";
import { useNetworkState } from "@react-hookz/web";
import type { TData } from "./calculations-worker/sharedStores";
import { useMatchRoute } from "@tanstack/react-router";
import { type TLoadingState, defaultLoadingState } from "./globals";
import type { TPageSettings } from "../triplit/schema";
import { calcWorker } from "./calculations-worker/hooks";

export const store = getDefaultStore();

export const logoutRequestAtom = atom(false);

export type TAddProductsModal = {
  modalId: "add-products";
  pageId: string;
};

export type TModalState = TAddProductsModal | null;

export type ModalIds = NonNullable<TModalState>["modalId"];

export const modalAtom = atom<TModalState>(null);

export function useModal() {
  return useAtom(modalAtom);
}

type TLoadingStateRoute = keyof typeof defaultLoadingState;

type TLoadingStateKey<K extends TLoadingStateRoute> =
  keyof (typeof defaultLoadingState)[K];

export const loadingStateAtom = atom<TLoadingState>(defaultLoadingState);
loadingStateAtom.debugLabel = "loadingStateAtom";

export function useLoadingState() {
  const matchRoute = useMatchRoute();
  const isMarketRoute = matchRoute({ to: "/app/market", fuzzy: true });
  const isTradingChartRoute = matchRoute({
    to: "/app/trading-chart",
    fuzzy: true,
  });

  const [loadingState, setLoadingState] = useAtom(loadingStateAtom);

  const route = useMemo(() => {
    if (isMarketRoute) {
      return "market";
    }

    if (isTradingChartRoute) {
      return "tradingChart";
    }
  }, [isMarketRoute, isTradingChartRoute]);

  const allIsLoading = Object.values(loadingState.all).some((state) => {
    return state === "loading";
  });

  const routeIsLoading =
    route &&
    Object.values(loadingState[route]).some((state) => {
      return state === "loading";
    });

  const isLoading = useMemo(() => {
    return allIsLoading || routeIsLoading;
  }, [allIsLoading, routeIsLoading]);

  const setLoaded = useCallback(
    <R extends TLoadingStateRoute>(route: R, key: TLoadingStateKey<R>) => {
      if (loadingState?.[route]?.[key as string] === "loaded") return;

      // since this sets state we need to ensure the component using it has been mounted
      // not actually sure how to do that properly but a timeout works and has very little overhead
      setTimeout(() => {
        setLoadingState((prev) => {
          prev[route][key as string] = "loaded";
          return { ...prev };
        });
      }, 2000);
    },
    [loadingState, setLoadingState],
  );

  return {
    isLoading,
    loadingState,
    setLoadingState,
    setLoaded,
  };
}

export const pageSettingsAtom = atom<TPageSettings[] | null>(null);
pageSettingsAtom.debugLabel = "pageSettingsAtom";

export type TRangeSelection = TRangeCell[][];
export const rangeSelectionAtom = atom<TRangeSelection>([]);
rangeSelectionAtom.debugLabel = "rangeSelectionAtom";

export function useRangeSelection() {
  return useAtom(rangeSelectionAtom);
}

export const pressedKeysAtom = atom(new Set<string>());
pressedKeysAtom.debugLabel = "pressedKeysAtom";

const eventsToRegister = new Set<string>(["Meta", "Control", "Alt"]);

export function usePressedKeys() {
  const [pressedKeys, setPressedKeys] = useAtom(pressedKeysAtom);

  const addRemoveKey = useMemo(() => {
    return {
      add: (key: string) => {
        if (!eventsToRegister.has(key)) {
          return;
        }
        setPressedKeys((prev) => {
          const newSet = new Set(prev);
          newSet.add(key);
          return newSet;
        });
      },
      delete: (key: string) => {
        if (!eventsToRegister.has(key)) {
          return;
        }
        setPressedKeys((prev) => {
          const newSet = new Set(prev);
          newSet.delete(key);
          return newSet;
        });
      },
    };
  }, [setPressedKeys]);

  return [pressedKeys, addRemoveKey] as const;
}

export function refreshTargetCells(rowIds: string[], api?: GridApi<TData>) {
  if (!api || api.isDestroyed()) {
    return;
  }
  const rowNodes = rowIds.map((id) => api.getRowNode(id)).filter(Boolean);
  api.refreshCells({ force: true, rowNodes });
}
const root = document.documentElement;

export function useCssVar() {
  const callback = useCallback((name: string, value: string) => {
    root.style.setProperty(name, value);
  }, []);
  return callback;
}

export function useNetworkStatusListener() {
  const networkStatus = useNetworkState();
  const worker = calcWorker?.().proxy;
  useEffect(() => {
    if (!worker) return;
    console.log("network status effect");
    worker.networkStatusChange(networkStatus);
  }, [worker, networkStatus]);
}
