import styled from "@emotion/styled";
import { useMatchRoute } from "@tanstack/react-router";
import { Box, Button, Typography } from "@mui/joy";
import { loadingZIndex } from "../globals";
import { useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { loadingStateAtom } from "../sharedHooks";
import { ArtisLogo } from "../../images/ArtisLogo";
import { useLogout } from "../../auth/hooks";

const CustomLoader = styled.div`
  .lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #ff7400;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-of-type(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-of-type(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-of-type(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%,
    100% {
      top: 24px;
      height: 32px;
    }
  }

  .loading-logo {
    width: 100%;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    & svg {
      width: 200px;
    }
  }
`;

export function LoadingOverlay(mode: "light" | "dark" | "system" | undefined) {
  const isDark = mode === "dark";

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CustomLoader
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="lds-facebook">
          <div />
          <div />
          <div />
        </div>
        <div className="loading-logo">
          <ArtisLogo mode={isDark ? "dark" : "light"} />
        </div>
      </CustomLoader>
    </Box>
  );
}

export default function Loading({
  showLogo,
  syncing,
  workerLoading,
  mode,
}: {
  showLogo?: boolean;
  syncing: boolean;
  workerLoading?: boolean;
  mode?: "light" | "dark" | "system" | undefined;
}) {
  const [tooLong, setTooLong] = useState(false);
  const loadingState = useAtomValue(loadingStateAtom);
  const isDark = mode === "dark";
  const matchRoute = useMatchRoute();
  const isTradingChartRoute = matchRoute({
    to: "/app/trading-chart",
    fuzzy: true,
  });

  const isSyncing = isTradingChartRoute ? false : syncing;

  useEffect(() => {
    // If the loading screen is up for more than 10 seconds, something could be wrong. Show an error message if that happens.
    const timeout = setTimeout(() => {
      setTooLong(true);
      console.warn(
        "Loading screen has been up for more than 10 seconds.",
        loadingState,
        `isSyncing: ${isSyncing}, workerLoading: ${workerLoading}`,
      );
    }, 15_000);

    return () => clearTimeout(timeout);
  }, [loadingState, isSyncing, workerLoading]);

  const logout = useLogout();

  return (
    <Box
      id="initial-loading-screen"
      sx={{
        width: "100dvw",
        height: "100dvh",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: loadingZIndex,
        backgroundColor: isDark ? "black" : "white",
      }}
    >
      <CustomLoader
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="lds-facebook">
          <div />
          <div />
          <div />
        </div>
        {showLogo && (
          <div className="loading-logo">
            <ArtisLogo mode={isDark ? "dark" : "light"} />
          </div>
        )}
        {tooLong && (
          <Box
            sx={{
              position: "absolute",
              bottom: "16%",
            }}
          >
            <Typography>
              This is taking longer than it should. Please refresh, log out and
              log in again, or contact Support.
            </Typography>
            <Button
              color="neutral"
              onClick={() => {
                logout();
              }}
            >
              Logout
            </Button>
          </Box>
        )}
        {import.meta.env.MODE === "development" && (
          <Typography>
            <br />
            <b>Development Mode:</b>{" "}
            {JSON.stringify({ ...loadingState, isSyncing, workerLoading })}
          </Typography>
        )}
      </CustomLoader>
    </Box>
  );
}
