import { Box, Divider } from "@mui/joy";
import { useCallback, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { sortAlphabeticallyEarlyMatch } from "../../shared/utils";
import {
  type LinkEntry,
  OrgSearchInput,
  SidebarToggableItemWithIcon,
} from "./Components";
import { useOrgs } from "../orgs";
import { setStarredOrgs, useStarredOrgs } from "./hooks";
import { useAuth0 } from "@auth0/auth0-react";

function getOrgLists(
  orgs: LinkEntry[],
  starredOrgIds: string[] | undefined,
  query: string,
) {
  const reduceInitValue: { starredOrgs: LinkEntry[]; otherOrgs: LinkEntry[] } =
    {
      starredOrgs: [],
      otherOrgs: [],
    };

  const { starredOrgs, otherOrgs } = orgs.reduce((lists, org) => {
    if (starredOrgIds?.includes(org.id)) {
      lists.starredOrgs.push(org);
    } else {
      lists.otherOrgs.push(org);
    }
    return lists;
  }, reduceInitValue);

  const filterAndSort = (list: LinkEntry[]) =>
    list
      .filter((org) => org.label.toLowerCase().includes(query.toLowerCase()))
      .sort((a, b) => sortAlphabeticallyEarlyMatch(a.label, b.label, query));

  return {
    starredOrgs: filterAndSort(starredOrgs),
    otherOrgs: filterAndSort(otherOrgs),
  };
}

function useStarredItems(orgs: LinkEntry[]) {
  const userId = useAuth0().user?.sub || "";
  const [query, setQuery] = useState<string>("");
  const { starredOrgs: starredOrgsArray, fetching } = useStarredOrgs();

  const handleStarred = useCallback(
    (orgId: string) => {
      const newStarredOrgs = starredOrgsArray.includes(orgId)
        ? starredOrgsArray.filter((id) => id !== orgId)
        : [...starredOrgsArray, orgId];

      setStarredOrgs(userId, newStarredOrgs);
    },
    [starredOrgsArray, userId],
  );

  const { starredOrgs, otherOrgs } = getOrgLists(orgs, starredOrgsArray, query);

  return { starredOrgs, fetching, otherOrgs, setQuery, handleStarred };
}

export function OrgSearchItems({ handleClick }: { handleClick: () => void }) {
  const orgs = useOrgs();
  const orgLinks =
    orgs?.data?.organisation.map(
      (org) =>
        ({
          id: org.id.toString(),
          to: "/admin/orgs/$org/users",
          params: { org: org.id.toString() },
          label: org.sourceBySource.name,
        }) satisfies LinkEntry,
    ) || [];

  const { starredOrgs, otherOrgs, setQuery, handleStarred } = useStarredItems(
    orgLinks || [],
  );

  return (
    <ErrorBoundary fallback={<div>error</div>}>
      <OrgSearchInput setQuery={(e) => setQuery(e.target.value)} />
      <Box
        sx={{
          mt: 2,
          overflowY: "auto",
          gap: 0.4,
          display: "flex",
          flexDirection: "column",
          maxHeight: 200,
        }}
      >
        {starredOrgs.map(({ label, to, params, id }) => (
          <SidebarToggableItemWithIcon
            id={id}
            key={label}
            to={to}
            params={params}
            handleStarred={() => handleStarred(id)}
            handleClick={handleClick}
            label={label}
            starred
          />
        ))}
      </Box>

      {starredOrgs.length && otherOrgs.length ? (
        <Divider
          sx={{ borderColor: (theme) => theme.palette.neutral[700], margin: 2 }}
        />
      ) : (
        <></>
      )}
      <Box
        sx={{
          maxHeight: 200,
          gap: 0.4,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        {otherOrgs.map(({ label, to, params, id }) => (
          <SidebarToggableItemWithIcon
            id={id}
            key={label}
            to={to}
            params={params}
            handleStarred={() => handleStarred(id)}
            handleClick={handleClick}
            label={label}
          />
        ))}
      </Box>
    </ErrorBoundary>
  );
}
