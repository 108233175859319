import { useSearch, useNavigate, useMatchRoute } from "@tanstack/react-router";
import { type TEnv, envSchema } from "./globals";

export function safeEnv(env: unknown): TEnv {
  const envString = env ?? "dev";
  const parseResult = envSchema.safeParse(envString);
  if (!parseResult.success) {
    console.error(parseResult.error);
  }
  return parseResult.success ? parseResult.data : "dev";
}

export const environments = [
  { label: "DEV", value: "dev", initials: "DEV" },
  { label: "UAT", value: "uat", initials: "UAT" },
  { label: "PROD", value: "global-Prod", initials: "PROD" },
  { label: "TPICAP-PROD", value: "TPICAP-Prod", initials: "TP" },
];

export function useEnv() {
  const search = useSearch({ strict: false });
  const e = safeEnv(search.env);
  const navigate = useNavigate();
  const matchRoute = useMatchRoute();

  const setEnv = (env: TEnv) => {
    const isOrgRoute = matchRoute({
      fuzzy: true,
      to: "/admin/orgs",
    });

    if (!isOrgRoute) {
      navigate({
        to: ".",
        search: { env },
        replace: false,
      });
    } else {
      navigate({
        search: { env },
        from: "/admin/orgs",
      });
    }
  };

  return [e, setEnv] as const;
}
