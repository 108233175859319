function Icon({ selected }: { selected: boolean }) {
  return (
    <svg
      className={selected ? "nav-icon selected" : "nav-icon"}
      width="42"
      height="42"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{selected ? "Manage Pages Selected" : "Manage Pages"}</title>
      <rect
        className="background"
        opacity="0.15"
        width="42"
        height="42"
        rx="2"
        fill="white"
      />
      <g clipPath="url(#clip0_206_1166)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0002 9C9.89567 9 9.00024 9.89543 9.00024 11V23.5817L11.0002 22.0191V11H27.0002V26H15.6506L13.0907 28H27.0002C28.1048 28 29.0002 27.1046 29.0002 26V11C29.0002 9.89543 28.1048 9 27.0002 9H11.0002Z"
          fill="white"
        />
        <rect x="13" y="13" width="5" height="2" fill="white" />
        <rect x="20" y="13" width="5" height="2" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.0176 24H25.0001V22H22.2055C21.9792 22.7649 21.5655 23.4492 21.0176 24Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 16.5582C20.6069 16.9193 21.1255 17.4133 21.5159 18H25V16H20V16.5582Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2831 21C22.2942 20.8681 22.2999 20.7347 22.2999 20.6C22.2999 20.0382 22.2013 19.4994 22.0205 19H25.0001V21H22.2831Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6309 16H13V18H13.6839C14.3549 16.9915 15.4051 16.2569 16.6309 16Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4075 17.8967C16.5077 17.0371 17.9609 16.9393 19.1316 17.5254L16.2449 19.7807L18.0919 22.1448L20.9784 19.8895C21.2637 21.1671 20.8172 22.5533 19.7171 23.4128C18.6168 24.2724 17.1636 24.3702 15.993 23.7841L9.02958 29.2245C8.37677 29.7346 7.43411 29.6188 6.92407 28.966C6.41404 28.3132 6.52979 27.3705 7.1826 26.8605L14.1461 21.42C13.8608 20.1424 14.3073 18.7563 15.4075 17.8967Z"
          className="orange"
        />
      </g>
      <defs>
        <clipPath id="clip0_206_1166">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(5 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ManagePagesIcon({ selected = false }: { selected?: boolean }) {
  return <Icon selected={selected} />;
}
