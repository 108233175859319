import { useSetAtom } from "jotai";
import { useEntity } from "@triplit/react";
import { useEffect } from "react";
import { client } from "../../../triplit/triplit";
import { adhocSpreadsAtom } from "../../grid-settings/atomStore";
import { parseAdhocSpreadsJSON } from "./timespreadHelpers";
import { usePageId } from "../../market-pages";

export function useAdhocSpreads() {
  const pageId = usePageId();
  const setAdhocSpreads = useSetAtom(adhocSpreadsAtom);
  const { result: page, fetchingLocal: fetching } = useEntity(
    client,
    "pages",
    pageId || "",
    {
      localOnly: true,
    },
  );

  const adhocSpreads = parseAdhocSpreadsJSON(page?.adhocSpreadsJSON);

  const formatted: Record<
    string,
    Omit<(typeof adhocSpreads)[number], "rowId">
  > = {};

  useEffect(() => {
    if (page) {
      adhocSpreads.map((spread) => {
        formatted[spread.rowId] = {
          from: spread.from,
          to: spread.to,
        };
      });

      setAdhocSpreads(adhocSpreads || []);
    }
  }, [page, setAdhocSpreads, adhocSpreads]);

  return {
    fetching,
    adhocSpreads,
    formattedAdhocSpreads: formatted,
  };
}
