import type { ISeriesApi } from "lightweight-charts";
import {
  type ForwardedRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from "react";
import { createLazyValue } from "../../internal/lazy-value";
import {
  type SeriesActionParams,
  type SeriesActionResult,
  series,
} from "../../internal/series";
import { ChartContext } from "./chart-context";

export function createSeriesHook<T extends SeriesActionParams>(
  type: T["type"],
) {
  return (
    props: Omit<T, "type"> & {
      onSeriesReady?: (api: ISeriesApi<T["type"]>) => void;
    },
    ref: ForwardedRef<ISeriesApi<T["type"]>>,
  ) => {
    const chart = useContext(ChartContext);

    const context = useRef(
      createLazyValue(
        () => {
          if (!chart) throw new Error("Chart context is not provided");
          return series(chart(), { ...props, type } as T);
        },
        (value: SeriesActionResult<T>) => value.destroy(),
      ),
    );

    const onGridReadyStable = useRef(props?.onSeriesReady);

    useEffect(() => {
      const id = setTimeout(() => {
        onGridReadyStable?.current?.(context.current().subject());
      }, 0);
      return () => clearTimeout(id);
    }, [onGridReadyStable]);

    useLayoutEffect(() => {
      const ctx = context.current;
      ctx();

      return () => {
        ctx.reset();
      };
    }, []);

    useLayoutEffect(() => {
      context.current().update({ ...props, type } as T);
    }, [props]);

    useImperativeHandle(ref, () => context.current().subject(), []);

    return context;
  };
}
