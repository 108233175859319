import { Toaster } from "react-hot-toast";
import { bottomBarHeight } from "../globals";
import { useWebappTheme } from "../../styles/extendTheme";

export function ToasterContainer() {
  const theme = useWebappTheme();

  return (
    <Toaster
      position="top-center"
      reverseOrder={false}
      gutter={8}
      containerStyle={{
        bottom: bottomBarHeight + 16,
      }}
      toastOptions={{
        style: {
          background: theme.palette.background.popup,
          color: theme.palette.text.primary,
          textAlign: "center",
        },
      }}
    />
  );
}
