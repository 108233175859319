import { Box, Tooltip, type TooltipProps } from "@mui/joy";

export function CustomTooltip({ title, children }: TooltipProps) {
  return (
    <Tooltip
      placement="right"
      arrow={true}
      size="md"
      sx={{
        cursor: "pointer",
      }}
      title={title}
    >
      <Box>{children}</Box>
    </Tooltip>
  );
}
