import { formatUnknownToString, setDifference } from "../../shared/utils";

export function formatEdits<T extends object>({
  editableKeys,
  editedRow,
  dbData,
}: {
  editableKeys: (keyof T)[];
  editedRow: T;
  dbData: Partial<T> | undefined;
}) {
  return editableKeys
    .filter((key) => editedRow[key] !== null && editedRow[key] !== undefined)
    .map((key) => {
      const newValue = editedRow[key];
      const oldValue = dbData?.[key];

      if (newValue instanceof Set && oldValue instanceof Set) {
        const added = setDifference(newValue, oldValue);
        const removed = setDifference(oldValue, newValue);
        return {
          key,
          addedValues: added,
          removedValues: removed,
        };
      }

      if (newValue === oldValue) {
        return;
      }

      return {
        key,
        newValue: formatUnknownToString(newValue),
        prevValue: formatUnknownToString(oldValue),
      };
    })
    .filter(Boolean);
}
