import { Money, Round } from "bigint-money";
import type { TRangeCell } from "../tableUtils";

export type TNumberOrDecimal = number | Money | string;

export function isNumber(str: string): number | null {
  const val = Number.parseFloat(str);
  return Number.isNaN(val) ? null : val;
}

// Caching initialization
const decimalConversionCache = new Map<number | string, Money>();

const roundingStrategy = Round.HALF_AWAY_FROM_0;

type DecimalReturnType<T> = T extends Money ? Money : Money | null;

function bigintPower(base: bigint, exponent: bigint) {
  if (exponent < 0n) {
    return { numerator: 1n, denominator: base ** -exponent };
  }
  return { numerator: base ** exponent, denominator: 1n };
}

export function Decimal<T extends number | string | Money>(
  n: T,
): DecimalReturnType<T> {
  if (n instanceof Money) return n as DecimalReturnType<T>;
  if (decimalConversionCache.has(n))
    return decimalConversionCache.get(n) as DecimalReturnType<T>;

  const currency = "USD";

  // Helper function to create Money object
  const createMoney = (value: string | number | bigint) => {
    return new Money(value.toString(), currency, roundingStrategy);
  };

  try {
    // Attempt to create Money object directly
    const result = createMoney(n);
    decimalConversionCache.set(n, result);
    return result as DecimalReturnType<T>;
  } catch (e1) {
    // If the initial conversion fails, check if the input is a number in exponential notation
    if (typeof n === "number" && n.toString().includes("e")) {
      try {
        // Determine if the number is negative
        const isNegative = n < 0;
        // Convert the absolute value of the number to a string in exponential notation
        const exponentialString = Math.abs(n).toExponential();
        // Split the string into base and exponent
        const [base, exponent] = exponentialString.split("e");
        // Convert the base to a BigInt by scaling it up
        const [integerPart, fractionalPart] = base.split(".");
        const significantDigits = (fractionalPart || "").length;
        const scaledBase = BigInt(integerPart + (fractionalPart || ""));
        const scaleFactor = bigintPower(
          BigInt(10),
          BigInt(Number.parseInt(exponent) - significantDigits),
        );
        const baseBigInt =
          (scaledBase * scaleFactor.numerator) / scaleFactor.denominator;
        const result = createMoney(baseBigInt);
        const finalResult = isNegative ? result.multiply(-1) : result;
        decimalConversionCache.set(n, finalResult);
        return finalResult as DecimalReturnType<T>;
      } catch (e2) {
        console.error(
          "Error creating decimal object after handling exponential notation",
          { n, e2 },
        );
        return null as DecimalReturnType<T>;
      }
    } else {
      console.error("Error creating decimal object", { n, e1 });
      return null as DecimalReturnType<T>;
    }
  }
}

export type TNumber = number;
export type TMoney = Money;

function toNumber(n: Money): number {
  return Number.parseFloat(n.format());
}

function parse(n: unknown): number | null {
  if (n instanceof Money) return toNumber(n);
  if (typeof n === "number") return n;
  if (typeof n === "string") {
    const validationRegex = /^-?\d+(\.\d+)?([eE][-+]?\d+)?$/;
    if (validationRegex.test(n)) {
      const parsed = Number.parseFloat(n);
      return Number.isNaN(parsed) ? null : parsed;
    }
    return null;
  }
  return null;
}

const formattersCache: { [index: string]: Intl.NumberFormat } = {};

function getFormatter(decimalPlaces: number): Intl.NumberFormat {
  const key = `decimal-${decimalPlaces}`;
  if (!formattersCache[key]) {
    formattersCache[key] = new Intl.NumberFormat("en", {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
  }
  return formattersCache[key];
}
function format(n: TNumber, decimalPlaces: number) {
  const formatter = getFormatter(decimalPlaces);
  return formatter.format(n);
}

function toFixed(n: TNumber | Money | string, decimalPlaces: number): string {
  const decimal = Decimal(n);
  if (!decimal) return "";
  return decimal.toFixed(decimalPlaces);
}

function formatGridNumber(
  n: TNumber,
  decimalPlaces: number,
  separator = false,
) {
  return separator ? format(n, decimalPlaces) : toFixed(n, decimalPlaces);
}

function safeSum(numbers: (TNumber | string)[]): Money | null {
  try {
    const init = Decimal(0);
    if (!init) return null;
    const sum = numbers.reduce((acc: Money, n) => {
      if (typeof n === "string") {
        const parsed = isNumber(n);
        if (parsed === null) return acc;
        const decimal = Decimal(parsed);
        if (!decimal) return acc;
        return acc.add(decimal);
      }
      const decimal = Decimal(n);
      if (!decimal) return acc;
      return acc.add(decimal);
    }, init);
    return sum;
  } catch (e) {
    console.error(e);
    return null;
  }
}

function mean(nums: (TNumber | string)[]): Money | undefined | null {
  if (nums.length === 0) return null;
  try {
    const meanResult = safeSum(nums)?.divide(nums.length);
    return meanResult;
  } catch (e) {
    console.error("error creating mean", { nums, e });
    return null;
  }
}

export type RangeCellWithValue = TRangeCell & { cellValue: number };

function selectionToValues(rangeSelection: RangeCellWithValue[]) {
  return rangeSelection.map((cell) => cell.cellValue);
}

const defaultDiffRatio = { diff: 0, ratio: 0 };

export function formatAgg(value: Money | null) {
  if (!value) return null;
  return value
    .toFixed(4)
    .replace(/(\.\d*?[1-9])0+$/, "$1")
    .replace(/\.0*$/, "");
}

function genSumAndCount(rangeSelection: RangeCellWithValue[][]) {
  const numbers = selectionToValues(rangeSelection.flat());
  const count = numbers.length;
  if (!count || count === 1) return null;
  const sum = safeSum(numbers);
  const zeroSum = !sum || sum?.isEqual(0);
  const avg = zeroSum ? 0 : formatAgg(sum.divide(count));
  const scm = { sum: formatAgg(sum), count: count.toString(), avg };
  if (rangeSelection.length !== 2) {
    return scm;
  }

  const scmdr = { ...scm, diffRatio: defaultDiffRatio };

  if (!count) return scmdr;

  const firstRange = selectionToValues(rangeSelection[0]);
  const secondRange = selectionToValues(rangeSelection[1]);
  const firstSum = mean(firstRange);
  const secondSum = mean(secondRange);
  const zeroFirst = !firstSum || firstSum?.isEqual(0);
  const zeroSecond = !secondSum || secondSum?.isEqual(0);

  if (!firstSum || !secondSum) return scmdr;

  const diff = formatAgg(firstSum.subtract(secondSum));
  const ratio =
    !zeroFirst && !zeroSecond ? formatAgg(firstSum.divide(secondSum)) : 0;
  return { ...scm, diffRatio: { diff, ratio } };
}

export {
  parse,
  format,
  toFixed,
  formatGridNumber,
  safeSum,
  mean,
  genSumAndCount,
  selectionToValues,
};
