import { atom, getDefaultStore } from "jotai";
import type { TGridModalDockview } from "../market-grid/modals/modalComponents";
import type { TPeriodColumn } from "../market-grid/periodHelpers";
import type {
  TManualStoreStorageType,
  TStatus,
} from "../market-grid/statuses/statusLogic";
import type { Product_Artis_Type_Enum } from "../../__generated__/gql/graphql";
import type { useAdhocSpreads } from "../market-grid/modals/timespreadHooks";

export type TSharedCellUser = {
  id?: string | null;
  username?: string | null;
  firstname?: string | null;
  lastname?: string | null;
} | null;

export const UserAtom = atom<TSharedCellUser | null>(null);
UserAtom.debugLabel = "UserAtom";

export type TCell = number | string | undefined;

export type TData = Record<string, TCell> & TPeriodColumn;

export type TSpreadData = Record<string, TCell>;

export type TCalciteEntry = (
  | {
      Ok: TCell;
      Err?: undefined;
    }
  | {
      Ok?: undefined;
      Err: string;
    }
) & {
  product: string;
  offset: number;
  field: string;
};

export type TLivePricesMap = Map<ReturnType<typeof livepricesId>, TCell>;

export const store = getDefaultStore();

export type CalculationsState = {
  lastUpdated: Date | null;
  gridData: TData;
  livePrices: TLivePricesMap;
};

export function livepricesId(productId: string, field: string) {
  return `${productId}:${field}`;
}

export const emptyDockview = <TGridModalDockview>{};
export const gridModalDockviewAtom = atom<TGridModalDockview>(emptyDockview);
gridModalDockviewAtom.debugLabel = "gridModalDockviewAtom";

export type TLivePricesStoreKey = {
  selector: string;
  productId: string;
  rowId: string;
};

export type TManualStoreKey = {
  storageType: TManualStoreStorageType;
  productId: string;
};

export type TLatestEditInfoKey = TManualStoreKey & {
  rowId: string;
};

export type TLatestEditInfoValue = {
  username: string;
  firstname: string;
  lastname: string;
  editedAt: string;
};

export type TSharedStoreForCalcsKey = {
  productId: string;
  rowId: string;
};

export type TEodStoreKey = {
  productId: string;
  rowId: string;
  evaluationDate: string;
};

export type TCellValue = number | string | undefined;

export type TManualCellValue = Record<string, TCellValue>;

export type TOptimisticUpdateStoreKey = TManualStoreKey & {
  rowId: string;
};

export type TOptimisticUpdateValue = {
  value: TCellValue;
  month: string;
  editedAt: string;
};

export type TOptimisticUpdateEntry = TOptimisticUpdateStoreKey &
  TOptimisticUpdateValue;

export type TCalciteResultValue =
  | {
      Ok: TCellValue;
      Err?: undefined;
    }
  | {
      Ok?: undefined;
      Err: string;
    };

export type TCalciteData = Record<string, TCalciteResultValue>;

export type TGridDataColumn = {
  columnId: string;
  productId: string;
  eodId: string | undefined | null;
  artisType: Product_Artis_Type_Enum;
  hasSharedCell: boolean;
  selector: string;
  status: TStatus;
  isPermissioned: boolean;
};

export type TGridDataRowId = readonly [
  rowType: TPeriodColumn["rowType"],
  rowId: string,
  index?: number,
];

export type TGetGridData = {
  rowIds: TGridDataRowId[];
  columns: TGridDataColumn[];
  eodEvalDate?: string;
  adhocSpreads?: ReturnType<typeof useAdhocSpreads>["formattedAdhocSpreads"];
};

// string is columnId
export type TGridDataEntry = Record<string, TCalciteResultValue | undefined>;

export const configsKey = "productConfigs";

export type TProductConfig = Record<
  string,
  {
    formula?: string | null | undefined;
    product: string;
    offset: number;
  }[]
>;

export type ProductForCalcs = {
  product: string;
  field: string;
  offset: number;
  result: number | undefined | string;
};

export type TAggregatedResultsValue = {
  [productId: string]: {
    [fieldSelector: string]: TCalciteData;
  };
};
