import { useAuth0 } from "@auth0/auth0-react";
import { client } from "../triplit/triplit";
import { createContext, useCallback, useMemo } from "react";
import { useColorScheme } from "@mui/joy";
import type { GridApi } from "ag-grid-community";
import { atom, useSetAtom } from "jotai";

// make sure this is called within the right css provider
export function useThemeMode(
  section: "lumiTheme" | "webappTheme" | "liveChartsTheme",
) {
  const { user } = useAuth0();
  const userId = user?.sub;

  if (!userId) throw new Error("No userId in useThemeMode");

  const { mode, setMode } = useColorScheme();

  const parsedResult = useMemo(() => {
    if (!mode) return undefined;
    return mode === "dark" ? "dark" : "light";
  }, [mode]);

  const setTheme = useCallback(
    async (theme: "dark" | "light") => {
      setMode(theme);
      const existing = await client.fetchById("theme", userId);
      if (existing) {
        client.update("theme", userId, (prev) => {
          prev[section] = theme;
        });
      } else {
        client.insert("theme", {
          id: userId,
          [section]: theme,
        });
      }
    },
    [section, userId, setMode],
  );

  return {
    mode: parsedResult || "light",
    setMode: setTheme,
  } as const;
}

type ApiContextType = {
  api: GridApi | null;
  setApi: (api: GridApi | null) => void;
};

export const ApiContext = createContext<ApiContextType | undefined>(undefined);

let api: GridApi | null = null;

export const apiReadyAtom = atom<boolean>(false);

export function useGridApi() {
  const setApiReady = useSetAtom(apiReadyAtom);

  const setApi = useCallback(
    (newApi: GridApi | null) => {
      api = newApi;
      setApiReady(Boolean(newApi));
    },
    [setApiReady],
  );

  const getApi = useCallback(() => api, []);

  return {
    getApi,
    setApi,
  } as const;
}

export function getApi() {
  return api;
}

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
const isAndroid = /android/i.test(navigator.userAgent);

export const isMobile = isIOS || isAndroid;
