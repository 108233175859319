import type {
  DeepPartial,
  IPriceScaleApi,
  PriceScaleOptions,
} from "lightweight-charts";
import {
  type ForwardedRef,
  type MutableRefObject,
  type ReactNode,
  forwardRef,
  memo,
  useContext,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from "react";
import { type LazyValue, createLazyValue } from "../internal/lazy-value";
import {
  type PriceScaleActionResult,
  priceScale,
} from "../internal/price-scale";
import { ChartContext } from "./internal/chart-context";
import { useDeepCompareMemo } from "@react-hookz/web";

export interface PriceScaleProps extends DeepPartial<PriceScaleOptions> {
  id: string;
  children?: ReactNode;
}

function usePriceScaleAction(
  props: PriceScaleProps,
  ref: ForwardedRef<IPriceScaleApi>,
): MutableRefObject<LazyValue<PriceScaleActionResult>> {
  const { children, ...rest } = props;

  const chart = useContext(ChartContext);

  const context = useRef(
    createLazyValue(
      () => {
        if (!chart) throw new Error("Chart is not defined");
        return priceScale(chart(), rest);
      },
      (value: PriceScaleActionResult) => value.destroy(),
    ),
  );

  useLayoutEffect(() => {
    const ctx = context.current;
    ctx();

    return () => {
      ctx.reset();
    };
  }, []);

  const propsWithNoChildren = useDeepCompareMemo(() => {
    return rest;
  }, [rest]);

  useLayoutEffect(() => {
    context.current().update(propsWithNoChildren);
  }, [propsWithNoChildren]);

  useImperativeHandle(ref, () => context.current().subject(), []);

  return context;
}

export const PriceScale = memo(
  forwardRef((props: PriceScaleProps, ref: ForwardedRef<IPriceScaleApi>) => {
    usePriceScaleAction(props, ref);

    return null;
  }),
);
