import type {
  ISeriesApi,
  LineSeriesPartialOptions,
  SeriesDataItemTypeMap,
  SeriesMarker,
  Time,
} from "lightweight-charts";
import { type ForwardedRef, forwardRef, memo, type ReactNode } from "react";

import type { LineSeriesParams } from "../internal/series.js";
import { createSeriesHook } from "./internal/create-series-hook.js";
import { SeriesContext } from "./internal/series-context.js";

const useLineSeriesAction = createSeriesHook<LineSeriesParams>("Line");

export interface LineSeriesProps extends LineSeriesPartialOptions {
  data: SeriesDataItemTypeMap["Line"][];
  markers?: SeriesMarker<Time>[];
  reactive?: boolean;
  children?: ReactNode;
}

export const LineSeries = memo(
  forwardRef(
    (props: LineSeriesProps, ref: ForwardedRef<ISeriesApi<"Line">>) => {
      const { children, ...rest } = props;

      const context = useLineSeriesAction(rest, ref);

      return (
        <SeriesContext.Provider value={context.current}>
          {children}
        </SeriesContext.Provider>
      );
    },
  ),
);
