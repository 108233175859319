import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Option, Select, Typography } from "@mui/joy";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
//import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { useEntity } from "@triplit/react";
import {
  DockviewReact,
  type IDockviewPanelProps,
  type IDockviewReactProps,
} from "dockview";
import "dockview/dist/styles/dockview.css";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { client } from "../../triplit/triplit";
import { topBarHeight } from "../globals";
import { TradingView, UnselectedTradingView } from "./LiveChart";
import {
  chartFetchingAtom,
  queryClient,
  useChangeLayoutCharts,
  useChartSettings,
  useLiveChart,
} from "./hooks";
import "../../styles/liveCharts.css";
import { dockviewAtom, genChartId } from "./utils";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import { useThemeMode } from "../../shared/hooks";
import { liveChartsTheme } from "../../styles/extendTheme";
import { useUserId } from "../../auth";
import { QueryClientProvider } from "@tanstack/react-query";
import { compressedDockviewState } from "../../utils/compressedStringify";

function LayoutSelector() {
  const userId = useUserId();

  if (!userId) throw new Error("No userId in LaoutSelector");

  const { result: chartSettings } = useEntity(client, "chartSettings", userId);

  const layout = chartSettings?.layout;

  const dockviewApi = useAtomValue(dockviewAtom);
  const changeLayout = useChangeLayoutCharts();

  return (
    <>
      <Select<number>
        size="sm"
        placeholder="Select Layout"
        value={layout || 1}
        onChange={async (_, v) => {
          if (!v || !dockviewApi) return;

          changeLayout(v, dockviewApi);

          await client.insert("chartSettings", {
            id: userId,
            layout: v,
            userId,
            layoutState: await compressedDockviewState(dockviewApi),
          });
        }}
      >
        <Option value={1}>1 Chart</Option>
        <Option value={2}>2 Charts</Option>
        <Option value={4}>4 Charts</Option>
      </Select>
    </>
  );
}

function ThemeSelector() {
  const { mode, setMode } = useThemeMode("liveChartsTheme");

  return (
    <>
      <Select<"light" | "dark">
        size="sm"
        value={mode}
        onChange={async (_, v) => {
          if (!v) return;
          await setMode(v);
        }}
      >
        <Option value="light">Light</Option>
        <Option value="dark">Dark</Option>
      </Select>
    </>
  );
}

function TopBar({
  close,
}: {
  close?: () => void;
}) {
  const isFetching = useAtomValue(chartFetchingAtom);
  return (
    <Box
      sx={{
        height: topBarHeight,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 2,
        borderBottom: "1px solid",
        borderColor: "divider",
        backgroundColor: (theme) => theme.palette.background.surface,
      }}
    >
      <Typography>Live Charts</Typography>
      {isFetching && <Typography>Fetching Data...</Typography>}
      <Box sx={{ display: "flex", gap: 1 }}>
        <LayoutSelector />
        <ThemeSelector />
        {close && (
          <Button
            onClick={() => {
              close();
            }}
            sx={{
              display: {
                xs: "block",
                sm: "none",
              },
            }}
            color="neutral"
            variant="outlined"
          >
            Close
          </Button>
        )}
      </Box>
    </Box>
  );
}

function ThemeComponent(_props: IDockviewPanelProps) {
  const { user } = useAuth0();
  const userId = user?.sub;

  if (!userId) throw new Error("No userId in UnselectedTradingView");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      hello
    </Box>
  );
}

export function ChartComponent({ api }: IDockviewPanelProps) {
  const chartIdx = api.id;

  const { user } = useAuth0();
  const userId = user?.sub;

  if (!userId) throw new Error("No userId in UnselectedTradingView");

  const chartId = genChartId({ chartIdx, userId });

  const { results: chartMetadata, fetching } = useLiveChart(chartId);
  const [loading, setLoading] = useState(fetching);
  useEffect(() => {
    const t = setTimeout(() => setLoading(false), fetching ? 5000 : 0);
    return () => clearTimeout(t);
  }, [fetching]);
  if (loading && fetching) return <Box>Loading...</Box>;

  if (
    chartMetadata?.productId &&
    chartMetadata?.sampleTime &&
    chartMetadata?.periodFrom
  ) {
    return (
      <TradingView
        key={`
      ${chartMetadata.id}-${chartMetadata.productId}-${chartMetadata.sampleTime}
  `}
        chartMetadata={chartMetadata}
      />
    );
  }

  return <UnselectedTradingView chartIdx={chartIdx} />;
}

const components = {
  default: ChartComponent,
  theme: ThemeComponent,
} satisfies IDockviewReactProps["components"];

export function ChartsLayoutInner({
  close,
}: {
  close?: () => void;
}) {
  const [dockview, setDockview] = useAtom(dockviewAtom);
  const { user } = useAuth0();
  const userId = user?.sub;

  if (!userId) throw new Error("No userId in TradingViewWrapper");

  useEffect(() => {
    if (!dockview) {
      return;
    }

    const disposable = dockview.onDidLayoutChange(async () => {
      client.insert("chartSettings", {
        id: userId,
        layoutState: await compressedDockviewState(dockview),
        userId,
      });
    });

    return () => disposable.dispose();
  }, [dockview, userId]);

  const { results: chartSettings, fetching, error } = useChartSettings();

  const layout = chartSettings?.layout;

  const changeLayout = useChangeLayoutCharts();

  const { mode } = useThemeMode("liveChartsTheme");

  if (error) return <Box>Error...</Box>;
  if (fetching) return <Box>Loading...</Box>;

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <TopBar close={close} />
      <Box
        sx={{
          height: `calc(100% - ${topBarHeight}px)`,
        }}
      >
        <DockviewReact
          className={
            mode === "dark" ? "dockview-theme-dark" : "dockview-theme-light"
          }
          onReady={(event) => {
            const api = event.api;
            setDockview(api);
            changeLayout(layout || 1, api);
            const currentDockView = api.toJSON();
            try {
              if (chartSettings?.layoutState) {
                api.fromJSON(chartSettings.layoutState);
              }
            } catch (e) {
              console.warn(
                "dockview state compromised. Rolling back to default.",
              );
              api.fromJSON(currentDockView);
            }
          }}
          components={components}
        />
      </Box>
    </Box>
  );
}

export function ChartsLayout() {
  // persistOptions={{
  //   buster: "v1.4",
  //   persister,
  //   maxAge: Number.POSITIVE_INFINITY,
  //   dehydrateOptions: {
  //     // do not persist queries that are not marked as persist i.e. live data
  //     shouldDehydrateQuery: (query) => !!query.meta?.persist,
  //   },
  return (
    <JoyCssVarsProvider
      disableNestedContext
      theme={liveChartsTheme}
      attribute="data-live-charts-color-scheme"
      defaultMode="light"
    >
      <QueryClientProvider client={queryClient}>
        <Box
          sx={{
            height: "100dvh",
          }}
        >
          <ChartsLayoutInner />
        </Box>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </JoyCssVarsProvider>
  );
}
