import { faker } from "@faker-js/faker";
import type { extractFormulaDependencies } from "./webapp/utils";
const uuids = Array.from({ length: 1000 }, () => faker.string.uuid());

// Dev
export const testUserIdsDev = [
  "auth0|65a7b1b74dda8db5fb8c176d", //Dev ui-test1
  "auth0|65a7b1f00a71b081c57463d5",
  "auth0|65a7b209cc2de5f1421999ba",
  "auth0|65a7b22c4dda8db5fb8c17df",
];

// Staging
export const testUserIdsStaging = [
  "auth0|66b0afec0f20161b0a659ffc", //Staging ui-test1
  "auth0|66b61c9c159e6774f7391811",
  "auth0|66b61d0a188f0806d362fdcf",
  "auth0|66b61d46a89e707c38dabf1c",
];

const randomUuid = () => faker.helpers.arrayElement(uuids);

type TProducts = Parameters<typeof extractFormulaDependencies>[0];
const generateProduct = (ids: string[]): TProducts[keyof TProducts] => ({
  product_configs: ids.map((id) => ({
    formula: `[${id}].value / 8.9 - [${randomUuid()}].fv`,
  })),
});

const generateProductWithDeepDependencies = (
  numDependencies: number,
): TProducts[keyof TProducts] => {
  let formula = "";

  for (let i = 0; i < numDependencies; i++) {
    formula +=
      i === 0 ? `[${randomUuid()}].value / 8.9 - ` : `[${randomUuid()}].fv / `;
  }

  formula += `8.9 - [${randomUuid()}].fv`;

  return { product_configs: [{ formula }] };
};

export const generateProductsAndLookupIds = (numProducts: number) => {
  const products: TProducts = {};
  const lookupIds = new Set<string>();

  for (let i = 1; i <= numProducts; i++) {
    // All products are processed and all product IDs are added to the lookupIds Set.
    products[i] =
      i % 2 === 0
        ? generateProduct([(i + 1).toString(), (i + 2).toString()])
        : generateProductWithDeepDependencies(i);
    lookupIds.add(i.toString());
  }

  return { products, lookupIds };
};
