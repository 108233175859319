import { LinearProgress } from "@mui/joy";
import type { PropsWithChildren } from "react";

export function QueryAllStates<
  T extends {
    data: ReadonlyArray<unknown> | undefined | null;
    fetching: boolean;
    error: Error | undefined;
  },
>({ children, fetching, error, data }: PropsWithChildren<T>) {
  if (fetching) {
    return <LinearProgress />;
  }
  if (error) {
    return <p>Oh no... {error?.message}</p>;
  }

  return <>{children}</>;
}
