import type { Theme } from "@mui/joy/styles/types";
import { Box, Modal, Typography, Button, Link } from "@mui/joy";

export function ContactUsModal({
  open,
  setClose,
  theme,
}: {
  open: boolean;
  setClose: () => void;
  theme: Theme;
}) {
  return (
    <Modal
      disablePortal={true}
      open={open}
      onClose={setClose}
      sx={{
        "&:focus-visible": {
          outline: "none",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: "600px",
          bgcolor: "white",
          borderRadius: "sm",
          background: theme.palette.background.surface,
          p: 0,
          paddingBottom: 1,
          maxHeight: "90vh",
          outline: "none",
        }}
      >
        <Typography
          level="h3"
          component="h2"
          sx={{
            paddingTop: 1.5,
            paddingLeft: 2,
            paddingBottom: 1.5,
            borderRadius: "sm",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            color: theme.palette.text.primary,
            background: (theme) =>
              theme.palette.mode === "dark"
                ? theme.palette.neutral[700]
                : theme.palette.neutral[50],
          }}
        >
          Contact Support
        </Typography>
        <Typography
          level="body-md"
          sx={{ padding: "20px 20px 0 20px", fontSize: "14px" }}
        >
          You can contact us on WhatsApp at{" "}
          <Link
            href="https://wa.me/447537143416"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: theme.palette.primary[500],
              textDecoration: "none",
              "&:hover": {
                textDecoration: "none",
                color: theme.palette.primary[500],
              },
            }}
          >
            +44 7537 143416
          </Link>
          , or e-mail us at{" "}
          <Link
            href="mailto:support@artis.works"
            sx={{
              color: theme.palette.primary[500],
              textDecoration: "none",
              "&:hover": {
                textDecoration: "none",
                color: theme.palette.primary[500],
              },
            }}
          >
            support@artis.works
          </Link>
          .
        </Typography>
        <Button
          variant="solid"
          color="neutral"
          onClick={setClose}
          sx={{ float: "right", margin: "20px 20px 10px 20px" }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}
