import type {
  DeepPartial,
  ITimeScaleApi,
  LogicalRangeChangeEventHandler,
  SizeChangeEventHandler,
  TimeRangeChangeEventHandler,
  TimeScaleOptions,
  Time,
} from "lightweight-charts";
import {
  type ForwardedRef,
  type MutableRefObject,
  type ReactNode,
  forwardRef,
  memo,
  useContext,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from "react";
import { type LazyValue, createLazyValue } from "../internal/lazy-value";
import { type TimeScaleActionResult, timeScale } from "../internal/time-scale";
import { ChartContext } from "./internal/chart-context";
import { useDeepCompareMemo } from "@react-hookz/web";

export interface TimeScaleProps extends DeepPartial<TimeScaleOptions> {
  children?: ReactNode;
  onVisibleTimeRangeChange?: TimeRangeChangeEventHandler<Time>;
  onVisibleLogicalRangeChange?: LogicalRangeChangeEventHandler;
  onSizeChange?: SizeChangeEventHandler;
}

function useTimeScaleAction(
  props: TimeScaleProps,
  ref: ForwardedRef<ITimeScaleApi<Time>>,
): MutableRefObject<LazyValue<TimeScaleActionResult>> {
  const { children, ...rest } = props;

  const chart = useContext(ChartContext);

  const context = useRef(
    createLazyValue(
      () => {
        if (!chart) throw new Error("Chart is not defined");
        return timeScale(chart(), rest);
      },
      (value: TimeScaleActionResult) => value.destroy(),
    ),
  );

  useLayoutEffect(() => {
    const ctx = context.current;
    ctx();

    return () => {
      ctx.reset();
    };
  }, []);

  const propsWithNoChildren = useDeepCompareMemo(() => {
    return rest;
  }, [rest]);

  useLayoutEffect(() => {
    context.current().update(propsWithNoChildren);
  }, [propsWithNoChildren]);

  useImperativeHandle(ref, () => context.current().subject(), []);

  return context;
}

export const TimeScale = memo(
  forwardRef(
    (props: TimeScaleProps, ref: ForwardedRef<ITimeScaleApi<Time>>) => {
      useTimeScaleAction(props, ref);

      return null;
    },
  ),
);
