import { atom, useAtomValue, useSetAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import * as R from "remeda";
import type { TPageProduct } from "../../triplit/schema";
import { loadingStateAtom } from "../sharedHooks";
import { client } from "../../triplit/triplit";
import { useState } from "react";
import { usePageId } from "./pagesHooks";
import { useDeepCompareEffect, useDeepCompareMemo } from "@react-hookz/web";
import { useProductsByIds } from "../curve-management/hooks";
import { genColumnDefs } from "../market-grid/columnDefs";
import {
  gridSettingsForRowDataAtom,
  gridSettingsOptions as gridSettingsOptionsAtom,
} from "../grid-settings/settingsStore";
import { getContextMenuItems } from "../market-grid/getContextMenuItems";
import type {
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
} from "ag-grid-community";
import { userDetailsAtom } from "../../auth";
import { useQuery } from "@triplit/react";
import { deepEqual } from "@tanstack/react-router";

export const pageIdAtom = atom<string | null>(null);
pageIdAtom.debugLabel = "pageIdAtom";

type Product = NonNullable<ReturnType<typeof useProductsByIds>["data"]>[number];

export const hasuraProductInfoAtom = atom<Product[] | null>(null);
hasuraProductInfoAtom.debugLabel = "hasuraProductInfoAtom";

export const hasuraProductByIdFamily = atomFamily(
  (productId) =>
    atom(
      (get) => {
        const products = get(hasuraProductInfoAtom);
        if (!products) return null;
        return products.find((p) => p.id === productId) || null;
      },
      (get, set, update: Partial<Product>) => {
        const products = get(hasuraProductInfoAtom);
        if (!products) return;
        set(
          hasuraProductInfoAtom,
          products.map((product) =>
            product.id === productId ? { ...product, ...update } : product,
          ),
        );
      },
    ),
  deepEqual,
);

export const pageProductsByPageIdAtom = atom<Record<
  string,
  TPageProduct[]
> | null>(null);
pageProductsByPageIdAtom.debugLabel = "pageProductsByPageIdAtom";

export const currentPageProductsAtom = atom((get) => {
  const pageId = get(pageIdAtom);
  const productInfo = get(hasuraProductInfoAtom);
  if (!pageId || !productInfo) return [];
  const pageProducts = get(pageProductsByPageIdAtom);
  const productsForPage = pageProducts?.[pageId] || [];
  return productsForPage
    .map((p) => {
      const product = productInfo.find((product) => product.id === p.productId);
      return {
        ...product,
        ...p,
      };
    })
    .filter(Boolean);
});
currentPageProductsAtom.debugLabel = "currentPageProductsAtom";

export const currentPageShadowColsAtom = atom((get) => {
  const pageId = get(pageIdAtom);
  const productInfo = get(hasuraProductInfoAtom);
  if (!pageId || !productInfo) return [];
  const pageProducts = get(pageProductsByPageIdAtom);
  const productsForPage = pageProducts?.[pageId] || [];
  return productsForPage
    .map((p) => {
      if (p.columnType === "product") return null;
      return p;
    })
    .filter(Boolean);
});
currentPageShadowColsAtom.debugLabel = "currentPageShadowColsAtom";

const currentPageColDefsAtom = atom((get) => {
  const pageProducts = get(currentPageProductsAtom);
  const products = get(hasuraProductInfoAtom);
  const gridSettingsForRowData = get(gridSettingsForRowDataAtom);
  const pageId = get(pageIdAtom);
  const user = get(userDetailsAtom);
  const gridSettingsOptions = get(gridSettingsOptionsAtom);
  const disabledFeatures = user.subscriptionTier.disabledFeatures;
  const mainMenuItems = (
    params: GetContextMenuItemsParams | GetMainMenuItemsParams,
  ) => {
    if (!pageId || !products) return [];

    return getContextMenuItems({
      params,
      userId: pageProducts[0].userId,
      isHeaderMenu: true,
      disabledFeatures,
      pageId,
      products: pageProducts,
    });
  };
  if (!pageProducts || !products) return [];
  const newColDefs = genColumnDefs({
    currentPageProducts: pageProducts,
    products,
    mainMenuItems,
    mainMonthColumnWidth: gridSettingsOptions.mainMonthColumnWidth,
    statusMap: gridSettingsForRowData.statusMap || {},
    flashCellUpdates: gridSettingsOptions.flashCellUpdates || false,
  });
  return newColDefs;
});
currentPageColDefsAtom.debugLabel = "currentPageColDefsAtom";

export function useCurrentPageColDefs() {
  const colDefs = useAtomValue(currentPageColDefsAtom);
  return useDeepCompareMemo(() => {
    return colDefs;
  }, [colDefs]);
}

export const allPageProductsQuery = client
  .query("pageProducts")
  .where("userId", "=", "$session.SESSION_USER_ID")
  .order("idx", "ASC");

const allPageProductIdsAtom = atom((get) => {
  const pageProductsRecord = get(pageProductsByPageIdAtom);
  if (!pageProductsRecord) return null;
  const pageProducts = Object.values(pageProductsRecord).flat();

  return R.pipe(
    pageProducts.filter((p) => p.productId),
    R.map((p) => ({
      pageId: p.pageId,
      productId: p.productId,
      gridId: p.id,
    })),
    R.uniqueBy((p) => p.productId),
  ).filter(Boolean);
});
allPageProductIdsAtom.debugLabel = "allPageProductIdsAtom";

export function updatePageProducts(
  results: TPageProduct[],
  setPageProducts: ReturnType<
    typeof useSetAtom<typeof pageProductsByPageIdAtom>
  >,
) {
  const resultsArray = results || [];
  const indexedPageProducts = resultsArray.reduce(
    (acc, pageProduct) => {
      const pageId = pageProduct.pageId;
      if (!acc[pageId]) {
        acc[pageId] = [];
      }
      acc[pageId].push(pageProduct);
      return acc;
    },
    {} as Record<string, TPageProduct[]>,
  );
  setPageProducts((old) => {
    return {
      ...old,
      ...indexedPageProducts,
    };
  });
  return indexedPageProducts;
}

export function useSubscribePageProducts() {
  const setLoaded = useSetAtom(loadingStateAtom);
  const pageId = usePageId();
  const setPageProducts = useSetAtom(pageProductsByPageIdAtom);
  const setPageId = useSetAtom(pageIdAtom);

  const setProducts = useSetAtom(hasuraProductInfoAtom);
  const [pageProductIds, setPageProductIds] = useState<string[] | null>(null);
  const { data: products, fetching: productsByIdsFetching } = useProductsByIds(
    pageProductIds || [],
  );
  const { results } = useQuery(
    client,
    client
      .query("pageProducts")
      .where([
        ["pageId", "=", pageId || ""],
        ["userId", "=", "$session.SESSION_USER_ID"],
      ])
      .order("idx", "ASC"),
  );
  useQuery(
    client,
    client
      .query("pages")
      .id(pageId || "")
      .order("idx", "ASC")
      .include("conditionalFormattingRules"),
  );

  useDeepCompareEffect(() => {
    if (products && !productsByIdsFetching) {
      setProducts((prev) => {
        if (!prev) return products;
        const updatedOldProducts = prev.map((p) => {
          const newProduct = products.find((newP) => newP.id === p.id);

          return newProduct || p;
        });
        return [
          ...updatedOldProducts,
          ...products.filter((p) => !updatedOldProducts.includes(p)),
        ];
      });
    }
  }, [products, productsByIdsFetching, setProducts]);

  useDeepCompareEffect(() => {
    if (pageId && pageId !== "no pages") {
      setPageId(pageId);
    }
  }, [pageId, setPageId]);

  useDeepCompareEffect(() => {
    if (pageId === "no pages" || !results) return;

    if (results && pageId && pageId !== "no pages") {
      const indexedPageProducts = updatePageProducts(results, setPageProducts);
      setPageProductIds(
        indexedPageProducts?.[pageId || ""]
          ?.map((p) => p.productId)
          .filter(Boolean),
      );

      setLoaded((old) => {
        return {
          ...old,
          market: {
            ...old.market,
            pageProductsLoading: "loaded",
          },
        };
      });
    }
  }, [results, setPageProducts, setLoaded, pageId]);
}

const pagesWithProductsAtom = atom((get) => {
  const pageProducts = get(pageProductsByPageIdAtom);
  if (!pageProducts) return "loading";
  const pagesWithProducts: string[] = [];
  for (const [id, pages] of Object.entries(pageProducts)) {
    if (pages.length > 0) {
      pagesWithProducts.push(id);
    }
  }
  return pagesWithProducts;
});
pagesWithProductsAtom.debugLabel = "pagesWithProductsAtom";

export function usePageLoadingState() {
  const pageId = useAtomValue(pageIdAtom) || "no pages";
  const pagesWithProducts = useAtomValue(pagesWithProductsAtom);
  if (pagesWithProducts === "loading" || !pagesWithProducts) return "loading";

  return pagesWithProducts.includes(pageId) ? "loaded" : "empty";
}

export function useCurrentPageProducts() {
  const pageProducts = useAtomValue(currentPageProductsAtom);

  const noProducts = Boolean(pageProducts && pageProducts.length === 0);

  return {
    fetching: !pageProducts,
    noProducts,
    error: null,
    results: pageProducts || [],
  };
}

/*
 * Returns the first grid Id for a given product ID
 * Remember that a non customer product can be added multiple times
 */
export function useColIdForProductId(productId: string | undefined) {
  const { results: pageProducts } = useCurrentPageProducts();
  return useDeepCompareMemo(() => {
    const pageProduct = pageProducts?.find((p) => p.productId === productId);
    return pageProduct?.id;
  }, [pageProducts, productId]);
}

const pageProductIdsAtom = atom((get) => {
  const pageProducts = get(currentPageProductsAtom);
  return R.pipe(
    pageProducts,
    R.filter((p) => !!p.productId),
    R.map((p) => ({
      pageId: p.pageId,
      productId: p.productId,
      gridId: p.id,
    })),
    R.uniqueBy((p) => p.productId),
  ).filter(Boolean);
});
pageProductIdsAtom.debugLabel = "pageProductIdsAtom";

export function usePageProductIds() {
  const pageProductIds = useAtomValue(pageProductIdsAtom);
  return { data: pageProductIds };
}

export function usePagesProductIds() {
  const results = useAtomValue(allPageProductIdsAtom);

  return {
    fetching: !results,
    results,
    error: null,
  };
}
