import { useQuery } from "@triplit/react";
import { nanoid } from "nanoid";
import { z } from "zod";
import { client } from "../../../../triplit/triplit";
import type { TAlert } from "../../../../triplit/schema";
import {
  monthCodeToShortMonthAndYear,
  relativeRowToRowId,
} from "../../periodHelpers";
import { parseLimitRef } from "../../../utils";
import type { TApolloClient } from "../../../../graphqlClient";
import { graphql } from "../../../../graphql";
import { useMemo } from "react";
import { useQuery as useApolloQuery } from "@apollo/client";
import { useUserId } from "../../../../auth/hooks";

export const alertValidation = z.object({
  id: z.string(),
  limit: z.string(),
  limitRef: z
    .object({
      columnId: z.string(),
      productId: z.string(),
      fieldSelector: z.string(),
      rowId: z.string(),
      name: z.string(),
      period: z.string(),
    })
    .optional(),
  note: z.string(),
  status: z.string(),
  recurring: z.boolean().optional(),
  sound: z.boolean().optional(),
  valueBelowLimit: z.boolean().optional(),
  productId: z.string(),
  columnId: z.string(),
  rowId: z.string(),
});

export type TAlertForm = z.infer<typeof alertValidation>;

export function emptyAlert(): TAlertForm {
  return {
    id: nanoid(),
    limit: "",
    note: "",
    status: "Active",
    sound: false,
    productId: "",
    recurring: false,
    columnId: "",
    rowId: "",
  };
}

// Create an object where the key is `columnId-rowId` and the value is an array of rules based on the ones that have the same columnId and rowId.
function formatAlerts(alerts: TAlert[]) {
  const formatted: Record<string, TAlert[]> = {};

  for (const alert of alerts) {
    const key = `${alert.productId}-${alert.rowId}`;
    if (!formatted[key]) {
      formatted[key] = [];
    }

    formatted[key].push(alert);
  }

  return formatted;
}

export function useAlerts() {
  const userId = useUserId();
  const { results, fetchingLocal } = useQuery(
    client,
    client.query("alerts").where("userId", "=", userId),
  );

  const alerts = results || [];
  const formatted = formatAlerts(alerts);

  return {
    alerts: formatted,
    fetching: fetchingLocal,
  };
}

// Used to fetch all alerts to display them regardless of the page the user is on.
export function useTriggeredAlerts() {
  const { results } = useQuery(
    client,
    client.query("alerts").where([
      ["status", "=", "Triggered"],
      ["triggeredAt", "isDefined", true],
    ]),
  );

  const alerts = results || [];

  return useMemo(() => alerts, [alerts]);
}

export function getAlertDetails(
  alert: TAlert,
  colName: string,
  limitName?: string | null,
) {
  const period = relativeRowToRowId[alert.rowId];
  const operator = alert.valueBelowLimit ? " >= " : " <= ";

  const limitIsRef = alert.limit.includes(":");
  const parsedLimitRef = limitIsRef ? parseLimitRef(alert.limit) : null;

  const limitDetails =
    parsedLimitRef?.columnId && parsedLimitRef?.rowId
      ? {
          period: monthCodeToShortMonthAndYear(parsedLimitRef.rowId),
        }
      : null;

  return {
    name: colName,
    period,
    operator,
    limitDetails,
    formattedMessage: `${colName} [${period}] ${operator} ${
      limitDetails ? `${limitName} [${limitDetails.period}]` : alert.limit
    }`,
  };
}

let permissionsRequested = false;

export async function requestNotificationPermission() {
  if (permissionsRequested) return false;
  permissionsRequested = true;
  if (typeof Notification === "undefined") {
    console.warn("Notifications are not supported in this browser.");
    return false;
  }

  if (Notification.permission !== "granted") {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted.");
        return true;
      }
      console.log("Notification permission denied.");
      return false;
    } catch (error) {
      console.error("Error requesting notification permission:", error);
      return false;
    }
  } else {
    console.log("Notification permission already granted.");
    return true;
  }
}

const productNameQuery = graphql(`
query productName($id: uuid!) {
  product_by_pk(id: $id) {
    name
  }
}
`);

export function useProductName(id: string) {
  const { data } = useApolloQuery(productNameQuery, {
    variables: { id },
  });
  return data?.product_by_pk?.name;
}

export async function getProductName(id: string, apolloClient: TApolloClient) {
  const product = await client.fetchById("pageProducts", id, {
    policy: "local-only",
  });
  const productId = product?.productId;
  if (!productId) {
    console.error("No productId found for alert");
    return;
  }
  const productRes = await apolloClient.query({
    query: productNameQuery,
    variables: { id: productId },
  });
  const productName = productRes.data.product_by_pk?.name;
  return productName;
}
