import type {
  BarSeriesPartialOptions,
  ISeriesApi,
  SeriesDataItemTypeMap,
  SeriesMarker,
  Time,
} from "lightweight-charts";
import { type ForwardedRef, forwardRef, memo, type ReactNode } from "react";

import type { BarSeriesParams } from "../internal/series.js";
import { createSeriesHook } from "./internal/create-series-hook.js";
import { SeriesContext } from "./internal/series-context.js";

const useBarSeriesAction = createSeriesHook<BarSeriesParams>("Bar");

export interface BarSeriesProps extends BarSeriesPartialOptions {
  data: SeriesDataItemTypeMap["Bar"][];
  markers?: SeriesMarker<Time>[];
  reactive?: boolean;
  children?: ReactNode;
}

export const BarSeries = memo(
  forwardRef((props: BarSeriesProps, ref: ForwardedRef<ISeriesApi<"Bar">>) => {
    const { children, ...rest } = props;

    const context = useBarSeriesAction(rest, ref);

    return (
      <SeriesContext.Provider value={context.current}>
        {children}
      </SeriesContext.Provider>
    );
  }),
);
