import { useState } from "react";
import type { TOption, TAutocomplete } from "./Autocomplete";

export function useAutocomplete({
  defaultValue,
  multiple,
}:
  | {
      multiple: true;
      defaultValue?: TOption[];
    }
  | {
      multiple: false;
      defaultValue?: TOption;
    }) {
  const isMulti = multiple;
  const singleState = useState<TAutocomplete["singleState"][0]>(
    isMulti ? undefined : defaultValue,
  );
  const multipleState = useState<TAutocomplete["multipleState"][0]>(
    isMulti ? defaultValue || [] : [],
  );
  return {
    singleState: singleState,
    multipleState: multipleState,
    defaultValue: defaultValue,
  };
}
