import { z } from "zod";

export const periodTypeSchema = z.enum([
  "months",
  "quarters",
  "halves",
  "cals",
  "seasons",
]);

export const adhocSpreadSchema = z.array(
  z.object({
    rowId: z.string(),
    from: z
      .object({
        periodType: periodTypeSchema.optional().nullable(),
        periodValue: z.string().nullable().optional(),
      })
      .optional()
      .nullable(),
    to: z
      .object({
        periodType: periodTypeSchema.optional().nullable(),
        periodValue: z.string().nullable().optional(),
      })
      .optional()
      .nullable(),
  }),
);

export type TAdhocSpread = z.infer<typeof adhocSpreadSchema>;
export type TSpread = TAdhocSpread[number]["from"] | TAdhocSpread[number]["to"];

export function parseAdhocSpreadsJSON(json: string | undefined): TAdhocSpread {
  if (!json) return [];
  try {
    const parsed = JSON.parse(json);
    const spread = adhocSpreadSchema.safeParse(parsed);
    if (spread?.success) return spread?.data;
    return [];
  } catch (e) {
    return [];
  }
}

export type TParsedAdhocSpread = ReturnType<typeof parseAdhocSpreadsJSON>;
