import type { Theme } from "@mui/joy/styles/types";
import { Box, Modal, Typography, Button } from "@mui/joy";
import { gridKeyboardShortcuts } from "../market-grid/keyboardShortcuts";

export function KeyboardShortcutsModal({
  open,
  setClose,
  theme,
}: {
  open: boolean;
  setClose: () => void;
  theme: Theme;
}) {
  return (
    <Modal
      disablePortal={true}
      open={open}
      onClose={setClose}
      sx={{
        "&:focus-visible": {
          outline: "none",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          overflow: "auto",
          minWidth: "500px",
          bgcolor: "white",
          borderRadius: "sm",
          background: theme.palette.background.surface,
          p: 0,
          paddingBottom: 1,
          maxHeight: "90vh",
          outline: "none",
        }}
      >
        <Typography
          level="h3"
          component="h2"
          sx={{
            paddingTop: 1.5,
            paddingLeft: 2,
            paddingBottom: 1.5,
            borderRadius: "sm",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            color: theme.palette.text.primary,
            background: (theme) =>
              theme.palette.mode === "dark"
                ? theme.palette.neutral[700]
                : theme.palette.neutral[50],
          }}
        >
          Keyboard Shortcuts
        </Typography>

        <Box
          id="shortcut-help"
          sx={{
            marginTop: 5,
            display: "grid",
            gridTemplateColumns: "3.5fr 3fr",
            paddingBottom: 2,
            paddingLeft: 5,
          }}
        >
          {gridKeyboardShortcuts.map((shortcut) => (
            <Box
              key={shortcut.name}
              className="item"
              sx={{
                cursor: "pointer",
                display: "contents",
                marginBottom: 2,
                "&:hover .key__button": {
                  color: theme.palette.primary[500],
                },
              }}
            >
              <Typography
                component="p"
                sx={{
                  alignSelf: "center",
                }}
              >
                {shortcut.name}
              </Typography>
              <Box
                className="shortcut-column"
                sx={{
                  display: "flex",
                  gap: 0.5,
                }}
              >
                {shortcut.shortcuts
                  .filter((keyCombo) => !keyCombo.includes("cmd"))
                  .slice(0, 1)
                  .map((keyCombo) => (
                    <Box
                      key={keyCombo}
                      className="shortcut"
                      sx={{ display: "flex", gap: 0.5 }}
                    >
                      {keyCombo
                        .split("+")
                        .map((key) =>
                          key === "ArrowUp"
                            ? "↑"
                            : key === "ArrowDown"
                              ? "↓"
                              : key,
                        )
                        .map((key) => (
                          <Box
                            key={key}
                            className="key__button"
                            sx={{
                              display: "inline-block",
                              height: "30px",
                              minWidth: "30px",
                              padding: "8px",
                              backgroundColor: "rgba(233, 234, 237, 0.24)",
                              boxShadow:
                                "-3px -3px 7px #ffffff73, 2px 2px 5px rgba(94, 104, 121, 0.288)",
                              borderRadius: "5px",
                              margin: "5px",
                              color: "grey",
                              fontSize: "14px",
                              textAlign: "center",
                              transition: "color 0.2s ease-in-out",
                            }}
                          >
                            {key}
                          </Box>
                        ))}
                    </Box>
                  ))}
              </Box>
            </Box>
          ))}
        </Box>

        <Button
          variant="solid"
          color="neutral"
          onClick={setClose}
          sx={{ float: "right", margin: "20px 20px 10px 20px" }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}
