import { graphql } from "../../graphql";
import { useQuerySub } from "../../utils/useQuerySub";
import { useQuery } from "@apollo/client";
import type { TAgreementType } from "../licensing/licensing";
import { useUserId } from "../../auth/hooks";
import { usePageId, useCurrentPageProducts } from "../market-pages";

export const amendExchangeUsage = graphql(`
  mutation AmendExchangeUsage(
    $exchangeUsages: [exchange_usage_insert_input!]!
  ) {
    insert_exchange_usage(objects: $exchangeUsages) {
      affected_rows
    }
  }
`);

const sources = graphql(`
  query Sources {
    source {
       id
       name
     }
  }
`);

export function useSources() {
  const res = useQuery(sources);
  return res;
}

const lastExchangeUsageForSingleUser = graphql(`
  subscription lastExchangeUsageForSingleUser($userId: String!) {
    exchange_usage_last(
      where: { folio_user: { _eq: $userId } }
      order_by: { event_date: desc }
    ) {
      source
      event
    }
  }
`);

export function useExchangeUsageForSingleUser(id: string) {
  const res = useQuerySub({
    query: lastExchangeUsageForSingleUser,
    variables: { userId: id },
  });
  return res;
}

export const sourceToAgreementTypeMap: Record<string, TAgreementType> = {
  "CME NYMEX": "CME_NYMEX",
  "ICE FUTURES EUROPE": "ICE_FUTURES_EUROPE",
  "ICE FUTURES ABU DHABI": "ICE_FUTURES_ABU_DHABI",
  "ICE ENDEX": "ICE_ENDEX",
  IDS: "IDS",
  tpicapXC: "TP_ICAP",
  artisXC: "ARTIS_XC",
};

export const agreementTypeToSourceMap: Record<TAgreementType, string> =
  Object.keys(sourceToAgreementTypeMap).reduce(
    (acc, key) => {
      const agreementType = sourceToAgreementTypeMap[key];
      acc[agreementType] = key;
      return acc;
    },
    {} as Record<TAgreementType, string>,
  );

const userPermissionsSub = graphql(`
  subscription userPermissionsSub($folio_user: String!) {
    permission(where: { folio_user: { _eq: $folio_user } }) {
      id
      packageByPackage {
        id
        name
        sourceBySource {
          id
          name
        }
      }
    }
  }
`);

export function useUserPermissions(folio_user: string) {
  return useQuerySub({
    query: userPermissionsSub,
    variables: { folio_user },
  });
}

export function useLicenceAgreement() {
  const userId = useUserId();
  const pageId = usePageId();
  const { data } = useUserPermissions(userId);
  const { data: sourcesData } = useSources();
  const { data: signedLicenseAgreements } =
    useExchangeUsageForSingleUser(userId);
  const { noProducts, results } = useCurrentPageProducts();
  if ((!noProducts && !results) || !pageId) return {};
  const signedLicenseAgreementsArray =
    signedLicenseAgreements?.exchange_usage_last || [];
  const signedLicenseAgreementsStart = signedLicenseAgreementsArray.filter(
    (agreement) => agreement.event === "start",
  );
  const filteredPermissions =
    data?.permission
      .filter((perm) => {
        const sourceName = perm.packageByPackage?.sourceBySource?.name;
        const source = sourcesData?.source.find((s) => s.name === sourceName);

        if (!sourceName || !source) {
          return false;
        }

        const isSigned = signedLicenseAgreementsStart.some(
          (agreement) => agreement.source === source.id,
        );

        return (
          source &&
          !isSigned &&
          sourceToAgreementTypeMap[sourceName] !== undefined
        );
      })
      .map((perm) => {
        const sourceName = perm.packageByPackage?.sourceBySource?.name;
        const agreementName = perm.packageByPackage?.name;

        if (!sourceName || !agreementName) {
          return null;
        }

        return {
          name: sourceToAgreementTypeMap[sourceName],
          agreement: agreementName,
        };
      })
      .filter((perm): perm is { name: TAgreementType; agreement: string } => {
        return (
          perm !== null &&
          perm.name !== undefined &&
          perm.agreement !== undefined
        );
      }) || [];

  const licenceAgreement = filteredPermissions[0]?.name;
  const originalSourceName = licenceAgreement
    ? agreementTypeToSourceMap[licenceAgreement]
    : null;

  const selectedSource = sourcesData?.source.find(
    (source) => source.name === originalSourceName,
  );

  const sourceId = selectedSource?.id;

  return { licenceAgreement, sourceId };
}
