export const selectorsData = {
  756: {
    future: {
      grid: [
        "chgexch",
        "oichg",
        "oidate",
        "expiry",
        "volmprior",
        "chgtick",
        "asksize",
        "lastsize",
        "bidsize",
        "close",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "oi",
        "last",
        "lasttime",
        "ask",
        "bid",
        "fv-mid",
        "chgfv-mid",
      ],
      formula: [
        "chgexch",
        "volmprior",
        "asksize",
        "bidsize",
        "close",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "last",
        "ask",
        "bid",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "fv-mid",
        "chgfv-mid",
      ],
      code: [
        "symbol-exch-ticker",
        "exchange-id",
        "symbol-ticker-alias",
        "activity-datetime",
        "month",
        "type",
        "symbol-ticker",
        "product",
        "product-type",
        "implied-month-offset",
        "trade-official-time",
        "exchange",
        "display-precision",
      ],
      metadata: ["contract-size", "contract-units", "prodname"],
    },
    inter_month_spreads: {
      grid: [
        "chgexch",
        "expiry",
        "volmprior",
        "chgtick",
        "asksize",
        "lastsize",
        "bidsize",
        "close",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "last",
        "lasttime",
        "ask",
        "bid",
        "fv-mid",
        "chgfv-mid",
      ],
      formula: [
        "chgexch",
        "volmprior",
        "asksize",
        "bidsize",
        "close",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "last",
        "ask",
        "bid",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "fv-mid",
        "chgfv-mid",
      ],
      code: [
        "symbol-exch-ticker",
        "exchange-id",
        "activity-datetime",
        "month",
        "symbol-ticker",
        "product",
        "product-type",
        "implied-month-offset",
        "trade-official-time",
        "exchange",
        "display-precision",
      ],
      metadata: ["contract-size", "contract-units", "prodname"],
    },
    monthly: {
      grid: [
        "second-effective",
        "pricing-days",
        "fv",
        "first-effective",
        "fv-mid",
        "close",
        "total-pricing-days",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "mm-nyc",
        "mm-lon",
        "mm-sin",
      ],
      formula: [
        "second-effective",
        "pricing-days",
        "fv",
        "first-effective",
        "fv-mid",
        "close",
        "total-pricing-days",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "mm-nyc",
        "mm-lon",
        "mm-sin",
      ],
      code: [
        "end-with-roll",
        "exchange-id",
        "end-date",
        "month",
        "product",
        "product-type",
        "start-date",
      ],
    },
    fx: {
      grid: [
        "days-before",
        "days-total",
        "settle-date",
        "days-end",
        "value-after",
        "first-used",
        "value-before",
        "fv",
        "fv-mid",
        "last-used",
        "days-start",
      ],
      formula: [
        "days-before",
        "days-total",
        "value-after",
        "value-before",
        "fv",
        "fv-mid",
      ],
      code: [
        "days-before",
        "days-total",
        "value-after",
        "value-before",
        "fv",
        "fv-mid",
      ],
    },
  },
  686: {
    future: {
      grid: [
        "chgexch",
        "oidate",
        "expiry",
        "volmprior",
        "chgtick",
        "asksize",
        "lastsize",
        "bidsize",
        "settle-chg",
        "fv-mid",
        "chgfv-mid",
        "close",
        "settle-today",
        "settle-prior",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "oi",
        "last",
        "lasttime",
        "ask",
        "bid",
      ],
      formula: [
        "chgexch",
        "volmprior",
        "asksize",
        "bidsize",
        "close",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "last",
        "ask",
        "bid",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "fv-mid",
        "chgfv-mid",
      ],
      code: [
        "symbol-exch-ticker",
        "exchange-id",
        "symbol-ticker-alias",
        "activity-datetime",
        "month",
        "symbol-ticker",
        "product",
        "product-type",
        "implied-month-offset",
        "trade-official-time",
        "exchange",
        "display-precision",
      ],
      metadata: ["contract-size", "contract-units", "prodname"],
    },
    inter_month_spreads: {
      grid: [
        "chgexch",
        "expiry",
        "volmprior",
        "chgtick",
        "asksize",
        "lastsize",
        "bidsize",
        "close",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "last",
        "lasttime",
        "ask",
        "bid",
        "fv-mid",
        "chgfv-mid",
      ],
      formula: [
        "chgexch",
        "volmprior",
        "asksize",
        "bidsize",
        "close",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "last",
        "ask",
        "bid",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "fv-mid",
        "chgfv-mid",
      ],
      code: [
        "symbol-exch-ticker",
        "exchange-id",
        "activity-datetime",
        "month",
        "symbol-ticker",
        "product",
        "product-type",
        "implied-month-offset",
        "trade-official-time",
        "exchange",
        "display-precision",
      ],
      metadata: ["contract-size", "contract-units", "prodname"],
    },
    monthly: {
      grid: [
        "second-effective",
        "pricing-days",
        "fv",
        "first-effective",
        "fv-mid",
        "close",
        "total-pricing-days",
        "settle-today",
        "settle-prior",
        "settle-chg",
      ],
      formula: [
        "second-effective",
        "pricing-days",
        "fv",
        "first-effective",
        "fv-mid",
        "close",
        "total-pricing-days",
        "settle-today",
        "settle-prior",
        "settle-chg",
      ],
      code: [
        "end-with-roll",
        "exchange-id",
        "end-date",
        "month",
        "product",
        "product-type",
        "start-date",
      ],
    },
    fx: {
      grid: [],
      formula: [],
      code: [],
    },
  },
  270: {
    future: {
      grid: [
        "chgexch",
        "oichg",
        "oidate",
        "expiry",
        "volmprior",
        "chgtick",
        "asksize",
        "lastsize",
        "bidsize",
        "close",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "oi",
        "last",
        "lasttime",
        "ask",
        "bid",
        "fv-mid",
        "chgfv-mid",
      ],
      formula: [
        "chgexch",
        "volmprior",
        "asksize",
        "bidsize",
        "close",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "last",
        "ask",
        "bid",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "fv-mid",
        "chgfv-mid",
      ],
      code: [
        "symbol-exch-ticker",
        "exchange-id",
        "symbol-ticker-alias",
        "activity-datetime",
        "month",
        "type",
        "symbol-ticker",
        "product",
        "product-type",
        "implied-month-offset",
        "trade-official-time",
        "exchange",
        "display-precision",
      ],
      metadata: ["contract-size", "contract-units", "prodname"],
    },
    inter_month_spreads: {
      grid: [
        "chgexch",
        "expiry",
        "volmprior",
        "chgtick",
        "asksize",
        "lastsize",
        "bidsize",
        "close",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "last",
        "lasttime",
        "ask",
        "bid",
        "fv-mid",
        "chgfv-mid",
      ],
      formula: [
        "chgexch",
        "volmprior",
        "asksize",
        "bidsize",
        "close",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "last",
        "ask",
        "bid",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "fv-mid",
        "chgfv-mid",
      ],
      code: [
        "symbol-exch-ticker",
        "exchange-id",
        "activity-datetime",
        "month",
        "symbol-ticker",
        "product",
        "product-type",
        "implied-month-offset",
        "trade-official-time",
        "exchange",
        "display-precision",
      ],
      metadata: ["contract-size", "contract-units", "prodname"],
    },
    monthly: {
      grid: [
        "second-effective",
        "pricing-days",
        "fv",
        "first-effective",
        "fv-mid",
        "close",
        "total-pricing-days",
        "settle-today",
        "settle-prior",
        "settle-chg",
      ],
      formula: [
        "second-effective",
        "pricing-days",
        "fv",
        "first-effective",
        "fv-mid",
        "close",
        "total-pricing-days",
        "settle-today",
        "settle-prior",
        "settle-chg",
      ],
      code: [
        "end-with-roll",
        "exchange-id",
        "end-date",
        "month",
        "product",
        "product-type",
        "start-date",
      ],
    },
    fx: {
      grid: [
        "days-before",
        "days-total",
        "settle-date",
        "days-end",
        "value-after",
        "first-used",
        "value-before",
        "fv",
        "fv-mid",
        "last-used",
        "days-start",
      ],
      formula: [
        "days-before",
        "days-total",
        "value-after",
        "value-before",
        "fv",
        "fv-mid",
      ],
      code: [
        "days-before",
        "days-total",
        "value-after",
        "value-before",
        "fv",
        "fv-mid",
      ],
    },
  },
  1321: {
    future: {
      grid: [
        "chgexch",
        "oichg",
        "oidate",
        "expiry",
        "volmprior",
        "chgtick",
        "asksize",
        "lastsize",
        "bidsize",
        "close",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "oi",
        "last",
        "lasttime",
        "ask",
        "bid",
        "fv-mid",
        "chgfv-mid",
      ],
      formula: [
        "chgexch",
        "volmprior",
        "asksize",
        "bidsize",
        "close",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "last",
        "ask",
        "bid",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "fv-mid",
        "chgfv-mid",
      ],
      code: [
        "symbol-exch-ticker",
        "exchange-id",
        "symbol-ticker-alias",
        "activity-datetime",
        "month",
        "type",
        "symbol-ticker",
        "product",
        "product-type",
        "implied-month-offset",
        "trade-official-time",
        "exchange",
        "display-precision",
      ],
      metadata: ["contract-size", "contract-units", "prodname"],
    },
    inter_month_spreads: {
      grid: [
        "chgexch",
        "expiry",
        "volmprior",
        "chgtick",
        "asksize",
        "lastsize",
        "bidsize",
        "close",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "last",
        "lasttime",
        "ask",
        "bid",
        "fv-mid",
        "chgfv-mid",
      ],
      formula: [
        "chgexch",
        "volmprior",
        "asksize",
        "bidsize",
        "close",
        "volm",
        "high",
        "chgfv",
        "chg%exch",
        "fv",
        "low",
        "wav",
        "last",
        "ask",
        "bid",
        "settle-today",
        "settle-prior",
        "settle-chg",
        "fv-mid",
        "chgfv-mid",
      ],
      code: [
        "symbol-exch-ticker",
        "exchange-id",
        "activity-datetime",
        "month",
        "symbol-ticker",
        "product",
        "product-type",
        "implied-month-offset",
        "trade-official-time",
        "exchange",
        "display-precision",
      ],
      metadata: ["contract-size", "contract-units", "prodname"],
    },
    monthly: {
      grid: [
        "second-effective",
        "pricing-days",
        "fv",
        "first-effective",
        "fv-mid",
        "close",
        "total-pricing-days",
        "settle-today",
        "settle-prior",
        "settle-chg",
      ],
      formula: [
        "second-effective",
        "pricing-days",
        "fv",
        "first-effective",
        "fv-mid",
        "close",
        "total-pricing-days",
        "settle-today",
        "settle-prior",
        "settle-chg",
      ],
      code: [
        "end-with-roll",
        "exchange-id",
        "end-date",
        "month",
        "product",
        "product-type",
        "start-date",
      ],
    },
    fx: {
      grid: [
        "days-before",
        "days-total",
        "settle-date",
        "days-end",
        "value-after",
        "first-used",
        "value-before",
        "fv",
        "fv-mid",
        "last-used",
        "days-start",
      ],
      formula: [
        "days-before",
        "days-total",
        "value-after",
        "value-before",
        "fv",
        "fv-mid",
      ],
      code: [
        "days-before",
        "days-total",
        "value-after",
        "value-before",
        "fv",
        "fv-mid",
      ],
    },
  },
} as const;
