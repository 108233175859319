import { Box, Link, Stack, Table } from "@mui/joy";
import { usePageId } from "../../../market-pages";
import { ModalButtons } from "../Buttons";
import { GridModalContainer } from "../GridModal";
import { GridModalSelectionInfo } from "../modalComponents";
import { useAtomValue } from "jotai";
import {
  gridModalDockviewAtom,
  store,
} from "../../../calculations-worker/sharedStores";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import {
  type TConditionalRule,
  ruleValidation,
  headerStyle,
  useResetLimitSelection,
  getNodePeriod,
} from "./helpers";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth0 } from "@auth0/auth0-react";
import {
  relativeRowToRowId,
  rowIdToRelativeRow,
  rowStringToCode,
} from "../../periodHelpers";
import {
  DndContext,
  type DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useCallback, useState } from "react";
import { client } from "../../../../triplit/triplit";
import { generateNKeysBetween } from "fractional-indexing";
import type { TConditionalFormattingRules } from "../../../../triplit/schema";
import toast from "react-hot-toast";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { collisionDetection } from "../../../utils";
import {
  ConditionalFormattingHeaders,
  ConditionalFormattingRow,
} from "./CreateRule";
import {
  conditionalFormattingRulesAtom,
  type useConditionalFormattingRules,
} from "../formatCellHelpers";
import { useGridApi } from "../../../../shared/hooks";
import type { GridApi } from "ag-grid-community";
import { addProp } from "remeda";

export type TConditionalRuleFormatted = TConditionalRule & {
  ids?: Array<string>;
};

function formatRules(
  gridApi: GridApi | null,
  rules: ReturnType<typeof useConditionalFormattingRules>["formatted"] | null,
  filter: "page" | "range",
  cells: { columnId: string; rowId: string }[],
) {
  if (!gridApi || !rules) return [];

  const formattingRules = Object.values(rules).flat();

  const formatted = formattingRules
    ?.map((rule) => {
      if (filter === "range") {
        if (
          !cells.find(
            (cell) =>
              cell.columnId === rule.columnId && cell.rowId === rule.rowId,
          )
        ) {
          return;
        }
      }

      const parts = rule.limit.includes(":")
        ? rule.limit.split(":")
        : undefined;

      const limitRef = parts
        ? {
            columnId: parts[0],
            rowId: parts[1],
            name: gridApi?.getColumn(parts[0])?.getColDef()?.headerName || "",
            period: getNodePeriod(
              gridApi?.getRowNode(
                rowStringToCode(relativeRowToRowId[parts[1]]),
              ),
            ),
          }
        : undefined;

      return {
        id: rule.id,
        rule: rule.rule as TConditionalRule["rule"],
        limit: rule.limit.includes(":") ? "" : rule.limit,
        limitRef,
        formatting: {
          bgColor: rule.bgColor,
          boldText: rule.boldText,
          invertTextColor: rule.invertTextColor,
        },
        note: rule.note,
        stopIfTrue: rule.stopIfTrue,
        columnId: rule.columnId,
        rowId: rule.rowId,
      } satisfies TConditionalRule;
    })
    .filter(Boolean);

  // Loop over the rules, and group them if all their properties are the same except for the ID. The result would be an array of TCoditionalRule, with the ID being an array of IDs.
  const grouped = formatted.reduce(
    (acc: TConditionalRuleFormatted[], rule) => {
      const match = acc.find(
        (item) =>
          item.rule === rule.rule &&
          item.limit === rule.limit &&
          item.limitRef === rule.limitRef &&
          item.formatting?.bgColor === rule.formatting?.bgColor &&
          item.formatting.boldText === rule.formatting.boldText &&
          item.formatting.invertTextColor === rule.formatting.invertTextColor &&
          item.note === rule.note &&
          item.stopIfTrue === rule.stopIfTrue,
      ) as TConditionalRuleFormatted | undefined;

      if (match) {
        match?.ids?.push(rule.id);
      } else {
        const newRule = addProp(rule, "ids", [rule.id]);
        acc.push(newRule);
      }

      return acc;
    },
    [] satisfies Array<TConditionalFormattingRules>,
  );

  return grouped;
}

export function ManageConditionalFormattingRules() {
  const pageId = usePageId();

  const dockviewValue = useAtomValue(gridModalDockviewAtom);
  const rangeSelection = dockviewValue?.conditional?.selectedRange || [];

  const conditionalFormattingRules = store.get(conditionalFormattingRulesAtom);

  const [rulesToShow, setRulesToShow] = useState<"page" | "range">("range");

  const { getApi } = useGridApi();
  const gridApi = getApi();

  const cells =
    rangeSelection
      .flatMap((range) => {
        return range.map((cell) => {
          const rowNode = gridApi?.getRowNode(cell.rowId);
          const period = getNodePeriod(rowNode);
          const rowId = period ? rowIdToRelativeRow[period] : rowNode?.id;

          if (rowId) {
            return {
              columnId: cell.columnId,
              rowId,
            };
          }
        });
      })
      .filter(Boolean) || [];

  const form = useForm<{
    rules: TConditionalRuleFormatted[];
  }>({
    defaultValues: {
      rules: formatRules(
        gridApi,
        conditionalFormattingRules,
        rulesToShow,
        cells,
      ),
    },
    resolver: zodResolver(ruleValidation),
  });

  const { fields, replace, remove } = useFieldArray({
    control: form.control,
    name: "rules",
  });

  const formData = useWatch({
    control: form.control,
    name: "rules",
  });

  const auth = useAuth0();
  const userId = auth.user?.sub;

  const sensors = useSensors(useSensor(MouseSensor));

  function filterRulesBy(filter: "page" | "range") {
    setRulesToShow(filter);
    form.reset({
      rules: formatRules(gridApi, conditionalFormattingRules, filter, cells),
    });
  }

  // Ideally we'd use move/swap, but in some cases we need to do multiple, and it is not recommended to do so by the docs, so replace is better otherwise we risk the rules getting messed up.
  const reorderRulesHanlder = useCallback(
    (e: DragEndEvent) => {
      if (!e.over || !fields?.length) return;

      const draggedRuleId = e.active.id.toString();
      const dropTargetId = e.over.id.toString();

      const draggedRule = fields.find((rule) => rule.id === draggedRuleId);
      const dropTargetRule = fields.find((rule) => rule.id === dropTargetId);

      if (!draggedRule || !dropTargetRule)
        throw new Error("dragged or drop target rule not found");

      const draggedRuleIndex = fields.findIndex(
        (rule) => rule.id === draggedRuleId,
      );
      const dropTargetIndex = fields.findIndex(
        (rule) => rule.id === dropTargetId,
      );

      const direction = draggedRuleIndex < dropTargetIndex ? "top" : "bottom";

      if (draggedRuleId === dropTargetId) {
        return;
      }

      // Item is moved to be the first item.
      if (direction === "bottom" && dropTargetIndex === 0) {
        const current = [...formData];
        const draggedRule = current.splice(draggedRuleIndex, 1)[0];
        current.unshift(draggedRule);
        replace(current);
      }

      // Item is moved to be the last item.
      if (direction === "top" && dropTargetIndex === fields.length - 1) {
        const current = [...formData];
        const draggedRule = current.splice(draggedRuleIndex, 1)[0];
        current.push(draggedRule);
        replace(current);
      }

      const current = [...formData];
      const draggedRuleSpliced = current.splice(draggedRuleIndex, 1)[0];
      current.splice(dropTargetIndex, 0, draggedRuleSpliced);
      replace(current);
    },
    [fields, replace, formData],
  );

  const fieldIds = formData.map((data) => data.id);

  // When rules are grouped, they have an "ids" property. fieldIds would not take this into account. So we need to loop over the fieldIds, check which item in "fields" has an "ids" property with the ID in it, and return an array of TConditionalRuleFormatted with the "ids" property. This is what we will use to update all the grouped rules.
  const formDataGrouped = fieldIds
    .map((id) => {
      const data = formData.find((data) => data.id === id);
      const fieldIds = fields.find((field) => field?.ids?.includes(id))?.ids;
      if (fieldIds && data) {
        return {
          ...data,
          ids: fieldIds,
        };
      }
    })
    .filter(Boolean) satisfies Array<TConditionalRuleFormatted>;

  const resetLimitSelection = useResetLimitSelection();

  async function handleSave() {
    try {
      resetLimitSelection();

      if (!pageId) throw new Error("pageId is not defined");
      if (!userId) throw new Error("userId is not defined");

      const query = client
        .query("conditionalFormattingRules")
        .where("pageId", "=", pageId)
        .build();
      const existingRulesResponse = await client.fetch(query);
      const allExistingRules = existingRulesResponse || [];

      const cellIds = cells.map((cell) => `${cell.columnId}-${cell.rowId}`);

      // Filter existing rules to only include ones in cellIds.
      const existingRules =
        rulesToShow === "page"
          ? allExistingRules
          : allExistingRules.filter((rule) =>
              cellIds.includes(`${rule.columnId}-${rule.rowId}`),
            );

      // Delete all rules that are not in formData.
      if (!formData?.length) {
        const deletes = existingRules.map((rule) => {
          return client.delete("conditionalFormattingRules", rule.id);
        });

        await Promise.all(deletes);
      }

      const formIds = formDataGrouped
        .flatMap((data) => data.ids)
        .filter(Boolean);

      // Check if any rules have been removed (not in "formDataGrouped.ids" but in existingRules).
      const rulesToDelete = existingRules
        .filter((rule) => {
          const ruleId = rule.id;
          const ruleData = formDataGrouped.find((data) =>
            data.ids?.includes(ruleId),
          );
          return !ruleData;
        })
        .map((rule) => rule.id);

      // Sort the existing rules according to the order of "fields", and filter out the ones that are to be deleted.
      const sortedExistingRules = formIds
        .map((id) => existingRules.find((rule) => rule.id === id))
        .filter(Boolean)
        .filter((rule) => !rulesToDelete.includes(rule.id));

      const priorityIdxs = generateNKeysBetween(
        null,
        null,
        sortedExistingRules.length,
      );

      // Update sortedExistingRules with data in "formData".
      const updatedRules = sortedExistingRules
        .map((existing, index) => {
          if (
            rulesToShow === "range" &&
            !cellIds.includes(`${existing.columnId}-${existing.rowId}`)
          )
            return;

          const rule = formDataGrouped.find(
            (data) =>
              data?.ids?.includes(existing.id) || data.id === existing.id,
          );

          if (rule) {
            const limit = rule?.limitRef
              ? `${rule.limitRef.columnId}:${rule.limitRef.rowId}`
              : rule.limit;

            const updated = {
              id: existing.id,
              rule: rule.rule,
              limit,
              priorityIdx: priorityIdxs[index],
              bgColor: rule.formatting.bgColor || undefined,
              boldText: rule.formatting.boldText,
              invertTextColor: rule.formatting.invertTextColor,
              note: rule.note,
              stopIfTrue: rule.stopIfTrue,
              columnId: existing.columnId,
              rowId: existing.rowId || "",
              userId,
              pageId,
            } satisfies TConditionalFormattingRules;

            return updated;
          }
        })
        .filter(Boolean);

      const deletes = rulesToDelete.map((id) => {
        return client.delete("conditionalFormattingRules", id);
      });

      const updates = updatedRules
        .map((rule) => {
          return client.update(
            "conditionalFormattingRules",
            rule.id,
            (existing) => {
              existing.rule = rule.rule;
              existing.limit = rule.limit;
              existing.priorityIdx = rule.priorityIdx;
              existing.bgColor = rule.bgColor;
              existing.boldText = rule.boldText;
              existing.invertTextColor = rule.invertTextColor;
              existing.note = rule.note;
              existing.stopIfTrue = rule.stopIfTrue;
            },
          );
        })
        .filter(Boolean);

      await Promise.all([...deletes, ...updates]);
    } catch (error) {
      console.error(error);
      toast.error("Failed to save conditional formatting rules");
    }
  }

  return (
    <GridModalContainer
      panel="conditional"
      body={
        <>
          <GridModalSelectionInfo selectedRange={rangeSelection} />
          <form onSubmit={form.handleSubmit(handleSave)}>
            <Box display="flex" gap={1} alignItems={"center"} mt={1}>
              <Link
                onClick={() => {
                  filterRulesBy("page");
                }}
                fontSize="sm"
                sx={(theme) => ({
                  color:
                    rulesToShow === "page"
                      ? "var(--artis-orange)"
                      : theme.palette.neutral[400],
                  textDecoration:
                    rulesToShow === "range" ? "underline" : "none",
                  cursor: "pointer",
                  display: "inline",
                  "&:hover": {
                    color: "var(--artis-orange)",
                    textDecoration:
                      rulesToShow === "range" ? "underline" : "none",
                  },
                })}
              >
                Page
              </Link>
              |
              <Link
                onClick={() => {
                  filterRulesBy("range");
                }}
                fontSize="sm"
                sx={(theme) => ({
                  color:
                    rulesToShow === "range"
                      ? "var(--artis-orange)"
                      : theme.palette.neutral[400],
                  textDecoration: rulesToShow === "page" ? "underline" : "none",
                  cursor: "pointer",
                  display: "inline",
                  "&:hover": {
                    color: "var(--artis-orange)",
                    textDecoration:
                      rulesToShow === "page" ? "underline" : "none",
                  },
                })}
              >
                Range
              </Link>
            </Box>
            <Box pt={2}>
              <Stack gap={2} mb={4}>
                <DndContext
                  sensors={sensors}
                  onDragEnd={reorderRulesHanlder}
                  collisionDetection={collisionDetection}
                >
                  <SortableContext
                    items={fields}
                    strategy={verticalListSortingStrategy}
                  >
                    <Table
                      sx={(theme) => ({
                        tableLayout: "fixed",
                        minWidth: 950,
                        td: {
                          minWidth: 40,
                          textAlign: "center",
                        },
                        "tr:first-of-type td": {
                          paddingTop: 1.5,
                        },
                        th: headerStyle(theme),
                        borderSpacing: "6px 4px",
                      })}
                      noWrap
                      borderAxis="none"
                    >
                      <ConditionalFormattingHeaders type="manage" />
                      <tbody>
                        {fields.map((rule, index) => (
                          <ConditionalFormattingRow
                            key={rule.id}
                            form={form}
                            rule={rule}
                            index={index}
                            remove={remove}
                            type="manage"
                          />
                        ))}
                      </tbody>
                    </Table>
                  </SortableContext>
                </DndContext>
              </Stack>
            </Box>
          </form>
        </>
      }
      buttons={
        <ModalButtons
          parentPanel={"conditional"}
          onCancel={() => {
            resetLimitSelection();
          }}
          onSave={() => handleSave()}
        />
      }
    />
  );
}
