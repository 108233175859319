import { useAuth0 } from "@auth0/auth0-react";
import { Box, DialogTitle, Typography } from "@mui/joy";
import { nanoid } from "nanoid";
import { client } from "../../../triplit/triplit";
import type { TPageProduct } from "../../../triplit/schema";
import { columnWidth } from "../../globals";
import { usePageId, useCurrentPageProducts } from "../../market-pages";
import { modalAtom, store } from "../../sharedHooks";
import { ProductSelect } from "../../components/ProductSelect";
import { useAutocomplete } from "../../components/Autocomplete/hooks";
import { defaultFieldNameSelector } from "../../utils";
import { generateIdxs } from "../../utils";
import type { TOption } from "../../components/Autocomplete/Autocomplete";
import type { GridApi } from "ag-grid-community";
import type { TData } from "../../calculations-worker/sharedStores";
import { Button } from "@mui/joy";
import { useState, type MouseEventHandler } from "react";
import { useSetAtom } from "jotai";
import { useGridApi } from "../../../shared/hooks";
import { ApolloClientStore } from "../../../auth/store";
import {
  ProductFragmentGrid,
  gridProductsByIds,
} from "../../curve-management/graphql";
import { getFragmentData } from "../../../__generated__/gql";

function CancelButton({
  onClick,
  label = "Cancel",
}: {
  onClick?: MouseEventHandler<HTMLElement>;
  label?: string;
}) {
  const setModal = useSetAtom(modalAtom);
  return (
    <Button
      size="sm"
      variant="outlined"
      color="neutral"
      type="button"
      onClick={(e) => {
        onClick?.(e);
        setModal(null);
      }}
    >
      {label}
    </Button>
  );
}

function SaveButton({
  onClick,
  disabled,
  loading,
  label = "Save",
  maxWidth = "100%",
}: {
  onClick: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  loading?: boolean;
  label: string;
  maxWidth?: number | string;
}) {
  const setModal = useSetAtom(modalAtom);
  return (
    <Button
      type="submit"
      disabled={disabled}
      loading={loading}
      onClick={(e) => {
        onClick(e);
        setModal(null);
      }}
      variant="solid"
      color="primary"
      size="sm"
      sx={{
        width: "100%",
        maxWidth,
      }}
    >
      {label}
    </Button>
  );
}

interface HandleAddProductsToPageParams {
  api?: GridApi<TData> | null;
  userId: string;
  pageId: string;
  currentPageProducts: TPageProduct[];
  selectedValues: TOption[];
}

async function handleAddProductsToPage({
  api,
  userId,
  pageId,
  currentPageProducts,
  selectedValues,
  setLoading,
}: HandleAddProductsToPageParams & { setLoading: (loading: boolean) => void }) {
  if (!userId) throw new Error("No userId in handleAddProductsToPage");
  if (!pageId) throw new Error("No pageId in handleAddProductsToPage");

  setLoading(true);

  const idxs = generateIdxs({
    api,
    currentPageProducts,
    amount: selectedValues.length,
  });

  const newProducts = selectedValues.map((product, index) => {
    const artis_type = product.metadata?.artis_type;

    return {
      id: nanoid(),
      idx: idxs[index],
      productId: product.value,
      userId,
      pageId,
      columnType: "product",
      columnWidth,
      columnFieldSelector: artis_type
        ? defaultFieldNameSelector(artis_type)
        : undefined,
      decimalPlaces: undefined,
      thousandsSeparator: undefined,
    } satisfies TPageProduct;
  });

  try {
    console.log("Adding products to page in transaction", newProducts);
    const t1 = performance.now();
    await client.transact(async (tx) => {
      for (const product of newProducts) {
        await tx.insert("pageProducts", product);
      }
    });
    const t2 = performance.now();
    console.log("Transaction time:", t2 - t1, "ms");

    console.log("Products added successfully in transaction");
  } catch (error) {
    console.error("Error adding products to page:", error);
  } finally {
    setLoading(false);
  }
}

export default function InsertCurveModal({ allowCancel = true }) {
  const { getApi } = useGridApi();
  const api = getApi();
  const pageId = usePageId() || "";
  const { user } = useAuth0();
  const userId = user?.sub;

  const { results: currentPageProducts } = useCurrentPageProducts();

  const productIds = currentPageProducts
    .map((product) => product.productId)
    .filter(Boolean);

  const prodRes = store.get(ApolloClientStore)?.readQuery({
    query: gridProductsByIds,
    variables: { ids: productIds, folioUser: userId || "" },
  })?.product;

  const dbProducts = getFragmentData(ProductFragmentGrid, prodRes);

  const customerCurveIds = dbProducts
    ?.map((product) => {
      if (product?.artis_type === "customer_curve") return product.id;
    })
    .filter(Boolean);

  const useAutocompleteProps = useAutocomplete({ multiple: true });
  const {
    multipleState: [selectedValues, setSelectedValues],
  } = useAutocompleteProps;

  const [loading, setLoading] = useState(false);
  const handleSave = async () => {
    if (userId) {
      setLoading(true);
      try {
        await handleAddProductsToPage({
          api,
          userId,
          pageId,
          currentPageProducts,
          selectedValues,
          setLoading,
        });
        setSelectedValues([]);
      } catch (error) {
        console.error("Failed to save products to page:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "22dvw",
        maxWidth: 400,
        minWidth: 300,
      }}
    >
      <DialogTitle sx={{ alignItems: "center" }}>
        Add to current Page
      </DialogTitle>
      <Typography>Select Curve(s)</Typography>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          gap: 1,
        }}
      >
        <ProductSelect
          multiple
          {...useAutocompleteProps}
          excludeIds={customerCurveIds}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          justifyContent: "flex-start",
        }}
      >
        <SaveButton
          label="Add"
          disabled={!userId || loading || !selectedValues.length}
          loading={loading}
          onClick={handleSave}
        />
        {api && allowCancel && <CancelButton />}
      </Box>
    </Box>
  );
}
