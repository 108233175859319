import type { TIndicatorsState } from "../webapp/live-charts/utils";
import type { usePage } from "../webapp/market-pages/pagesHooks";
import type { DockviewApi, SerializedDockview } from "dockview";

// Determine if we are running in a Node.js environment
const isNode =
  typeof process !== "undefined" &&
  process.versions != null &&
  process.versions.node != null;

type BrotliModule = {
  compress: (data: Uint8Array, options?: { quality: number }) => Uint8Array;
  decompress: (data: Uint8Array) => Uint8Array;
};

let brotli: BrotliModule | undefined;
let brotliPromise: Promise<BrotliModule> | undefined;

if (isNode) {
  // Node.js environment
  const { compress, decompress } = require("brotli");
  brotli = { compress, decompress };
} else {
  // Browser environment
  brotliPromise = (async () => {
    const { default: init, ...brotliWasm } = await import(
      "../../node_modules/brotli-wasm/pkg.web/brotli_wasm"
    );
    const wasmUrl = await import(
      "../../node_modules/brotli-wasm/pkg.web/brotli_wasm_bg.wasm?url"
    );
    await init(wasmUrl.default);
    return brotliWasm;
  })();
}

// Polyfills for atob and btoa in Node.js environment
if (isNode) {
  if (typeof global.atob === "undefined") {
    global.atob = (str) => Buffer.from(str, "base64").toString("binary");
  }
  if (typeof global.btoa === "undefined") {
    global.btoa = (str) => Buffer.from(str, "binary").toString("base64");
  }
}

const prefix = "BROTLI:";
const quality = 11;

async function ensureBrotliLoaded(): Promise<BrotliModule> {
  if (!brotli && brotliPromise) {
    brotli = await brotliPromise;
  }
  if (!brotli) {
    throw new Error("Brotli module not loaded");
  }
  return brotli;
}

export async function compressJsonString(json: string): Promise<string> {
  const brotli = await ensureBrotliLoaded();

  // Encode JSON string to Uint8Array
  const uncompressedData = new TextEncoder().encode(json);

  // Compress data
  const compressedDataBytes = brotli.compress(uncompressedData, { quality });

  // Convert compressed data to base64 string without using spread operator to save memory
  let binaryString = "";
  for (let i = 0; i < compressedDataBytes.length; i++) {
    binaryString += String.fromCharCode(compressedDataBytes[i]);
  }
  const compressedDataBase64 = btoa(binaryString);

  return `${prefix}${compressedDataBase64}`;
}

function isStr(value: unknown): value is string {
  return typeof value === "string";
}

async function decompressString(
  encodedRaw: string | undefined | null,
): Promise<string | undefined | null> {
  if (!isStr(encodedRaw)) {
    return encodedRaw;
  }
  if (!encodedRaw.startsWith(prefix)) {
    return encodedRaw;
  }

  const brotli = await ensureBrotliLoaded();
  const encoded = encodedRaw.slice(prefix.length);

  // Decode base64 string to Uint8Array
  const binaryString = atob(encoded);
  const compressedData = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    compressedData[i] = binaryString.charCodeAt(i);
  }

  // Decompress data
  const decompressedDataBytes = brotli.decompress(compressedData);

  // Decode Uint8Array to string
  const decompressedData = new TextDecoder().decode(decompressedDataBytes);

  return decompressedData;
}

export type TPageFormatting = Record<
  string,
  {
    color: string | null;
    boldText: boolean;
    invertTextColor: boolean;
  }
>;

async function parseFormattingJson(json: string): Promise<TPageFormatting> {
  try {
    if (json === "") {
      return {};
    }
    const decompressed = await decompressString(json);
    const parsed = isStr(decompressed) ? JSON.parse(decompressed) : {};
    return (parsed || {}) as TPageFormatting;
  } catch (e) {
    console.error("Failed to parse formatting json", json, e);
    return {};
  }
}

export async function parsePageFormatting(
  page: Partial<ReturnType<typeof usePage>["results"]>,
): Promise<{
  cellHighlights: TPageFormatting;
  columnHighlights: TPageFormatting;
  periodHighlights: TPageFormatting;
}> {
  const cellHighlightsJson = page?.cellHighlights || "";
  const columnHighlightsJson = page?.columnHighlights || "";
  const periodHighlightsJson = page?.periodHighlights || "";

  const cellHighlights = isStr(cellHighlightsJson)
    ? await parseFormattingJson(cellHighlightsJson)
    : {};
  const columnHighlights = isStr(columnHighlightsJson)
    ? await parseFormattingJson(columnHighlightsJson)
    : {};
  const periodHighlights = isStr(periodHighlightsJson)
    ? await parseFormattingJson(periodHighlightsJson)
    : {};

  return {
    cellHighlights,
    columnHighlights,
    periodHighlights,
  };
}

export async function compressedDockviewState(
  dockviewApi: DockviewApi,
): Promise<string> {
  const state = JSON.stringify(dockviewApi.toJSON());
  const compressed = await compressJsonString(state);
  return compressed;
}

export async function parsedDockviewState(
  compressed: string,
): Promise<SerializedDockview | null> {
  try {
    const state = await decompressString(compressed);
    if (isStr(state)) {
      return JSON.parse(state) as SerializedDockview;
    }
    return null;
  } catch (e) {
    console.error("Failed to parse dockview state", e);
    return null;
  }
}

export async function compressIndicatorsState(
  indicatorsState: TIndicatorsState,
): Promise<string> {
  const compressed = await compressJsonString(JSON.stringify(indicatorsState));
  return compressed;
}

export async function decompressIndicatorsState(
  compressed: string,
): Promise<TIndicatorsState | null> {
  const decompressed = await decompressString(compressed);
  if (isStr(decompressed)) {
    return JSON.parse(decompressed) as TIndicatorsState;
  }
  return null;
}
