import { schema } from "./schema";
import { TriplitClient, type ClientOptions } from "@triplit/client";
import { triplitServerUrl } from "../globals";

export const triplitStorage = "indexeddb";

const clientOpts = {
  schema,
  claimsPath: "triplitClaims",
  serverUrl: triplitServerUrl(),
  storage: triplitStorage,
} as const satisfies ClientOptions<typeof schema>;

export const client = new TriplitClient(clientOpts);
