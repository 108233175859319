import { useQuery } from "@apollo/client";
import { useParams } from "@tanstack/react-router";
import { graphql } from "../../graphql";
import { yesterdayDate } from "../../utils";
import type { TopNav } from "../nav/Components";
import { useQuery as useTriplitQuery } from "@triplit/react";
import { client } from "../../triplit/triplit";

const organisations = graphql(`
  query Organizations($_gte: timestamptz!) {
    organisation(order_by: { sourceBySource: { name: asc } }) {
      id
      allUsers: folio_users_aggregate {
        aggregate {
          count
        }
      }
      activeUsers: folio_users_aggregate(
        where: { sessions: { last_seen: { _gte: $_gte } } }
      ) {
        aggregate {
          count
        }
      }
      sourceBySource {
        id
        name
      }
      is_allowed_global_packages
    }
  }
`);

export const orgUsersQuery = graphql(`
  query OrgUsers($orgs: [Int!]!) {
    folio_user(where: { organisation: { _in: $orgs } }) {
      __typename
      id
    }
  }
`);

export function useOrgUsers(orgIds: number[]) {
  const res = useQuery(orgUsersQuery, {
    variables: { orgs: orgIds || [] },
  });

  return res || [];
}

export function useOrgs() {
  const yesterday = yesterdayDate();
  const res = useQuery(organisations, {
    variables: { _gte: yesterday },
  });
  return res;
}

export function useOrgId() {
  const params = useParams({
    strict: false,
  });
  if (!("org" in params) || typeof params.org === "undefined") return undefined;
  const orgIdString = params.org;
  const orgId = Number.parseInt(orgIdString || "", 10);
  return Number.isNaN(orgId) ? undefined : orgId;
}

export function useOrg() {
  const orgId = useOrgId();

  // TODO use fragments and new query
  const orgs = useOrgs();

  const org = orgs.data?.organisation.find((o) => o.id === orgId);
  return org;
}

export function useSingleOrgLinks() {
  const params = useParams({
    strict: false,
  });

  const links: Parameters<typeof TopNav>[number]["links"] = [
    { id: "users", to: "/admin/orgs/$org/users", label: "Users", params },
    {
      id: "permissions",
      to: "/admin/orgs/$org/permissions",
      label: "Permissions",
      params,
    },
    {
      id: "global-permissions",
      to: "/admin/orgs/$org/global-permissions",
      label: "Global Permissions",
      params,
    },
    {
      id: "packages",
      to: "/admin/orgs/$org/packages",
      label: "Packages",
      params,
    },
    {
      id: "products",
      to: "/admin/orgs/$org/products",
      label: "Products",
      params,
    },
  ];

  return links;
}

export function usePendingOrganisation() {
  const { id } = useParams({ from: "/admin/orgs/new/$id" });

  const { results, fetching, fetchingLocal } = useTriplitQuery(
    client,
    client.query("pendingOrganisation"),
  );

  const pendingOrganisation = results?.find((o) => o.id === id);

  return {
    pendingOrganisation,
    tempOrgId: id,
    fetching,
    fetchingLocal,
  };
}

export const createSourceMutation = graphql(`
	mutation createSource(
		$name: String!
    $source_type: product_source_enum!
    $description: String!
    $alias: String!
    $logo: String!
	) {
		insert_source_one(
			object: {
				name: $name
        source_type: $source_type
        description: $description
        alias: $alias
        logo: $logo
			}
		) {
			id
		}
	}
`);

export const deleteSourceMutation = graphql(`
  mutation deleteSource($id: Int!) {
    delete_source_by_pk(id: $id) {
      id
    }
  }
`);

export const createOrganisationMutation = graphql(`
  mutation createOrganisation(
    $source: Int!
    $is_allowed_global_packages: Boolean!
    $needs_historical_data: Boolean!
  ) {
    insert_organisation_one(
      object: {
        source: $source
        is_allowed_global_packages: $is_allowed_global_packages
        needs_historical_data: $needs_historical_data
      }
    ) {
      id
    }
  }
`);
