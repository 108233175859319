import { atom, useAtom, useAtomValue } from "jotai";
import { gridSettingsTransitionMatch, gridSettingsWidth } from "../globals";
import {
  defaultMonthsRangeSettings,
  genMonthList,
  nthNextCals,
  nthNextHlvs,
  nthNextMonths,
  nthNextQtrs,
  nthNextSeasons,
} from "../market-grid/periodHelpers";
import { londonDate } from "../utils";
import { gridSettingsForRowDataAtom } from "./settingsStore";
import { initGridSettings } from "../market-grid/defaultSettings";

export const gridSettingsVisibleAtom = atom<boolean>(false);
gridSettingsVisibleAtom.debugLabel = "gridSettingsVisibleAtom";

const dateColumnSettingsAtom = atom((get) => {
  const gridSettings = get(gridSettingsForRowDataAtom) || initGridSettings;

  const date = londonDate();
  const monthList = genMonthList(defaultMonthsRangeSettings, date);

  const monthOptions = nthNextMonths(monthList || gridSettings.months);
  const monthValue = (gridSettings.months ?? monthOptions[0][0]).toString();

  const qtrsOptions = nthNextQtrs(monthList, gridSettings.qtrCurrent);
  const qtrsValue = (gridSettings.qtrs ?? qtrsOptions[0][0]).toString();

  const hlvsOptions = nthNextHlvs(monthList, gridSettings.hlvCurrent);
  const hlvsValue = (gridSettings.hlvs ?? hlvsOptions[0][0]).toString();

  const seasonsOptions = nthNextSeasons(monthList, gridSettings.seasonCurrent);
  const seasonsValue = (
    gridSettings.seasons ?? seasonsOptions[0][0]
  ).toString();

  const calsOptions = nthNextCals(monthList, gridSettings.calCurrent);
  const calsValue = (gridSettings.cals ?? calsOptions[0][0]).toString();

  const orderedDateColumnSettings = [
    {
      options: monthOptions,
      value: monthValue,
      valueKey: "months",
      current: null,
      show: null,
      showKey: null,
      currentKey: null,
    },
    {
      options: qtrsOptions,
      value: qtrsValue,
      current: gridSettings.qtrCurrent,
      show: gridSettings.qtrSwitch,
      valueKey: "qtrs",
      showKey: "qtrSwitch",
      currentKey: "qtrCurrent",
    },
    {
      options: hlvsOptions,
      value: hlvsValue,
      current: gridSettings.hlvCurrent,
      show: gridSettings.hlvSwitch,
      valueKey: "hlvs",
      showKey: "hlvSwitch",
      currentKey: "hlvCurrent",
    },
    {
      options: seasonsOptions,
      value: seasonsValue,
      current: gridSettings.seasonCurrent,
      show: gridSettings.seasonSwitch,
      valueKey: "seasons",
      showKey: "seasonSwitch",
      currentKey: "seasonCurrent",
    },
    {
      options: calsOptions,
      value: calsValue,
      current: gridSettings.calCurrent,
      show: gridSettings.calSwitch,
      valueKey: "cals",
      showKey: "calSwitch",
      currentKey: "calCurrent",
    },
  ] as const;

  return {
    orderedDateColumnSettings,
    gridSettings,
    monthOptions,
    monthValue,
    qtrsOptions,
    qtrsValue,
    hlvsOptions,
    hlvsValue,
    seasonsOptions,
    seasonsValue,
    calsOptions,
    calsValue,
  };
});
dateColumnSettingsAtom.debugLabel = "dateColumnSettingsAtom";

export function useGridSettingsVisibility() {
  const [isGridSettingsVisible, setIsGridSettingsVisible] = useAtom(
    gridSettingsVisibleAtom,
  );
  const left = isGridSettingsVisible ? gridSettingsWidth : 0;

  return {
    isGridSettingsVisible,
    setIsGridSettingsVisible,
    left,
    transition: gridSettingsTransitionMatch,
  };
}

export function useDateColumnSettings() {
  return useAtomValue(dateColumnSettingsAtom);
}
