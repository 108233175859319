import type { TManualStoreStorageType } from "../market-grid/statuses/statusLogic";
import { store, UserAtom } from "./sharedStores";
import {
  getManualCellsStore,
  setLatestEditInfoStore,
  setOptimisticUpdatesStore,
} from "./storeLogic";

type TOptimisticCell = {
  product: string;
  offset: number;
  field: string;
  result: number | undefined;
  storageType: TManualStoreStorageType;
  month: string;
  rowId: string;
};

const cellBatch = new Map<string, TOptimisticCell>();
let batchTimeout: NodeJS.Timeout | null = null;

function getCellKey(cell: TOptimisticCell): string {
  return `${cell.product}-${cell.offset}-${cell.field}-${cell.storageType}-${cell.rowId}`;
}

function processBatch(runCalcs: (cells: TOptimisticCell[]) => void) {
  const cells = Array.from(cellBatch.values());
  cellBatch.clear();
  batchTimeout = null;

  runCalcs(cells);

  const user = store.get(UserAtom);
  const now = new Date().toISOString();

  const latestEditedAt = {
    username: user?.username || "",
    firstname: user?.firstname || "",
    lastname: user?.lastname || "",
    editedAt: now,
  };

  for (const cell of cells) {
    const key = {
      productId: cell.product,
      rowId: cell.rowId,
      storageType: cell.storageType,
    };
    const value = { value: cell.result, month: cell.month, editedAt: now };

    const currentValue = getManualCellsStore(key)?.[cell.rowId];

    if (cell.result === currentValue) {
      continue;
    }

    setOptimisticUpdatesStore(key, value);
    setLatestEditInfoStore({ ...key, rowId: cell.rowId }, latestEditedAt);
  }
}

export function processOptimisticCellUpdate(
  cells: TOptimisticCell[],
  runCalcs: (cells: TOptimisticCell[]) => void,
) {
  for (const cell of cells) {
    const key = getCellKey(cell);
    cellBatch.set(key, cell);
  }

  if (!batchTimeout) {
    batchTimeout = setTimeout(() => processBatch(runCalcs), 100);
  }
}
