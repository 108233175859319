import {
  Box,
  Typography,
  Button,
  Modal,
  AspectRatio,
  Skeleton,
} from "@mui/joy";
import { PortableText, type PortableTextComponents } from "@portabletext/react";
import { assetRefToSanityUrl } from "../infoSection/utils";
import type { NewFeature } from "../../../src/__generated__/sanity/graphql-request";
import type { Theme } from "@mui/joy/styles/types";
import { useState } from "react";

type ImageWithSkeletonProps = {
  value: {
    asset: {
      _ref: string;
    };
  };
};

function ImageWithSkeleton({ value }: ImageWithSkeletonProps) {
  const [loading, setLoading] = useState(true);
  const imageUrl = assetRefToSanityUrl(value.asset._ref);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <AspectRatio
      variant="plain"
      sx={{ width: "100%", maxWidth: 800, my: 2, mx: "auto" }}
    >
      <Skeleton loading={loading}>
        <img
          src={imageUrl}
          alt=""
          onLoad={handleImageLoad}
          style={{
            maxWidth: "100%",
            width: "auto",
            height: "auto",
            maxHeight: 800,
            objectFit: "contain",
          }}
        />
      </Skeleton>
    </AspectRatio>
  );
}

export function AnnouncementModal({
  open,
  setClose,
  announcement,
  theme,
}: {
  open: boolean;
  setClose: () => void;
  announcement: NewFeature | null;
  theme: Theme;
}) {
  const components: PortableTextComponents = {
    block: {
      normal: ({ children }) => <Typography>{children}</Typography>,
      h1: ({ children }) => <Typography level="h1">{children}</Typography>,
      h2: ({ children }) => <Typography level="h2">{children}</Typography>,
    },
    marks: {
      strong: ({ children }) => <strong>{children}</strong>,
      link: ({ value, children }) => (
        <a href={value.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
    },
    types: {
      breakline: () => <br />,
      image: ImageWithSkeleton,
    },
  };

  return (
    <Modal
      open={open}
      onClose={setClose}
      aria-labelledby="announcement-title"
      aria-describedby="announcement-description"
      sx={{
        "&:focus-visible": {
          outline: "none",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "90%",
          maxWidth: "500px",
          bgcolor: "white",
          borderRadius: "sm",
          background: theme.palette.background.surface,
          p: 0,
          paddingBottom: 1,
          maxHeight: "90vh",
          outline: "none",
        }}
      >
        <Typography
          id="announcement-title"
          level="h3"
          component="h2"
          sx={{
            paddingTop: 1.5,
            paddingLeft: 2,
            paddingBottom: 1.5,
            borderRadius: "sm",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            color: theme.palette.text.primary,
            background: (theme) =>
              theme.palette.mode === "dark"
                ? theme.palette.neutral[700]
                : theme.palette.neutral[50],
          }}
        >
          {announcement?.title}
        </Typography>
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "70vh",
            padding: 3,
          }}
        >
          {announcement?.bodyRaw && (
            <PortableText
              value={announcement.bodyRaw}
              components={components}
            />
          )}
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mt: 2 }}
          >
            <Button variant="solid" color="neutral" onClick={setClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
