import { SideNavIconContainer } from "./SideNavIconContainer";
import type { Theme } from "@mui/joy/styles/types";

function Icon({
  selected,
  unviewedAnnouncements,
  theme,
}: { selected: boolean; unviewedAnnouncements?: boolean; theme: Theme }) {
  return (
    <svg
      className={selected ? "nav-icon selected" : "nav-icon"}
      width="23"
      height="23"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{selected ? "Help Selected" : "Help"}</title>
      <path
        d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-8 0 0-88c0-13.3-10.7-24-24-24l-48 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l24 0 0 64-24 0zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
        className="orange"
      />
      {unviewedAnnouncements && (
        <circle cx="450" cy="105" r="90" fill={theme.palette.primary[500]} />
      )}
    </svg>
  );
}

export function InfoIcon({
  selected = false,
  unviewedAnnouncements = false,
  theme,
}: { selected?: boolean; unviewedAnnouncements?: boolean; theme: Theme }) {
  return (
    <SideNavIconContainer>
      <Icon
        selected={selected}
        unviewedAnnouncements={unviewedAnnouncements}
        theme={theme}
      />
    </SideNavIconContainer>
  );
}
