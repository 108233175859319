// exported separately as imported by worker
export default [
  { name: "FAIR VALUES", subItems: ["chgfv", "chgfv-mid", "fv", "fv-mid"] },
  {
    name: "QUOTES",
    subItems: [
      "ask",
      "asksize",
      "bid",
      "bidsize",
      "chg%exch",
      "chgexch",
      "chgtick",
      "high",
      "last",
      "lastsize",
      "lasttime",
      "low",
      "wav",
    ],
  },
  {
    name: "VOLUMES & OI",
    subItems: ["oi", "oichg", "oidate", "volm", "volmprior"],
  },
  { name: "MARKERS", subItems: ["mm-lon", "mm-nyc", "mm-sin"] },
  {
    name: "EOD",
    subItems: ["close", "settle-chg", "settle-prior", "settle-today"],
  },
  {
    name: "DATES",
    subItems: [
      "expiry",
      "first-effective",
      "second-effective",
      "pricing-days",
      "total-pricing-days",
    ],
  },
];
