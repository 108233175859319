import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { atom } from "jotai";

export const ApolloClientStore =
  atom<ApolloClient<NormalizedCacheObject> | null>(null);
ApolloClientStore.debugLabel = "ApolloClientStore";

export const SanityApolloClientStore =
  atom<ApolloClient<NormalizedCacheObject> | null>(null);
SanityApolloClientStore.debugLabel = "ApolloClientStore";
