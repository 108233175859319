import {
  FiExternalLink,
  FiHelpCircle,
  FiLogOut,
  FiMoon,
  FiSettings,
  FiSun,
} from "react-icons/fi";
import { ErrorBoundary } from "react-error-boundary";
import {
  Box,
  Dropdown,
  Input,
  ListDivider,
  ListItemButton,
  type ListItemButtonTypeMap,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/joy";
import { useTheme } from "@mui/joy/styles";
import type { SxProps, Theme } from "@mui/joy/styles/types";
import {
  type LinkProps,
  Link as RouterLink,
  useRouterState,
} from "@tanstack/react-router";
import { type ChangeEvent, memo, type ReactNode } from "react";
import { GoChevronDown, GoChevronRight } from "react-icons/go";
import { IoSearchOutline } from "react-icons/io5";
import { MdStarOutline, MdStarRate } from "react-icons/md";
import { headerHeight } from "../../globals";
import Link from "../../utils/Link";
import { useAuth0 } from "@auth0/auth0-react";
import { sideBarLinkStyle } from "../sharedStyle";
import { useSideBar } from "./hooks";
import { useEnv } from "../../envUtils";
import { OrgPopup } from "./OrgPopup";
import { useUserByUserId } from "../users";
import { EnvSelect } from "./EnvSelector";
import { useThemeMode } from "../../shared/hooks";
import { useLogout } from "../../auth";

function sideBarTopLinkStyle(sideBarCollpased: boolean | undefined): SxProps {
  return {
    display: "flex",
    width: "100%",
    justifyContent: sideBarCollpased ? "center" : "space-between",
    alignItems: "center",
    padding: "0.8rem 1rem",
    cursor: "pointer",
    borderRadius: 10,
    fontWeight: 500,
  };
}

const sideBarTopLinkBackground = (theme: Theme) =>
  theme.palette.mode === "dark"
    ? theme.palette.neutral[800]
    : theme.palette.neutral[100];

export const SidebarLink = memo(SidebarLinkRaw);

function SidebarLinkRaw({
  label,
  to,
  params,
  icon,
}: Omit<LinkEntry, "params"> & {
  icon?: ReactNode;
  params?: LinkEntry["params"];
}) {
  const { collapsedSidebar } = useSideBar();
  const {
    location: { pathname },
  } = useRouterState();
  if (!to) return null;
  return (
    <Link sx={sideBarLinkStyle} to={to} params={params} search={(prev) => prev}>
      <Box
        sx={{
          ...sideBarTopLinkStyle(collapsedSidebar),
          background: (theme) =>
            pathname === to ? sideBarTopLinkBackground(theme) : "transparent",
          "&:hover": {
            background: sideBarTopLinkBackground,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontSize: 14,
            textTransform: "capitalize",
          }}
        >
          {icon}
          {!collapsedSidebar && (
            <Typography
              sx={{
                maxWidth: "80%",
              }}
            >
              {label}
            </Typography>
          )}
        </Box>
      </Box>
    </Link>
  );
}

function TabsWrapper({
  links,
  closeDrawer,
}: {
  links: LinkEntry[];
  closeDrawer?: () => void;
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          m: 1,
          gap: 1,
        },
      }}
    >
      {links.map(({ label, to, params }) => (
        <Box
          key={to}
          onClick={closeDrawer}
          sx={{
            textTransform: "uppercase",
            cursor: "pointer",
            "& a": {
              textDecorationLine: "none !important",
              color: "inherit",
            },
            color: (theme) =>
              theme.palette.mode === "dark" ? "white" : "black",
            "&:hover": {
              color: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.neutral[200]
                  : theme.palette.neutral[700],
            },
          }}
        >
          <Link
            textTransform="capitalize"
            to={to}
            sx={{
              [theme.breakpoints.down("md")]: {
                width: "100%",
                padding: 2,
                borderRadius: (theme) => theme.radius.md,
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.neutral[800]
                    : theme.palette.neutral[200],
              },
            }}
            params={params}
            search={true}
          >
            <Typography
              sx={{
                '[data-status="active"] &': {
                  fontWeight: 500,
                  textDecorationLine: "underline",
                },
              }}
            >
              {label}
            </Typography>
          </Link>
        </Box>
      ))}
    </Box>
  );
}

export function TopNav({ links }: { links?: LinkEntry[] }) {
  const { sideBarWidth, toggleSidebar, collapsedSidebar } = useSideBar();
  const { user } = useAuth0();
  const logout = useLogout();

  if (!user?.sub) throw new Error("No user found");
  const [currentEnv] = useEnv();
  const theme = useTheme();

  const { mode, setMode } = useThemeMode("lumiTheme");
  const userDetails = useUserByUserId(user?.sub ?? "");

  return (
    <Box
      sx={{
        zIndex: 1000,
        bgcolor: (theme) => (theme.palette.mode === "dark" ? "black" : "white"),
        boxShadow: "none",
        borderBottom: (theme) =>
          theme.palette.mode === "dark"
            ? `1px solid ${theme.palette.neutral[700]}`
            : `1px solid ${theme.palette.neutral[200]}`,
        left: sideBarWidth,
        borderRadius: 0,
        position: "sticky",
        height: headerHeight,
        width: "100%",
        top: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        m: 0,
        p: 2,
      }}
    >
      <Stack
        flexDirection={"row"}
        sx={{
          display: {
            sm: "flex",
            md: "none",
          },
          gap: 2,
        }}
      >
        <Box
          onClick={() => toggleSidebar(true)}
          sx={{
            ...(collapsedSidebar && {
              width: "100%",
            }),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              opacity: 0.8,
            },
            cursor: "pointer",
            transform: collapsedSidebar ? "rotate(180deg)" : "rotate(0deg)",
          }}
        >
          <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            width="20"
            height="20"
          >
            <title>
              {collapsedSidebar ? "Expand Sidebar" : "Collapse Sidebar"}
            </title>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.59 6.47a.75.75 0 0 0-1.06 0l-5.06 5a.75.75 0 0 0 0 1.06l5.06 5a.75.75 0 0 0 1.05-1.06l-3.76-3.72H21a.75.75 0 0 0 0-1.5H9.82l3.76-3.72c.3-.29.3-.76 0-1.06Z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 3.25a.75.75 0 0 0-.75.75v16a.75.75 0 0 0 1.5 0V4A.75.75 0 0 0 3 3.25Z"
            />
          </svg>
        </Box>
        <Box
          sx={{
            display: {
              xs: collapsedSidebar ? "flex" : "none",
              md: "none",
            },
          }}
        >
          <EnvSelect />
        </Box>
      </Stack>
      <Stack
        pl={{
          xs: 2,
          md: 0,
        }}
        direction="row"
        gap={2}
        alignItems="center"
      >
        <OrgPopup />
        <Box
          sx={{
            display: "flex",
            gap: 2,
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          }}
        >
          {links ? <TabsWrapper links={links} /> : <></>}
        </Box>
      </Stack>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          [theme.breakpoints.down("md")]: {
            justifyContent: "flex-end",
            width: "100%",
          },
        }}
      >
        <Dropdown>
          <MenuButton id="nav-username" variant="outlined" size="sm">
            <Typography level="body-xs" textColor="text.tertiary">
              {userDetails?.user?.username ?? "..."}
            </Typography>
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: "99999",
              p: 1,
              gap: 1,
              "--ListItem-radius": "var(--lumi-radius-sm)",
            }}
          >
            <MenuItem>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography level="title-sm" textColor="text.primary">
                  {user?.name ?? "Loading User..."}
                </Typography>
                {user?.name !== user?.email && (
                  <Typography level="body-xs" textColor="text.tertiary">
                    {user?.email ?? "Loading User..."}
                  </Typography>
                )}
              </Box>
            </MenuItem>
            <ListDivider />
            <MenuItem
              onClick={() => {
                const newMode = mode === "dark" ? "light" : "dark";
                setMode(newMode);
              }}
            >
              {mode === "dark" ? <FiMoon /> : <FiSun />}
              {mode === "dark" ? "Light Mode" : "Dark Mode"}
            </MenuItem>
            <MenuItem>
              <FiHelpCircle />
              Help
            </MenuItem>
            <MenuItem>
              <FiSettings />
              Settings
            </MenuItem>
            <ListDivider />
            <MenuItem
              component="a"
              target="_blank"
              href={
                currentEnv === "global-Prod" || currentEnv === "TPICAP-Prod"
                  ? "https://folio.artis.works"
                  : currentEnv === "uat"
                    ? "https://main--artis-demo.netlify.app"
                    : "https://github.com/artisworks/folio-webapp/pulls"
              }
            >
              {currentEnv === "dev" ? "Latest Dev PRs" : "Artis Web App"}
              <FiExternalLink />
            </MenuItem>
            <ListDivider />
            <MenuItem
              onClick={() => {
                logout();
              }}
            >
              <FiLogOut />
              Log out
            </MenuItem>
          </Menu>
        </Dropdown>
      </Box>
    </Box>
  );
}

export type LinkEntry = {
  label: string;
  id: string;
  params?: object;
  to: LinkProps["to"];
};

export function OrgSearchInput({
  setQuery,
}: {
  setQuery: (e: ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <Box
      sx={{
        my: 0.5,
      }}
    >
      <Input
        autoFocus
        size="sm"
        placeholder="Search"
        startDecorator={<IoSearchOutline size={16} />}
        onChange={setQuery}
        sx={{ fontSize: 14, fontWeight: 400 }}
      />
    </Box>
  );
}

export function SidebarItemBox(props: ListItemButtonTypeMap["props"]) {
  return (
    <ListItemButton
      {...props}
      sx={{
        width: "100%",
        fontSize: 14,
        px: 1,
        py: 0.4,
        minHeight: 30,
        borderRadius: 5,
      }}
    />
  );
}

type SmartLink = {
  pathParam: NonNullable<LinkEntry["to"]>;
  replacementPath: NonNullable<LinkEntry["to"]>;
  defaultPath: NonNullable<LinkEntry["to"]>;
};

function useSmartSidebarLink({
  smartLink,
  to,
}: {
  smartLink?: SmartLink;
  to: LinkEntry["to"] | undefined;
}) {
  const { location, matches } = useRouterState();
  const currentLocation = location.pathname;

  if (to) return to;

  if (!smartLink) throw "pathParam or replacementPath is undefined";

  const { pathParam, replacementPath } = smartLink;

  const isPathParamEndOfPath = new RegExp(`${pathParam}/?$`).test(
    currentLocation,
  );

  const oneDynamicPathParam = matches.every(
    (match) => Object.keys(match.params).length <= 1,
  );

  if (
    currentLocation.includes(pathParam) &&
    !isPathParamEndOfPath &&
    oneDynamicPathParam
  ) {
    // matches /pathParam/anything/
    const pathParamMatch = new RegExp(`${pathParam}/[^/]+`);

    // replaces with `replacementPath`
    return currentLocation.replace(
      pathParamMatch,
      `/${replacementPath}`,
    ) as LinkEntry["to"];
  }

  return smartLink.defaultPath;
}

export function SidebarItem({
  label,
  params,
  id,
  to,
  smartLink,
  handleClick,
}: LinkEntry & {
  smartLink?: SmartLink;
  handleClick?: () => void;
}) {
  const path = useSmartSidebarLink({
    smartLink,
    to,
  });
  const currentPath = useRouterState().location.pathname;
  const orgIdRegex = /(?<=orgs\/)\d+(?=\/)/;
  const currentOrgId = currentPath.match(orgIdRegex)?.[0];

  if (!params) return null;

  return (
    <ErrorBoundary fallback={<div>error</div>}>
      <RouterLink
        style={{
          textDecoration: "none",
          color: "inherit",
          width: "100%",
        }}
        to={path}
        id={id}
        params={params}
        search={(prev) => prev}
        onClick={() => {
          console.log("handleClick", handleClick);
          handleClick?.();
        }}
      >
        <SidebarItemBox selected={currentOrgId === id}>{label}</SidebarItemBox>
      </RouterLink>
    </ErrorBoundary>
  );
}

export function SidebarToggableItemWithIcon({
  label,
  to,
  id,
  params,
  starred,
  handleStarred,
  handleClick,
}: {
  starred?: boolean;
  handleStarred: () => void;
  handleClick?: () => void;
} & LinkEntry) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        mr: 1,
        cursor: "pointer",
      }}
    >
      {starred ? (
        <MdStarRate
          onClick={(e) => {
            e.stopPropagation();
            handleStarred();
          }}
        />
      ) : (
        <MdStarOutline
          onClick={(e) => {
            e.stopPropagation();
            handleStarred();
          }}
        />
      )}
      <SidebarItem
        id={id}
        label={label}
        to={to}
        params={params}
        handleClick={handleClick}
        smartLink={{
          pathParam: "/admin/orgs",
          replacementPath: "/admin/orgs/$org",
          defaultPath: "/admin/orgs/$org/users",
        }}
      />
    </Box>
  );
}

export function SidebarToggableList({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
        position: "relative",
        mx: 1,
      }}
    >
      {children}
    </Box>
  );
}

export function SidebarButton({
  children,
  label,
  icon,
}: {
  label: string;
  children: ReactNode;
  icon: ReactNode;
}) {
  const { collapsedSidebar: open, toggleSidebar } = useSideBar();

  return (
    <>
      <Box
        sx={{
          ...sideBarTopLinkStyle(!open),
          background: (theme) =>
            open ? sideBarTopLinkBackground(theme) : "transparent",
          "&:hover": {
            background: sideBarTopLinkBackground,
          },
        }}
        onClick={() => {
          open && toggleSidebar(!open);
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
            fontSize: 14,
            textTransform: "capitalize",
          }}
        >
          {icon}
          {label}
        </Box>
        {children &&
          (open ? (
            <GoChevronDown fontSize={20} />
          ) : (
            <GoChevronRight fontSize={20} />
          ))}
      </Box>
      {open && children}
    </>
  );
}
