import { gql } from "@apollo/client";
import { useQuery as useApolloQuery } from "@apollo/client";
import { SanityApolloClientStore } from "../auth/store";
import { getDefaultStore } from "jotai";

export const ALL_USER_GUIDES = gql`
  query UserGuide {
    allUserGuide {
      _id
      title
      index
      mainImage {
        asset {
          _id
          url
        }
      }
      publishedAt
    }
  }
`;

const USER_GUIDE_BY_ID = gql`
  query UserGuideById($id: ID!) {
    UserGuide(id: $id) {
      _id
      title
      mainImage {
        asset {
          _id
          url
        }
      }
      publishedAt
      bodyRaw
    }
  }
`;

export const ALL_NEW_FEATURES = gql`
  query newFeatures {
    allNewFeature {
      _id
      title
      publishedAt
      bodyRaw
    }
  }
`;

const PRICING = gql`
query Pricing {
    allPricing {
      _id
      title
      subscripts
      body {
        _key
        rows {
          cells
        }
      }
      publishedAt
    }
  }
  `;

export function useAllUserGuides() {
  const store = getDefaultStore();
  const client = store.get(SanityApolloClientStore) ?? undefined;
  return useApolloQuery(ALL_USER_GUIDES, {
    client,
  });
}

export function useUserGuideById(id: string) {
  const store = getDefaultStore();
  const client = store.get(SanityApolloClientStore) ?? undefined;
  return useApolloQuery(USER_GUIDE_BY_ID, {
    client,
    variables: { id },
  });
}

export function useNewFeatures() {
  const store = getDefaultStore();
  const client = store.get(SanityApolloClientStore) ?? undefined;
  return useApolloQuery(ALL_NEW_FEATURES, {
    client,
  });
}

export function usePricing() {
  const store = getDefaultStore();
  const client = store.get(SanityApolloClientStore) ?? undefined;
  return useApolloQuery(PRICING, {
    client,
  });
}
