// page level
export const sideBarWidth = 60;
export const gridSettingsWidth = 820;

export const topBarHeight = 37;
export const bottomBarHeight = 35;
export const bottomPanelHeight = 200;

export const triplitStatusBarZIndex = 9999; // Needs to be higher than everything.
export const loadingZIndex = 1001; // Needs to be higher than everything except Triplit status bar.
export const sideBarZIndex = 100; // Needs to be higher than grid settings.
export const gridSettingsZIndex = 91; // Needs to be higher than Dockview.
export const gridSettingsResetConfirmationZIndex = 93; // Needs to be higher than grid settings and color picker.
export const dockviewZIndex = 90; // Limited to grid area, so doesn't need to be very high.
export const colorPickerZIndex = 92; // Needs to be higher than Dockview and grid settings.
export const formulaEditorZIndex = 100; // Needs to be higher than Dockview.
export const autocompleteZIndex = 101; // Needs to be higher than Dockview.
export const licenseAgreementZIndex = 99; // Needs to be lower than loading.
export const changeAllZIndex = 100; // Needs to be higher than Dockview.
export const statusComponentsZIndex = 100; // Needs to be higher than Dockview.

export const gridSettingsTransitionMatch = "left 0.3s ease";

// grid level
export const columnWidth = 115;
export const maxColumnWidth = 200;
export const minColumnWidth = 20;

export const headerHeight = 20;
export const rowHeight = 20;

// component level
export const virtualizedListAutocompleteZIndex = 100;
export const contextMenuZIndex = 100;

export const months = 60;

// this number must match the --separator-row-height css variable in market.css
export const separatorHeight = 10;

export const agGridCellAutoPadding = "6px";

export type TLoadingState = {
  [key: string]: {
    [key: string]: "loading" | "loaded";
  };
};

export const defaultLoadingState = {
  all: {
    auth: "loading",
  },
  market: {
    pageProductsLoading: "loading",
  },
  tradingChart: {
    chartSettings: "loading",
  },
} satisfies TLoadingState;

// To be expanded upon in the future.
export const alertSounds = {
  huthut: "/assets/audio/huthut_alert.mp3",
};
