import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
  type NormalizedCacheObject,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition, type Observable } from "@apollo/client/utilities";
import type { UseNetworkState } from "@react-hookz/web";
import * as Comlink from "comlink";
import { createClient } from "graphql-ws";
import * as idb from "idb-keyval";
import { atom } from "jotai";
import { jwtDecode } from "jwt-decode";
import { decode, registerExtension } from "msgpack-es";
import {
  ArrayQueue,
  ExponentialBackoff,
  WebsocketBuilder,
  type Websocket,
} from "websocket-ts";
import type {
  TSyncInstrumentsRequest,
  TSyncInstrumentsResponse,
} from "../../../api/sync-instruments.mjs";
import init, { Spreadsheet } from "../../../rust/calcite/pkg";
import {
  getHasuraUrl,
  orgIdToEnv,
  setEnv,
  triplitServerUrl,
  type JWT,
} from "../../globals";
import { objectEntries } from "../../shared/utils";
import { profileEnd, profileStart, timeAgoShort } from "../../utils";
import { getOffset, monthStringToCode } from "../market-grid/periodHelpers";
import {
  type TLivePricesStoreKey,
  UserAtom,
  configsKey,
  store,
  type TCalciteData,
  type TSharedCellUser,
  type ProductForCalcs,
  type TAggregatedResultsValue,
  type TLatestEditInfoKey,
  type TManualStoreKey,
} from "./sharedStores";
import {
  getGridData,
  setLivePricesStore,
  setManualCellsStore,
  removeProcessedItemsFromOptimisticStore,
  uniqueOptimisticUpdates,
  setLivePricesForCalcsStore,
  getAllLivePricesForCalcsStore,
  getAllManualCellsForCalcsStore,
  setCalcuatedResultsStore,
  setAggregatedResultsStore,
  getLatestEditInfoStore,
  getLastestEditInfoForProduct,
  setProductInfoStore,
} from "./storeLogic";
import {
  type ProductInfo,
  handleConfigs,
  handleProductInfo,
  handleSharedInsts,
  productInfoKey,
  type TOptimisticCell,
  handleEodInsts,
  handleLocalInsts,
  onGridSettingsUpdate,
  type TWorkerGridSettings,
} from "./subscriptionHandlers";
import { schema } from "../../triplit/schema";
import type {
  AllConfigsQueryQuery,
  UserSessionSubscription,
} from "../../__generated__/gql/graphql";
import {
  AllConfigsSub,
  ProductInfoSub,
  SharedInstsSub,
  currentDay,
  currentDate,
  EodEntriesSub,
  ProductInfoQuery,
  AllConfQuery,
  SharedInstsQuery,
  currentMonth,
  LocalInstsSub,
  subtractFourYears,
  LatestEodDateQuery,
  SessionCheck,
  StartSessionMutation,
  LogoutRequestCheck,
  MarketUserPermissionsSub,
} from "./graphql";
import { TriplitClient } from "@triplit/client";
import { processOptimisticCellUpdate } from "./optimisticLogic";
import { cache } from "./apolloCache";
import { alertsQuery, handleAlerts } from "./alerts";
import { setContext } from "@apollo/client/link/context";
import { broadcastToClients } from "./workerToClientLayer";

let client: ApolloClient<NormalizedCacheObject>;
let triplitClient: TriplitClient<typeof schema> | undefined;
let gridSettings: TWorkerGridSettings | undefined;
let priorEodDate = "2024-08-01";

function getEodDate() {
  if (gridSettings?.eodDate === "prior") return priorEodDate;
  return gridSettings?.eodDate || priorEodDate;
}

export function createApolloClient(
  token: string,
): ApolloClient<NormalizedCacheObject> {
  const jwt = jwtDecode<JWT>(token);
  const hasuraUrl = getHasuraUrl(orgIdToEnv(jwt.org_id));

  const httpLink = createHttpLink({
    uri: hasuraUrl,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: hasuraUrl.replace("https", "wss").replace("http", "ws"),
      connectionParams: {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    }),
  );

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
      },
      mutate: {
        fetchPolicy: "no-cache",
      },
    },
  });
}

let calcsStarted = false;

const updateSession = async (
  userId: string,
  isMobile: boolean,
  sessionId: string,
) => {
  try {
    await client.mutate({
      mutation: StartSessionMutation,
      variables: {
        user: userId,
        mobile: isMobile,
        session: sessionId,
      },
    });
  } catch (error) {
    console.error("Error starting session:", error);
  }
};

const handleSessionCheck = async (
  data: UserSessionSubscription,
  auth0Session: { id: string; start: string },
  userId: string | null | undefined,
  isMobile: boolean,
) => {
  const existingSession = data?.session_by_pk;
  const session = {
    id: auth0Session.id,
    start: `${auth0Session.start.slice(0, -3)}:00`,
  };

  if (!userId) {
    console.error("User ID is null or undefined.");
    return;
  }

  if (!CalculationsWorker.logoutRequested) {
    if (!existingSession || existingSession.id === auth0Session.id) {
      CalculationsWorker.existingSession = false;
      setTimeout(() => {
        // this will trigger the subscription that calls handleSessionCheck
        // and update the 'lastSeen' field
        updateSession(userId, isMobile, session.id);
      }, 10_000);
    } else {
      console.log("Existing session found", {
        existingSession,
        auth0Session,
        userId,
        isMobile,
      });
      CalculationsWorker.existingSession = true;
    }
  }
};

export const startSessionSubscriptions = async ({
  auth0Session,
  userId,
  token,
  isMobile,
}: {
  auth0Session: { id: string; start: string };
  userId: string | null | undefined;
  token: string;
  isMobile: boolean;
}) => {
  if (!userId || !triplitClient) return;
  const multiSessionQuery = triplitClient
    .query("userGroups")
    .id("multisession")
    .select(["users"])
    .where(["users", "has", userId])
    .build();
  try {
    client
      .subscribe({
        query: LogoutRequestCheck,
        variables: {
          user: userId,
          context: isMobile ? "mobile" : "desktop",
          since: auth0Session.start,
        },
      })
      .subscribe({
        next: ({ data }) => {
          console.log("Logout request data", data);
          const logoutRequestedAt =
            data?.logout_request?.[0]?.logout_requested_at;
          const ignoreBefore = CalculationsWorker.ignoreLogoutRequestsBefore;
          if (logoutRequestedAt) {
            if (new Date(logoutRequestedAt) < ignoreBefore) {
              console.log("Ignoring logout request", {
                logoutRequestedAt,
                ignoreBefore,
              });
              return;
            }
            const requestedToday =
              logoutRequestedAt.slice(0, 10) === currentDay;
            if (requestedToday && logoutRequestedAt > auth0Session.start) {
              console.log("Logout requested", {
                logoutRequestedAt,
                auth0Session,
                userId,
                isMobile,
              });
              broadcastToClients({ type: "logoutRequest" });
            }
          }
        },
      });

    const hasMultiSessionGroup = await triplitClient.fetchOne(
      multiSessionQuery,
      { policy: "remote-only" },
    );

    // Check if "multisession" is present in the fetched groups
    if (hasMultiSessionGroup || userId === "auth0|607833598949f5006c1db592") {
      console.log(
        "User is part of 'multisession' group, skipping session monitoring.",
      );
      return;
    }

    console.debug("Starting session monitoring subs", {
      userId,
      token,
      hasMultiSessionGroup,
    });

    client
      .subscribe({
        query: SessionCheck,
        variables: { user: userId, mobile: isMobile },
      })
      .subscribe({
        next: async ({ data }) => {
          if (data) {
            try {
              await handleSessionCheck(data, auth0Session, userId, isMobile);
            } catch (e) {
              console.error("Error handling session check", e);
            }
          }
        },
        error: (error) => console.error("Subscription error:", error),
      });
  } catch (error) {
    console.error("Error starting session sub:", error);
  }
};

export const CalculationsWorker = {
  isInited: 0,
  isPaused: false,
  token: null,
  user: null,
  existingSession: false,
  logoutRequested: false,
  ignoreLogoutRequestsBefore: new Date("2024"),

  setIgnoreLogoutRequestsBefore(date: Date) {
    this.ignoreLogoutRequestsBefore = date;
  },

  printRandomNumber() {
    console.log(Math.random());
  },

  pause() {
    console.log("isPaused", this.isPaused);
    this.isPaused = true;
  },

  requestLogout() {
    console.log("logoutRequested", this.logoutRequested);
    this.logoutRequested = true;
  },

  resume() {
    console.log("isPaused", this.isPaused);
    this.isPaused = false;
  },

  getPriorEodDate() {
    return priorEodDate || "";
  },

  getEodDate,

  getCurrentState() {
    console.log("getting current state", this.isInited, calcsStarted);
    if (this.isInited === 0 || !calcsStarted) return null;
    return { isPaused: this.isPaused, isInited: this.isInited };
  },

  getSessionState() {
    return { existingSession: this.existingSession };
  },

  getLogoutState() {
    return { logoutRequested: this.logoutRequested };
  },

  initWorker(
    env: Record<string, string>,
    user: TSharedCellUser,
    session: {
      jwt: string;
      auth0Session: {
        id: string;
        start: string;
      };
    },
    isMobile: boolean,
  ) {
    console.log("init worker", {
      env,
      user,
      session,
      isMobile,
    });
    if (this.isInited) return;
    this.isInited++;
    this.isPaused = false;
    const userId = user?.id;
    console.log("starting calc worker", this.isInited, Math.random());
    store.set(UserAtom, user);
    setEnv(env);
    const token = session.jwt;
    const auth0Session = session.auth0Session;
    setTimeout(() => {
      if (triplitClient) {
        startSessionSubscriptions({ auth0Session, userId, token, isMobile });
      } else {
        console.error("Triplit client not initialized");
      }
    }, 1000);
  },

  start(token: string) {
    this.isPaused = false;
    console.log("starting calcs", this.isInited);
    triplitClient = new TriplitClient({
      schema,
      storage: "indexeddb",
      token,
      serverUrl: triplitServerUrl(),
    });
    const alertsQ = alertsQuery(triplitClient);
    triplitClient.subscribe(alertsQ, () => {
      console.log("Alerts updated");
    });
    startCalcs(token);
  },

  stop() {
    console.log("stopping calcs");
    self.close();
  },

  sessionCheck() {
    console.log("checking session");
    return { session: this.existingSession };
  },

  getGridData,

  getCellInfo(key: TLatestEditInfoKey) {
    return getLatestEditInfoStore(key);
  },
  getManualProductInfo(key: TManualStoreKey) {
    const info = getLastestEditInfoForProduct(key);
    const editedAtStr = info?.editedAt;
    try {
      const editedAt = editedAtStr ? new Date(editedAtStr) : null;
      const now = new Date();
      const humanReadable = editedAt ? timeAgoShort(editedAt, now) : null;
      const username = info?.username;
      if (!humanReadable || !username) return null;

      const latestEditInfo = `${username.toUpperCase()}: ${humanReadable}`;

      return { latestEditInfo };
    } catch (e) {
      console.error("Failed to get latest edit info", e);
      return null;
    }
  },
  optimisticCellEdit: (cells: TOptimisticCell[]) =>
    processOptimisticCellUpdate(cells, runCalcs),
  networkStatusChange(e: UseNetworkState) {
    console.log("nstpo worker", e);
  },
  updateJWT(token: string) {
    closeLiveprices();
    connectToLiveprices({ jwt: token });
  },
};

export type TCalculationsWorker = typeof CalculationsWorker;

function onSharedWorkerConnect(e: MessageEvent) {
  const port = e.ports[0];
  Comlink.expose(CalculationsWorker, port);
  console.log("exposed shared worker");
}

let isInitialized = false;
if (typeof onconnect !== "undefined") {
  self.addEventListener("connect", (e: MessageEvent) => {
    onSharedWorkerConnect(e);
  });
} else {
  self.onmessage = (event) => {
    if (!isInitialized) {
      console.log("Initializing worker...", event);
      try {
        Comlink.expose(CalculationsWorker);
        console.log("Worker initialized");
        isInitialized = true;
      } catch (e) {
        console.error("Error initializing worker", e);
      }
    }
  };
}

registerExtension(
  // id used for keywords in live-prices
  3,
  String,
  // we don't encode anything so don't bother implementing
  () => new Uint8Array(),
  // treat keywords as strings but strip :
  (keyword) => new TextDecoder().decode(keyword).substring(1),
);

export let sheet: Spreadsheet;

type LivePrices = {
  asksize?: number;
  bidsize?: number;
  product: string;
  "exchange-id"?: number;
  "implied-month-offset"?: number;
  month: string;
  fv?: number;
  value?: number;
  chgfv?: number;
  volm?: number;
  // other fields.... should probably export this type from the rust code somehow?
};

function runCalcs(dataForSheet: ProductForCalcs[]) {
  sheet?.update_market_data(dataForSheet);
}

const iceUrl = "wss://liveprices.dev.artis.works/ws?format=msgpack";

function cleanUpReader(reader: FileReader) {
  reader.onload = null;
  reader.onerror = null;
}

async function updateLivePrices(blob: Blob) {
  const reader = new FileReader();

  reader.onload = async () => {
    const result = reader.result;
    if (!result || typeof result === "string") return;
    try {
      const marketData = decode<LivePrices[] | undefined>(result);

      if (marketData) {
        marketData.flatMap((obj) => {
          const productId = obj.product;
          const month = `${obj.month.slice(0, -2)}01`;

          return objectEntries(obj)
            .map(([k, v]) => {
              const rowId = monthStringToCode(obj.month);
              if (!rowId) throw new Error("monthCode is undefined");

              const { key, productForCalcs } = {
                key: {
                  productId: obj.product,
                  rowId,
                  selector: k,
                } satisfies TLivePricesStoreKey,
                productForCalcs: {
                  field: k,
                  offset: getOffset(month),
                  product: productId,
                  result: v,
                } satisfies ProductForCalcs,
              };
              setLivePricesForCalcsStore(key, productForCalcs);
              setLivePricesStore(key, v);
            })
            .filter(Boolean);
        });
      }
    } catch (e) {
      console.error("Error processing liveprices data", e);
    }
    cleanUpReader(reader);
  };

  reader.onerror = (error) => {
    console.error("Error reading message:", error);
    cleanUpReader(reader);
  };

  reader.readAsArrayBuffer(blob);
}

const LivepricesAtom = atom<Websocket | null>(null);

export function isLivepricesConnected() {
  const ws = store.get(LivepricesAtom);
  return ws?.readyState === 1;
}

export function closeLiveprices() {
  const ws = store.get(LivepricesAtom);
  if (ws) ws.close();
  store.set(LivepricesAtom, null);
}

export function connectToLiveprices(options: { jwt: string }) {
  const { jwt } = options;
  try {
    const websocket = new WebsocketBuilder(iceUrl)
      .withBackoff(new ExponentialBackoff(2000, 5))
      .withBuffer(new ArrayQueue())
      .onMessage((_, e) => {
        try {
          const blob = e.data;
          if (blob instanceof Blob && !CalculationsWorker.isPaused) {
            updateLivePrices(blob);
          }
        } catch (e) {
          console.error("error while updating liveprices", e);
        }
      })
      .onError(console.error)
      .onClose((_ws, e) => {
        const code = e.code;
        switch (code) {
          case 4403:
          case 4400:
            console.warn("Liveprices access forbidden");
            break;
          default:
            if (code !== 1000) console.log("WS Closed with code ", { code });
            else
              throw new Error(
                "Error code not caught in ::sse-listen, this shouldn't be possible.",
              );
        }
      })
      .onOpen((ws) => {
        console.debug("Connected to liveprices");
        ws.send(jwt);
      })
      .onRetry(console.log)
      .build();

    store.set(LivepricesAtom, websocket);
    console.debug("Connecting to liveprices", websocket);
  } catch (e) {
    console.error("Error connecting to liveprices", e);
  }
}

/**
 * Send updates to the grid - this is called from the calcite code
 *
 * @param  data - a stringified JSON array of updates,
 * each update is an object with a key equal to `productId::fieldSelector:offset`
 * and a value of either a string if there's an error, a number if there's a result or undefined
 * @returns void
 */

export function send_updates(data: string) {
  profileStart("send_updates");
  const { agg_results, ...updates } = JSON.parse(data) as TCalciteData & {
    agg_results: TAggregatedResultsValue;
  };

  if (!updates) {
    profileEnd("send_updates");
    return;
  }

  profileStart("send_updates: update store");

  for (const key in updates) {
    if (Object.prototype.hasOwnProperty.call(updates, key)) {
      const value = updates[key];
      setCalcuatedResultsStore(key, value);
    }
  }

  profileStart("send_updates: update agg_results");

  for (const productId in agg_results) {
    const product = agg_results[productId];
    for (const fieldSelector in product) {
      const currentSelector = product[fieldSelector];
      for (const rowId in currentSelector) {
        const value = currentSelector[rowId];

        setAggregatedResultsStore(
          {
            productId,
            rowId,
            selector: fieldSelector,
          },
          value,
        );
      }
    }
  }

  profileEnd("send_updates: update agg_results");

  try {
    profileEnd("send_updates: update store");
  } catch (err) {
    console.log("Failed to update state:", err);
    profileEnd("send_updates: update store");
  }

  profileEnd("send_updates");
}

function createSpreadsheet(
  configs: AllConfigsQueryQuery["product_config"],
  productInfo: ProductInfo,
) {
  return new Spreadsheet(configs, productInfo);
}

async function initialiseCalcite() {
  try {
    const t1 = performance.now();
    //const configList = R.take(configs, 10000);

    // Instantiate the Spreadsheet with the configuration
    const configs = await idb.get<ReturnType<typeof handleConfigs>>(configsKey);
    const configsToArray = objectEntries(configs || {})
      .flatMap(([_k, v]) => v)
      .map((v) => ({
        ...v,
        formula: v.formula || "",
      }));

    const productInfo =
      await idb.get<ReturnType<typeof handleProductInfo>>(productInfoKey);
    if (!configs || !productInfo) return;
    setProductInfoStore(productInfo);
    console.log("Initialising calcite with", [configsToArray, productInfo]);
    sheet = createSpreadsheet(configsToArray, productInfo);
    const t2 = performance.now();
    console.log(
      "Spreadsheet instantiated in",
      t2 - t1,
      "ms",
      " with ",
      configsToArray.length,
      " configs",
      sheet,
    );
    setTimeout(() => {
      calcsStarted = true;
    }, 500);
  } catch (e) {
    console.error("Error initialising calcite", e);
  }
}

const isStarted = false;

function startSubscriptions(
  token: string,
  client: ApolloClient<NormalizedCacheObject>,
  user: TSharedCellUser,
) {
  if (!user || !user?.id) {
    throw new Error("User is missing, can't start subscriptions");
  }
  if (!triplitClient) {
    throw new Error("Triplit client is missing, can't start subscriptions");
  }
  try {
    const userId = user.id;
    client
      .query({
        query: SharedInstsQuery,
        variables: {
          current_month: currentMonth,
        },
      })
      .then((res) => handleSharedInsts(userId, res))
      .catch(console.error);
    console.log("starting subsriptions");
    const connectToLP = () => connectToLiveprices({ jwt: token });
    const configsRes = client.subscribe({ query: AllConfigsSub });
    configsRes.subscribe(async (res) => {
      const info = handleConfigs(res);
      if (isStarted) {
        console.log("restarting calcite");
        closeLiveprices();
        try {
          await idb.clear();
          await idb.set(configsKey, info);
        } catch (e) {
          console.error("Error setting configs in idb", e);
        }
        connectToLP();
      } else {
        try {
          await idb.set(configsKey, info);
        } catch (e) {
          console.error("Error setting configs in idb", e);
        }
      }
    });

    const productsRes = client.subscribe({ query: ProductInfoSub });
    productsRes.subscribe(async (res) => {
      const info = handleProductInfo(res);
      try {
        await idb.set(productInfoKey, info);
      } catch (e) {
        console.error("Error setting product info in idb", e);
      }
      if (isStarted) {
        initialiseCalcite();
      }
    });

    const sharedInstsRes = client.subscribe({
      query: SharedInstsSub,
      variables: {
        current_date: currentDate.toISOString(),
        current_month: currentMonth,
      },
    });

    sharedInstsRes.subscribe((res) => {
      handleSharedInsts(userId, res);
    }, console.error);

    const localInstsRes = client.subscribe({
      query: LocalInstsSub,
      variables: {
        current_month: currentMonth,
        latest_date: subtractFourYears(),
      },
    });

    localInstsRes.subscribe((res) => {
      handleLocalInsts(res);
    }, console.error);

    const marketUserPermissions = client.subscribe({
      query: MarketUserPermissionsSub,
      variables: {
        folio_user: userId,
      },
    });

    let initialLoad = true;

    marketUserPermissions.subscribe(() => {
      console.log("Market user permissions updated");
      if (initialLoad) {
        initialLoad = false;
        return;
      }

      broadcastToClients({ type: "renewToken" });
    }, console.error);

    const gridSettingsSub = triplitClient
      .query("gridSettings")
      .id(userId)
      .build();

    let eodSubscription:
      | ReturnType<typeof Observable.prototype.subscribe>
      | undefined;

    triplitClient.subscribe(gridSettingsSub, (res) => {
      console.log("gridSettings updated", res);
      if (res.length === 0) {
        triplitClient?.insert("gridSettings", {
          id: userId,
          statusMap: "{}",
        });
      }

      const settings = res.find((setting) => setting.id === userId);
      if (!settings) return;
      onGridSettingsUpdate({
        settings,
        currentSettings: gridSettings,
        setGridSettings: (settings) => {
          gridSettings = settings;
        },
      });
      const eodDate = getEodDate();

      if (eodDate) {
        const eodEntriesRes = client.subscribe({
          query: EodEntriesSub,
          variables: {
            eval_date: eodDate,
            date: currentMonth,
          },
        });

        if (eodSubscription) return;

        eodSubscription = eodEntriesRes.subscribe((res) => {
          handleEodInsts(res);
        }, console.error);
      }
    });
  } catch (e) {
    console.error("Error starting subscriptions", e);
  }
}

export async function startCalcs(token: string) {
  try {
    console.log("starting calcs", token);
    const jwt = jwtDecode<JWT>(token);
    const hasuraUrl = getHasuraUrl(orgIdToEnv(jwt.org_id));
    const httpLink = createHttpLink({
      uri: hasuraUrl,
    });

    const wsLink = new GraphQLWsLink(
      createClient({
        url: hasuraUrl.replace("https", "wss").replace("http", "ws"),
        connectionParams: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      }),
    );

    const link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink,
    );

    client = new ApolloClient({
      link,
      cache,
      defaultContext: {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    });

    const user = store.get(UserAtom);
    if (!user) throw new Error("User is not set in store");

    const eodDate = await client
      .query({
        query: LatestEodDateQuery,
      })
      .then((res) => {
        const dateStr = res?.data?.eod_entry?.[0]?.evaluation_date;
        if (!dateStr) return;

        return dateStr;
      });

    if (eodDate) {
      priorEodDate = eodDate;
    }

    startSubscriptions(token, client, user);

    startWorker(token, client);
  } catch (e) {
    console.error("Error starting worker", e);
  }
}

let outboxInterval: NodeJS.Timeout;
const outboxIntervalMs = 1000;

function syncOutboxToServerInterval(token: string) {
  if (outboxInterval) clearInterval(outboxInterval);

  console.log("starting outbox interval");

  // the logic in here must be 100% 'synchronous', as we schedule the next update
  // when we're certain the synching succeeded (or failed)
  async function syncData() {
    const { originalCount, optimisticUpdates } = uniqueOptimisticUpdates();
    const countOfInstrumentsToProcess = originalCount;

    const scheduleNextIteration = () => {
      outboxInterval = setTimeout(syncData, outboxIntervalMs);
    };

    if (!countOfInstrumentsToProcess) {
      scheduleNextIteration();
      return;
    }

    console.log("synching outbox to server", {
      originalCount,
      countOfInstrumentsToProcess,
      optimisticUpdates,
    });

    const folio_user = store.get(UserAtom);

    const payload: TSyncInstrumentsRequest = {
      shared_instruments: [],
      local_instruments: [],
      jwt: token,
    };

    for (const instrument of optimisticUpdates) {
      const storageType = instrument.storageType;

      if (!folio_user?.id) return;

      if (typeof instrument.value === "string") return;

      if (storageType === "shared" || storageType === "broadcast") {
        payload.shared_instruments?.push({
          value: instrument.value,
          month: instrument.month,
          edited_by: folio_user?.id,
          product: instrument.productId,
        });
      }

      payload.local_instruments?.push({
        folio_user: folio_user?.id,
        month: instrument.month,
        edited_at: instrument.editedAt,
        product: instrument.productId,
        storage_type: storageType === "local" ? "local" : "broadcast",
        value: instrument.value,
      });
    }

    async function sendRequest() {
      return await fetch("/api/sync-instruments", {
        method: "POST",
        body: JSON.stringify(payload),
      }).then((res) => {
        if (!res.ok) {
          console.error("outbox synching request failed:", res);
          return Promise.reject(res);
        }
        return res.json() as Promise<TSyncInstrumentsResponse>;
      });
    }

    const requestMaxAttempts = 3;
    const pauseForMs = (ms: number) =>
      new Promise((res) => setTimeout(res, ms));

    try {
      for (let i = 1; i <= requestMaxAttempts; i++) {
        console.log("synching attempt:", i);

        try {
          const data = await sendRequest();
          console.log("outbox synching success!");

          const { local_instruments_updated, shared_instruments_updated } =
            data;

          const updatedInstruments = [
            ...local_instruments_updated,
            ...shared_instruments_updated,
          ];

          // to write the updated instruments directly to idb.
          // this makes sure that the grid doesn't flicker due to
          // race conditions between deleting the outbox values and
          // the instruments subscription returning the new data
          for (const instrument of updatedInstruments) {
            const rowId = monthStringToCode(instrument.month);

            const storageType =
              "storage_type" in instrument
                ? instrument.storage_type
                : "broadcast";
            if (!rowId) {
              console.error("monthToCode is undefined");
              continue;
            }

            const productId = instrument.product;

            setManualCellsStore(
              {
                productId,
                storageType,
              },
              rowId,
              instrument.value ?? undefined,
            );
          }

          break;
        } catch (e) {
          console.error("outbox synching request failed:", e);

          if (i === requestMaxAttempts) {
            // fire up alert to say that things went wrong
            console.log("fire up alert to say that things went wrong");
            break;
          }
          await pauseForMs(1000);
        }
      }
    } catch (e) {
      console.error("Failed to send request", {
        e,
        payload,
      });
    }

    // now we can remove the instruments from the outbox
    setTimeout(() => {
      removeProcessedItemsFromOptimisticStore(countOfInstrumentsToProcess);
    }, 1000);

    scheduleNextIteration();
  }

  outboxInterval = setTimeout(syncData, outboxIntervalMs);
}

let calcsInterval: ReturnType<typeof setTimeout> | undefined;
const calcsIntervalMs = 250;

function startCalcsInterval() {
  if (calcsInterval) clearInterval(calcsInterval);

  async function runCalcsFn() {
    const scheduleNextIteration = () => {
      outboxInterval = setTimeout(runCalcsFn, calcsIntervalMs);
    };

    const liveProducts = getAllLivePricesForCalcsStore();
    const folio_user = store.get(UserAtom);

    if (!folio_user?.id) throw new Error("userId doesn't exist in store");

    const statusMap = gridSettings?.statusMap || {};
    if (!gridSettings?.statusMap) {
      console.warn("statusMap is undefined", gridSettings);
    }

    const eodEvalDate = getEodDate();
    const manualProducts = getAllManualCellsForCalcsStore(
      statusMap,
      eodEvalDate,
    );

    const products = [...liveProducts, ...manualProducts];

    if (products.length) runCalcs(products);

    scheduleNextIteration();

    try {
      if (triplitClient) {
        handleAlerts({ triplitClient, statusMap, eodEvalDate });
      }
    } catch (e) {
      console.error("Failed to handle alerts", e);
    }
  }
  outboxInterval = setTimeout(runCalcsFn, calcsIntervalMs);
}

async function startWorker(
  token: string,
  client: ApolloClient<NormalizedCacheObject>,
) {
  try {
    const decoded = jwtDecode(token);

    console.log("Decoded Token Data:", decoded);
  } catch (error) {
    console.error("Failed to decode token:", error);
  }
  await idb.clear();
  console.log("starting calculations worker");
  await init();

  syncOutboxToServerInterval(token);

  setTimeout(() => {
    startCalcsInterval();
  }, 2000);

  const productInfoRes = await client.query({ query: ProductInfoQuery });
  const productInfo = handleProductInfo(productInfoRes);
  const configsRes = await client.query({ query: AllConfQuery });
  const configs = handleConfigs(configsRes);

  if (!configs || !productInfo) {
    console.error("Error fetching configs or product info");
    return;
  }

  await idb.set(configsKey, configs);
  await idb.set(productInfoKey, productInfo);

  setTimeout(async () => {
    connectToLiveprices({
      jwt: token,
    });
    await initialiseCalcite();
  }, 500);
}
