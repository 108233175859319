import { Autocomplete } from "@mui/joy";
import { useQuery as useTriplitQuery } from "@triplit/react";
import { client } from "../../triplit/triplit";
import { type TOptionType, optionFilter } from "./multiSelectHelpers";
import { sanitizeString } from "../../shared/utils";

export function GroupsSelect({
  sx,
  autoFocus,
  userId,
}: {
  sx?: Parameters<typeof Autocomplete>[0]["sx"];
  autoFocus?: boolean;
  userId: string;
}) {
  const groupsQuery = useTriplitQuery(client, client.query("userGroups"));
  const groups = groupsQuery.results || [];

  const usersGroups = groups.filter((group) => group?.users?.has(userId));

  const usersGroupsValue: TOptionType[] = usersGroups.map((group) => ({
    id: group?.id,
    title: group?.displayName,
  }));

  const groupOptions: TOptionType[] = groups.map((group) => {
    return {
      id: group.id,
      title: group.displayName,
    };
  });

  return (
    <Autocomplete
      sx={sx}
      autoFocus={autoFocus}
      multiple
      filterSelectedOptions
      openOnFocus
      handleHomeEndKeys
      isOptionEqualToValue={(option, value) => {
        if (value.inputValue) {
          return option.inputValue === value.inputValue;
        }
        return option.id === value.id;
      }}
      options={groupOptions}
      value={usersGroupsValue}
      onChange={(_, groups, reason) => {
        if (reason === "removeOption") {
          const missingGroup = usersGroups.find((group) => {
            return !groups.some((option) => option.id === group.id);
          });
          if (missingGroup) {
            client.update("userGroups", missingGroup.id, (row) => {
              row.users?.delete(userId);
            });
          }
        }
        if (reason === "clear") {
          for (const group of usersGroups) {
            client.update("userGroups", group.id, (row) => {
              row.users?.delete(userId);
            });
          }
        } else {
          for (const group of groups) {
            const groupId = sanitizeString(group.inputValue || group.id);
            // if group isn't in db create it
            const newGroup = !groupsQuery.results?.some(
              (g) => g.id === groupId,
            );
            if (newGroup) {
              client.insert("userGroups", {
                id: groupId,
                displayName: groupId,
                users: new Set([userId]),
              });
            } else if (!usersGroups.some((g) => g.id === groupId)) {
              // or if group exists and user isn't in it, add user
              client.update("userGroups", groupId, (row) => {
                row.users?.add(userId);
              });
            }
          }
        }
      }}
      getOptionLabel={(option) => option.title}
      filterOptions={(options, params) => {
        const filtered = optionFilter(options, params);
        const { inputValue } = params;

        const isExisting = options.some(
          (option) => inputValue === option.title,
        );

        if (inputValue?.trim() !== "" && !isExisting) {
          filtered.push({
            inputValue,
            id: inputValue,
            title: `+ Add ${inputValue}`,
          });
        }

        return filtered;
      }}
    />
  );
}
