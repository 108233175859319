import { usePageId } from "../../../market-pages/pagesHooks";
import {
  Box,
  Button,
  Checkbox,
  Input,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import { ModalButtons } from "../Buttons";
import { GridModalContainer } from "../GridModal";
import {
  Controller,
  useFieldArray,
  useForm,
  useWatch,
  type UseFormReturn,
} from "react-hook-form";
import {
  alertValidation,
  emptyAlert,
  useProductName,
  type TAlertForm,
} from "./hooks";
import { FaTimes, FaHandPointUp, FaTrash } from "react-icons/fa";
import {
  relativeRowToRowId,
  rowIdToRelativeRow,
  rowStringToCode,
} from "../../periodHelpers";
import {
  getNodePeriod,
  headerStyle,
  useResetLimitSelection,
} from "../ConditionalFormatting/helpers";
import { useAtomValue } from "jotai";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth0 } from "@auth0/auth0-react";
import type { TAlert } from "../../../../triplit/schema";
import toast from "react-hot-toast";
import { parse } from "../../../numbers";
import { client } from "../../../../triplit/triplit";
import { useGridApi } from "../../../../shared/hooks";
import { gridModalDockviewAtom } from "../../../calculations-worker/sharedStores";
import { alertSounds } from "../../../globals";
import captureEvent from "../../../../eventTracker";
import { GridModalSelectionInfo } from "../modalComponents";
import { currentPageProductsAtom } from "../../../market-pages/pageProductHooks";
import { useNavigate } from "@tanstack/react-router";

const maxHeight = 32;

export function AlertHeader({ type }: { type: "create" | "manage" }) {
  const headers = {
    limit: (
      <th key="limit">
        <Typography fontSize={"xs"}>Limit</Typography>
      </th>
    ),
    note: (
      <th key="note">
        <Typography fontSize={"xs"}>Note</Typography>
      </th>
    ),
    actions: (
      <th
        key="actions"
        style={{
          width: type === "create" ? 70 : 120,
        }}
      >
        <Typography fontSize={"xs"}>Actions</Typography>
      </th>
    ),
    affected: (
      <th key="affected">
        <Typography fontSize={"xs"}>Affected</Typography>
      </th>
    ),
    status: (
      <th key="status">
        <Typography fontSize={"xs"}>Status</Typography>
      </th>
    ),
    recuring: (
      <th key="recurring">
        <Typography fontSize={"xs"}>Recurring</Typography>
      </th>
    ),

    sound: (
      <th key="sound" style={{ width: 100 }}>
        <Typography fontSize={"xs"}>Sound</Typography>
      </th>
    ),
  };

  const headerItems =
    type === "create"
      ? [
          headers.limit,
          headers.note,
          headers.actions,
          headers.recuring,
          headers.sound,
        ]
      : [
          headers.limit,
          headers.affected,
          headers.note,
          headers.actions,
          headers.status,
          headers.recuring,
          headers.sound,
        ];

  return (
    <thead>
      <tr>{headerItems}</tr>
    </thead>
  );
}

export function AlertRow({
  form,
  alert,
  index,
  remove,
  type,
}: {
  form: UseFormReturn<{ alerts: TAlertForm[] }>;
  alert: TAlertForm;
  index: number;
  remove: (index: number) => void;
  type: "create" | "manage";
}) {
  const { getApi } = useGridApi();
  const gridApi = getApi();
  const resetLimitSelection = useResetLimitSelection();

  const { register, control } = form;

  const output = useWatch({
    control,
    name: "alerts",
  });

  const period = relativeRowToRowId[alert.rowId];

  const rowNode = period
    ? gridApi?.getRowNode(rowStringToCode(period))
    : undefined;

  const pageId = usePageId() || "";

  const productName = useProductName(alert.productId);

  const affectedString = `${productName} [${period}]`;
  const disabled = output[index]?.status !== "Active";
  const navigate = useNavigate();

  const items = {
    limit: (
      <td key="limit">
        <Stack flexDirection={"row"} gap={1}>
          {output[index]?.limitRef?.name ? (
            <Stack
              flexDirection={"row"}
              gap={1}
              overflow="hidden"
              width="100%"
              justifyContent={"space-between"}
            >
              <Stack overflow="hidden">
                <Typography
                  fontSize="xs"
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  overflow="hidden"
                >
                  {output[index]?.limitRef?.name}
                </Typography>
                <Typography fontSize="xs">
                  [{output[index]?.limitRef?.period}]
                </Typography>
              </Stack>
              <Controller
                disabled={disabled}
                name={`alerts.${index}.limitRef`}
                control={control}
                render={({ field }) => (
                  <Button
                    size="sm"
                    variant="soft"
                    color="neutral"
                    disabled={disabled}
                    sx={{
                      maxHeight,
                    }}
                    onClick={() => {
                      field.onChange(undefined);
                    }}
                  >
                    <FaTimes />
                  </Button>
                )}
              />
            </Stack>
          ) : (
            <>
              <Input
                {...register(`alerts.${index}.limit`)}
                type="number"
                size="sm"
                disabled={disabled}
                fullWidth
              />
              <Controller
                name={`alerts.${index}.limitRef`}
                control={control}
                disabled={disabled}
                render={({ field }) => (
                  <Button
                    size="sm"
                    variant="soft"
                    color="neutral"
                    disabled={disabled}
                    onClick={() => {
                      if (
                        gridApi?.getGridOption("rowClass") ===
                        "ag-cell-selection"
                      ) {
                        resetLimitSelection();
                        return;
                      }

                      gridApi?.setGridOption("onCellClicked", (e) => {
                        resetLimitSelection();

                        const columnId = e.column.getColId();
                        const name = e.colDef.headerName;
                        const period = getNodePeriod(e.node);
                        const rowId = period
                          ? rowIdToRelativeRow[period]
                          : e.node.id;

                        field.onChange({
                          columnId,
                          rowId,
                          name,
                          period,
                        });
                      });

                      gridApi?.setGridOption("rowClass", "ag-cell-selection");
                    }}
                  >
                    <FaHandPointUp />
                  </Button>
                )}
              />
            </>
          )}
        </Stack>
      </td>
    ),
    note: (
      <td key="note">
        <Input
          {...register(`alerts.${index}.note`)}
          size="sm"
          disabled={disabled}
        />
      </td>
    ),
    actions: (
      <td key="actions">
        <Stack gap={1} flexDirection={"row"} justifyContent={"center"}>
          <Button
            fullWidth={type === "create"}
            size="sm"
            variant="soft"
            color="neutral"
            onClick={() => remove(index)}
            disabled={disabled}
          >
            <FaTrash />
          </Button>
          {type === "manage" && (
            <Button
              size="sm"
              variant="soft"
              color="neutral"
              onClick={async () => {
                if (!pageId) return;
                const alertProduct = await client.fetchById(
                  "pageProducts",
                  alert.columnId,
                );
                const isOnPage = alertProduct?.pageId === pageId;
                if (!isOnPage) {
                  navigate({
                    to: "/app/market/$id",
                    params: {
                      id: alertProduct?.pageId || "",
                    },
                  });
                }
                setTimeout(
                  () => {
                    if (rowNode) {
                      gridApi?.clearCellSelection();
                      gridApi?.addCellRange({
                        rowStartIndex: rowNode.rowIndex,
                        rowEndIndex: rowNode.rowIndex,
                        columnStart: output[index]?.columnId,
                        columnEnd: output[index]?.columnId,
                      });
                      gridApi?.flashCells({
                        columns: [output[index]?.columnId],
                        rowNodes: [rowNode],
                      });
                    }
                  },
                  isOnPage ? 0 : 1000,
                );
              }}
              disabled={disabled}
            >
              Go To
            </Button>
          )}
        </Stack>
      </td>
    ),
    affected: (
      <td key="affected">
        <Typography
          fontSize="xs"
          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          overflow="hidden"
          title={affectedString}
        >
          {affectedString}
        </Typography>
      </td>
    ),
    status: (
      <td key="status">
        <Typography
          fontSize="xs"
          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          overflow="hidden"
          title={output[index]?.status}
        >
          {output[index]?.status}
        </Typography>
      </td>
    ),
    recurring: (
      <td key="recurring">
        <Controller
          name={`alerts.${index}.recurring`}
          control={control}
          disabled={disabled}
          render={({ field }) => (
            <Checkbox
              disabled={disabled}
              checked={output[index].recurring}
              onChange={(e) => {
                field.onChange(e.target.checked);
              }}
              size="sm"
            />
          )}
        />
      </td>
    ),

    sound: (
      <td key="sound">
        <Controller
          name={`alerts.${index}.sound`}
          control={control}
          disabled={disabled}
          render={({ field }) => (
            <Checkbox
              disabled={disabled}
              checked={output[index].sound}
              onChange={(e) => {
                field.onChange(e.target.checked);

                if (e.target.checked) {
                  if (sessionStorage.getItem("soundPlayed") === "true") return;
                  new Audio(alertSounds.huthut).play();
                  sessionStorage.setItem("soundPlayed", "true");
                }
              }}
              size="sm"
            />
          )}
        />
      </td>
    ),
  };

  const tableItems =
    type === "create"
      ? [items.limit, items.note, items.actions, items.recurring, items.sound]
      : [
          items.limit,
          items.affected,
          items.note,
          items.actions,
          items.status,
          items.recurring,
          items.sound,
        ];

  return <tr>{tableItems}</tr>;
}

export function CreateAlert() {
  const pageId = usePageId();

  const dockviewValue = useAtomValue(gridModalDockviewAtom);
  const rangeSelection = dockviewValue?.alerts?.selectedRange || [];

  const { getApi } = useGridApi();
  const gridApi = getApi();
  const resetLimitSelection = useResetLimitSelection();

  const form = useForm<{
    alerts: TAlertForm[];
  }>({
    defaultValues: {
      alerts: [emptyAlert()],
    },
    resolver: zodResolver(alertValidation),
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "alerts",
  });

  const formData = useWatch({
    control: form.control,
    name: "alerts",
  });

  const auth = useAuth0();
  const userId = auth.user?.sub;

  const selectedCell = rangeSelection?.[0]?.[0];
  const rowNode = gridApi?.getRowNode(selectedCell?.rowId);
  const selectedPeriod = getNodePeriod(rowNode);
  const selectedColumnId = selectedCell?.columnId;

  const cell = rowNode
    ? {
        value: gridApi?.getCellValue({
          rowNode,
          colKey: selectedColumnId,
        }),
        period: selectedPeriod,
        rowId: selectedPeriod
          ? rowIdToRelativeRow[selectedPeriod]
          : rowNode?.id,
        columnId: selectedColumnId,
      }
    : undefined;

  const currentPageProducts = useAtomValue(currentPageProductsAtom);
  const product = currentPageProducts.find(
    (product) => product.productId === selectedCell?.productId,
  );
  const productId = product?.productId;

  async function handleSave() {
    toast.promise(
      new Promise((resolve) => {
        resetLimitSelection();

        if (!cell) throw new Error("Cell is not defined");
        if (!pageId) throw new Error("pageId is not defined");
        if (!userId) throw new Error("userId is not defined");
        if (!gridApi) throw new Error("gridApi is not defined");
        if (!formData.length) throw new Error("No alerts defined");

        const formattedAlerts = formData
          .flatMap((alert) => {
            const limit = alert?.limitRef
              ? `${alert.limitRef.columnId}:${alert.limitRef.productId}:${alert.limitRef.fieldSelector}:${alert.limitRef.rowId}`
              : parse(alert.limit);

            if (!limit) return;

            const limitRefRowNode = alert?.limitRef
              ? gridApi.getRowNode(rowStringToCode(alert.limitRef?.period))
              : undefined;

            const limitRefCellValue =
              limitRefRowNode && alert.limitRef?.columnId
                ? gridApi.getCellValue({
                    rowNode: limitRefRowNode,
                    colKey: alert.limitRef?.columnId,
                  })
                : undefined;

            if (!product) {
              console.log(
                "product not found for alert",
                alert,
                currentPageProducts,
              );
              throw new Error("Product not found for alert");
            }

            const fieldSelector = product.columnFieldSelector;

            return {
              limit: limit.toString(),
              // Decides whether the alert will use <= or >= to determine when to trigger.
              valueBelowLimit: limitRefCellValue
                ? cell.value < limitRefCellValue
                : cell.value < limit,
              note: alert.note,
              status: "Active",
              recurring: alert.recurring ?? false,
              sound: alert?.sound ?? false,
              triggeredAt: null,
              columnId: cell.columnId,
              rowId: cell.rowId || "",
              userId,
              pageId,
              productId: productId || alert.productId,
              fieldSelector,
            } satisfies Omit<TAlert, "id">;
          })
          .filter(Boolean);

        const inserts = formattedAlerts.map((alert) => {
          return client.insert("alerts", alert);
        });
        console.log("inserts:", inserts);

        resolve(Promise.all(inserts));
      }),
      {
        loading: "Creating Alert(s)...",
        success: "Alert(s) Created!",
        error: "Error Creating Alert(s)",
      },
    );
  }

  return (
    <GridModalContainer
      panel="alerts"
      body={
        <>
          <GridModalSelectionInfo selectedRange={rangeSelection} />
          <form onSubmit={form.handleSubmit(handleSave)}>
            <Box pt={2}>
              <Stack gap={2} mb={4}>
                <Table
                  sx={(theme) => ({
                    tableLayout: "fixed",
                    minWidth: 400,
                    td: {
                      minWidth: 40,
                      textAlign: "center",
                    },
                    "tr:first-of-type td": {
                      paddingTop: 1.5,
                    },
                    th: headerStyle(theme),
                    borderSpacing: "6px 4px",
                  })}
                  noWrap
                  borderAxis="none"
                >
                  <AlertHeader type="create" />
                  <tbody>
                    {fields.map((alert, index) => (
                      <AlertRow
                        key={alert.id}
                        form={form}
                        alert={alert}
                        index={index}
                        remove={remove}
                        type="create"
                      />
                    ))}
                  </tbody>
                </Table>
                <Box>
                  <Typography
                    onClick={() => append(emptyAlert())}
                    sx={{
                      color: "var(--artis-orange)",
                      cursor: "pointer",
                      display: "inline",
                      pl: 2,
                    }}
                  >
                    + Add Alert
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </form>
        </>
      }
      buttons={
        <ModalButtons
          parentPanel={"alerts"}
          onCancel={() => {
            gridApi?.setGridOption("onCellClicked", undefined);
            gridApi?.setGridOption("rowClass", undefined);
          }}
          onSave={() => {
            captureEvent("Create Alert Save");
            handleSave();
          }}
        />
      }
    />
  );
}
