// tooltip-element.ts
import type { IChartApi } from "lightweight-charts";

export interface TooltipOptions {
  title: string;
  theme: "dark" | "light";
  followMode: "top" | "tracking";
  /** fallback horizontal deadzone width */
  horizontalDeadzoneWidth: number;
  verticalDeadzoneHeight: number;
  verticalSpacing: number;
  /** topOffset is the vertical spacing when followMode is 'top' */
  topOffset: number;
}

const defaultOptions: TooltipOptions = {
  title: "",
  theme: "light",
  followMode: "tracking",
  horizontalDeadzoneWidth: 45,
  verticalDeadzoneHeight: 100,
  verticalSpacing: 20,
  topOffset: 20,
};

export interface TooltipContentData {
  title?: string;
  price: string;
  change: string;
  date: string;
  time: string;
  //field: string;
}

export interface TooltipPosition {
  visible: boolean;
  paneX: number;
  paneY: number;
}

export class TooltipElement {
  private _chart: IChartApi | null;

  private _element: HTMLDivElement | null;
  private _titleElement: HTMLDivElement | null;
  private _priceHighestElement: HTMLDivElement | null;
  private _priceLowestElement: HTMLDivElement | null;
  private _priceOpenElement: HTMLDivElement | null;
  private _priceCloseElement: HTMLDivElement | null;
  private _changeElement: HTMLDivElement | null;
  //private _fieldElement: HTMLDivElement | null;

  private _options: TooltipOptions;

  private _lastTooltipWidth: number | null = null;

  public constructor(chart: IChartApi, options: Partial<TooltipOptions>) {
    this._options = {
      ...defaultOptions,
      ...options,
    };
    this._chart = chart;

    const element = document.createElement("div");
    const isDark = this._options.theme === "dark";
    console.log("tooltip theme", isDark);
    applyStyle(element, {
      display: "flex",
      "flex-direction": "column",
      position: "absolute",
      transform: "translate(calc(0px - 50%), 0px)",
      left: "0%",
      top: "0",
      "z-index": "100",
      "background-color": isDark
        ? "rgb(0 0 0 / 30%)"
        : "rgb(221 221 221 / 30%)",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      opacity: "0",
      border: "1px solid rgba(255, 255, 255, 0.3)",
      padding: "5px 5px",
      "font-size": "10px",
      "pointer-events": "none",
      color: isDark ? "#fff" : "#000",
    });

    const titleElement = document.createElement("div");
    applyStyle(titleElement, {
      "font-size": "10px",
      "text-align": "center",
      "margin-bottom": "5px",
    });
    setElementText(titleElement, this._options.title);
    element.appendChild(titleElement);

    // Create a container for the fields and values
    const contentContainer = document.createElement("div");
    applyStyle(contentContainer, {
      display: "grid",
      "grid-template-columns": "auto auto",
      gap: "5px 30px",
      width: "100%",
    });

    const fields = ["Field", "Highest", "Lowest", "Open", "Close", "Change"];
    const labels = fields.map((field) => {
      const labelElement = document.createElement("div");
      applyStyle(labelElement, {
        "text-align": "left",
      });
      setElementText(labelElement, field);
      return labelElement;
    });

    const values = ["Value/FV", "", "", "", " ", " "];
    const valueElements = values.map((value) => {
      const valueElement = document.createElement("div");
      applyStyle(valueElement, {
        "text-align": "right",
      });
      setElementText(valueElement, value);
      return valueElement;
    });

    // Append labels and values to the content container
    labels.forEach((label, index) => {
      contentContainer.appendChild(label);
      contentContainer.appendChild(valueElements[index]);
    });

    element.appendChild(contentContainer);

    this._element = element;
    this._titleElement = titleElement;
    //this._fieldElement = valueElements[0]; // Field
    this._priceHighestElement = valueElements[1]; // Highest
    this._priceLowestElement = valueElements[2]; // Lowest
    this._priceOpenElement = valueElements[3]; // Open
    this._priceCloseElement = valueElements[4]; // Close
    this._changeElement = valueElements[5]; // Change

    const chartElement = this._chart.chartElement();
    chartElement.appendChild(this._element);

    const chartElementParent = chartElement.parentElement;
    if (!chartElementParent) {
      console.error("Chart Element is not attached to the page.");
      return;
    }
  }

  public destroy() {
    if (this._chart && this._element)
      this._chart.chartElement().removeChild(this._element);
  }

  public applyOptions(options: Partial<TooltipOptions>) {
    this._options = {
      ...this._options,
      ...options,
    };
  }

  public options(): TooltipOptions {
    return this._options;
  }

  public updateTooltipContent(tooltipContentData: TooltipContentData) {
    if (!this._element) return;
    const tooltipMeasurement = this._element.getBoundingClientRect();
    this._lastTooltipWidth = tooltipMeasurement.width;
    if (tooltipContentData.title !== undefined && this._titleElement) {
      setElementText(this._titleElement, tooltipContentData.title);
    }
    const [open, highest, lowest, close] = tooltipContentData.price.split(", ");
    const change = tooltipContentData.change.trim();
    const changeEl = this._changeElement;
    //setElementText(this._fieldElement, tooltipContentData.field);
    setElementText(this._priceHighestElement, highest?.split(": ")?.[1] ?? "");
    setElementText(this._priceLowestElement, lowest?.split(": ")?.[1] ?? "");
    setElementText(this._priceOpenElement, open?.split(": ")?.[1] ?? "");
    setElementText(this._priceCloseElement, close?.split(": ")?.[1] ?? "");
    setElementText(changeEl, change);
    if (changeEl) {
      if (change === "0") {
        applyStyle(changeEl, { color: "var(--ag-data-color)" });
      } else if (change.includes("-")) {
        applyStyle(changeEl, { color: "var(--downColor)" });
      } else {
        applyStyle(changeEl, { color: "var(--upColor)" });
      }
    }
  }

  public updatePosition(positionData: TooltipPosition) {
    if (!this._chart || !this._element) return;
    this._element.style.opacity = positionData.visible ? "1" : "0";
    this._element.style.backdropFilter = positionData.visible
      ? "blur(5px)"
      : "none";
    this._element.style.borderRadius = positionData.visible ? "8px" : "0";
    if (!positionData.visible) {
      return;
    }
    const x = this._calculateXPosition(positionData, this._chart);
    const y = this._calculateYPosition(positionData);
    this._element.style.transform = `translate(${x}, ${y})`;
  }

  private _calculateXPosition(
    positionData: TooltipPosition,
    chart: IChartApi,
  ): string {
    const x = positionData.paneX + chart.priceScale("left").width();
    const deadzoneWidth = this._lastTooltipWidth
      ? Math.ceil(this._lastTooltipWidth / 2)
      : this._options.horizontalDeadzoneWidth;
    const xAdjusted = Math.min(
      Math.max(deadzoneWidth, x),
      chart.timeScale().width() - deadzoneWidth,
    );
    return `calc(${xAdjusted}px - 50%)`;
  }

  private _calculateYPosition(positionData: TooltipPosition): string {
    if (this._options.followMode === "top") {
      return `${this._options.topOffset}px`;
    }
    const y = positionData.paneY;
    const flip =
      y <= this._options.verticalSpacing + this._options.verticalDeadzoneHeight;
    const yPx = y + (flip ? 1 : -1) * this._options.verticalSpacing;
    const yPct = flip ? "" : " - 100%";
    return `calc(${yPx}px${yPct})`;
  }
}

function setElementText(element: HTMLDivElement | null, text: string) {
  if (!element || text === element.innerText) return;
  element.innerText = text;
  element.style.display = text ? "block" : "none";
}

function applyStyle(element: HTMLElement, styles: Record<string, string>) {
  for (const [key, value] of Object.entries(styles)) {
    if (element.style.getPropertyValue(key) !== value) {
      element.style.setProperty(key, value);
    }
  }
}
