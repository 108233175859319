import { client } from "../../triplit/triplit";
import type { TGridSettings } from "../../triplit/schema";
import { parseSpreaderStateRowData } from "../../triplit/schema";
import toast from "react-hot-toast";
import captureEvent from "../../eventTracker";

export async function gridSettingsUpsert<
  K extends keyof TGridSettings,
  V extends TGridSettings[K],
>(userId: string, k: K, v: V) {
  try {
    if (k === "id") return;

    const value =
      k === "statusMap" && typeof v === "object" ? JSON.stringify(v) : v;

    const current = await client.fetchById("gridSettings", userId, {
      policy: "local-only",
    });
    const exists = current && k in current;

    await client.insert("gridSettings", {
      ...(!exists && { id: userId }),
      ...current,
      statusMap: current?.statusMap ? JSON.stringify(current.statusMap) : "{}",
      [k]: value,
    });
    console.log("Upserted grid settings", k, value);
  } catch (error) {
    toast.error("An error occurred while saving your data. Please try again.", {
      position: "bottom-left",
    });
    throw error;
  }
  captureEvent("Updated Grid Settings");
}
export async function spreadUpsert(
  userId: string,
  pageId: string,
  rowIdx: number,
  rowData: unknown,
) {
  try {
    const validRowData = parseSpreaderStateRowData(rowData);

    await client.insert("spreaderState", {
      userId: userId,
      pageId: pageId,
      rowIdx: rowIdx,
      rowData: validRowData,
    });
  } catch (error) {
    console.error("Error during spreadUpsert:", error);

    toast.error("An error occurred while saving your data. Please try again.");
  }
}

export async function spreadUpdate(
  id: string,
  userId: string,
  pageId: string,
  rowIdx: number,
  rowData: unknown,
) {
  const validRowData = parseSpreaderStateRowData(rowData);

  await client.update("spreaderState", id, (entity) => {
    entity.userId = userId;
    entity.pageId = pageId;
    entity.rowIdx = rowIdx;
    entity.rowData = validRowData;
  });
}

export async function spreadDelete(id: string) {
  await client.delete("spreaderState", id);
}
