import { createContext } from "react";
import type { SeriesActionParams, SeriesActionResult } from "../../internal/series";

export type SeriesContextValue =
  | null
  | (() => SeriesActionResult<SeriesActionParams>);

export const SeriesContext = createContext<SeriesContextValue>(null);

SeriesContext.displayName = "SeriesContext";
