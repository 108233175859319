import { Auth0Provider, type User, useAuth0 } from "@auth0/auth0-react";
import * as R from "remeda";
import * as Sentry from "@sentry/react";
import { useRouterState } from "@tanstack/react-router";
import {
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  type JWT,
  UserOrgAtom,
  orgIdToEnv,
} from "../globals";
import { createApolloClient, createSanityClient } from "../graphqlClient";
import { ApolloProvider } from "@apollo/client";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { jwtDecode } from "jwt-decode";
import { useEnv } from "../envUtils";
import { client as triplitClient } from "../triplit/triplit";
import { useLoadingState } from "../webapp/sharedHooks";
import { useAtom } from "jotai";
import { ApolloClientStore, SanityApolloClientStore } from "./store";
import posthog from "posthog-js";
import { ORG_BY_ID_QUERY } from "../graphqlOperations";
import { parse } from "../webapp/numbers";
import { useLogout, fetchUserGroups } from "./hooks";
import { datadogRum } from "@datadog/browser-rum";

if (process.env.NODE_ENV === "development") {
  loadDevMessages();
  loadErrorMessages();
}

export function AuthorizedApolloProvider({
  children,
}: {
  children: ReactNode;
}) {
  const logout = useLogout();
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0<
    User & {
      "folio-webapp": unknown;
    }
  >();

  const { setLoaded, isLoading } = useLoadingState();

  const [env] = useEnv();

  const [orgId, setOrgId] = useAtom(UserOrgAtom);

  const [client, setClient] = useAtom(ApolloClientStore);
  const [sanityClient, setSanityClient] = useAtom(SanityApolloClientStore);
  const isApp = useRouterState({
    select: (state) => state.location.pathname.includes("/app"),
  });
  const [clientSettings, setClientSettings] = useState({
    isApp: false,
    env,
  });

  const tokenValid = useRef(false);

  const onToken = useCallback(
    (token: string, user: User) => {
      triplitClient.updateToken(token);
      triplitClient.connect();
      const jwt = jwtDecode<JWT>(token);
      const auth0OrgId = jwt.org_id;
      const hasuraOrgId = parse(
        jwt?.["https://hasura.io/jwt/claims"]?.["x-hasura-org-id"],
      );
      const clientEnv = isApp ? orgIdToEnv(auth0OrgId) : env;
      posthog.identify(jwt.sub, R.omit(user, ["folio-webapp"]));
      datadogRum.setUser({
        id: jwt.sub,
        username: `${user.given_name} ${user.family_name}`,
        email: user.email,
        hasuraOrgId,
        auth0OrgId,
        env: clientEnv,
      });
      Sentry.setUser({
        id: jwt.sub,
        username: `${user.given_name} ${user.family_name}`,
        email: user.email,
      });

      setOrgId(auth0OrgId);

      const globals = {
        userId: jwt.sub,
        auth0OrgId,
        hasuraOrgId,
        env: clientEnv,
      };
      triplitClient.db.updateGlobalVariables({
        ...globals,
      });

      const client = createApolloClient(
        clientEnv,
        getAccessTokenSilently,
        isApp,
      );

      const sanityClient = createSanityClient();

      setSanityClient(sanityClient);
      setClient(client);
      setLoaded("all", "auth");
      setTimeout(() => {
        if (!hasuraOrgId) {
          console.error("No hasura org id found in token");
          return;
        }
        client
          .query({
            query: ORG_BY_ID_QUERY,
            variables: { id: hasuraOrgId },
          })
          .then((res) => {
            const source = res.data?.organisation_by_pk?.sourceBySource;
            if (!source) {
              console.error("No source found for org id", hasuraOrgId);
              return;
            }
            posthog.group("org", hasuraOrgId.toString(), {
              env: clientEnv,
              alias: source.alias,
              name: source.name,
              description: source.description,
            });

            fetchUserGroups().then((res) => {
              if (!res) return;
              for (const { id } of res) {
                posthog.group("userGroup", id);
              }
            });
          });
      }, 5000);
      setTimeout(() => {
        tokenValid.current = false;
      }, 1000 * 60);
    },
    [
      env,
      getAccessTokenSilently,
      isApp,
      setClient,
      setSanityClient,
      setLoaded,
      setOrgId,
    ],
  );

  useEffect(() => {
    if (
      (isApp && tokenValid.current) ||
      (isApp === clientSettings.isApp &&
        env === clientSettings.env &&
        !isLoading) ||
      !user
    ) {
      return;
    }
    getAccessTokenSilently()
      .then((res) => {
        tokenValid.current = true;
        setClientSettings({ isApp, env });
        onToken(res, user);
      })
      .catch(() => {
        console.warn("error getting access token - logging out");
        logout();
      });
  }, [
    clientSettings.env,
    clientSettings.isApp,
    env,
    getAccessTokenSilently,
    isApp,
    logout,
    isLoading,
    onToken,
    user,
  ]);

  if (!isAuthenticated) {
    return <div>Not Authenticated</div>;
  }

  if (!client || !orgId) {
    console.log("no client or org id");
    return null;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export function AuthProvider({ children }: { children: ReactNode }) {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={(state) => {
        posthog.capture("login", {
          $set_once: {
            first_login: new Date().toISOString(),
          },
        });
        if (state?.returnTo) {
          window.location.href = window.location.origin + state.returnTo;
        }
      }}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "hasura",
      }}
    >
      {children}
    </Auth0Provider>
  );
}
