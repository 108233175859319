import { InMemoryCache } from "@apollo/client";
import type { SharedInstrumentsSubscription } from "../../__generated__/gql/graphql";

const mergeSharedInstStream = (
  existing: SharedInstrumentsSubscription["shared_instrument_stream"] = [],
  incoming: SharedInstrumentsSubscription["shared_instrument_stream"] = [],
) => {
  const merged = [...existing];

  for (const incomingItem of incoming) {
    const index = merged.findIndex(
      (existingItem) =>
        existingItem.product === incomingItem.product &&
        existingItem.month === incomingItem.month,
    );

    if (index > -1) {
      merged[index] = { ...merged[index], ...incomingItem };
    } else {
      merged.push(incomingItem);
    }
  }

  return merged;
};

export const cache = new InMemoryCache({
  typePolicies: {
    Subscription: {
      fields: {
        shared_instrument_stream: {
          merge: mergeSharedInstStream,
        },
      },
    },
  },
});
