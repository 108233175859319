import type { ProductFragmentGridFragment } from "../../../__generated__/gql/graphql";
import type { usePageProductIds } from "../../market-pages/pageProductHooks";

export const knownStatusList = [
  "hybrid_broadcast",
  "listen",
  "broadcast",
  "private",
  "eod",
] as const;

export type TKnownStatus = (typeof knownStatusList)[number];

export type TStatus = TKnownStatus | (string & {});
export type TManualStatus = "private" | "broadcast" | "hybrid_broadcast";

export type TStorageType = "broadcast" | "local" | "eod" | "global" | "shared";

export type TManualStoreStorageType = "shared" | "broadcast" | "local";

export const statusMap = {
  hybrid_broadcast: "shared",
  listen: "shared",
  private: "local",
  broadcast: "broadcast",
  eod: "eod",
} satisfies Record<TKnownStatus, TStorageType>;

export function isPrivateStorageType(status: TStatus = "listen") {
  return status === "private";
}

export function isSharedStorageType(status: TStatus = "listen") {
  return (
    status === "hybrid_broadcast" ||
    status === "listen" ||
    status === "broadcast"
  );
}

export function isEodStorageType(status: TStatus = "listen") {
  return status === "eod";
}

export function isGlobalStorageType(status: TStatus = "listen") {
  return !knownStatusList.includes(status);
}

export const statusMapVals = Object.values(statusMap);

export function getInstrumentType(status: TStatus = "listen") {
  return knownStatusList.includes(status)
    ? statusMap[status as (typeof knownStatusList)[number]]
    : "global";
}

export function getManualInstrumentType(
  status: TManualStatus | string = "listen",
) {
  if (
    status === "private" ||
    status === "broadcast" ||
    status === "hybrid_broadcast" ||
    status === "listen"
  ) {
    return statusMap[status];
  }
  return null;
}

export function statusId(productId: string, status: TStatus) {
  return `${productId}:${getInstrumentType(status)}`;
}

export type TProductWithGridIdAndStatus = ProductFragmentGridFragment & {
  gridId: string | undefined;
  status: string | undefined;
};

export type TAffectedProducts = {
  "current-page"?: TProductWithGridIdAndStatus[];
  "all-pages"?: TProductWithGridIdAndStatus[];
};

export function joinProductsWithGridPageProducts(
  gridProductIds: ReturnType<typeof usePageProductIds>["data"],
  products: readonly ProductFragmentGridFragment[] | null | undefined,
  statusMap: Record<string, TStatus>,
) {
  return gridProductIds
    ?.map(({ productId, gridId }) => {
      const matchingProduct = products?.find((p) => p.id === productId);

      if (!matchingProduct) {
        return;
      }

      return {
        ...matchingProduct,
        gridId,
        status: productId ? statusMap?.[productId] : "listen",
      };
    })
    .filter(Boolean)
    .filter((product) => product.has_shared_cell) satisfies
    | TProductWithGridIdAndStatus[]
    | undefined;
}
