import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Switch,
  Typography,
} from "@mui/joy";
import { type ComponentProps, forwardRef } from "react";
import {
  type Control,
  Controller,
  type FieldError,
  type FieldValues,
  type Path,
  type RegisterOptions,
  type UseFormRegister,
} from "react-hook-form";

export const InputField = forwardRef<
  HTMLInputElement,
  {
    label?: string;
    dirty?: boolean;
    error?: FieldError | undefined;
    type?: ComponentProps<typeof Input>["type"];
    sx?: ComponentProps<typeof FormControl>["sx"];
    endDecorator?: React.ReactNode;
  } & ReturnType<UseFormRegister<FieldValues>>
>(({ label, error, dirty, sx, ...rest }, ref) => {
  return (
    <FormControl sx={sx}>
      {label && (
        <FormLabel sx={{ textTransform: "capitalize" }}>{label}</FormLabel>
      )}
      <Input
        color={error ? "danger" : dirty ? "primary" : "neutral"}
        className={dirty ? "Input-dirty" : ""}
        slotProps={{ input: { ref: ref } }}
        {...rest}
      />
      {error && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
});

type ControllerProps<TFields extends FieldValues> = {
  name: Path<TFields>;
  defaultValue?: string;
  rules?: RegisterOptions;
  error?: FieldError;
  control: Control<TFields>;
};

export function SelectField<TFields extends FieldValues>({
  name,
  label,
  options,
  multiple,
  renderOption,
  control,
  onChange,
  sx,
}: {
  label: string;
  multiple?: true | undefined;
  options: string[];
  onChange?: (value: string[]) => unknown;
  renderOption?: (_option: string) => string;
  sx?: ComponentProps<typeof FormControl>["sx"];
} & ControllerProps<TFields>) {
  const renderOptionFn = renderOption || ((opt: string) => opt);
  return (
    <FormControl sx={sx}>
      <FormLabel sx={{ textTransform: "capitalize" }}>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        rules={{ required: `${name} field is required` }}
        render={({ field: { value, ...props }, fieldState: { error } }) => {
          if (typeof value !== "string" && typeof value !== "object") {
            throw new Error("value must be a string or an object", value);
          }
          const values: string[] =
            typeof value === "string" ? value.split(",") : value;
          return (
            <>
              <Select
                multiple={multiple}
                value={values}
                onChange={(_event, newValue) => {
                  if (newValue?.length !== 0) {
                    onChange?.(newValue);
                    props.onChange(newValue);
                  }
                }}
              >
                {options.map((opt) => {
                  return (
                    <Option key={opt} value={opt}>
                      {renderOptionFn(opt)}
                    </Option>
                  );
                })}
              </Select>
              {error && <FormHelperText>{error.message}</FormHelperText>}
            </>
          );
        }}
      />
    </FormControl>
  );
}

export function SwitchField<TFields extends FieldValues>({
  name,
  label,
  control,
  onChange,
}: {
  onChange?: (value: boolean) => unknown;
  label: string;
} & ControllerProps<TFields>) {
  return (
    <FormControl>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Typography
              component="label"
              sx={{ textTransform: "capitalize" }}
              endDecorator={
                <Switch
                  checked={field.value || false}
                  onChange={(e) => {
                    const newValue = e.target.checked;
                    field.onChange(e);
                    onChange?.(newValue);
                  }}
                  sx={{ ml: 1 }}
                />
              }
            >
              {label}
            </Typography>
          );
        }}
      />
    </FormControl>
  );
}

export function PrettySwitchField<TFields extends FieldValues>({
  name,
  control,
  onChange,
  slotProps,
  color = "neutral",
  label,
}: {
  onChange?: (value: boolean) => unknown;
  label: string | ((value: boolean) => string);
  slotProps?: ComponentProps<typeof Switch>["slotProps"];
  color?: ComponentProps<typeof Switch>["color"];
} & ControllerProps<TFields>) {
  return (
    <FormControl>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <FormLabel sx={{ textTransform: "capitalize" }}>
                {typeof label === "function" ? label(field.value) : label}
              </FormLabel>
              <Switch
                color={color}
                slotProps={slotProps}
                checked={field.value || false}
                onChange={(e) => {
                  const newValue = e.target.checked;
                  field.onChange(e);
                  onChange?.(newValue);
                }}
                sx={{
                  alignSelf: "initial",
                  "--Switch-thumbSize": "24px",
                  "--Switch-trackWidth": "60px",
                  "--Switch-trackHeight": "31px",
                }}
              />
            </Box>
          );
        }}
      />
    </FormControl>
  );
}
