import { type FetchPolicy, useSubscription } from "@apollo/client";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";

/**
 * Custom hook for querying data with Apollo Client.
 * For now it just polls but in the future it could use subscriptions.
 *
 * @template T - The type of data returned by the query.
 * @template V - The type of variables used in the query.
 * @param options - The options for the query.
 * @param options.query - The GraphQL query document.
 * @param [options.variables] - The variables used in the query.
 * @param [options.pause] - Whether to pause the query.
 * @returns - An object containing the query data, loading state, and error.
 */
export function useQuerySub<T, V>({
  query,
  variables,
  pause,
  fetchPolicy,
}: {
  query: TypedDocumentNode<T, V>;
  variables?: Record<string, unknown>;
  pause?: boolean;
  fetchPolicy?: FetchPolicy;
}) {
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useSubscription(query, {
    variables,
    skip: pause,
    fetchPolicy: fetchPolicy ?? "cache-first",
    onError(error) {
      console.error("Error in gql subscription", error);
    },
    onData(data) {
      console.log("Data in gql subscription", {
        data,
        query,
        variables,
      });
    },
  });

  return {
    data: queryData,
    fetching: queryLoading,
    error: queryError,
  };
}
