import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Button, Sheet, Typography } from "@mui/joy";
import { OrgSearchItems } from "./OrgSearch";
import { useState, type MouseEvent } from "react";
import { useOrg } from "../orgs";
import { useResetUserFilters } from "../components/tableHelpers";

export function OrgPopup() {
  const orgName = useOrg()?.sourceBySource?.name;

  const { resetUserFilters } = useResetUserFilters();

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const handleItemClick = () => {
    // When switching to a different org, reset the table filters.
    resetUserFilters();

    setAnchor(null);
  };

  const open = Boolean(anchor);

  return (
    <>
      <Button
        data-testid="org-selector-button"
        variant="solid"
        onClick={handleClick}
      >
        <Typography
          sx={{
            maxWidth: {
              xs: 80,
              sm: 150,
              md: 250,
            },
          }}
          overflow={"hidden"}
          whiteSpace={"nowrap"}
          textOverflow={"ellipsis"}
          level="body-xs"
          textColor="white"
        >
          {orgName || "Orgs"}
        </Typography>
      </Button>
      <ClickAwayListener
        disableReactTree
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleItemClick}
      >
        <BasePopup
          style={{
            zIndex: 1000,
          }}
          id="org-popup"
          offset={4}
          open={open}
          anchor={anchor}
          placement="bottom-end"
        >
          <Sheet
            variant="outlined"
            sx={(theme) => ({
              background: theme.palette.background.body,
              padding: 2,
              borderRadius: theme.radius.md,
              zIndex: 1000,
            })}
          >
            <OrgSearchItems handleClick={handleItemClick} />
          </Sheet>
        </BasePopup>
      </ClickAwayListener>
    </>
  );
}
