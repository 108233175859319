import { atom } from "jotai";
import type { TGridSettings } from "../../triplit/schema";
import { initGridSettings } from "../market-grid/defaultSettings";
import type { TAdhocSpread } from "../market-grid/modals/timespreadHelpers";

export const gridSettingsAtom = atom<TGridSettings | undefined>(undefined);

export const adhocSpreadsAtom = atom<TAdhocSpread>([]);
adhocSpreadsAtom.debugLabel = "adhocSpreadsAtom";

// Prevent multiple refresh timeouts from being set.
export const refreshTimeoutSet = atom<boolean>(false);
refreshTimeoutSet.debugLabel = "refreshTimeoutAtom";

export const statusMapAtom = atom((get) => {
  const settings = get(gridSettingsAtom) || initGridSettings;

  return settings.statusMap;
});
