//import "./wydr";
import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./styles/common.css";
import { LicenseManager } from "ag-grid-enterprise";
import * as Sentry from "@sentry/react";
import { AG_KEY, GIT_SHA } from "./globals";
import posthog from "posthog-js";
import "jotai-devtools/styles.css";
import { DevTools } from "jotai-devtools";
import { datadogRum, type RumInitConfiguration } from "@datadog/browser-rum";
import { datadogLogs, type LogsInitConfiguration } from "@datadog/browser-logs";

const env = import.meta.env.VITE_CONTEXT || "local";

const datadogBaseConfig = {
  applicationId: "34d168e9-fd17-4c97-904f-31b4a16cc6e9",
  clientToken: "pub6c78fd501a04491acf79bcdd7fbc1701",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "datadoghq.eu",
  service: "artis-webapp",
  env,
  version: GIT_SHA,
  sessionSampleRate: 100,
} as const satisfies RumInitConfiguration;

const datadogConfig = {
  ...datadogBaseConfig,
  compressIntakeRequests: true,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  trackViewsManually: true,
  storeContextsAcrossPages: true,
  silentMultipleInit: true,
  allowFallbackToLocalStorage: true,
} satisfies RumInitConfiguration;

const datadogLogsConfig = {
  ...datadogBaseConfig,
  // debug logs are not forwarded to datadog
  forwardConsoleLogs: ["warn", "error", "info"],
  telemetrySampleRate: 100,
  telemetryConfigurationSampleRate: 100,
  telemetryUsageSampleRate: 100,
} satisfies LogsInitConfiguration;

datadogRum.init(datadogConfig);
datadogLogs.init(datadogLogsConfig);

async function measureMemory() {
  try {
    if (
      typeof performance === "undefined" ||
      !("measureUserAgentSpecificMemory" in performance)
    ) {
      return;
    }
    // @ts-ignore - measureUserAgentSpecificMemory is not yet part of the spec but it's available in Chrome
    const memorySample = await performance.measureUserAgentSpecificMemory();
    // Transform the breakdown into key-value pairs
    const memoryDetails = memorySample.breakdown.reduce(
      (
        acc: Record<string, number>,
        item: {
          types?: string[];
          attribution?: { scope: string; url: string }[];
          bytes: number;
        },
      ) => {
        const type = item.types?.[0] || "Unknown";
        const attribution = item.attribution?.[0] || {
          scope: "Unknown",
          url: "Unknown",
        };
        const key = `${type} - ${attribution.scope} ${attribution.url}`;
        acc[key] = item.bytes;
        return acc;
      },
      {},
    );

    // Add the total bytes to the memory details
    const memoryUsageData = {
      totalBytes: memorySample.bytes,
      ...memoryDetails,
    };
    // Send memory usage data to Datadog
    datadogLogs.logger.debug("memory-usage-sample", memoryUsageData);
    datadogRum.setGlobalContextProperty("memory-usage-sample", memoryUsageData);

    // Schedule the next measurement
    setTimeout(measureMemory, 5 * 60 * 1000);
  } catch (error) {
    console.error("Failed to measure memory:", error);
  }
}

if (crossOriginIsolated) {
  setTimeout(measureMemory, 30000);
}

posthog.init("phc_5VQrxO6QX946p5WIhQcFj6QJK5fsVbXn8iOsVsbhCh2", {
  capture_pageview: false,
  api_host: `${window.location.origin}/ingest`,
  ui_host: "https://eu.posthog.com",
  person_profiles: "identified_only",
});

Sentry.init({
  dsn: "https://fe87329662ff690016184c6cb335abb1@o4505007010414592.ingest.us.sentry.io/4506944634224640",
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  profilesSampleRate: 1.0,

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],
});

LicenseManager.setLicenseKey(AG_KEY);

const rootEl = document.getElementById("root");

if (!rootEl) {
  throw new Error("No root element");
}

ReactDOM.createRoot(rootEl).render(
  <React.StrictMode>
    <DevTools position="bottom-right" />
    <App />
  </React.StrictMode>,
);
