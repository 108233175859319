import { type ForwardedRef, forwardRef, memo, type ReactNode } from "react";
import type {
  AreaSeriesPartialOptions,
  ISeriesApi,
  SeriesDataItemTypeMap,
  SeriesMarker,
  Time,
} from "lightweight-charts";

import { SeriesContext } from "./internal/series-context.js";
import { createSeriesHook } from "./internal/create-series-hook.js";
import type { AreaSeriesParams } from "../internal/series.js";

const useAreaSeriesAction = createSeriesHook<AreaSeriesParams>("Area");

export interface AreaSeriesProps extends AreaSeriesPartialOptions {
  data: SeriesDataItemTypeMap["Area"][];
  markers?: SeriesMarker<Time>[];
  reactive?: boolean;
  children?: ReactNode;
}

export const AreaSeries = memo(
  forwardRef(
    (props: AreaSeriesProps, ref: ForwardedRef<ISeriesApi<"Area">>) => {
      const { children, ...rest } = props;

      const context = useAreaSeriesAction(rest, ref);

      return (
        <SeriesContext.Provider value={context.current}>
          {children}
        </SeriesContext.Provider>
      );
    },
  ),
);
