import { useColorScheme } from "@mui/joy";
import { useThemeMode } from "../../shared/hooks";
import type { IHeaderParams, ITooltipParams } from "ag-grid-community";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import type {
  ProductFragmentGridFragment,
  Product_Maturity_Enum,
  Product_Uom_Enum,
} from "../../__generated__/gql/graphql";
import type {
  TData,
  TLatestEditInfoValue,
} from "../calculations-worker/sharedStores";
import { useProductById } from "../curve-management";
import { agGridCellAutoPadding, headerHeight } from "../globals";
import { ArtisSmallLogo } from "../icons/ArtisSmallLogo";
import {
  colParams,
  defaultFieldNameSelector,
  fieldNameSelectorToLabel,
  isListOnlyPermissions,
  uomToLabel,
} from "../utils";
import {
  ColumnHeaderSelect,
  EodHeader,
  LastEditedInfoHeader,
} from "./statuses/StatusComponents";
import {
  mainMonthColumnId,
  type CurveColumnStackName,
  type validColumnSettings,
} from "./columnDefs";
import { Box, Typography } from "@mui/joy";
import { For } from "million/react";
import {
  gridSettingsColorsAtom,
  useGridSettingsColors,
  useGridSettingsOptions,
  useVisibleColumns,
} from "../grid-settings";
import { useEntity } from "@triplit/react";
import { client } from "../../triplit/triplit";
import type { TPageProduct } from "../../triplit/schema";
import { brightColor, calculateTextColor } from "../colorUtils";
import { isDev } from "../../globals";
import { getInstrumentType, type TStatus } from "./statuses/statusLogic";
import { calcWorker } from "../calculations-worker/hooks";
import { ChangeAllStatusButton } from "./statuses/ChangeAll";
import { getColorFromVarName, getStatusClass } from "./classRules";
import { useAtomValue } from "jotai";

dayjs.extend(duration);

function retrieveLabel(
  displayName: CurveColumnStackName,
  product: ProductFragmentGridFragment | null | undefined,
  columnSettings?: TPageProduct | null,
) {
  if (!product) return "loading...";

  const parseLabel = () => {
    switch (displayName) {
      case "commodityParentGroup":
        return product.commodityGroupByCommodityGroup?.commodity_parent_group
          .commodity_parent_group;
      case "geographicalRegion":
        return product.geographicalRegionByGeographicalRegion
          ?.geographical_region;
      case "commodityGroup":
        return product.commodityGroupByCommodityGroup?.commodity_group;
      case "package":
        return product.packageByPackage?.name;
      case "name":
        return product.name;
      case "description":
        return product.description;
      case "source":
        return product.packageByPackage?.sourceBySource?.name;
      case "fieldName":
        return fieldNameSelectorToLabel(
          columnSettings?.columnFieldSelector ??
            defaultFieldNameSelector(product.artis_type),
        );
      case "uom":
        return uomToLabel({
          artisType: product.artis_type,
          source: product.packageByPackage?.sourceBySource?.name ?? "",
          productId: product.id,
          selector:
            columnSettings?.columnFieldSelector ??
            defaultFieldNameSelector(product.artis_type),
          cellLabel: product.uom,
        });
      default:
        throw new Error(
          `header name could not be parsed for value: ${displayName}`,
        );
    }
  };

  const label = parseLabel();

  return label || "N/A";
}

function retrieveStyle({
  columnName,
  gridSettingsColors,
  mode,
}: {
  columnName: CurveColumnStackName;
  gridSettingsColors: { headerColor: string; subheaderColor: string };
  mode: "dark" | "light";
}) {
  // Determine the background color based on column name
  const backgroundColor = ["name", "uom"].includes(columnName)
    ? gridSettingsColors.headerColor
    : gridSettingsColors.subheaderColor;

  // Override the color based on mode when backgroundColor is 'inherit'
  let color: string;
  if (backgroundColor === "inherit") {
    color = mode === "dark" ? "white" : "black";
  } else {
    // Determine text color based on the brightness of the background color
    color = brightColor(backgroundColor) ? "black" : "white";
  }

  return {
    backgroundColor,
    fontWeight: columnName === "name" ? "bold" : "normal",
    color: color,
  };
}

export type TColumnHeaderProps = {
  gridId: string;
  status: TStatus;
  productId?: string;
  artisType?: string;
  exchange?: number;
  settings?: ReturnType<typeof validColumnSettings>;
  maturity?: Product_Maturity_Enum;
  hasSharedCell?: boolean;
  permissions:
    | ProductFragmentGridFragment["packageByPackage"]["permissions"]
    | null
    | undefined;
  sharedCellOffsets: string[];
  columnLabels?: {
    commodityParentGroup?: string | null;
    commodityGroup?: number | null;
    geographicalRegion?: number | null;
    package?: string | null;
    uom?: Product_Uom_Enum;
  };
  hasFormula?: boolean;
};

function HeaderArtisLogo() {
  const { mode } = useColorScheme();

  return (
    <Box sx={{ width: 50, pl: agGridCellAutoPadding }}>
      <ArtisSmallLogo mode={mode === "dark" ? "dark" : "light"} />
    </Box>
  );
}

function StaticColumnHeader({
  columnName,
  product,
  columnSettings,
  fetching,
  gridSettingsColors,
  mode,
}: {
  columnName: CurveColumnStackName;
  product: ReturnType<typeof useProductById>["data"];
  columnSettings?: TPageProduct | null;
  fetching: boolean;
  gridSettingsColors: {
    headerColor: string;
    subheaderColor: string;
  };
  mode: "dark" | "light";
}) {
  const perms = product?.packageByPackage.permissions;
  const isListOnly = isListOnlyPermissions(perms);
  const noPermissions = !perms;

  function genLabelAndStyle() {
    const data = { label: "", style: {} };

    if (fetching) {
      data.label = "Loading...";
      return data;
    }

    const retrievedLabel = retrieveLabel(columnName, product, columnSettings);

    if (isListOnly) {
      data.label = columnName === "name" ? retrievedLabel : "";
      return data;
    }

    if (noPermissions) {
      data.label = columnName === "name" ? "No Permission" : "";
      return data;
    }

    const retrievedStyle = retrieveStyle({
      columnName,
      gridSettingsColors,
      mode,
    });
    data.label = retrievedLabel;
    data.style = retrievedStyle;

    return data;
  }

  const { label, style } = genLabelAndStyle();

  return (
    <div
      style={style}
      className={`column-header column-header-product-${columnName}`}
    >
      {label}
    </div>
  );
}

const StaticColumnHeaderMemo = memo(StaticColumnHeader);

function TooltipHeaderProductText({
  title,
  content,
}: {
  title: string;
  content: string;
}) {
  return (
    <Box>
      <Typography level="title-sm" fontWeight={700}>
        {title}
      </Typography>
      <Typography level="body-sm">{content}</Typography>
    </Box>
  );
}

function TooltipLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      Loading...
    </Box>
  );
}

function ProductHeaderTooltipRaw({
  curveName,
  packageName,
  commodity,
  region,
  source,
}: {
  curveName?: string | null;
  packageName?: string | null;
  commodity?: string | null;
  region?: string | null;
  source?: string | null;
}) {
  return (
    <Box
      sx={{
        background: "var(--ag-tooltip-background-color)",
        padding: "var(--ag-widget-container-horizontal-padding)",
        boxShadow: "var(--ag-popup-shadow)",
        borderRadius: "var(--ag-border-radius)",
        gap: 1.5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {curveName && (
        <TooltipHeaderProductText title="Curve Name" content={curveName} />
      )}
      {packageName && (
        <TooltipHeaderProductText title="Package" content={packageName} />
      )}
      {(commodity || region || source) && (
        <>
          {commodity && (
            <TooltipHeaderProductText title="Commodity" content={commodity} />
          )}
          {region && (
            <TooltipHeaderProductText title="Region" content={region} />
          )}
          {source && (
            <TooltipHeaderProductText title="Source" content={source} />
          )}
        </>
      )}
    </Box>
  );
}

const ProductHeaderTooltip = memo(ProductHeaderTooltipRaw);

function TooltipHeaderColumn({ params }: { params: ITooltipParams }) {
  const { value: productId } = params;

  const { data: product } = useProductById(productId || "");

  if (!product) return <TooltipLoader />;

  const curveName = product.description;
  const packageName = product.packageByPackage.name;
  const commodity = product.commodityGroupByCommodityGroup?.commodity_group;
  const region =
    product.geographicalRegionByGeographicalRegion?.geographical_region;
  const source = product.packageByPackage.sourceBySource?.name;

  return (
    <>
      <ProductHeaderTooltip
        curveName={curveName}
        packageName={packageName}
        commodity={commodity}
        region={region}
        source={source}
      />
      {isDev && (
        <Box bgcolor="#ffefff">
          will only show in dev
          <TooltipHeaderProductText title="Product ID" content={productId} />
          <TooltipHeaderProductText
            title="Grid ID"
            content={params.column?.getUniqueId() || "hmm"}
          />
          <TooltipHeaderProductText
            title="Artis Type"
            content={product.artis_type}
          />
        </Box>
      )}
    </>
  );
}

export type TTooltipCell = {
  productId: string | undefined;
  status: TStatus | undefined;
  isEditable: boolean;
};

type TInfo = TLatestEditInfoValue | undefined;

function TooltipCell({
  params,
}: { params: ITooltipParams<TData, TTooltipCell, unknown> }) {
  const [info, setInfo] = useState<TInfo>();

  const { proxy: calculationWorker } = calcWorker?.() || {};

  const getInfo = useCallback(async () => {
    if (!params.value) return;
    // coming from tooltip params in column defs
    const { productId, status, isEditable } = params.value;

    if (!isEditable) return;

    const monthCode = params.data?.id;

    if (!monthCode) return;

    const storageType = getInstrumentType(status);

    // this means that global curves won't have an edited by/at info
    // it might be what we want
    if (storageType === "global" || storageType === "eod") return;

    if (productId && status) {
      const entry = await calculationWorker?.getCellInfo({
        productId,
        storageType,
        rowId: monthCode,
      });

      if (!entry) {
        setInfo(undefined);
        return;
      }

      const infoBox = {
        firstname: entry.firstname,
        lastname: entry.lastname,
        username: entry.username,
        editedAt: entry.editedAt,
      } satisfies TInfo;

      setInfo((prevInfo) => {
        // Only update state if the new info is different from the previous info
        if (
          prevInfo?.firstname !== infoBox.firstname ||
          prevInfo?.lastname !== infoBox.lastname ||
          prevInfo?.username !== infoBox.username ||
          prevInfo?.editedAt !== infoBox.editedAt
        ) {
          return infoBox;
        }
        return prevInfo;
      });
    }
  }, [params.value, params.data, calculationWorker]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  const user = `${info?.username} ${info?.firstname} ${info?.lastname}`;

  const now = dayjs();
  const editedAt = dayjs(info?.editedAt);
  const passedTime =
    info?.editedAt &&
    dayjs.duration(editedAt.diff(now), "milliseconds").humanize(true);

  return (
    <div>
      {info ? (
        <Box
          sx={{
            padding: 0.5,
            background: (theme) =>
              theme.palette.mode === "dark"
                ? theme.palette.neutral[900]
                : theme.palette.neutral[100],
          }}
        >
          <Typography level="body-xs">
            {user}: {passedTime}
          </Typography>
        </Box>
      ) : (
        ""
      )}
    </div>
  );
}

// As this is a custom tooltip, it is called for both headers and rows.
export function TooltipProduct(params: ITooltipParams) {
  return params.location === "header" ? (
    <TooltipHeaderColumn params={params} />
  ) : (
    <TooltipCell params={params} />
  );
}

export const ColumnHeaderSelectMemo = memo(ColumnHeaderSelect);

function BlankHeader() {
  return <div className="column-header" />;
}

export function ColumnHeaderProduct(
  params: TColumnHeaderProps & IHeaderParams,
) {
  const { productId, gridId } = params;
  const { data: product } = useProductById(productId || "");
  const visibleColumns = useVisibleColumns();
  const logo = product?.packageByPackage?.sourceBySource?.logo;
  const hasSharedCell = product?.has_shared_cell;

  const { mode } = useThemeMode("webappTheme");
  const { result: columnSettings } = useEntity(client, "pageProducts", gridId, {
    localOnly: true,
  });
  const gridSettingsColors = useAtomValue(gridSettingsColorsAtom);

  const { hideStatusRow } = useGridSettingsOptions();

  const permissions = product?.packageByPackage.permissions;
  const isListOnly = isListOnlyPermissions(
    product?.packageByPackage.permissions,
  );
  const eodCurve = product?.artis_type === "eod";

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "flex-start",
        fontSize: 10,
      }}
    >
      {eodCurve || (hasSharedCell && permissions && !isListOnly) ? (
        <>
          {!hideStatusRow && !eodCurve && (
            <ColumnHeaderSelectMemo {...params} logo={logo} />
          )}
          {eodCurve && <BlankHeader />}
          <ColumnHeaderStatus
            {...params}
            headerColor={gridSettingsColors.headerColumnColour}
          />
        </>
      ) : (
        <>
          {!hideStatusRow && <BlankHeader />}
          <BlankHeader />
        </>
      )}
      <For each={visibleColumns}>
        {({ name }) => (
          <StaticColumnHeaderMemo
            key={name}
            fetching={false}
            columnName={name}
            product={product}
            columnSettings={columnSettings}
            gridSettingsColors={{
              headerColor: gridSettingsColors.headerColumnColour,
              subheaderColor: gridSettingsColors.subheaderColumnColour,
            }}
            mode={mode}
          />
        )}
      </For>
    </div>
  );
}

export function ColumnHeaderStatus(
  params: IHeaderParams & { headerColor: string },
) {
  const colInfo = colParams(params.column);
  const colors = useGridSettingsColors();
  const status = colInfo?.status;
  const eodCurve = colInfo?.artisType === "eod" || status === "eod";

  const statusColour = useMemo(
    () =>
      eodCurve
        ? colors.eodColour
        : getColorFromVarName(
            getStatusClass(eodCurve ? "eod" : status || "listen"),
          ),
    [status, eodCurve, colors],
  );
  const textColour = calculateTextColor(statusColour, false);
  return (
    <Box
      className="column-header"
      sx={{
        backgroundColor: statusColour,
        color: textColour,
        display: "flex",
        width: "100%",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {eodCurve ? (
        <EodHeader />
      ) : status === "listen" || status === "hybrid_broadcast" ? (
        <LastEditedInfoHeader colInfo={colInfo} />
      ) : null}
    </Box>
  );
}

function ColumnMonthsRaw({
  isMainMonthColumn,
}: { isMainMonthColumn: boolean }) {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        alignSelf: "flex-start",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
        backgroundColor: "var(--ag-header-background-color)",
      }}
    >
      {isMainMonthColumn ? <ChangeAllStatusButton /> : <div />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HeaderArtisLogo />
        <Box
          sx={{
            width: "100%",
            fontWeight: "700",
            height: headerHeight,
            display: "flex",
            alignItems: "center",
            pl: agGridCellAutoPadding,
            backgroundColor: "var(--headerColumnColour)",
            color: calculateTextColor(
              getColorFromVarName("--headerColumnColour"),
              false,
            ),
          }}
        >
          Month
        </Box>
      </Box>
    </Box>
  );
}

export function ColumnMonths(params: IHeaderParams) {
  const isMainMonthColumn = params.column.getColId() === mainMonthColumnId;

  return <ColumnMonthsRaw isMainMonthColumn={isMainMonthColumn} />;
}

export function ColumnMonthTimespread() {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        alignSelf: "flex-start",
        justifyContent: "flex-end",
        flexDirection: "column",
        backgroundColor: "var(--ag-header-background-color)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HeaderArtisLogo />
        <Box
          sx={{
            width: "100%",
            fontWeight: "700",
            height: headerHeight,
            display: "flex",
            alignItems: "center",
            pl: agGridCellAutoPadding,
            backgroundColor: "var(--headerColumnColour)",
            color: calculateTextColor(
              getColorFromVarName("--headerColumnColour"),
              false,
            ),
          }}
        >
          Spread
        </Box>
      </Box>
    </Box>
  );
}
