import type { useSingleUserById } from "../../admin/users";
import {
  RequestLogoutMutation,
  StartSessionMutation,
} from "../calculations-worker/graphql";
import { useMutation } from "@apollo/client";
import { Box, Button, Typography } from "@mui/joy";
import { useLogout } from "../../auth";
import { isDev } from "../../globals";
import { calcWorker } from "../calculations-worker/hooks";

type UserType = NonNullable<ReturnType<typeof useSingleUserById>>;
export type SessionType = NonNullable<UserType["sessions"]>[number];

export const SessionView = ({
  currentSession,
  userId,
  deviceType,
  session,
  sessionType,
  lastSeenStr,
}: {
  currentSession?: { id: string; start: string } | undefined;
  userId: string;
  deviceType: string;
  session: SessionType | undefined;
  sessionType: string;
  lastSeenStr: string | null;
}) => {
  const currentIsMobile = deviceType === "mobile";
  const logout = useLogout();
  const [executeStartSessionMutation] = useMutation(StartSessionMutation);
  const [logoutOtherSessionMutation] = useMutation(RequestLogoutMutation);
  const worker = calcWorker?.()?.proxy;

  const handleSignIn = async () => {
    if (!currentSession) {
      console.error("Current session is undefined");
      return;
    }
    try {
      await logoutOtherSessionMutation({
        variables: {
          user: userId,
          context: deviceType,
        },
      });
      await worker?.setIgnoreLogoutRequestsBefore(new Date());
      await executeStartSessionMutation({
        variables: {
          user: userId,
          mobile: currentIsMobile,
          session: currentSession.id,
        },
      });
    } catch (error) {
      console.error("Error starting session:", error);
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "80vw",
          maxWidth: "400px",
          textAlign: "center",
          color: "black",
        }}
      >
        <Box
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "16px",
          }}
        >
          Active session limit reached
        </Box>
        <Box
          sx={{
            fontSize: "16px",
            marginBottom: "16px",
          }}
        >
          {`You have reached your limit of 1 active ${deviceType} session on Artis. If you continue to sign in, the following session will be logged out.`}
          {isDev && (
            <pre
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                backgroundColor: "#f4f4f4",
                padding: "10px",
                borderRadius: "5px",
                overflowX: "auto",
              }}
            >
              {JSON.stringify({
                currentSession,
                userId,
                deviceType,
                session,
                sessionType,
                lastSeenStr,
                currentIsMobile,
              })}
            </pre>
          )}
        </Box>

        {session && (
          <Box
            sx={{
              marginTop: "16px",
              textAlign: "left",
              marginBottom: "8px",
              border: "1px solid #ccc",
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            <Box
              sx={{
                fontWeight: "bold",
              }}
            >{`Session ID: ${session?.id} `}</Box>
            <Typography
              sx={{
                fontStyle: "italic",
              }}
            >
              {sessionType}
            </Typography>
            {lastSeenStr && (
              <Box
                sx={{
                  color: "#888",
                }}
              >{`Last Seen: ${lastSeenStr}`}</Box>
            )}
          </Box>
        )}
      </Box>
      <Button
        onClick={handleSignIn}
        data-testid="log-out-other-session"
        color="danger"
        sx={{
          marginTop: "16px",
          color: "danger",
          padding: "12px 24px",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "16px",
          fontWeight: "bold",
          textTransform: "uppercase",
        }}
      >
        {`Log out other ${deviceType} session`}
      </Button>
      <Button
        onClick={() => {
          logout();
        }}
        color="neutral"
        sx={{
          marginTop: "16px",
          padding: "12px 24px",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "16px",
          fontWeight: "bold",
          textTransform: "uppercase",
        }}
      >
        Return to login page
      </Button>
    </Box>
  );
};
