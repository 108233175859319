import * as idb from "idb-keyval";
import {
  monthCodeToOffset,
  rowStringToCode,
} from "../market-grid/periodHelpers";
import {
  configsKey,
  type TGetGridData,
  type TGridDataEntry,
  type TLatestEditInfoValue,
  type TProductConfig,
  type TManualStoreKey,
  type TCellValue,
  type TCalciteResultValue,
  type TLivePricesStoreKey,
  type TManualCellValue,
  type TOptimisticUpdateStoreKey,
  type TOptimisticUpdateEntry,
  type TOptimisticUpdateValue,
  type ProductForCalcs,
  type TLatestEditInfoKey,
  type TEodStoreKey,
} from "./sharedStores";
import * as R from "remeda";
import {
  isEodStorageType,
  isGlobalStorageType,
  isPrivateStorageType,
  isSharedStorageType,
  type TManualStoreStorageType,
} from "../market-grid/statuses/statusLogic";
import type { TStatusMap } from "../../triplit/schema";
import type { Product_Artis_Type_Enum } from "../../__generated__/gql/graphql";
import { Product_Artis_Type_EnumSchema } from "../../__generated__/gql-validation/schemas";
import type { ProductInfo, ProductInfoFields } from "./subscriptionHandlers";
import { objectEntries } from "../../shared/utils";
import { workerIsDev } from "../../globals";

// Create a global object to hold the stores
const globalStores = {
  livePricesStore: new Map<string, TCellValue>(),
  livePricesForCalcStore: new Map<string, ProductForCalcs>(),
  manualCellsStore: new Map<string, TManualCellValue>(),
  optimisticUpdatesStore: [] as TOptimisticUpdateEntry[],
  calcuatedResultsStore: new Map<string, TCalciteResultValue>(),
  aggregatedResultsStore: new Map<string, TCalciteResultValue>(),
  eodStore: new Map<string, TCellValue>(),
  eodForCalcsStore: new Map<string, ProductForCalcs>(),
  latestEditInfoStore: new Map<string, TLatestEditInfoValue>(),
  productInfoStore: new Map<string, ProductInfoFields>(),
};

// Expose the global stores object to the window for debugging
if (workerIsDev() && typeof globalThis !== "undefined") {
  // @ts-ignore
  globalThis.globalStores = globalStores;
}

const {
  livePricesStore,
  livePricesForCalcStore,
  manualCellsStore,
  optimisticUpdatesStore,
  calcuatedResultsStore,
  aggregatedResultsStore,
  eodStore,
  eodForCalcsStore,
  latestEditInfoStore,
  productInfoStore,
} = globalStores;

export function getProductInfoStore(productId: string) {
  return productInfoStore.get(productId);
}

export function setProductInfoStore(info: ProductInfo) {
  productInfoStore.clear();
  for (const [productId, product] of objectEntries(info)) {
    productInfoStore.set(productId, product);
  }
}

export function manualCellsStoreKey({
  productId,
  storageType,
}: TManualStoreKey) {
  return `${productId}:${storageType}`;
}

export function livePricesStoreKey({
  productId,
  rowId,
  selector,
}: TLivePricesStoreKey) {
  return `${productId}:${selector}:${rowId}`;
}

export function getLivePricesStore(key: TLivePricesStoreKey) {
  return livePricesStore.get(livePricesStoreKey(key));
}

export function setLivePricesStore(
  key: TLivePricesStoreKey,
  value: TCellValue,
) {
  livePricesStore.set(livePricesStoreKey(key), value);
}

export function uniqueOptimisticUpdates() {
  const opUpdates = R.pipe(
    R.reverse(optimisticUpdatesStore),
    R.uniqueBy((cell) => `${cell.productId}${cell.rowId}${cell.storageType}`),
  );

  return {
    originalCount: optimisticUpdatesStore.length,
    dedupedCount: opUpdates.length,
    optimisticUpdates: opUpdates,
  };
}

// if rowId is not passed we retrieve all rowIds for a given storageType and productId
export function getOptimisticUpdatesStore(
  key: Omit<TOptimisticUpdateStoreKey, "rowId"> & { rowId?: string },
) {
  const { optimisticUpdates } = uniqueOptimisticUpdates();

  const matchingOptimisticUpdates: TManualCellValue = {};

  // search from end of array to address multiple updates of the same cell
  // so we can get the latest update
  for (let i = optimisticUpdates.length - 1; i >= 0; i--) {
    const { productId, rowId, storageType } = optimisticUpdates[i];
    if (productId === key.productId && storageType === key.storageType) {
      if (key.rowId) {
        if (rowId === key.rowId) {
          matchingOptimisticUpdates[rowId] = optimisticUpdates[i].value;
          return matchingOptimisticUpdates;
        }
      }
      matchingOptimisticUpdates[rowId] = optimisticUpdates[i].value;
    }
  }

  return matchingOptimisticUpdates;
}

export function setOptimisticUpdatesStore(
  key: TOptimisticUpdateStoreKey,
  value: TOptimisticUpdateValue,
) {
  optimisticUpdatesStore.push({
    ...key,
    ...value,
  });
}

export function removeProcessedItemsFromOptimisticStore(itemsNum: number) {
  const removedItems = optimisticUpdatesStore.splice(0, itemsNum);
  console.log(
    "removing",
    itemsNum,
    "items from optimistic store",
    JSON.stringify(optimisticUpdatesStore),
  );
  return removedItems;
}

export function getManualCellsStore(key: TManualStoreKey) {
  const lookupKey = manualCellsStoreKey(key);

  const shared = manualCellsStore.get(lookupKey);

  const optimistic = getOptimisticUpdatesStore({
    productId: key.productId,
    storageType: key.storageType,
  });

  const merged = Object.assign({}, shared, optimistic);

  return merged;
}

export function setManualCellsStore(
  key: TManualStoreKey,
  rowId: string,
  value: TCellValue,
) {
  const lookupKey = manualCellsStoreKey(key);

  const currentEntry = manualCellsStore.get(lookupKey);
  manualCellsStore.set(lookupKey, { ...currentEntry, [rowId]: value });
}

function latestEditInfoStoreKey({
  productId,
  storageType,
  rowId,
}: TLatestEditInfoKey) {
  return `${productId}:${storageType}:${rowId}`;
}

export function getLatestEditInfoStore(key: TLatestEditInfoKey) {
  return latestEditInfoStore.get(latestEditInfoStoreKey(key));
}

export function getLastestEditInfoForProduct(key: TManualStoreKey) {
  const { productId, storageType } = key;
  let latestEditInfo: TLatestEditInfoValue | undefined;
  latestEditInfoStore.forEach((value, key) => {
    const [pId, sType] = key.split(":");
    const currentTimestamp = latestEditInfo?.editedAt || 0;
    if (
      pId === productId &&
      sType === storageType &&
      (!currentTimestamp || value.editedAt > currentTimestamp)
    ) {
      latestEditInfo = value;
    }
  });
  return latestEditInfo;
}

export function setLatestEditInfoStore(
  key: TLatestEditInfoKey,
  value: TLatestEditInfoValue,
) {
  latestEditInfoStore.set(latestEditInfoStoreKey(key), value);
}

export function getCalcuatedResultsStore(key: TLivePricesStoreKey) {
  return calcuatedResultsStore.get(livePricesStoreKey(key));
}

// allowing string because the calcs use the same key as the live prices
// so we pass directly the string vs the object to avoid extra work in
// the calcs interval loop
export function setCalcuatedResultsStore(
  key: TLivePricesStoreKey | string,
  value: TCalciteResultValue,
) {
  const storageKey = typeof key === "string" ? key : livePricesStoreKey(key);
  calcuatedResultsStore.set(storageKey, value);
}

export function getAggregatedResultsStore(key: TLivePricesStoreKey) {
  return aggregatedResultsStore.get(livePricesStoreKey(key));
}

export function setAggregatedResultsStore(
  key: TLivePricesStoreKey,
  value: TCalciteResultValue,
) {
  aggregatedResultsStore.set(livePricesStoreKey(key), value);
}

export function eodStoreKey({
  productId,
  rowId,
  evaluationDate,
}: TEodStoreKey) {
  return `${productId}:${rowId}:${evaluationDate}`;
}

export function setEodStore(key: TEodStoreKey, value: TCellValue) {
  eodStore.set(eodStoreKey(key), value);
}

export function getEodStore(key: TEodStoreKey) {
  return eodStore.get(eodStoreKey(key));
}

export function getEodForCalcsStore(key: TEodStoreKey) {
  return eodForCalcsStore.get(eodStoreKey(key));
}

export function setEodForCalcsStore(key: TEodStoreKey, value: ProductForCalcs) {
  eodForCalcsStore.set(eodStoreKey(key), value);
}

export function clearEodStore() {
  eodStore.clear();
}

export function getAllEodForCalcsStore() {
  return Array.from(eodForCalcsStore.values());
}

export function retrieveCellValues({
  columns,
  rowIds,
  productConfigs,
  eodEvalDate,
}: TGetGridData & {
  productConfigs: TProductConfig;
  eodEvalDate?: string;
}): TGridDataEntry[] {
  if (columns.length === 0) return [];
  return rowIds.map(([rowType, rowId], rowIdx) => {
    return columns.reduce((coll, column) => {
      const columnId = column.columnId;
      const isPermissioned = column.isPermissioned;

      if (!isPermissioned) {
        coll[columnId] = { Err: "no permission" };
        return coll;
      }

      const customerCurve = column.artisType === "customer_curve";
      const isMonthRow = rowType === "mth";
      // if no config for this row then skip
      if (
        customerCurve &&
        isMonthRow &&
        productConfigs?.[column.productId]?.[rowIdx] === undefined
      ) {
        return coll;
      }
      const lookupKey = {
        productId: column.productId,
        rowId,
        selector: column.selector,
      };

      if (!customerCurve && isMonthRow) {
        if (column.artisType === "eod") {
          // its a standalone eod curve
          if (!eodEvalDate || !column.eodId) {
            return coll;
          }

          const eodValue = getEodStore({
            productId: column.productId,
            rowId,
            evaluationDate: eodEvalDate,
          });

          coll[columnId] = { Ok: eodValue };
          return coll;
        }

        // it's a live market cell
        const livePricesValue = getLivePricesStore(lookupKey);

        coll[columnId] = { Ok: livePricesValue };
        return coll;
      }

      // it's either a formula or a shared cell
      if (column.hasSharedCell && isMonthRow) {
        const currentOffset = monthCodeToOffset(rowId);
        const formulaProduct = productConfigs[column.productId];
        const isFormula = formulaProduct?.find(
          (f) => f.offset === currentOffset && f.formula,
        );

        // it's definitely a shared cell
        if (customerCurve && !isFormula) {
          const status = column.status;

          if (isSharedStorageType(status)) {
            const sharedCellValue = getManualCellsStore({
              productId: column.productId,
              storageType: status === "broadcast" ? "broadcast" : "shared",
            });

            coll[columnId] = { Ok: sharedCellValue[rowId] };
          } else if (isEodStorageType(status)) {
            if (!column.eodId) {
              console.error(
                "EOD ID is missing for eod status product, this should never happen",
                {
                  column,
                  rowId,
                },
              );
              return coll;
            }
            if (!eodEvalDate) {
              console.error(
                "EOD evaluation date is missing for eod status product, this should never happen",
                {
                  column,
                  rowId,
                },
              );
              return coll;
            }
            const eodValue = getEodStore({
              productId: column.eodId,
              rowId,
              evaluationDate: eodEvalDate,
            });

            coll[columnId] = { Ok: eodValue };
          } else if (isPrivateStorageType(status)) {
            const cellValue = getManualCellsStore({
              productId: column.productId,
              storageType: "local",
            });

            coll[columnId] = { Ok: cellValue[rowId] };
          }
          return coll;
        }
      }

      // it's a formula
      if (isMonthRow) {
        const formulaValue = getCalcuatedResultsStore(lookupKey);

        coll[columnId] = formulaValue;

        return coll;
      }

      // it's an aggregated cell
      const aggregatedValue = getAggregatedResultsStore(lookupKey);

      coll[columnId] = aggregatedValue;

      return coll;
    }, {} as TGridDataEntry);
  });
}

export async function getGridData({
  rowIds,
  columns,
  eodEvalDate,
  adhocSpreads,
}: TGetGridData) {
  const productConfigs = await idb.get<TProductConfig | undefined>(configsKey);

  if (!productConfigs) return;

  const entries = retrieveCellValues({
    rowIds,
    columns,
    productConfigs,
    eodEvalDate,
  });

  // Calculate adhoc spreads
  if (adhocSpreads && Object.keys(adhocSpreads || {})?.length > 0) {
    rowIds.map(([rowType, rowId], rowIdx) => {
      if (rowType === "adhoc") {
        columns.map((column) => {
          const spread = adhocSpreads[rowId];
          const [from, to] = [
            spread?.from?.periodValue,
            spread?.to?.periodValue,
          ];

          if (from && to) {
            const [fromRowId, toRowId] = [
              rowStringToCode(from),
              rowStringToCode(to),
            ];

            const [fromRowIndex, toRowIndex] = [
              rowIds.findIndex(([, rId]) => rId === fromRowId),
              rowIds.findIndex(([, rId]) => rId === toRowId),
            ];

            const [fromValue, toValue] = [
              entries[fromRowIndex][column.columnId]?.Ok,
              entries[toRowIndex][column.columnId]?.Ok,
            ];

            if (typeof fromValue === "number" && typeof toValue === "number") {
              const spreadValue = fromValue - toValue;
              entries[rowIdx][column.columnId] = { Ok: spreadValue };
            }
          }
        });
      }
    });
  }

  return entries;
}

export function getAllLivePricesForCalcsStore() {
  return Array.from(livePricesForCalcStore.values());
}

export function setLivePricesForCalcsStore(
  key: TLivePricesStoreKey,
  value: ProductForCalcs,
) {
  livePricesForCalcStore.set(livePricesStoreKey(key), value);
}

export function getAllManualCellsForCalcsStore(
  statusMap: TStatusMap,
  eodEvalDate: string,
) {
  try {
    const products: ProductForCalcs[] = [];

    const optimisticStoreIndexKey = (
      productId: string,
      rowId: string,
      storageType: TManualStoreStorageType,
    ) => {
      return `${productId}:${rowId}:${storageType}`;
    };

    // to reorganize the optimistic updates for direct access later on
    const optimisticStoreIndex =
      uniqueOptimisticUpdates().optimisticUpdates.reduce(
        (acc, cell) => {
          const k = optimisticStoreIndexKey(
            cell.productId,
            cell.rowId,
            cell.storageType,
          );

          acc[k] = cell;
          return acc;
        },
        {} as Record<string, TOptimisticUpdateEntry>,
      );

    const allSharedCells = Array.from(manualCellsStore.entries());

    function optimisticValue(
      productId: string,
      rowId: string,
      storageType: TManualStoreStorageType,
    ) {
      const optimisticLookupKey = optimisticStoreIndexKey(
        productId,
        rowId,
        storageType,
      );

      return optimisticStoreIndex[optimisticLookupKey];
    }

    // looping over all shared columns
    for (const [key, value] of allSharedCells) {
      const [productId] = key.split(":");
      // looping over each single month cell (per column i.e. vertical)
      // rowId is monthCode
      for (const rowId in value) {
        // figure out status
        const status = statusMap?.[productId];

        // initiate a product map for calcs
        const productForCalcs: ProductForCalcs = {
          product: productId,
          field: "value",
          offset: monthCodeToOffset(rowId),
          result: value[rowId],
        };

        // we override the result based on the storage type

        if (isEodStorageType(status)) {
          const productEodId = getProductInfoStore(productId)?.eod_product_dep;
          if (productEodId) {
            productForCalcs.result = getEodStore({
              productId: productEodId,
              rowId,
              evaluationDate: eodEvalDate,
            });
          }
        } else if (isGlobalStorageType(status)) {
          // defaulting to 111111 as not implemented just yet
          productForCalcs.result = 111111;
        } else if (isPrivateStorageType(status)) {
          const isCellOptimistic = optimisticValue(productId, rowId, "local");

          if (isCellOptimistic) {
            productForCalcs.result = isCellOptimistic.value;
          } else {
            const localEntry = getManualCellsStore({
              productId,
              storageType: "local",
            });

            productForCalcs.result = localEntry?.[rowId];
          }
        } else if (status === "broadcast") {
          const isCellOptimistic = optimisticValue(
            productId,
            rowId,
            "broadcast",
          );

          if (isCellOptimistic) {
            productForCalcs.result = isCellOptimistic.value;
          } else {
            const broadcastEntry = getManualCellsStore({
              productId,
              storageType: "broadcast",
            });

            productForCalcs.result = broadcastEntry?.[rowId];
          }
        } else {
          const isCellOptimistic = optimisticValue(productId, rowId, "shared");

          if (isCellOptimistic) {
            productForCalcs.result = isCellOptimistic.value;
          } else {
            const sharedEntry = getManualCellsStore({
              productId,
              storageType: "shared",
            });

            productForCalcs.result = sharedEntry?.[rowId];
          }
        }

        products.push(productForCalcs);
      }
    }

    return products;
  } catch (e) {
    console.error("Error in getAllManualCellsForCalcsStore", e);
    return [];
  }
}

export function parseArtisType(
  value: string | undefined,
): Product_Artis_Type_Enum | null {
  if (!value) return null;
  return Product_Artis_Type_EnumSchema.safeParse(value)?.data ?? null;
}
