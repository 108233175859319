// tooltip.ts
// adapted from https://github.com/tradingview/lightweight-charts/blob/master/plugin-examples/src/plugins/tooltip/tooltip.ts
import type { CanvasRenderingTarget2D } from "fancy-canvas";
import {
  CrosshairMode,
  type ISeriesPrimitivePaneRenderer,
  type ISeriesPrimitivePaneView,
  type MouseEventParams,
  type SeriesPrimitivePaneViewZOrder,
  type ISeriesPrimitive,
  type SeriesAttachedParameter,
  type LineData,
  type WhitespaceData,
  type CandlestickData,
  type Time,
} from "lightweight-charts";
import { TooltipElement, type TooltipOptions } from "./tooltip-element";
import { convertTime, formattedDateAndTime } from "../plugin-helpers/time";
import { positionsLine } from "../plugin-helpers/dimensions/positions";
import { Decimal, formatAgg, toFixed } from "../../numbers";

class TooltipCrosshairLinePaneRenderer implements ISeriesPrimitivePaneRenderer {
  _data: TooltipCrosshairLineData;

  constructor(data: TooltipCrosshairLineData) {
    this._data = data;
  }

  draw(target: CanvasRenderingTarget2D) {
    if (!this._data.visible) return;
    // biome-ignore lint/correctness/useHookAtTopLevel: seems to be a badly named non hook
    target.useBitmapCoordinateSpace((scope) => {
      const ctx = scope.context;
      const crosshairPos = positionsLine(
        this._data.x,
        scope.horizontalPixelRatio,
        1,
      );
      ctx.fillStyle = this._data.color;
      ctx.fillRect(
        crosshairPos.position,
        this._data.topMargin * scope.verticalPixelRatio,
        crosshairPos.length,
        scope.bitmapSize.height,
      );
    });
  }
}

class MultiTouchCrosshairPaneView implements ISeriesPrimitivePaneView {
  _data: TooltipCrosshairLineData;
  constructor(data: TooltipCrosshairLineData) {
    this._data = data;
  }

  update(data: TooltipCrosshairLineData): void {
    this._data = data;
  }

  renderer(): ISeriesPrimitivePaneRenderer | null {
    return new TooltipCrosshairLinePaneRenderer(this._data);
  }

  zOrder(): SeriesPrimitivePaneViewZOrder {
    return "bottom";
  }
}

interface TooltipCrosshairLineData {
  x: number;
  visible: boolean;
  color: string;
  topMargin: number;
}

const defaultOptions: TooltipPrimitiveOptions = {
  lineColor: "rgba(0, 0, 0, 0.2)",
  theme: "light",
  priceExtractor: (data: LineData | CandlestickData | WhitespaceData) => {
    if ((data as CandlestickData).open !== undefined) {
      const candlestickData = data as Partial<CandlestickData>;
      const precision = 2;
      const { open, high, low, close } = candlestickData;
      function formatPrice(price: number | undefined) {
        if (!price) return "";
        return toFixed(price, precision);
      }
      return `O: ${formatPrice(open)}, H: ${formatPrice(
        high,
      )}, L: ${formatPrice(low)}, C: ${formatPrice(close)}`;
    }
    return "";
  },
};

export interface TooltipPrimitiveOptions {
  lineColor: string;
  theme: "light" | "dark";
  tooltip?: Partial<TooltipOptions>;
  priceExtractor: <T extends WhitespaceData>(dataPoint: T) => string;
}

export class TooltipPrimitive implements ISeriesPrimitive<Time> {
  private _options: TooltipPrimitiveOptions;
  private _tooltip: TooltipElement | undefined = undefined;
  _paneViews: MultiTouchCrosshairPaneView[];
  _data: TooltipCrosshairLineData = {
    x: 0,
    visible: false,
    color: "rgba(0, 0, 0, 0.2)",
    topMargin: 0,
  };
  _attachedParams: SeriesAttachedParameter<Time> | undefined;

  constructor(options: Partial<TooltipPrimitiveOptions>) {
    this._options = {
      ...defaultOptions,
      ...options,
    };
    this._paneViews = [new MultiTouchCrosshairPaneView(this._data)];
  }

  attached(param: SeriesAttachedParameter<Time>): void {
    this._attachedParams = param;
    this._setCrosshairMode();
    param.chart.subscribeCrosshairMove(this._moveHandler);
    this._createTooltipElement();
  }

  detached(): void {
    const chart = this.chart();
    if (chart) {
      chart.unsubscribeCrosshairMove(this._moveHandler);
    }
  }

  paneViews() {
    return this._paneViews;
  }

  updateAllViews() {
    this._paneViews.forEach((pw) => pw.update(this._data));
  }

  setData(data: TooltipCrosshairLineData) {
    this._data = data;
    this.updateAllViews();
    this._attachedParams?.requestUpdate();
  }

  currentColor() {
    return this._options.lineColor;
  }

  chart() {
    return this._attachedParams?.chart;
  }

  series() {
    return this._attachedParams?.series;
  }

  applyOptions(options: Partial<TooltipPrimitiveOptions>) {
    this._options = {
      ...this._options,
      ...options,
    };
    if (this._tooltip) {
      this._tooltip.applyOptions({ ...this._options.tooltip });
    }
  }

  destroy() {
    this._tooltip?.destroy();
  }

  private _setCrosshairMode() {
    const chart = this.chart();
    if (!chart) {
      throw new Error(
        "Unable to change crosshair mode because the chart instance is undefined",
      );
    }
    chart.applyOptions({
      crosshair: {
        mode: CrosshairMode.Magnet,
        vertLine: {
          visible: false,
          labelVisible: false,
        },
        horzLine: {
          visible: false,
          labelVisible: false,
        },
      },
    });
  }

  private _moveHandler = (param: MouseEventParams) => this._onMouseMove(param);

  private _hideTooltip() {
    if (!this._tooltip) return;
    this._tooltip.updateTooltipContent({
      //field: "",
      title: "",
      price: "",
      date: "",
      time: "",
      change: "",
    });
    this._tooltip.updatePosition({
      paneX: 0,
      paneY: 0,
      visible: false,
    });
  }

  private _hideCrosshair() {
    this._hideTooltip();
    this.setData({
      x: 0,
      visible: false,
      color: this.currentColor(),
      topMargin: 0,
    });
  }

  private _onMouseMove(param: MouseEventParams) {
    const chart = this.chart();
    const series = this.series();
    const logical = param.logical;
    if (!logical || !chart || !series) {
      this._hideCrosshair();
      return;
    }
    const data = param.seriesData.get(series);
    if (!data) {
      this._hideCrosshair();
      return;
    }
    const price = this._options.priceExtractor(data);
    const coordinate = chart.timeScale().logicalToCoordinate(logical);
    const [date, time] = formattedDateAndTime(
      param.time ? convertTime(param.time) : undefined,
    );
    const candleData = data as Partial<CandlestickData>;
    const changeRaw =
      candleData?.open !== undefined && candleData?.close !== undefined
        ? candleData.close - candleData.open
        : "";

    const changePercentage =
      candleData?.open && changeRaw
        ? formatAgg(Decimal((changeRaw / candleData.open) * 100))
        : "";
    const changePercentageStr = changePercentage
      ? `(${changePercentage}%)`
      : "";
    const changeStr = changeRaw ? formatAgg(Decimal(changeRaw)) : "";
    const change = `${changeStr} ${changePercentageStr}`;

    if (this._tooltip) {
      const tooltipOptions = this._tooltip.options();
      const topMargin =
        tooltipOptions.followMode === "top" ? tooltipOptions.topOffset + 10 : 0;
      this.setData({
        x: coordinate ?? 0,
        visible: coordinate !== null,
        color: this.currentColor(),
        topMargin,
      });
      this._tooltip.updateTooltipContent({
        //field,
        price,
        date,
        time,
        change,
      });
      this._tooltip.updatePosition({
        paneX: param.point?.x ?? 0,
        paneY: param.point?.y ?? 0,
        visible: true,
      });
    }
  }

  private _createTooltipElement() {
    const chart = this.chart();
    if (!chart)
      throw new Error("Unable to create Tooltip element. Chart not attached");
    this._tooltip = new TooltipElement(chart, {
      ...this._options.tooltip,
      theme: this._options.theme,
    });
  }
}
