import { useQuery } from "@triplit/react";
import { useQuery as useQueryApollo } from "@apollo/client";
import { graphql, readFragment } from "../../graphql";
import { client } from "../../triplit/triplit";
import { useQuerySub } from "../../utils/useQuerySub";
import { useOrgId } from "../orgs";
import * as R from "remeda";
import type {
  User_Right_Enum,
  UsersPermissionFragmentFragment,
} from "../../__generated__/gql/graphql";
import type { usePackagesTable } from "../packages";
import type { useApolloClient } from "@apollo/client";
import { useParams } from "@tanstack/react-router";
import { getFragmentData } from "../../__generated__/gql";

export const PermissionFragment = graphql(`
  fragment PermissionFragment on permission {
    id
    permission
    folio_user
    packageByPackage {
      id
      name
      sourceBySource {
        id
        exchange {
          id
          code
        }
      }
    }
  }
`);

export const updatePermission = graphql(`
  mutation setPermission(
    $folio_user: String!
    $package: Int!
    $source: Int!
    $permission: user_right_enum!
  ) {
    update_permission(
      where: {
        folioUserByFolioUser: { id: { _eq: $folio_user } }
        packageByPackage: { id: { _eq: $package }, source: { _eq: $source } }
      }
      _set: { permission: $permission }
    ) {
      returning {
        ...PermissionFragment
      }
    }
  }
`);

export const upsertPermission = graphql(`
  mutation upsertPermission($objects: [permission_insert_input!]!) {
    insert_permission(
      objects: $objects
      on_conflict: { constraint: permission_pkey, update_columns: [permission] }
    ) {
      returning {
        ...PermissionFragment
      }
    }
  }
`);

export const insertPermission = graphql(`
  mutation insertPermission(
    $folio_user: String!
    $package: Int!
    $permission: user_right_enum!
  ) {
    insert_permission(
      objects: {
        folio_user: $folio_user
        package: $package
        permission: $permission
      }
    ) {
      returning {
        ...PermissionFragment
      }
    }
  }
`);

export const deletePermission = graphql(`
  mutation deletePermission(
    $folio_user: String!
    $package: Int!
    $source: Int!
  ) {
    delete_permission(
      where: {
        folioUserByFolioUser: { id: { _eq: $folio_user } }
        packageByPackage: { id: { _eq: $package }, source: { _eq: $source } }
      }
    ) {
      returning {
        ...PermissionFragment
      }
    }
  }
`);

//
export const handleSubmitPermissions = graphql(`
  mutation handleSubmitPermissions(
    $upserts: [permission_insert_input!]!
    $deletes: [Int!]!
  ) {
    insert_permission(
      objects: $upserts
      on_conflict: { constraint: permission_pkey, update_columns: [permission] }
    ) {
      returning {
        id
        folio_user
        packageByPackage {
          id
        }
      }
    }
    delete_permission(where: { id: { _in: $deletes } }) {
      returning {
        id
        folio_user
        packageByPackage {
          id
        }
      }
    }
  }
`);

export const UserPermissionFragment = graphql(`
  fragment UserPermissionFragment on permission {
    id
    folio_user
    permission
    package
    folioUserByFolioUser {
      id
      username
      firstname
      lastname
      email
    }
    packageByPackage {
      id
      name
      package_type
      sourceBySource {
        id
        name
        source_type
      }
    }
  }
`);

export const UsersPermissionFragment = graphql(`
  fragment UsersPermissionFragment on folio_user {
    id
    email
    firstname
    lastname
    permissions {
      id
      package
      packageByPackage {
        id
        name
        source
      }
      permission
      user_right {
        value
      }
    }
  }
`);

const usersPermissions = graphql(`
  subscription usersPermissions($org: Int!) {
    folio_user(where: { organisation: { _eq: $org } }) {
      ...UsersPermissionFragment
    }
  }
`);

export function pendingPermissionPk({
  userId,
  packageId,
}: {
  userId: string;
  packageId: string | number;
}) {
  return `${userId}-${packageId}`;
}

export type TPermissionValue = User_Right_Enum | "-";

export function useUsersPermissions({
  packages,
}: {
  packages: ReturnType<typeof usePackagesTable>["packages"];
}) {
  const orgId = useOrgId();
  const res = useQuerySub({
    query: usersPermissions,
    variables: { org: orgId || 7 },
  });

  const permissions =
    readFragment(UsersPermissionFragment, res.data?.folio_user) || [];

  const { results: pendingPermissions, fetching: pendingPermissionsFetching } =
    useQuery(client, client.query("pendingPermission"));

  if (!packages)
    return {
      orgId,
      permissions: [],
      res,
      pendingPermissions,
      pendingPermissionsFetching,
    };

  // map over each user in the org
  const permissionsWithPackageIdIndex = R.map(permissions, (user) => {
    // create an object of user permissions indexed by package id for easy lookup
    const indexedUserPermissions = R.indexBy(user.permissions, (p) =>
      p.package.toString(),
    );

    // map over ALL packages to ensure we have a permission object for each package
    // if user has no permission for a package, we still need it for the pending permission
    const permissionsWithPendingEdits = packages.map((p) => {
      // get the permission object for the given package
      const permission = indexedUserPermissions[p.id.toString()];

      const pk = pendingPermissionPk({
        userId: user.id,
        packageId: p.id.toString(),
      });

      const pendingPermission = pendingPermissions?.find(
        (pp) => pp.id === pk,
      )?.permission;

      const defaultPermission = {
        id: p.id,
        package: p.id,
        packageByPackage: {
          source: p.sourceBySource?.id,
        },
      } satisfies Pick<
        UsersPermissionFragmentFragment["permissions"][number],
        "id" | "package"
      > & {
        packageByPackage: { source: number | undefined };
      };

      return R.merge(permission || defaultPermission, {
        pendingPermission: pendingPermission as TPermissionValue,
      });
    });

    const indexedPackages = R.indexBy(permissionsWithPendingEdits, (p) =>
      p.package.toString(),
    );

    return R.merge(user, indexedPackages);
  });

  return {
    orgId,
    permissions: permissionsWithPackageIdIndex,
    serverPermissions: permissions,
    res,
    pendingPermissions,
    pendingPermissionsFetching,
  };
}

export type TUserPermission = ReturnType<
  typeof useUsersPermissions
>["permissions"][number];

export const orgPermissions = graphql(`
  query orgPermissions($org: Int!) {
    permission(
      where: { folioUserByFolioUser: { organisation: { _eq: $org } } }
    ) {
      ...UserPermissionFragment
    }
  }
`);

export const userPermissions = graphql(`
  query userPermissions($folio_user: String!) {
    permission(where: { folio_user: { _eq: $folio_user } }) {
      ...UserPermissionFragment
    }
  }
`);

export const copyPermissionsFromUser = graphql(`
  mutation InsertUserPermissions(
    $permissions: [permission_insert_input!]!
    $users: [String!]!
  ) {
    delete_permission(where: { folio_user: { _in: $users } }) {
      affected_rows
    }
    insert_permission(
      objects: $permissions
      on_conflict: { constraint: permission_pkey, update_columns: permission }
    ) {
      affected_rows
    }
  }
`);

export const singleUserPermissions = graphql(`
  query singleUserPermissions($folio_user: String!) {
    permission(where: { folio_user: { _eq: $folio_user } }) {
      id
      permission
      folio_user
      package
      packageByPackage {
        id
        name
        sourceBySource {
          id
          name
          exchange {
            id
            code
          }
        }
      }
    }
  }
`);

export function useSingleUserPermissions(folio_user: string) {
  return useQuerySub({
    query: singleUserPermissions,
    variables: { folio_user },
  });
}

export async function generatePermissionsToCopy({
  apolloClient,
  userIdToCopyFrom,
  userIdsToCopyTo,
}: {
  apolloClient: ReturnType<typeof useApolloClient>;
  userIdToCopyFrom: string;
  userIdsToCopyTo: string[];
}) {
  const { data } = await apolloClient.query({
    query: singleUserPermissions,
    variables: { folio_user: userIdToCopyFrom },
  });

  const userPermissions = data.permission;

  const permissionsToCopy = userIdsToCopyTo.flatMap((userId) => {
    return userPermissions.map((permission) => {
      const removedAttrs = R.pick(permission, [
        "folio_user",
        "package",
        "permission",
      ]);
      const objWithUser = R.addProp(removedAttrs, "folio_user", userId);
      return objWithUser;
    });
  });

  return permissionsToCopy;
}

function permissionName(
  packageName: string | undefined,
  sourceName: string | undefined,
) {
  if (!sourceName) return "";
  return `${packageName}: ${sourceName}`;
}

function sortPermissions(aPermissionName: string, bPermissionName: string) {
  if (aPermissionName === "") return 1;
  if (bPermissionName === "") return -1;
  return aPermissionName.localeCompare(bPermissionName);
}

export function useSortedPermissionsForUser(showAvailablePermissions: boolean) {
  const { userId, org } = useParams({ from: "/admin/orgs/$org/users/$userId" });

  const allPermissions = useQueryApollo(orgPermissions, {
    variables: {
      org: Number.parseInt(org),
    },
  });

  const permissions =
    getFragmentData(UserPermissionFragment, allPermissions.data?.permission) ||
    [];

  const userPermissions = R.pipe(
    permissions,
    R.groupBy((permission) => permission.package),
    R.values(),
    R.map((group) => {
      const currentlyAssignedPermission = group.find(
        (permission) => permission.folio_user === userId,
      );
      const blankPermission = R.addProp(group[0], "permission", "blank");
      return currentlyAssignedPermission || blankPermission;
    }),
  );

  const sortedNonBlankPermissions = R.pipe(
    userPermissions,
    R.filter((permission) => {
      return permission.folio_user === userId;
    }),
    R.sort((a, b) => {
      const aPermissionName = permissionName(
        a.packageByPackage.name,
        a.packageByPackage.sourceBySource?.name,
      );
      const bPermissionName = permissionName(
        b.packageByPackage.name,
        b.packageByPackage.sourceBySource?.name,
      );
      return sortPermissions(aPermissionName, bPermissionName);
    }),
  );

  if (showAvailablePermissions) {
    // if showing available permissions, we concat sorted blank permissions
    // onto the end of the sorted non-blank permissions
    return R.concat(
      sortedNonBlankPermissions,
      R.pipe(
        userPermissions,
        R.filter((permission) => {
          return permission.folio_user !== userId;
        }),
        R.sort((a, b) => {
          const aPermissionName = permissionName(
            a.packageByPackage.name,
            a.packageByPackage.sourceBySource?.name,
          );
          const bPermissionName = permissionName(
            b.packageByPackage.name,
            b.packageByPackage.sourceBySource?.name,
          );
          return sortPermissions(aPermissionName, bPermissionName);
        }),
      ),
    );
  }
  return sortedNonBlankPermissions;
}
