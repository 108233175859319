import {
  _exists,
  type AgInputNumberField,
  AgInputNumberFieldSelector,
  type AgInputTextField,
  type ComponentSelector,
  type ICellEditorComp,
  type ICellEditorParams,
  type INumberCellEditorParams,
  KeyCode,
  PopupComponent,
  RefPlaceholder,
} from "ag-grid-community";
import { parse } from "../numbers";

const _isBrowserSafari = () => {
  return (
    typeof window !== "undefined" &&
    /Version\/[\d.]+.*Safari/.test(window.navigator.userAgent)
  );
};

interface CellEditorInput<
  TValue,
  P extends ICellEditorParams,
  I extends AgInputTextField,
> {
  getTemplate(): string;
  getAgComponents(): ComponentSelector[];
  init(eInput: I, params: P): void;
  getValue(): TValue | null | undefined;
  getStartValue(): string | null | undefined;
  setCaret?(): void;
}

class SimpleCellEditor<
    TValue,
    P extends ICellEditorParams,
    I extends AgInputTextField,
  >
  extends PopupComponent
  implements ICellEditorComp
{
  private highlightAllOnFocus: boolean | undefined;
  private focusAfterAttached: boolean | undefined;
  protected params: ICellEditorParams | undefined;
  protected readonly eInput: I = RefPlaceholder;

  constructor(protected cellEditorInput: CellEditorInput<TValue, P, I>) {
    super(
      /* html */ `
            <div class="ag-cell-edit-wrapper">
                ${cellEditorInput.getTemplate()}
            </div>`,
      cellEditorInput.getAgComponents(),
    );
  }

  public init(params: P): void {
    this.params = params;

    const eInput = this.eInput;
    this.cellEditorInput.init(eInput, params);
    let startValue: string | null | undefined;

    // cellStartedEdit is only false if we are doing fullRow editing
    if (params.cellStartedEdit) {
      this.focusAfterAttached = true;
      const eventKey = params.eventKey;

      if (
        eventKey === KeyCode.BACKSPACE ||
        params.eventKey === KeyCode.DELETE
      ) {
        startValue = "";
      } else if (eventKey && eventKey.length === 1) {
        startValue = eventKey;
      } else {
        startValue = this.cellEditorInput.getStartValue();

        if (eventKey !== KeyCode.F2) {
          this.highlightAllOnFocus = true;
        }
      }
    } else {
      this.focusAfterAttached = false;
      startValue = this.cellEditorInput.getStartValue();
    }

    if (startValue != null) {
      eInput.setStartValue(startValue);
    }

    this.addManagedElementListeners(eInput.getGui(), {
      keydown: (event: KeyboardEvent | undefined) => {
        if (!event) return;
        const { key } = event;

        if (key === KeyCode.PAGE_UP || key === KeyCode.PAGE_DOWN) {
          event.preventDefault();
        }
      },
    });
  }

  public afterGuiAttached(): void {
    const translate = this.localeService.getLocaleTextFunc();
    const eInput = this.eInput;

    eInput.setInputAriaLabel(translate("ariaInputEditor", "Input Editor"));

    if (!this.focusAfterAttached) {
      return;
    }
    // Added for AG-3238. We can't remove this explicit focus() because Chrome requires an input
    // to be focused before setSelectionRange will work. But it triggers a bug in Safari where
    // explicitly focusing then blurring an empty field will cause the parent container to scroll.
    if (!_isBrowserSafari()) {
      eInput.getFocusableElement().focus();
    }

    const inputEl = eInput.getInputElement();

    if (this.highlightAllOnFocus) {
      inputEl.select();
    } else {
      this.cellEditorInput.setCaret?.();
    }
  }

  // gets called when tabbing through cells and in full row edit mode
  public focusIn(): void {
    const eInput = this.eInput;
    const focusEl = eInput.getFocusableElement();
    const inputEl = eInput.getInputElement();

    focusEl.focus();
    inputEl.select();
  }

  public getValue(): TValue | null | undefined {
    return this.cellEditorInput.getValue();
  }

  public override isPopup() {
    return false;
  }
}

class NumberCellEditorInput
  implements
    CellEditorInput<number, INumberCellEditorParams, AgInputNumberField>
{
  private eInput: AgInputNumberField | undefined;
  private params: INumberCellEditorParams | undefined;

  public getTemplate() {
    return /* html */ `<ag-input-number-field class="ag-cell-editor" data-ref="eInput"></ag-input-number-field>`;
  }
  public getAgComponents() {
    return [AgInputNumberFieldSelector];
  }

  public init(
    eInput: AgInputNumberField,
    params: INumberCellEditorParams,
  ): void {
    this.eInput = eInput;
    this.params = params;
    if (params.max != null) {
      eInput.setMax(params.max);
    }
    if (params.min != null) {
      eInput.setMin(params.min);
    }
    if (params.precision != null) {
      eInput.setPrecision(params.precision);
    }

    const inputEl = eInput.getInputElement();
    inputEl.type = "text";
    inputEl.inputMode = "decimal";
    // onblur submits the value
    inputEl.addEventListener("blur", () => {
      params.stopEditing();
    });
  }

  public getValue(): number | null | undefined {
    if (!this.eInput) {
      console.error("NumberCellEditor: eInput is not set");
      return;
    }
    const value = this.eInput.getValue();

    if (!_exists(value) && !_exists(this.params?.value)) {
      return this.params?.value;
    }
    let parsedValue = parse(value);
    if (parsedValue == null) {
      return this.params?.value;
    }
    if (typeof parsedValue === "string") {
      if (parsedValue === "") {
        return null;
      }
      parsedValue = Number(parsedValue);
    }
    return Number.isNaN(parsedValue) ? null : parsedValue;
  }

  public getStartValue(): string | null | undefined {
    return this.params?.value?.toString();
  }

  public setCaret(): void {
    if (_isBrowserSafari()) {
      // If not safari, input is already focused.
      // For safari we need to focus only for this use case to avoid AG-3238,
      // but still ensure the input has focus.
      this.eInput?.getInputElement().focus({ preventScroll: true });
    }
  }
}

export class NumberCellEditor extends SimpleCellEditor<
  number,
  INumberCellEditorParams,
  AgInputNumberField
> {
  constructor() {
    super(new NumberCellEditorInput());
  }
}
