import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { rootRoute } from "../baseRoutes";

export const rootAdminRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "admin",
  component: lazyRouteComponent(() => import("./nav/Dashboard"), "Dashboard"),
});

export const orgsRoute = createRoute({
  getParentRoute: () => rootAdminRoute,
  path: "orgs",
  component: lazyRouteComponent(() => import("./orgs/Table"), "AllOrgs"),
});

export const createOrganisationRoute = createRoute({
  getParentRoute: () => rootAdminRoute,
  path: "orgs/new/$id",
  component: lazyRouteComponent(
    () => import("./orgs/Create"),
    "CreateOrganisation",
  ),
});

export const singleOrgRoute = createRoute({
  getParentRoute: () => rootAdminRoute,
  path: "orgs/$org",
  component: lazyRouteComponent(
    () => import("./components/OrgWrapper"),
    "SingleOrgWrapper",
  ),
});

export const singleOrgPermissionsRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "permissions",
  component: lazyRouteComponent(
    () => import("./permissions/Table"),
    "Permissions",
  ),
});

export const singleOrgGlobalPermissionsRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "global-permissions",
  component: lazyRouteComponent(
    () => import("./globalPermissions/Table"),
    "GlobalPermissions",
  ),
});

export const singleOrgPackagesRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "packages",
  component: lazyRouteComponent(() => import("./packages/Table"), "Packages"),
});

export const singleOrgProductsRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "products",
  component: lazyRouteComponent(() => import("./products/Table"), "Products"),
});

export const singleOrgAgreementsRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "agreements",
  component: lazyRouteComponent(() => import("./agreements"), "Agreements"),
});

export const singleOrgUsersRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "users",
  component: lazyRouteComponent(() => import("./users"), "Users"),
});

export const singleOrgCreateUserRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "users/new/$id",
  component: lazyRouteComponent(() => import("./users"), "CreateUser"),
});

export const singleOrgEditUserRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "users/$userId",
  component: lazyRouteComponent(() => import("./users"), "UserEdit"),
});

export const singleOrgSingleUserPackagesRoute = createRoute({
  getParentRoute: () => singleOrgEditUserRoute,
  path: "packages",
  component: lazyRouteComponent(() => import("./packages/Table"), "Packages"),
});

export const singleOrgCreatePackageRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "packages/new/$id",
  component: lazyRouteComponent(() => import("./packages"), "CreatePackage"),
});

export const singleOrgSingleUserProductsRoute = createRoute({
  getParentRoute: () => singleOrgEditUserRoute,
  path: "products",
  component: lazyRouteComponent(() => import("./products/Table"), "Products"),
});

/*  if the provider is directly in the Reports component the call in 
      useStore in useReportColDefs will return undefined. It's not actually a hook 
      so it doesn't get called again when the provider is added. */
export const reportsRoute = createRoute({
  getParentRoute: () => rootAdminRoute,
  path: "reports",
  component: lazyRouteComponent(
    () => import("./reports/Wrapper"),
    "ReportsWrapper",
  ),
});

export const reportsExchangesRoute = createRoute({
  getParentRoute: () => reportsRoute,
  path: "exchanges",
  component: lazyRouteComponent(() => import("./reports"), "Reports"),
});

export const reportsEditRoute = createRoute({
  getParentRoute: () => reportsRoute,
  path: "exchanges/$userId",
  component: lazyRouteComponent(() => import("./reports"), "UsersReports"),
});

export const reportsGlobalPermissionsRoute = createRoute({
  getParentRoute: () => reportsRoute,
  path: "global-permissions",
  component: lazyRouteComponent(
    () => import("./reports/GlobalPermissions"),
    "GlobalPermissions",
  ),
});

export const consoleRoute = createRoute({
  getParentRoute: () => rootAdminRoute,
  path: "console",
  component: lazyRouteComponent(
    () => import("./components/Console"),
    "Console",
  ),
});

export const auditLogRoute = createRoute({
  getParentRoute: () => rootAdminRoute,
  path: "audit",
  component: lazyRouteComponent(() => import("./audit"), "AuditLog"),
});
