import {
  type ForwardedRef,
  forwardRef,
  type HTMLAttributes,
  memo,
  type MutableRefObject,
  type ReactNode,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import type {
  ChartOptions,
  DeepPartial,
  IChartApi,
  MouseEventHandler,
  Time,
} from "lightweight-charts";

import { chart, type ChartActionResult } from "../internal/chart.js";
import { createLazyValue, type LazyValue } from "../internal/lazy-value.js";
import { ChartContext } from "./internal/chart-context.js";
import * as R from "remeda";
import { useDeepCompareMemo } from "@react-hookz/web";

export interface ChartProps extends DeepPartial<ChartOptions> {
  children?: ReactNode;
  container?: HTMLAttributes<HTMLDivElement> & {
    ref?: ForwardedRef<HTMLDivElement>;
  };
  onClick?: MouseEventHandler<Time>;
  onDblClick?: MouseEventHandler<Time>;
  onCrosshairMove?: MouseEventHandler<Time>;
  wrapperHeight?: string | number;
}

function useChartAction(
  props: Omit<ChartProps, "container"> & { container: HTMLElement },
  ref: ForwardedRef<IChartApi>,
): MutableRefObject<LazyValue<ChartActionResult>> {
  const { children, container, ...rest } = props;

  const context = useRef(
    createLazyValue(
      () => chart(container, rest),
      (value: ChartActionResult) => value.destroy(),
    ),
  );

  useLayoutEffect(() => {
    const { current } = context;
    current();
    return () => {
      current.reset();
    };
  }, []);

  const propsWithNoChildren = useDeepCompareMemo(() => {
    return rest;
  }, [rest]);

  useLayoutEffect(() => {
    // remove rightOffset from timeScale to avoid losing scrolling position
    const { timeScale, ...opts } = propsWithNoChildren;
    const newOpts = {
      ...opts,
      timeScale: R.omit(timeScale || {}, ["rightOffset"]),
    };

    context.current().update(newOpts);
  }, [propsWithNoChildren]);

  useImperativeHandle(ref, () => context.current().subject(), []);

  return context;
}

const ChartComponent = memo(
  forwardRef(
    (
      props: Omit<ChartProps, "container"> & { container: HTMLElement },
      ref: ForwardedRef<IChartApi>,
    ) => {
      const { children } = props;

      const context = useChartAction(props, ref);

      return (
        <ChartContext.Provider value={context.current}>
          {children}
        </ChartContext.Provider>
      );
    },
  ),
);

export const Chart = memo(
  forwardRef((props: ChartProps, ref: ForwardedRef<IChartApi>) => {
    const { container = {}, ...rest } = props;
    const { ref: containerRef, ...restContainer } = container;
    const [element, setElement] = useState<HTMLElement | null>(null);
    const handleContainerRef = useCallback(
      (ref: HTMLDivElement | null) => {
        setElement(ref);
        if (containerRef) {
          if (typeof containerRef === "function") {
            containerRef(ref);
          } else {
            containerRef.current = ref;
          }
        }
      },
      [containerRef],
    );

    return (
      <div
        style={{
          height: props.wrapperHeight || "100dvh",
        }}
        ref={handleContainerRef}
        {...restContainer}
      >
        {element !== null ? (
          <ChartComponent {...rest} ref={ref} container={element} />
        ) : null}
      </div>
    );
  }),
);
