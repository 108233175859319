import { type FetchPolicy, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";
import * as R from "remeda";
import { readFragment } from "../../graphql";
import type { TOption } from "../components/Autocomplete/Autocomplete";
import { productName, sortByStringCaseInsensitive } from "../utils";
import {
  ProductFragmentGrid,
  allProducts,
  globalProductsForLiveCharts,
  gridProductsByIds,
} from "./graphql";
import { hasuraProductByIdFamily } from "../market-pages/pageProductHooks";
import { useAtomValue } from "jotai";
import { useUserId } from "../../auth/hooks";

export function useProductsByIds(ids: string[], fetchPolicy?: FetchPolicy) {
  const { user } = useAuth0();
  const userId = user?.sub;

  if (!userId) console.error("no userId in useProductsByIds");

  const { data, loading, error } = useQuery(gridProductsByIds, {
    variables: { ids, folioUser: userId || "" },
    skip: ids.length === 0 || !userId,
    fetchPolicy,
  });

  const products = readFragment(ProductFragmentGrid, data?.product);

  return {
    data: products,
    fetching: loading,
    error,
  };
}

export function useProductById(id: string, fetchPolicy?: FetchPolicy) {
  const { user } = useAuth0();
  const userId = user?.sub;
  if (!userId) throw new Error("No userId in useProductById");

  const atom = useMemo(() => hasuraProductByIdFamily(id), [id]);
  const productInfo = useAtomValue(atom);

  return { data: productInfo, error: null };
}

export function useAllProducts() {
  const { user } = useAuth0();
  const userId = user?.sub;

  if (!userId) throw new Error("No userId in useAllProducts");

  const { data, loading, error } = useQuery(allProducts, {
    variables: { folioUser: userId },
    fetchPolicy: "no-cache",
  });

  const products = readFragment(ProductFragmentGrid, data?.product);
  if (error) console.error("error fetching all products", { error });

  return { data: products, fetching: loading, error };
}

export function useAllProductsOptions(props?: {
  disableListPermission?: boolean;
  selectedIds?: string[];
  excludeIds?: string[];
}) {
  const { disableListPermission, selectedIds, excludeIds } = props || {};

  const { data, fetching } = useAllProducts();

  const mappedData = useMemo(() => {
    const selectedIdsSet = new Set(selectedIds);
    const excludeIdsSet = new Set(excludeIds);

    return data
      ?.map((product) => {
        return {
          ...product,
          label: productName(product),
        };
      })
      .filter((product) => {
        const selectedIdsFilter =
          selectedIdsSet.size > 0 ? selectedIdsSet.has(product.id) : true;

        const excludeIdsFilter =
          excludeIdsSet.size > 0 ? !excludeIdsSet.has(product.id) : true;

        return product.name && selectedIdsFilter && excludeIdsFilter;
      });
  }, [data, selectedIds, excludeIds]);

  const groupedData = useMemo(() => {
    return (
      mappedData &&
      R.groupBy(mappedData, (product) => product.packageByPackage.name)
    );
  }, [mappedData]);

  const parsedData = useMemo(() => {
    return (
      groupedData &&
      R.mapValues(groupedData, (group) => {
        const packageName = group[0].packageByPackage.name;
        const sortedOptions = sortByStringCaseInsensitive("name", group).map(
          (p) => {
            return {
              label: p.label,
              value: p.id,
              disabled:
                (disableListPermission &&
                  p.packageByPackage.permissions?.[0]?.permission === "list") ||
                false,
              groupValue: packageName,
              metadata: p,
            } as const satisfies TOption;
          },
        );

        return sortedOptions;
      })
    );
  }, [groupedData, disableListPermission]);

  const options = useMemo(() => {
    return parsedData && R.values(parsedData).flat();
  }, [parsedData]);

  return { data: options, fetching };
}

export type TProductIdAndWidth = {
  gridId: string;
  productId?: string;
  width: number;
  columnFieldSelector?: string;
  columnSettings?: {
    decimalPlaces?: number | null;
    thousandsSeparator?: boolean | null;
  };
};

export function useGlobalProductOptions() {
  const userId = useUserId();
  const {
    data,
    loading: fetching,
    error,
  } = useQuery(globalProductsForLiveCharts, {
    variables: {
      folioUser: userId,
    },
  });

  if (error) console.error("error fetching global product options", { error });

  if (!data) {
    return { data: undefined, fetching };
  }

  const mappedData = data.global_product.map((product) => ({
    ...product,
    label: product.description || product.name,
  }));

  const groupedData = R.groupBy(
    mappedData,
    (product) => product.globalPackageByGlobalPackage.name,
  );

  const parsedData = R.mapValues(groupedData, (group) => {
    const packageName = group[0].globalPackageByGlobalPackage.name;
    return sortByStringCaseInsensitive("name", group).map(
      (p) =>
        ({
          label: p.label,
          value: p.id,
          disabled:
            p.globalPackageByGlobalPackage.global_permissions?.[0]
              ?.permission === "list",
          groupValue: packageName,
        }) as const satisfies TOption,
    );
  });

  const options = R.values(parsedData).flat();
  console.log("options", options);

  return { data: options, fetching };
}
