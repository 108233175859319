import posthog from "posthog-js";

type Info = Record<string, unknown>;

const captureEvent = (eventName: string, info: Info = {}) => {
  const eventData = {
    ...info,
  };
  posthog.capture(eventName, eventData);
};

export default captureEvent;
