import { z } from "zod";

export const dateSchema = z.coerce.date().nullable();

export const requiredStringSchema = (name: string) =>
  z
    .string()
    .trim()
    .min(1, { message: `Must have a ${name}` });

export const passwordValidation = z
  .string()
  .min(8)
  .refine((value) => /^(?=.*[0-9])(?=.*[A-Z]).{8,}$/.test(value), {
    message:
      "Password should contain at least one uppercase letter, one lowercase letter, and one number",
  });
