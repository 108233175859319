import {
  monthCodeToShortMonthAndYear,
  relativeRowToRowId,
  relativeRowToRowType,
  rowIdToRelativeRow,
  rowStringToCode,
} from "../market-grid/periodHelpers";
import {
  configsKey,
  type TGridDataRowId,
  type TGridDataColumn,
  type TGridDataEntry,
} from "./sharedStores";
import { parseArtisType, retrieveCellValues } from "./storeLogic";
import { type ProductInfo, productInfoKey } from "./subscriptionHandlers";
import { defaultFieldNameSelector, parseLimitRef } from "../utils";
import * as idb from "idb-keyval";
import type { TStatusMap, schema } from "../../triplit/schema";
import { parse } from "../numbers";
import type { TriplitClient } from "@triplit/client";
import { profileEnd, profileStart } from "../../utils";

function parseRowId(rowId: string) {
  try {
    const rowType = relativeRowToRowType(rowId);
    const parsedRowId = rowStringToCode(relativeRowToRowId[rowId]);
    if (rowType && parsedRowId) {
      return [rowType, parsedRowId] satisfies TGridDataRowId;
    }
  } catch (e) {
    console.error("Failed to parse rowId for alerts in worker", e);
  }
}

export function alertsQuery(client: TriplitClient<typeof schema>) {
  return client.query("alerts").where("status", "=", "Active").build();
}

export async function handleAlerts({
  triplitClient,
  statusMap,
  eodEvalDate,
}: {
  triplitClient: TriplitClient<typeof schema>;
  statusMap: TStatusMap;
  eodEvalDate: string;
}) {
  profileStart("handleAlerts");
  const alertsResult = await triplitClient.fetch(alertsQuery(triplitClient), {
    policy: "local-only",
  });

  const alerts = alertsResult || [];
  const productIds = alerts.map((alert) => alert.productId);

  if (productIds.length) {
    try {
      const [productInfo, configs] = await Promise.all([
        idb.get<ProductInfo>(productInfoKey),
        idb.get(configsKey),
      ]);

      const columns = alerts
        ?.reduce((acc, alert) => {
          const productId = alert.productId;
          try {
            if (!productId) return acc;

            const info = productInfo?.[productId];
            const config = configs?.[productId];
            const eodId = info?.eod_product_dep;

            const artisType = parseArtisType(info?.original_artis_type);
            if (!artisType) return acc;

            const status = statusMap?.[productId] || "listen";

            if (!acc.some((c) => c.columnId === alert.columnId)) {
              acc.push({
                productId,
                columnId: alert.columnId,
                eodId,
                status,
                artisType,
                selector:
                  alert.fieldSelector ?? defaultFieldNameSelector(artisType),
                isPermissioned: true,
                hasSharedCell: Boolean(config),
              } satisfies TGridDataColumn);
            }

            return acc;
          } catch (e) {
            console.error(
              "Failed to parse pageProduct into grid data column for alerts in worker",
              e,
            );
            return acc;
          }
        }, [] as TGridDataColumn[])
        .filter(Boolean);

      // Sort alerts according to their row index based on the rowId. This ensures that retrieveCellValues will return the data in the correct order.
      const alertsByRowIndex = Object.values(
        alerts.reduce(
          (acc, alert) => {
            const relativeRows = Object.keys(relativeRowToRowId);
            const rowIndex = relativeRows.indexOf(alert.rowId);
            if (!acc[rowIndex]) {
              acc[rowIndex] = [];
            }

            acc[rowIndex].push(alert);
            return acc;
          },
          {} as Record<number, typeof alerts>,
        ),
      );

      const alertRowIds = alertsByRowIndex
        .map((row) => {
          const rowId = row[0].rowId;
          return parseRowId(rowId);
        })
        .filter(Boolean);

      const limitRowIds = alertsByRowIndex
        .map((row) => {
          const limit = row[0].limit;
          if (limit.includes(":")) {
            const id = parseLimitRef(limit).rowId;
            if (id) {
              const month = monthCodeToShortMonthAndYear(id);
              return rowIdToRelativeRow[month];
            }
          }
        })
        .filter(Boolean)
        .map(parseRowId)
        .filter(Boolean);

      const alertsGridData =
        retrieveCellValues({
          rowIds: alertRowIds,
          columns,
          productConfigs: configs,
          eodEvalDate,
        }) || [];

      const limitGridData =
        retrieveCellValues({
          rowIds: limitRowIds,
          columns,
          productConfigs: configs,
          eodEvalDate,
        }) || [];

      await Promise.all(
        alertsByRowIndex
          .map(async (rowAlerts, idx) => {
            const data = alertsGridData[idx];

            for (const alert of rowAlerts) {
              const cellValue = data[alert.columnId]?.Ok ?? null;

              if (cellValue !== null) {
                const limit = getAlertLimit(
                  limitGridData,
                  alert.limit,
                  limitRowIds,
                );

                if (!limit) {
                  console.warn(
                    "No limit found for alert - should probably look into this as it shouldn't happen",
                    alert,
                  );
                  return;
                }

                const conditionMet = alertConditionMet(
                  parse(cellValue),
                  limit,
                  alert.valueBelowLimit,
                );

                if (conditionMet && !alert.triggeredAt) {
                  console.log("Triggering Alert...", alert);
                  const alertExists = await triplitClient.fetchById(
                    "alerts",
                    alert.id,
                  );

                  if (!alertExists) {
                    triplitClient.insert("alerts", {
                      ...alert,
                      status: "Triggered",
                      triggeredAt: new Date(),
                    });

                    return;
                  }
                  return triplitClient.update("alerts", alert.id, (row) => {
                    row.status = "Triggered";
                    row.triggeredAt = new Date();
                  });
                }
              }
            }
          })
          .filter(Boolean) || [],
      );
    } catch (e) {
      console.log("Failed to handle alerts in worker", e);
    }
  }
  profileEnd("handleAlerts");
}

function alertConditionMet(
  cellValue: number | null,
  limit: number | null,
  valueBelowLimit: boolean,
) {
  if (cellValue === null || limit === null) return false;
  // The valueBelowLimit is set when the alert is created. If the cell value at the time is below the limit, then it means that the user wants to be alerted when the value goes above the limit.
  if (valueBelowLimit) {
    return cellValue >= limit;
  }

  return cellValue <= limit;
}

function rowIdToString(rowId: TGridDataRowId) {
  return `${rowId[0]}:${rowId[1]}`;
}

function getAlertLimit(
  data: TGridDataEntry[],
  limit: string,
  limitRowIds: TGridDataRowId[],
) {
  const limitIsRef = limit.includes(":");

  if (limitIsRef) {
    const { columnId, rowId, rowType } = parseLimitRef(limit);
    if (!columnId || !rowId) return null;
    // because js is dumb and can't compare tuples
    const limitRowKeys = limitRowIds.map((id) => rowIdToString(id));
    const limitRowKey = rowIdToString([rowType, rowId]);
    const limitDataIndex = limitRowKeys.indexOf(limitRowKey);

    return parse(data[limitDataIndex][columnId]?.Ok);
  }

  return parse(limit);
}
