import {
  Button,
  type ColorPaletteProp,
  Dropdown,
  IconButton,
  Input,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  useColorScheme,
} from "@mui/joy";
import { Box } from "@mui/system";
// import "ag-grid-community/styles/ag-grid.css"; // Core CSS
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import type Ag from "ag-grid-community";
import type {
  GridApi,
  GridState,
  SuppressKeyboardEventParams,
  CellDoubleClickedEvent,
  BaseExportParams,
  GetContextMenuItemsParams,
  GridReadyEvent,
  IRowNode,
  MenuItemDef,
  SelectionChangedEvent,
  StateUpdatedEvent,
  GetRowIdParams,
  GetRowIdFunc,
  ColDef,
} from "ag-grid-community";
import { AgGridReact, type AgGridReactProps } from "ag-grid-react";
import type React from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import { IoCloseSharp, IoSearchOutline } from "react-icons/io5";
import { MdApps } from "react-icons/md";
import { headerHeight, isDev, tableTopToolbarHeight } from "../../globals";
import { useEntity } from "@triplit/react";
import "ag-grid-enterprise";
import type { RequiredTableFields } from "../../utils";
import * as R from "remeda";
import { resetApiFilters } from "./tableHelpers";
import { client } from "../../triplit/triplit";
import { useOrgId } from "../orgs";
import { useEnv } from "../../envUtils";
import { createStateTableId } from "../../triplit/id-factories";
import { useUserId } from "../../auth";
import { useForm } from "react-hook-form";
import { useDebouncedCallback } from "@react-hookz/web";
import { useGridApi } from "../../shared/hooks";
import { ErrorBoundary } from "@sentry/react";
import { ErrorMessage } from "./Alerts";

function handleExport(
  api: Ag.GridApi,
  exportedRows: BaseExportParams["exportedRows"],
) {
  if (api) {
    api.exportDataAsCsv({ exportedRows });
  } else {
    console.error("Grid API not available");
  }
}

function suppressNavigation(params: SuppressKeyboardEventParams) {
  const KEY_BACKSPACE = "Backspace";
  const event = params.event;
  const key = event.key;
  const editingKeys = [KEY_BACKSPACE];

  const suppress = editingKeys.some(
    (suppressedKey) =>
      suppressedKey === key || key.toUpperCase() === suppressedKey,
  );
  return suppress;
}

export function TableHeaderButton({
  label,
  onClick,
  color = "success",
}: {
  label: string;
  color?: ColorPaletteProp;
  onClick: () => void;
}) {
  return (
    <Button
      size="sm"
      id={`table-header-button-${label}`}
      variant="outlined"
      color={color}
      onClick={onClick}
    >
      {label}
    </Button>
  );
}

export type TAction<T> = {
  label: string;
  onClick: ({
    selectedData,
    ids,
  }: {
    selectedData: T[];
    ids: string[];
  }) => void;
  icon?: React.ReactNode;
};

function ActionMenu<T extends RequiredTableFields>({
  actions,
  checkboxSelection,
}: {
  api?: GridApi<T>;
  actions: TAction<T>[];
  checkboxSelection: IRowNode[] | undefined;
}) {
  return (
    <Dropdown>
      <MenuButton size="sm" variant="outlined" startDecorator={<MdApps />}>
        Actions
      </MenuButton>
      <Menu>
        {actions.map((action) => (
          <MenuItem
            key={action.label}
            onClick={() => {
              if (!checkboxSelection) return;
              const selectedData = checkboxSelection
                .map((node) => node.data)
                .filter(Boolean);
              const ids = selectedData.map((d) => d.id.toString());
              action.onClick({ selectedData, ids });
            }}
          >
            {action.icon && (
              <ListItemDecorator>{action.icon}</ListItemDecorator>
            )}
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
}

function TableTopToolbar<T extends RequiredTableFields>({
  stateTableId,
  stateName,
  actions = [],
  leftChildren,
  rightChildren,
  showExportButtons = true,
  checkboxSelection,
  showCheckboxSelection,
  searchInputPlaceholder = "Search",
  showQuickFilter,
  api,
  resetFiltersCallback,
}: {
  stateTableId?: string;
  stateName: string;
  api: GridApi<T>;
  showExportButtons?: boolean;
  editMode: boolean;
  actions: TAction<T>[];
  leftChildren?: React.ReactNode | ((api: GridApi<T>) => React.ReactNode);
  rightChildren?: React.ReactNode | ((api: GridApi<T>) => React.ReactNode);
  checkboxSelection: IRowNode[] | undefined;
  showCheckboxSelection: boolean;
  showQuickFilter: boolean;
  searchInputPlaceholder?: string;
  resetFiltersCallback?: () => void;
}) {
  const { register, handleSubmit, reset } = useForm<{ filterText: string }>();
  const [filterCheckboxed, setFilterCheckboxed] = useState(false);

  const isAnyRowSelected = (checkboxSelection?.length ?? 0) > 0;

  const handleShowSelect = useCallback(() => {
    if (filterCheckboxed) {
      api.setFilterModel(null);
    } else {
      const ids = checkboxSelection?.map((n) => n.id?.toString());
      api.setFilterModel({
        id: {
          filterType: "set",
          values: ids,
        },
      });
    }

    setFilterCheckboxed(!filterCheckboxed);
    api.onFilterChanged();
  }, [api, checkboxSelection, filterCheckboxed]);

  const handleDeselectAll = useCallback(() => {
    api.setFilterModel(null);
    setFilterCheckboxed(false);
    api.deselectAll();
  }, [api]);

  const handleResetFilters = useCallback(() => {
    resetApiFilters({ stateTableId, api });

    setFilterCheckboxed(false);
    reset();

    resetFiltersCallback?.();
  }, [api, resetFiltersCallback, reset, stateTableId]);

  const tableActions = showExportButtons
    ? [
        {
          label: "Reset Filters",
          onClick: () => {
            handleResetFilters();
          },
        },
        {
          label: "Export All",
          onClick: () => {
            handleExport(api, "all");
          },
        },
        {
          label: "Export Filtered View",
          onClick: () => {
            handleExport(api, "filteredAndSorted");
          },
        },
      ]
    : [
        {
          label: "Reset Filters",
          onClick: () => {
            handleResetFilters();
          },
        },
      ];

  const defaultActions = (
    isAnyRowSelected
      ? [
          {
            label: "Delete",
            onClick: (_selectedData) => {
              console.log("in delete");
            },
          },
          {
            label: filterCheckboxed ? "Reset Show Selected" : "Show Selected",
            onClick: () => handleShowSelect(),
          },
          {
            label: "Deselect All",
            onClick: () => handleDeselectAll(),
          },
          ...tableActions,
        ]
      : tableActions
  ) satisfies TAction<T>[];

  const onSubmit = handleSubmit((data, e) => {
    api.setGridOption("quickFilterText", e?.target.value);
    api.onFilterChanged();
  });

  const submitDebounced = useDebouncedCallback(onSubmit, [], 300);
  return (
    <Box
      id="actions"
      sx={{
        height: tableTopToolbarHeight,
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          pb: 1,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <ActionMenu
            api={api}
            checkboxSelection={checkboxSelection}
            actions={[...actions, ...defaultActions]}
          />

          {typeof leftChildren === "function"
            ? leftChildren(api)
            : leftChildren}

          {showQuickFilter && (
            <Input
              {...register("filterText")}
              id="search"
              autoComplete="off"
              size="sm"
              onChange={submitDebounced}
              placeholder={searchInputPlaceholder}
              startDecorator={<IoSearchOutline size={16} />}
              endDecorator={
                <IconButton
                  aria-label="clear filter"
                  onClick={() => {
                    reset({ filterText: "" });
                    api.setGridOption("quickFilterText", "");
                    api.onFilterChanged();
                  }}
                >
                  <IoCloseSharp />
                </IconButton>
              }
              sx={(theme) => ({
                width: { xs: theme.spacing(14), lg: theme.spacing(20) },
              })}
            />
          )}
          {rightChildren && (
            <Box
              sx={{
                display: {
                  md: "flex",
                },
                gap: 1,
              }}
            >
              {typeof rightChildren === "function"
                ? rightChildren(api)
                : rightChildren}
            </Box>
          )}
          {showCheckboxSelection && (
            <Box sx={{ gap: 1, display: { xs: "none", md: "flex" } }}>
              <Button
                size="sm"
                variant="outlined"
                onClick={() => handleShowSelect()}
              >
                {filterCheckboxed ? "Reset Show Selected" : "Show Selected"}
              </Button>
              <Button
                size="sm"
                variant="outlined"
                onClick={() => handleDeselectAll()}
              >
                Deselect All
              </Button>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              lg: "flex",
            },
            gap: 1,
          }}
        >
          <Button
            variant="outlined"
            size="sm"
            onClick={() => handleResetFilters()}
          >
            Reset Filters
          </Button>
          {showExportButtons && (
            <>
              <Button size="sm" onClick={() => handleExport(api, "all")}>
                Export All
              </Button>
              <Button
                size="sm"
                onClick={() => handleExport(api, "filteredAndSorted")}
              >
                Export Filtered View
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

const ColStateKeys = [
  "columnGroup",
  "columnOrder",
  "columnSizing",
  "columnPinning",
  "columnVisibility",
  "sort",
] as const;

type TColStateKeys = (typeof ColStateKeys)[number];

type TColState = Pick<GridState, TColStateKeys>;

type TGridState = Omit<GridState, keyof TColState>;

function useTableState({
  stateName,
  enabled,
}: {
  stateName: string;
  enabled: boolean;
}) {
  const orgId = useOrgId();
  const [env] = useEnv();

  const userId = useUserId();

  const stateTableId = createStateTableId(userId, orgId, env, stateName);

  const stateTable = useEntity(client, "tableState", stateTableId);

  const stateStr = stateTable?.result?.stateString;
  const state = stateStr ? (JSON.parse(stateStr) as GridState) : undefined;

  const colStateTable = useEntity(client, "tableColumnState", stateTableId);
  const colStateStr = colStateTable?.result?.stateString;
  const colState = colStateStr
    ? (JSON.parse(colStateStr) as TColState)
    : undefined;

  const setColState = useCallback(
    (newState: TColState) => {
      if (userId === "disabled") {
        client.insert("tableColumnState", {
          id: stateTableId,
          userId,
          stateString: JSON.stringify(newState),
        });
      }
    },
    [stateTableId, userId],
  );

  const setState = useCallback(
    (newState: TGridState) => {
      if (userId) {
        client.insert("tableState", {
          id: stateTableId,
          userId,
          stateString: JSON.stringify(newState),
        });
      }
    },
    [stateTableId, userId],
  );

  if (!enabled) {
    return {
      fetching: false,
    };
  }

  const fetching = stateTable?.fetchingLocal || colStateTable?.fetchingLocal;

  return {
    stateTableId,
    state,
    setState,
    quickFilter: stateTable?.results?.quickFilter ?? "",
    colState,
    setColState,
    fetching,
  };
}

// If we use useTableState inside the Table, it won't have the data available when the Table is iniitially rendered, so the filters and column state won't be applied. We need to use it outside the Table and pass the states as props to the Table.
export function TableWithState<TData extends RequiredTableFields>({
  states,
  stateName,
  colDefs,
  rowData,
  handleNavigate,
  customItems = () => [],
  actions = [],
  gridProps = {},
  onGridReady,
  showCheckboxSelection = true,
  sideBar = true,
  leftChildren,
  rightChildren,
  searchInputPlaceholder,
  tableHeight,
  showExportButtons = true,
  toolbar = true,
  suppressExcelExport = false,
  showQuickFilter = true,
  storeState = true,
  resetFiltersCallback,
}: {
  states: ReturnType<typeof useTableState>;
  stateName: string;
  storeState?: boolean;
  onGridReady?: (params: GridReadyEvent<TData>) => void;
  showCheckboxSelection?: boolean;
  showExportButtons?: boolean;
  showQuickFilter?: boolean;
  colDefs: Ag.ColDef<TData>[];
  rowData: TData[];
  sideBar?: boolean;
  customItems?: (
    params: GetContextMenuItemsParams<TData>,
    checkboxSelection: IRowNode<TData>[] | undefined,
  ) => MenuItemDef<TData>[];
  handleNavigate?: (params?: TData) => void;
  actions?: TAction<TData>[];
  leftChildren?: React.ReactNode | ((api: GridApi<TData>) => React.ReactNode);
  rightChildren?: React.ReactNode | ((api: GridApi<TData>) => React.ReactNode);
  gridProps?: Partial<AgGridReactProps<TData>>;
  searchInputPlaceholder?: string;
  tableHeight?: number;
  toolbar?: boolean;
  suppressExcelExport?: boolean;
  resetFiltersCallback?: () => void;
}) {
  const gridRef = useRef<AgGridReact<TData>>(null);

  const gridState = R.merge(states?.state, states?.colState);

  const gridStateEmpty = Object.keys(gridState || {}).length === 0;

  const initialState = !storeState
    ? undefined
    : !gridStateEmpty
      ? gridState
      : {
          sideBar: {
            openToolPanel: null,
            toolPanels: ["columns", "filters"],
            position: "right",
            visible: true,
          } as const,
        };

  const { mode } = useColorScheme();

  const dark = mode === "dark";
  const pageContainerHeight = tableHeight
    ? `${tableHeight}px`
    : `calc(100dvh - ${headerHeight}px)`;
  const { setApi } = useGridApi();

  const [checkboxSelection, setCheckboxSelection] = useState<IRowNode<TData>[]>(
    [],
  );

  const getRowId: GetRowIdFunc<TData> = useCallback(
    (params: GetRowIdParams<TData>) => {
      return params.data.id.toString();
    },
    [],
  );

  const onGridPreDestroyed = useCallback(() => {
    setApi(null);
  }, [setApi]);

  const api = gridRef.current?.api;

  const onGridReadyCallback = useCallback(
    (params: GridReadyEvent<TData>) => {
      console.log("Grid ready");
      setApi(params.api);
      onGridReady?.(params);
    },
    [onGridReady, setApi],
  );

  const stateUpdateFn = useCallback(
    ({ state: newState, sources }: StateUpdatedEvent<TData>) => {
      if (!storeState || !states?.setColState || !states?.setState) return;
      const excludedStateKeys: typeof sources = [
        "gridInitializing",
        // these two when not excluded cause the grid scroll position to reset
        "scroll",
        "focusedCell",
      ];

      const sourceIsExcluded = sources.some((s) =>
        excludedStateKeys.includes(s),
      );
      const pivotEnabled = newState.pivot?.pivotMode;

      if (sourceIsExcluded || pivotEnabled) {
        return;
      }

      const newColState: TColState = Object.fromEntries(
        Object.entries(newState).filter(([k]) => ColStateKeys.includes(k)),
      );
      const nonColState = Object.fromEntries(
        Object.entries(newState).filter(([k]) => !ColStateKeys.includes(k)),
      );
      if (JSON.stringify(newColState) !== JSON.stringify(states?.colState)) {
        states?.setColState(newColState);
      }

      if (JSON.stringify(nonColState) !== JSON.stringify(states?.state)) {
        states?.setState(nonColState);
      }
    },
    [states, storeState],
  );

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    setCheckboxSelection(event.api.getSelectedNodes());
  }, []);

  const getContextMenuItems = useCallback(
    function genCtxItems(params: GetContextMenuItemsParams<TData>) {
      return [...customItems(params, checkboxSelection), "copy"];
    },
    [checkboxSelection, customItems],
  );

  const onCellDoubleClicked = useCallback(
    (params: CellDoubleClickedEvent<TData>) => {
      if (handleNavigate && !params.colDef.editable) {
        handleNavigate(params.data);
      }
    },
    [handleNavigate],
  );

  const defaultColDef: ColDef<TData> = useMemo(
    () => ({
      editable: false,
      enableRowGroup: true,
      filter: true,
      onCellDoubleClicked,

      suppressKeyboardEvent: suppressNavigation,
    }),
    [onCellDoubleClicked],
  );

  return (
    <ErrorBoundary
      fallback={
        <Box
          sx={{
            height: pageContainerHeight,
            width: "100%",
            display: "flex",
            p: 1,
            flexDirection: "column",
            bgcolor: "background.paper",
          }}
        >
          <ErrorMessage
            title="Error loading table"
            message="Try refreshing"
            severity="danger"
          />
        </Box>
      }
    >
      <Box
        sx={{
          height: pageContainerHeight,
          width: "100%",
          display: "flex",
          p: 1,
          flexDirection: "column",
          bgcolor: "background.paper",
        }}
      >
        {toolbar && api ? (
          <TableTopToolbar
            stateTableId={states.stateTableId}
            api={api}
            showExportButtons={showExportButtons}
            showCheckboxSelection={
              checkboxSelection.length > 0 && showCheckboxSelection
            }
            checkboxSelection={checkboxSelection}
            stateName={stateName}
            showQuickFilter={showQuickFilter}
            editMode={false}
            rightChildren={rightChildren}
            leftChildren={leftChildren}
            actions={actions}
            searchInputPlaceholder={searchInputPlaceholder}
            resetFiltersCallback={resetFiltersCallback}
          />
        ) : (
          <Box sx={{ height: tableTopToolbarHeight }} />
        )}
        <Box
          className={
            dark
              ? "ag-theme-quartz-dark ag-theme-dark-custom"
              : "ag-theme-quartz ag-theme-light-custom"
          }
          sx={{
            height: "100dvh",
          }}
        >
          <AgGridReact<TData>
            rowData={rowData}
            defaultColDef={defaultColDef}
            ref={gridRef}
            columnDefs={colDefs}
            loading={states.fetching}
            enableBrowserTooltips
            sideBar={sideBar}
            readOnlyEdit={true}
            getRowId={getRowId}
            onGridPreDestroyed={onGridPreDestroyed}
            onGridReady={onGridReadyCallback}
            rowSelection="multiple"
            suppressScrollOnNewData
            maintainColumnOrder
            debug={isDev}
            onStateUpdated={stateUpdateFn}
            suppressExcelExport={suppressExcelExport}
            groupSelectsChildren
            suppressRowClickSelection
            onSelectionChanged={onSelectionChanged}
            suppressAggFuncInHeader
            rowGroupPanelShow="onlyWhenGrouping"
            removePivotHeaderRowWhenSingleValueColumn
            initialState={initialState}
            getContextMenuItems={getContextMenuItems}
            {...gridProps}
          />
        </Box>
      </Box>
    </ErrorBoundary>
  );
}

type TTableProps<T extends RequiredTableFields> = Parameters<
  typeof TableWithState<T>
>[0];

export function Table<TData extends RequiredTableFields>(
  props: Omit<TTableProps<TData>, "states"> & {
    showAllMenuTabs?: boolean;
  },
) {
  const states = useTableState({
    stateName: props?.stateName,
    enabled: props?.storeState ?? true,
  });

  const columnDefs = props?.showAllMenuTabs
    ? props.colDefs
    : (props.colDefs.map((def) => {
        return {
          ...def,
          menuTabs: ["filterMenuTab"],
        };
      }) satisfies ColDef<TData>[]);

  return <TableWithState {...props} colDefs={columnDefs} states={states} />;
}
