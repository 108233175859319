import { useDeepCompareMemo } from "@react-hookz/web";
import { useEntity } from "@triplit/react";
import { atom, useSetAtom, useAtomValue } from "jotai";
import { useCallback, useEffect } from "react";
import { useUserId } from "../../auth";
import {
  type schema,
  statusMapSchema,
  type TStatusMap,
  type TIncrementMap,
  incrementMapSchema,
} from "../../triplit/schema";
import { client } from "../../triplit/triplit";
import { curveColumnStack } from "../market-grid/columnDefs";
import type { TStatus } from "../market-grid/statuses/statusLogic";
import { gridSettingsAtom, statusMapAtom } from "./atomStore";
import { initGridSettings } from "../market-grid/defaultSettings";
import { calcWorker } from "../calculations-worker/hooks";
import type { Entity } from "@triplit/client";

export const gridSettingsForRowDataAtom = atom((get) => {
  const settings = get(gridSettingsAtom) || initGridSettings;

  return {
    months: settings.months,
    qtrs: settings.qtrs,
    cals: settings.cals,
    hlvs: settings.hlvs,
    seasons: settings.seasons,
    qtrSwitch: settings.qtrSwitch,
    hlvSwitch: settings.hlvSwitch,
    seasonSwitch: settings.seasonSwitch,
    calSwitch: settings.calSwitch,
    adhocSpreadsSwitch: settings.adhocSpreadsSwitch,
    adhocSpreadsRows:
      settings.adhocSpreadsRows ?? initGridSettings.adhocSpreadsRows,
    qtrCurrent: settings.qtrCurrent,
    hlvCurrent: settings.hlvCurrent,
    seasonCurrent: settings.seasonCurrent,
    calCurrent: settings.calCurrent,
    commodityParentGroup: settings.commodityParentGroup,
    geographicalRegion: settings.geographicalRegion,
    commodityGroup: settings.commodityGroup,
    description: settings.description,
    source: settings.source,
    fieldName: settings.fieldName,
    statusMap: settings.statusMap,
  };
});

export const gridSettingsForVisibleColumnsAtom = atom((get) => {
  const settings = get(gridSettingsAtom) || initGridSettings;

  return {
    commodityParentGroup: settings.commodityParentGroup,
    geographicalRegion: settings.geographicalRegion,
    commodityGroup: settings.commodityGroup,
    package: settings.package,
    description: settings.description,
    source: settings.source,
    fieldName: settings.fieldName,
    headerColumnColour:
      settings.headerColumnColour ?? initGridSettings.headerColumnColour,
    subheaderColumnColour:
      settings.subheaderColumnColour ?? initGridSettings.subheaderColumnColour,
  };
});

export const gridSettingsColorsAtom = atom((get) => {
  const settings = get(gridSettingsAtom) || initGridSettings;

  return {
    alternatingRowColours: settings.alternatingRowColours ?? null,
    indicatorColour: settings.indicatorColour ?? null,
    headerColumnColour:
      settings.headerColumnColour ?? initGridSettings.headerColumnColour,
    subheaderColumnColour:
      settings.subheaderColumnColour ?? initGridSettings.subheaderColumnColour,
    listenColour: settings.listenColour ?? null,
    localColour: settings.localColour ?? null,
    hybridColour: settings.hybridColour ?? null,
    eodColour: settings.eodColour ?? null,
    globalColour: settings.globalColour ?? null,
    broadcastColour: settings.broadcastColour ?? null,
  };
});

export const gridSettingsOptions = atom((get) => {
  const settings = get(gridSettingsAtom) || initGridSettings;

  return {
    sound: settings.sound,
    flashCellUpdates: settings.flashCellUpdates,
    hideStatusRow: settings.hideStatusRow,
    hideEOD: settings.hideEOD,
    hideBroadcast: settings.hideBroadcast,
    hidePrivate: settings.hidePrivate,
    hideGlobal: settings.hideGlobal,
    enableFullMonthRowFeature: settings.enableFullMonthRowFeature,
    gridScratchpadSwitch: settings.gridScratchpadSwitch,
    scratchpadMaxRows: settings.scratchpadMaxRows,
    scratchpadMaxCols: settings.scratchpadMaxCols,
    mainMonthColumnWidth: settings.mainMonthColumnWidth,
    incrementMap: settings.incrementMap,
  };
});

export const visibleColumnsAtom = atom((get) => {
  const gridSettingsForVisibleColumns =
    get(gridSettingsForVisibleColumnsAtom) || initGridSettings;

  return curveColumnStack.filter(({ name, defaultVisible, required }) => {
    return (
      required || (gridSettingsForVisibleColumns?.[name] ?? defaultVisible)
    );
  });
});

const eodDateAtom = atom<string | null>(null);
eodDateAtom.debugLabel = "eodDateAtom";

export function useEodDate() {
  return useAtomValue(eodDateAtom);
}

export function useUpdateGridSettings() {
  const setGridSettings = useSetAtom(gridSettingsAtom);

  return useCallback(
    (
      gridSettings: Entity<typeof schema, "gridSettings"> | null | undefined,
    ) => {
      let statusMap: TStatusMap = {};
      try {
        if (
          gridSettings?.statusMap &&
          typeof gridSettings.statusMap === "string" &&
          gridSettings.statusMap !== "{}"
        ) {
          const statusM = statusMapSchema.parse(
            JSON.parse(gridSettings.statusMap),
          );
          if (!statusM) {
            throw new Error("Failed to parse statusMap");
          }
          statusMap = statusM;
        }
      } catch (e) {
        console.log("statusMap:", gridSettings?.statusMap);
        console.error("Failed to parse statusMap", e);
      }

      let incrementMap: TIncrementMap = {};
      try {
        incrementMap = incrementMapSchema.parse(
          JSON.parse(gridSettings?.incrementMap || "{}"),
        );
      } catch (e) {
        console.log("incrementMap:", gridSettings?.incrementMap);
        console.error("Failed to parse incrementMap", e);
      }

      const newGridSettings = {
        ...initGridSettings,
        ...gridSettings,
        statusMap: statusMap,
        incrementMap: incrementMap,
      };
      setGridSettings(newGridSettings);
      return newGridSettings;
    },
    [setGridSettings],
  );
}

export function useGridSettings() {
  const userId = useUserId();

  const { result, fetchingLocal, error } = useEntity(
    client,
    "gridSettings",
    userId,
  );

  const setGridSettings = useUpdateGridSettings();

  const settingsQueryRes = useDeepCompareMemo(() => {
    const gridSettings = result && setGridSettings(result);
    return {
      error,
      fetching: fetchingLocal,
      results: gridSettings,
      userId,
    };
  }, [result, userId, fetchingLocal, error]);

  const eodDate = settingsQueryRes.results?.eodDate;
  const fetching = settingsQueryRes.fetching;

  const getPriorEodDate = calcWorker?.()?.proxy?.getPriorEodDate;
  const setSelectedDate = useSetAtom(eodDateAtom);
  useEffect(() => {
    if (!getPriorEodDate) return;
    if (eodDate === "prior" || (!fetching && !eodDate)) {
      async function setNewEodDate() {
        const date = await getPriorEodDate?.();
        if (!date) {
          return;
        }
        setSelectedDate(date);
      }
      setNewEodDate();
    } else {
      setSelectedDate(eodDate ?? null);
    }
  }, [eodDate, getPriorEodDate, fetching, setSelectedDate]);

  return {
    gridSettings: settingsQueryRes.results,
    userId,
    fetching: fetchingLocal,
    error,
  };
}

export function useVisibleColumns() {
  return useAtomValue(visibleColumnsAtom);
}

export function useGridSettingsForRowData() {
  return useAtomValue(gridSettingsForRowDataAtom);
}

export function useGridSettingsForVisibleColumns() {
  return useAtomValue(gridSettingsForVisibleColumnsAtom);
}

export function useGridSettingsColors() {
  return useAtomValue(gridSettingsColorsAtom);
}

export function useGridSettingsOptions() {
  return useAtomValue(gridSettingsOptions);
}

export type TGridSettingsForRowData = ReturnType<
  typeof useGridSettingsForRowData
>;

export function useOneStatus(id: string): TStatus {
  const v = useAtomValue(statusMapAtom);
  if (!v) return "listen";
  return v[id] ? v[id] : "listen";
}
