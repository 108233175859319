import {
  Box,
  Button,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  type GridProps,
  ModalClose,
  Option,
  Select,
  Sheet,
  Typography,
  Stack,
} from "@mui/joy";
import { useDebouncedCallback } from "@react-hookz/web";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import type React from "react";
import { memo, useState } from "react";
import { useThemeMode } from "../../shared/hooks";
import { objectKeys } from "../../shared/utils";
import { capitalizeFirstLetter } from "../../utils";
import {
  alertSounds,
  gridSettingsResetConfirmationZIndex,
  gridSettingsWidth,
  gridSettingsZIndex,
  sideBarWidth,
} from "../globals";
import { ColorPicker } from "./ColorPicker";
import { useGridSettingsVisibility } from "./hooks";
import { useDateColumnSettings } from "./hooks";
import { gridSettingsUpsert } from "./triplitCallbacks";
import { useUserHasRole } from "../layout/hooks";
import {
  useGridSettingsColors,
  useGridSettingsForRowData,
  useGridSettingsOptions,
} from "./settingsStore";
import { useUserId } from "../../auth";
import { initGridSettings } from "../market-grid/defaultSettings";
import Checkbox from "./Checkbox";
import { useWebappTheme } from "../../styles/extendTheme";

function GridAppearance() {
  const userId = useUserId();
  const { alternatingRowColours } = useGridSettingsColors();
  const {
    hideStatusRow,
    hideEOD,
    hideBroadcast,
    hidePrivate,
    hideGlobal,
    flashCellUpdates,
  } = useGridSettingsOptions();

  const { mode, setMode } = useThemeMode("webappTheme");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Checkbox
        label="Flash green / red cell updates"
        checked={Boolean(flashCellUpdates)}
        onChange={(event) => {
          const newValue = event.target.checked;
          gridSettingsUpsert(userId, "flashCellUpdates", newValue);
        }}
      />

      <Checkbox
        label="Alternating row shading"
        checked={Boolean(alternatingRowColours)}
        onChange={(event) => {
          const newValue = event.target.checked;
          gridSettingsUpsert(userId, "alternatingRowColours", newValue);
        }}
      />
      <Checkbox
        label="Hide status row"
        checked={Boolean(hideStatusRow)}
        onChange={(event) => {
          const newValue = event.target.checked;
          gridSettingsUpsert(userId, "hideStatusRow", newValue);
        }}
      />
      <Checkbox
        label="Dark mode"
        checked={mode === "dark"}
        onChange={() => {
          const newMode = mode === "dark" ? "light" : "dark";
          setMode(newMode);
        }}
      />
      <Checkbox
        label="Disable EOD"
        checked={Boolean(hideEOD)}
        onChange={(event) => {
          const newValue = event.target.checked;
          gridSettingsUpsert(userId, "hideEOD", newValue);
        }}
      />
      <Checkbox
        label="Disable Broadcast"
        checked={Boolean(hideBroadcast)}
        onChange={(event) => {
          const newValue = event.target.checked;
          gridSettingsUpsert(userId, "hideBroadcast", newValue);
        }}
      />
      <Checkbox
        label="Disable Private"
        checked={Boolean(hidePrivate)}
        onChange={(event) => {
          const newValue = event.target.checked;
          gridSettingsUpsert(userId, "hidePrivate", newValue);
        }}
      />
      <Checkbox
        label="Disable Global"
        checked={Boolean(hideGlobal)}
        onChange={(event) => {
          const newValue = event.target.checked;
          gridSettingsUpsert(userId, "hideGlobal", newValue);
        }}
      />
    </Box>
  );
}

function AlertsSettings() {
  const userId = useUserId();
  const { sound } = useGridSettingsOptions();
  return (
    <>
      <Box sx={{ justifyContent: "space-between" }}>
        <Typography level="h4" fontWeight="bold" sx={{ mt: 1 }}>
          Alert settings
        </Typography>

        <Checkbox
          label="Enable Alert sound"
          checked={sound}
          onChange={async (event) => {
            const newValue = event.target.checked;
            await gridSettingsUpsert(userId, "sound", newValue);

            if (newValue) {
              new Audio(alertSounds.huthut).play();
            }
          }}
        />
      </Box>
    </>
  );
}

function FullMonthRow() {
  const userId = useUserId();
  const { enableFullMonthRowFeature } = useGridSettingsOptions();
  return (
    <>
      <Box sx={{ justifyContent: "space-between" }}>
        <Typography level="h4" fontWeight="bold" sx={{ mt: 1 }}>
          Full Month row
        </Typography>

        <Checkbox
          label="Enable"
          checked={enableFullMonthRowFeature}
          onChange={(event) => {
            const newValue = event.target.checked;
            gridSettingsUpsert(userId, "enableFullMonthRowFeature", newValue);
          }}
        />
      </Box>
    </>
  );
}

function TimeSpreads() {
  const userId = useUserId();
  const { adhocSpreadsSwitch, adhocSpreadsRows } = useGridSettingsForRowData();
  return (
    <>
      <Stack gap={1}>
        <Box sx={{ justifyContent: "space-between" }}>
          <Typography level="h4" fontWeight="bold" sx={{ mt: 1 }}>
            Time Spreads
          </Typography>

          <Checkbox
            label="Show"
            checked={adhocSpreadsSwitch}
            onChange={(event) => {
              const newValue = event.target.checked;
              gridSettingsUpsert(userId, "adhocSpreadsSwitch", newValue);
            }}
          />
        </Box>
        <Stack justifyContent={"space-between"} flexDirection={"row"}>
          <Typography level="body-sm" sx={{ mt: 1, mr: 1 }}>
            Rows to Show
          </Typography>

          <Select
            sx={{ width: 60, height: 30 }}
            value={adhocSpreadsRows}
            onChange={(_e, newValue) => {
              if (!newValue) return;
              gridSettingsUpsert(userId, "adhocSpreadsRows", newValue);
            }}
          >
            {Array.from({ length: 20 }, (_, i) => {
              const value = i + 1;
              return (
                <Option key={value} value={value}>
                  {value}
                </Option>
              );
            })}
          </Select>
        </Stack>
      </Stack>
    </>
  );
}

function ScratchpadSettings() {
  const userId = useUserId();
  const { gridScratchpadSwitch, scratchpadMaxRows, scratchpadMaxCols } =
    useGridSettingsOptions();
  const hasScratchpadRole = useUserHasRole("scratchpad-beta");

  if (!hasScratchpadRole) {
    return null;
  }
  const NumberOptions = () => {
    return (
      <>
        {Array.from({ length: 20 }, (_, i) => {
          const value = i + 1;
          return (
            <Option key={value} value={value}>
              {value}
            </Option>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Box sx={{ justifyContent: "space-between" }}>
        <Typography level="h4" fontWeight="bold" sx={{ mt: 1 }}>
          Scratchpad
        </Typography>

        <Checkbox
          label="Enable (beta)"
          checked={gridScratchpadSwitch}
          onChange={(event) => {
            const newValue = event.target.checked;
            gridSettingsUpsert(userId, "gridScratchpadSwitch", newValue);
          }}
        />
        <Box
          sx={{
            width: 150,
            justifyContent: "space-between",
            display: "flex",
            marginTop: "6px",
          }}
        >
          <Typography
            sx={{
              verticalAlign: "middle",
              paddingTop: 1,
            }}
          >
            Rows
          </Typography>
          <Select
            sx={{ width: 60, height: 30 }}
            value={scratchpadMaxRows}
            onChange={(_e, newValue) => {
              if (!newValue) return;
              gridSettingsUpsert(userId, "scratchpadMaxRows", newValue);
            }}
            aria-label="Scratchpad max rows"
          >
            <NumberOptions />
          </Select>
        </Box>
        <Box
          sx={{
            width: 150,
            justifyContent: "space-between",
            display: "flex",
            marginTop: "5px",
          }}
        >
          <Typography sx={{ verticalAlign: "middle", paddingTop: 1 }}>
            Columns
          </Typography>
          <Select
            sx={{ width: 60, height: 30 }}
            value={scratchpadMaxCols}
            onChange={(_e, newValue) => {
              if (!newValue) return;
              gridSettingsUpsert(userId, "scratchpadMaxCols", newValue);
            }}
            aria-label="Max Scratchpad columns"
          >
            <NumberOptions />
          </Select>
        </Box>
      </Box>
    </>
  );
}

const DateColumnSettings = memo(DateColumnSettingsRaw);

const DateColumnGridSx = {
  container: true,
  wrap: "nowrap",
  spacing: 1,
  sx: {
    width: 300,
  },
} satisfies GridProps;

function DateColumnSettingsRaw() {
  const userId = useUserId();
  const { orderedDateColumnSettings } = useDateColumnSettings();
  const keyMappings = {
    qtrs: "Quarters",
    hlvs: "Halves",
    months: "Months",
    cals: "Calendars",
    seasons: "Seasons",
  } satisfies Record<
    (typeof orderedDateColumnSettings)[number]["valueKey"],
    string
  >;
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography level="h4" fontWeight="bold" sx={{ mb: 2 }}>
            Grid appearance
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Grid {...DateColumnGridSx}>
              <Grid xs={4}>
                <Typography>Period</Typography>
              </Grid>
              <Grid xs={4}>
                <Typography>To</Typography>
              </Grid>
              <Grid xs={2}>
                <Typography>Enable</Typography>
              </Grid>
              <Grid xs={2}>
                <Typography>Current</Typography>
              </Grid>
            </Grid>

            {orderedDateColumnSettings.map(
              ({
                options,
                value,
                current,
                show,
                showKey,
                currentKey,
                valueKey,
              }) => (
                <Grid key={valueKey} {...DateColumnGridSx}>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    xs={4}
                  >
                    <Typography>
                      {keyMappings[valueKey] || capitalizeFirstLetter(valueKey)}
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Select
                      value={value.toString()}
                      renderValue={(newValue) => {
                        if (!newValue) return;
                        return newValue.label;
                      }}
                      onChange={(_e, newValue) => {
                        try {
                          if (!newValue) return;
                          const parseValue = Number.parseInt(newValue, 10);
                          gridSettingsUpsert(userId, valueKey, parseValue);
                        } catch (error) {
                          console.error("Error updating grid settings:", error);
                        }
                      }}
                      sx={{ width: "100%" }}
                    >
                      {options.map(([id, label]) => {
                        return (
                          <Option key={id} value={id.toString()}>
                            {label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    xs={2}
                  >
                    {showKey && (
                      <Checkbox
                        label=""
                        checked={show ?? false}
                        onChange={(event) => {
                          const newValue = event.target.checked;
                          gridSettingsUpsert(userId, showKey, newValue);
                        }}
                      />
                    )}
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    xs={2}
                  >
                    {currentKey && (
                      <Checkbox
                        label=""
                        checked={current ?? false}
                        onChange={(event) => {
                          const newValue = event.target.checked;
                          gridSettingsUpsert(userId, currentKey, newValue);
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              ),
            )}
          </Box>
        </Box>
      </div>
    </>
  );
}

export type GridColorSetting = {
  title: string;
  key:
    | "indicatorColour"
    | "headerColumnColour"
    | "subheaderColumnColour"
    | "broadcastColour"
    | "hybridColour"
    | "listenColour"
    | "eodColour"
    | "localColour"
    | "globalColour";
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  value: string | null;
};

function ColorOptions() {
  const userId = useUserId();
  const { indicatorColour, headerColumnColour, subheaderColumnColour } =
    useGridSettingsColors();

  const [showIndicatorColour, setShowIndicatorColour] = useState(false);
  const [showHeaderColumnColour, setShowHeaderColumnColour] = useState(false);
  const [showSubheaderColumnColour, setShowSubheaderColumnColour] =
    useState(false);

  const storeColorDebounced = useDebouncedCallback(
    (key: GridColorSetting["key"], value: string | null) => {
      gridSettingsUpsert(userId, key, value);
    },
    [],
    500,
  );
  const colorSettings = [
    {
      title: "Header",
      key: "headerColumnColour",
      show: showHeaderColumnColour,
      setShow: setShowHeaderColumnColour,
      value: headerColumnColour,
    },
    {
      title: "Subheader",
      key: "subheaderColumnColour",
      show: showSubheaderColumnColour,
      setShow: setShowSubheaderColumnColour,
      value: subheaderColumnColour,
    },
    {
      title: "Row Indicator",
      key: "indicatorColour",
      show: showIndicatorColour,
      setShow: setShowIndicatorColour,
      value: indicatorColour,
    },
  ] satisfies GridColorSetting[];

  return (
    <>
      <Typography level="h4" fontWeight="bold" sx={{ mt: 0 }}>
        Grid colours
      </Typography>

      <Box>
        {colorSettings.map(({ title, key, show, setShow, value }) => {
          return (
            <Box
              key={`setting-${key}`}
              sx={{
                width: 150,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
                mb: 2,
              }}
            >
              <Typography sx={{ flexShrink: 0 }}>{title}</Typography>
              <ColorPicker
                setting={key}
                show={show}
                setShow={setShow}
                value={{
                  color: value || undefined,
                }}
                onChange={(newValue) => {
                  storeColorDebounced(key, newValue.color || null);
                }}
              />
            </Box>
          );
        })}
      </Box>
    </>
  );
}

function ColumnColorOptions() {
  const userId = useUserId();
  const {
    broadcastColour,
    hybridColour,
    listenColour,
    eodColour,
    localColour,
    globalColour,
  } = useGridSettingsColors();

  const [showBroadcastColour, setShowBroadcastColour] = useState(false);
  const [showHybridColour, setShowHybridColour] = useState(false);
  const [showListenColour, setShowListenColour] = useState(false);
  const [showEodColour, setShowEodColour] = useState(false);
  const [showLocalColour, setShowLocalColour] = useState(false);
  const [showGlobalColour, setShowGlobalColour] = useState(false);

  const storeColorDebounced = useDebouncedCallback(
    (key: GridColorSetting["key"], value: string | null) => {
      gridSettingsUpsert(userId, key, value);
    },
    [],
    500,
  );

  const colorSettings = [
    {
      title: "Hybrid",
      key: "hybridColour",
      show: showHybridColour,
      setShow: setShowHybridColour,
      value: hybridColour,
    },
    {
      title: "Listen",
      key: "listenColour",
      show: showListenColour,
      setShow: setShowListenColour,
      value: listenColour,
    },

    {
      title: "Broadcast",
      key: "broadcastColour",
      show: showBroadcastColour,
      setShow: setShowBroadcastColour,
      value: broadcastColour,
    },
    {
      title: "Private",
      key: "localColour",
      show: showLocalColour,
      setShow: setShowLocalColour,
      value: localColour,
    },

    {
      title: "EOD",
      key: "eodColour",
      show: showEodColour,
      setShow: setShowEodColour,
      value: eodColour,
    },
    {
      title: "Global",
      key: "globalColour",
      show: showGlobalColour,
      setShow: setShowGlobalColour,
      value: globalColour,
    },
  ] satisfies GridColorSetting[];

  return (
    <>
      <Typography level="h4" fontWeight="bold" sx={{ mt: 0 }}>
        Status colours
      </Typography>
      <Box>
        {colorSettings.map(({ title, key, show, setShow, value }) => {
          return (
            <Box
              key={`setting-${key}`}
              sx={{
                display: "flex",
                width: 150,
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
                mb: 2,
              }}
            >
              <Typography sx={{ flexShrink: 0 }}>{title}</Typography>
              <ColorPicker
                setting={key}
                show={show}
                setShow={setShow}
                value={{
                  color: value || undefined,
                }}
                onChange={(newValue) => {
                  storeColorDebounced(key, newValue.color || null);
                }}
              />
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export function CollapsibleSettings() {
  const { isGridSettingsVisible, setIsGridSettingsVisible } =
    useGridSettingsVisibility();

  const userId = useUserId();
  const theme = useWebappTheme();

  const resetToDefault = () => {
    for (const key of objectKeys(initGridSettings)) {
      const value = initGridSettings[key];
      gridSettingsUpsert(userId, key, value);
    }
  };
  const scratchpadEnabled = useUserHasRole("scratchpad-beta");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    resetToDefault();
    setOpen(false);
  };
  return (
    <Drawer
      open={isGridSettingsVisible}
      onClose={() => setIsGridSettingsVisible(false)}
      hideBackdrop
      slotProps={{
        root: {
          sx: {
            zIndex: gridSettingsZIndex,
            width: {
              xs: "100%",
              md: `${gridSettingsWidth}px`,
            },
          },
        },
        content: {
          sx: {
            left: {
              xs: 0,
              md: sideBarWidth,
            },
            width: {
              xs: "100%",
              md: `${gridSettingsWidth}px`,
            },
            backgroundColor: "transparent",
            borderTopRightRadius: "1em",
            borderBottomRightRadius: "1em",
          },
        },
      }}
      sx={{
        "--Drawer-horizontalSize": {
          sm: `${gridSettingsWidth}px`,
          xs: "100%",
        },
        zIndex: gridSettingsZIndex,
      }}
    >
      <Sheet
        sx={{
          borderTopRightRadius: "md",
          borderBottomRightRadius: "md",
          p: 2,
          display: "flex",
          position: "relative",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          height: "100%",
          overflow: "auto",
          backgroundColor: theme.palette.background.level1,
        }}
      >
        <DialogTitle>Settings</DialogTitle>
        <ModalClose sx={{ position: "absolute", top: 10, right: 10 }} />
        <Divider />
        {isGridSettingsVisible && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <DateColumnSettings />
              <GridAppearance />
              <Button
                color="neutral"
                sx={{
                  display: "flex",
                  position: "relative",
                  top: 10,
                  width: "170px",
                }}
                onClick={handleOpen}
              >
                Restore default settings
              </Button>
              <ClickAwayListener
                disableReactTree
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={handleClose}
              >
                <BasePopup
                  open={open}
                  style={{
                    zIndex: gridSettingsResetConfirmationZIndex,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Sheet
                    variant="outlined"
                    sx={(theme) => ({
                      background: theme.palette.background.body,
                      padding: 2,
                      borderRadius: theme.radius.md,
                      zIndex: gridSettingsResetConfirmationZIndex + 1,
                    })}
                  >
                    <h2>Reset Settings</h2>
                    <Typography
                      sx={{
                        marginTop: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      Reset all grid settings back to default?
                    </Typography>
                    <Button
                      onClick={handleConfirm}
                      sx={{ marginRight: 1 }}
                      color="primary"
                    >
                      Reset
                    </Button>
                    <Button color="neutral" onClick={handleClose}>
                      Cancel
                    </Button>
                  </Sheet>
                </BasePopup>
              </ClickAwayListener>
            </Box>
            {/* hidden on mobile */}
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
                flexDirection: "row",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                <TimeSpreads />
                <FullMonthRow />
                <AlertsSettings />
                {scratchpadEnabled && <ScratchpadSettings />}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  marginRight: "20px",
                }}
              >
                <ColorOptions />
                <ColumnColorOptions />
              </Box>
            </Box>
          </Box>
        )}
      </Sheet>
    </Drawer>
  );
}
