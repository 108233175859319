import { useCurrentPageProducts } from "../../market-pages";
import {
  Box,
  Input,
  LinearProgress,
  Slider,
  Stack,
  Typography,
} from "@mui/joy";
import { ModalButtons } from "./Buttons";
import { GridModalContainer } from "./GridModal";
import { useCallback, useState } from "react";
import {
  columnWidth as defaultColumnWidth,
  maxColumnWidth,
  minColumnWidth,
} from "../../globals";
import { validColumnWidth } from "./columnSettingsHelpers";
import { client } from "../../../triplit/triplit";

export function ColumnWidthPreview({ previewWidth }: { previewWidth: number }) {
  const columnWidth = validColumnWidth(previewWidth);
  return (
    <Box display={"flex"} justifyContent={"center"} width={"100%"}>
      <Stack
        sx={{
          width: columnWidth,
          minWidth: columnWidth,
          maxWidth: columnWidth,
          ".MuiTypography-root": {
            color: "rgb(255,255,255)",
          },
          div: {
            width: "100%",
            display: "flex",
            margin: "1px",
            height: 20,
            overflow: "hidden",
          },
          "div:nth-of-type(1)": {
            background: "rgb(32, 56, 152)",
            justifyContent: "center",
            fontSize: 10,
          },
          "div:nth-of-type(2)": {
            background: "rgb(32, 56, 152)",
            justifyContent: "flex-end",
            fontSize: 9,
          },
          "div:nth-of-type(3)": {
            background: "rgb(255, 255, 255)",
          },
          "div:nth-of-type(4)": {
            background: "rgb(242, 242, 242)",
          },
        }}
      >
        <Box>
          <Typography mt={"-2px"}>BRT FT</Typography>
        </Box>
        <Box>
          <Typography mt={"-2px"} mr={"2px"}>
            $/bbl
          </Typography>
        </Box>
        <Box />
        <Box />
      </Stack>
    </Box>
  );
}

export function ColumnWidthSetting({
  columnWidth,
  setColumnWidth,
}: {
  columnWidth: number;
  setColumnWidth: React.Dispatch<React.SetStateAction<number>>;
}) {
  return (
    <Box mt={1} overflow={"hidden"}>
      <Box
        mt={2}
        mb={2}
        justifyContent={"center"}
        alignItems={"center"}
        display="flex"
        gap={2}
      >
        <Slider
          min={minColumnWidth}
          max={maxColumnWidth}
          step={1}
          valueLabelDisplay="on"
          value={columnWidth}
          onChange={(_, value) => setColumnWidth(Number(value))}
          sx={{
            maxWidth: "80%",
            ml: 2,
          }}
        />
        <Input
          type="number"
          value={columnWidth}
          onChange={(e) => setColumnWidth(Number(e.target.value))}
          sx={{
            width: 120,
            mr: 2,
          }}
        />
      </Box>
      <ColumnWidthPreview previewWidth={columnWidth} />
    </Box>
  );
}

export function SetAllColumnWidths() {
  const { results: products, fetching } = useCurrentPageProducts();

  const [columnWidth, setColumnWidth] = useState(defaultColumnWidth);

  const handleSave = useCallback(async () => {
    const width = validColumnWidth(columnWidth);
    const changes = products
      .map((product) => {
        if (product.columnType !== "product") return null;
        return client.update("pageProducts", product.id, (current) => {
          current.columnWidth = width;
        });
      })
      .filter(Boolean);

    await Promise.all(changes);
  }, [columnWidth, products]);

  if (fetching) return <LinearProgress />;

  return (
    <GridModalContainer
      panel="set-all-column-widths"
      body={
        <>
          <Typography>Set the width of every column on this page</Typography>
          <ColumnWidthSetting
            columnWidth={columnWidth}
            setColumnWidth={setColumnWidth}
          />
        </>
      }
      buttons={
        <ModalButtons onSave={handleSave} parentPanel="set-all-column-widths" />
      }
    />
  );
}
