import { useAtom } from "jotai";
import { useAuth0 } from "@auth0/auth0-react";
import { useDebouncedEffect, useWindowSize } from "@react-hookz/web";
import { sideBarWidth } from "../globals";
import { useState } from "react";
import {
  addChartButtonWidth,
  gridChartsAtom,
  maxChartWidth,
  minChartWidth,
} from "./constants";
import { client } from "../../triplit/triplit";
import { useQuery } from "@triplit/react";

function computeChartsWidth(
  resultsLength: number,
  containerWidth: number,
): number {
  const totalAvailableWidth =
    containerWidth - sideBarWidth - addChartButtonWidth;
  // max width is such that 3 charts (default number) take up 2/3 of the container width
  return Math.floor(
    Math.min(
      maxChartWidth,
      Math.max(totalAvailableWidth / resultsLength, minChartWidth),
    ),
  );
}

export function useChartsWidth(resultsLength: number): number {
  const windowSize = useWindowSize();
  const [chartsWidth, setChartsWidth] = useState(
    computeChartsWidth(resultsLength, windowSize.width),
  );

  useDebouncedEffect(
    () => {
      const newWidth = computeChartsWidth(resultsLength, windowSize.width);
      setChartsWidth(newWidth);
    },
    [resultsLength, windowSize.width],
    300,
  );

  return chartsWidth;
}

export const gridChartsQuery = client
  .query("gridCharts")
  .where("userId", "=", "$session.SESSION_USER_ID");

export function useCharts() {
  const { user } = useAuth0();
  const userId = user?.sub;

  if (!userId) throw new Error("No userId in Charts");

  const [chartData, setChartData] = useAtom(gridChartsAtom);
  //populates local cache which is looked up during update tick
  useQuery(client, gridChartsQuery);

  return { results: chartData, userId };
}
