import dayjs from "dayjs";

export function formatDate(date: string | Date) {
  return dayjs(date).format("DD-MMM-YYYY HH:mm:ss");
}

export function sortAlphabeticallyEarlyMatch(
  word1: string,
  word2: string,
  query: string,
) {
  const indexA = word1.toLowerCase().indexOf(query.toLowerCase());
  const indexB = word2.toLowerCase().indexOf(query.toLowerCase());

  if (indexA !== indexB) {
    return indexA - indexB;
  }

  return word1.localeCompare(word2);
}

export function arraysHaveSameItems(
  arr1: (string | number)[] | undefined,
  arr2: (string | number)[] | undefined,
) {
  if (arr1?.length !== arr2?.length) return false;

  if (!arr1?.length && !arr2?.length) return true;

  const sortedArr1 = arr1?.slice().sort();
  const sortedArr2 = arr2?.slice().sort();

  return sortedArr1?.every((item, index) => item === sortedArr2?.[index]);
}

export function humanizeRole(role: unknown) {
  if (typeof role !== "string") return "";
  return role
    .replace(/-/g, " ")
    .replace(/\b\w/g, (match) => match.toUpperCase());
}

export function diffedData<T>(
  dirtyFields: Partial<Record<keyof T, unknown>>,
  data: T,
): Partial<T> {
  return (Object.keys(dirtyFields) as Array<keyof T>).reduce(
    (acc: Partial<T>, key: keyof T) => {
      if (dirtyFields[key]) {
        acc[key] = data[key];
      }
      return acc;
    },
    {},
  );
}

/**
 * Like Object.keys, but unsound in exchange for more convenience.
 *
 * Casts the result of Object.keys to the known keys of an object type,
 * even though JavaScript objects may contain additional keys.
 *
 * Only use this function when you know/control the provenance of the object
 * you're iterating, and can verify it contains exactly the keys declared
 * to the type system.
 *
 * Example:
 * ```
 * const o = {x: "ok", y: 10}
 * o["z"] = "UNTRACKED_KEY"
 * const safeKeys = Object.keys(o)
 * const unsafeKeys = objectKeys(o)
 * ```
 * => const safeKeys: string[]
 * => const unsafeKeys: ("x" | "y")[] // Missing "z"
 */
export const objectKeys = Object.keys as <T>(obj: T) => Array<keyof T>;

/**
 * The type of a single item in `Object.entries<T>(value: T)`.
 *
 * Example:
 * ```
 * interface T {x: string; y: number}
 * type T2 = ObjectEntry<T>
 * ```
 * => type T2 = ["x", string] | ["y", number]
 */
export type ObjectEntry<T> = {
  // Without Exclude<keyof T, undefined>, this type produces `ExpectedEntries | undefined`
  // if T has any optional keys.
  [K in Exclude<keyof T, undefined>]: [K, T[K]];
}[Exclude<keyof T, undefined>];

/**
 * Like Object.entries, but returns a more specific type which can be less safe.
 *
 * Example:
 * ```
 * const o = {x: "ok", y: 10}
 * const unsafeEntries = Object.entries(o)
 * const safeEntries = objectEntries(o)
 * ```
 * => const unsafeEntries: [string, string | number][]
 * => const safeEntries: ObjectEntry<{
 *   x: string;
 *   y: number;
 * }>[]
 *
 * See `ObjectEntry` above.
 *
 * Note that Object.entries collapses all possible values into a single union
 * while objectEntries results in a union of 2-tuples.
 */
export const objectEntries = Object.entries as <T>(
  o: T,
) => Array<ObjectEntry<T>>;

export const objectFromEntries = <T>(
  entries: Array<[keyof T, T[keyof T]]>,
): T => {
  return Object.fromEntries(entries) as T;
};

export function structuredCloneWithIgnore(obj: unknown) {
  return JSON.parse(
    JSON.stringify(obj, function replacer(_key, value) {
      if (
        typeof value === "function" ||
        value instanceof Element ||
        value instanceof Window
      ) {
        return undefined;
      }
      return value;
    }),
  );
}

export const ukDateFormatter = new Intl.DateTimeFormat("en-GB", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

export function sanitizeString(str: string) {
  return str
    .replace(/[^a-zA-Z0-9]/g, "")
    .toLowerCase()
    .trim();
}

export function setDifference<T>(setA: Set<T>, setB: Set<T>): T[] {
  return Array.from(setA).filter((x) => !setB.has(x));
}

export function formatUnknownToString(v: unknown) {
  if (v instanceof Set) {
    return Array.from(v).join(", ");
  }
  if (v instanceof Date) {
    return Intl.DateTimeFormat("en-GB").format(v);
  }
  if (v instanceof Map) {
    return Array.from(v).join(", ");
  }
  if (Array.isArray(v) && v.every((i) => typeof i === "string")) {
    return v.join(", ");
  }
  if (v instanceof Object) {
    return JSON.stringify(v);
  }
  if (v === null || v === undefined) {
    return "";
  }
  return (v as string).toString() || "";
}

export function includes<T extends U, U>(
  arr: ReadonlyArray<T>,
  searchElement: U,
): searchElement is T {
  return arr.includes(searchElement as T);
}
