import type { useApolloClient } from "@apollo/client";
import type { ColDef, Column, GridApi, ColGroupDef } from "ag-grid-community";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { generateNKeysBetween } from "fractional-indexing";
import type {
  ProductFragmentGridFragment,
  Product_Artis_Type_Enum,
  User_Right_Enum,
} from "../__generated__/gql/graphql";
import type { TPageProduct } from "../triplit/schema";
import { defaultCurvesUom, exchangeCurvesUom } from "./market-grid/uomData";
import { pointerWithin, rectIntersection } from "@dnd-kit/core";
import type { TColumnHeaderProps } from "./market-grid/AgGridComponents";
import {
  relativeRowToRowId,
  relativeRowToRowType,
  rowStringToCode,
} from "./market-grid/periodHelpers";

dayjs.extend(utc);
dayjs.extend(timezone);

export function colParams(
  column: Column | null | undefined,
): TColumnHeaderProps | undefined {
  if (!column) return undefined;
  return column?.getUserProvidedColDef()?.headerComponentParams;
}

export function resolveUom(
  artisType: Product_Artis_Type_Enum,
  source: string,
  productId: string,
  selector: string,
  cellLabel: string,
) {
  const cannedOrSourced = ["canned", "sourced"].includes(artisType);

  if (selector === "fv") {
    return cannedOrSourced
      ? (exchangeCurvesUom[source]?.[productId]?.[selector] ?? cellLabel)
      : cellLabel;
  }
  return cannedOrSourced
    ? (defaultCurvesUom[selector] ?? cellLabel)
    : cellLabel;
}

export function uomToLabel({
  artisType,
  source,
  productId,
  selector,
  cellLabel,
}: {
  artisType: Product_Artis_Type_Enum;
  source: string;
  productId: string;
  selector: string;
  cellLabel: string;
}) {
  const resolvedUom = resolveUom(
    artisType,
    source,
    productId,
    selector,
    cellLabel,
  );

  const parsedUom = () => {
    switch (resolvedUom) {
      case "usd_bbl":
        return "$/bbl";
      case "usc_bbl":
        return "c/bbl";
      case "usd_ton":
        return "$/ton";
      case "usd_gal":
        return "$/gal";
      case "usc_gal":
        return "c/gal";
      case "percent":
        return "%";
      case "usd_thousands":
        return "$'000s";
      case "kg_m3":
        return "kg/m³";
      case "days":
        return "days";
      case "usd_day":
        return "$/day";
      case "usd_mmbtu":
        return "$/mmbtu";
      case "usdmm":
        return "$mm";
      case "p_thm":
        return "p/thm";
      case "none":
        return "Misc";
      case "ws_and_usd_ton":
        return "ws & $/ton";
      default:
        return resolvedUom;
    }
  };

  return parsedUom()?.replace(/000S/, "000s") ?? selector;
}

export function defaultFieldNameSelector(artisType: Product_Artis_Type_Enum) {
  switch (artisType) {
    case "eod":
    case "customer_curve":
      return "value";
    default:
      return "fv";
  }
}

export function fieldNameSelectorToLabel(fieldName: string) {
  return fieldName.replace("-", " ").toUpperCase();
}

export function londonDate() {
  const londonDateTime = dayjs().tz("Europe/London");
  const date = dayjs(londonDateTime).subtract(1, "hour");
  return date;
}

export function productName({
  name,
  description,
  packageByPackage,
}: {
  name: string;
  description?: string | null;
  packageByPackage: { name: string };
}) {
  const displayName = name === description ? name : `${name} (${description})`;
  return `${displayName}: ${packageByPackage.name}`;
}

export function productNameComparator(a: string, b: string) {
  const [a1, a2] = a.split(":");
  const [b1, b2] = b.split(":");
  const majorCompare = a1.localeCompare(b1);
  const minorCompare = a2.localeCompare(b2);
  return majorCompare === 0 ? minorCompare : majorCompare;
}

export function sortByStringCaseInsensitive<T>(k: keyof T, coll: T[]) {
  return coll.sort((a, b) => {
    const aLower = (a[k] || "").toString().toLowerCase();
    const bLower = (b[k] || "").toString().toLowerCase();
    return aLower.localeCompare(bLower);
  });
}

export function compareStrings(a: string, b: string) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

// *
// * Extracts dependencies from a set of product IDs
// *
// * @param products - a dictionary of products with product_configs containing formulas
// * @param lookupIds - The set of product IDs to extract dependencies from
// * @returns The set of unique dependencies
// *
export function extractFormulaDependencies(
  products: {
    [key: string]: { product_configs: { formula: string | null }[] };
  },
  lookupIds: Set<string>,
): Set<string> {
  const formulaPattern = /\[(.*?)\]/g;

  // The function to extract unique dependencies from a given set of product IDs
  function deps(lookupIds: Set<string>): Set<string> {
    const result = new Set<string>();

    for (const id of lookupIds) {
      const product = products[id];

      // Safely skip if the product does not exist or has no configurations
      if (!product || !product.product_configs) continue;

      // Process each configuration
      for (const config of product.product_configs) {
        const { formula } = config;

        if (!formula) continue;

        let match: RegExpExecArray | null;
        while (true) {
          match = formulaPattern.exec(formula);
          if (match === null) break;
          result.add(match[1]);
        }
      }
    }

    return result;
  }

  const dependencies = new Set(
    [...lookupIds].filter((id) =>
      Object.prototype.hasOwnProperty.call(products, id),
    ),
  );
  let toExplore = new Set([...lookupIds]);

  while (toExplore.size > 0) {
    const nextToExplore = deps(toExplore);
    toExplore = new Set<string>();

    for (const id of nextToExplore) {
      if (!dependencies.has(id)) {
        toExplore.add(id);
        dependencies.add(id);
      }
    }
  }

  return dependencies;
}

export function insert<T>(coll: T[], index: number, element: T): T[] {
  return [...coll.slice(0, index), element, ...coll.slice(index)];
}

export function generateIdxs({
  api,
  currentPageProducts,
  amount,
}: {
  api?: GridApi | null;
  currentPageProducts: TPageProduct[];
  amount: number;
}) {
  if (!currentPageProducts || typeof amount !== "number") {
    console.warn("Invalid arguments for generateIdxs", {
      api,
      currentPageProducts,
      amount,
    });
    return [];
  }

  if (!api) {
    return generateNKeysBetween(null, null, amount);
  }

  const columns = api.getColumns();

  // Ensure columns are available
  if (!columns) {
    console.warn("Columns are not available");
    return generateNKeysBetween(null, null, amount);
  }

  const focusedCell = api.getFocusedCell();
  const currentlyFocusedId = colParams(focusedCell?.column)?.gridId;

  // Ensure that a focused cell and its ID are available
  if (!focusedCell || !currentlyFocusedId) {
    return generateNKeysBetween(null, null, amount);
  }

  const currentlyFocusedIndex = columns.findIndex(
    (c) => colParams(c)?.gridId === currentlyFocusedId,
  );

  // Ensure that the currently focused index is valid
  if (currentlyFocusedIndex === -1) {
    console.warn("Invalid currently focused index");
    return generateNKeysBetween(null, null, amount);
  }

  const previousColumn = columns[currentlyFocusedIndex - 1];

  const previousProduct = currentPageProducts.find(
    (p) => p.id === colParams(previousColumn)?.gridId,
  );

  const currentlyFocusedProduct = currentPageProducts.find(
    (p) => p.id === currentlyFocusedId,
  );

  const previousIdx = previousProduct?.idx || null;
  const currentIdx = currentlyFocusedProduct?.idx;

  // Ensure that currentIdx is defined
  if (currentIdx === undefined) {
    console.warn("Invalid currentIdx");
    return generateNKeysBetween(null, null, amount);
  }

  console.log("previousIdx:", previousIdx, "currentIdx:", currentIdx);

  if (previousIdx === currentIdx) {
    return generateNKeysBetween(currentIdx, currentIdx + 1, amount);
  }

  if (previousIdx !== null && previousIdx >= currentIdx) {
    console.warn(
      "Invalid index comparison: previousIdx should be less than currentIdx",
    );
    return generateNKeysBetween(currentIdx, currentIdx + 1, amount);
  }

  if (previousIdx !== null && previousIdx < currentIdx) {
    return generateNKeysBetween(previousIdx, currentIdx, amount);
  }
  return generateNKeysBetween(currentIdx, previousIdx, amount);
}

function deepNonFunctionCompare(obj1: unknown, obj2: unknown) {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;
    for (let i = 0; i < obj1.length; i++) {
      if (!deepNonFunctionCompare(obj1[i], obj2[i])) return false;
    }
    return true;
  }

  if (Array.isArray(obj1) || Array.isArray(obj2)) {
    return false; // one is array and the other is not
  }

  const keys1 = Object.keys(obj1).filter(
    (key) => typeof (obj1 as Record<string, unknown>)[key] !== "function",
  );
  const keys2 = Object.keys(obj2).filter(
    (key) => typeof (obj2 as Record<string, unknown>)[key] !== "function",
  );

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (
      !Object.prototype.hasOwnProperty.call(obj2, key) ||
      !deepNonFunctionCompare(
        (obj1 as Record<string, unknown>)[key],
        (obj2 as Record<string, unknown>)[key],
      )
    ) {
      return false;
    }
  }

  return true;
}

type TColDefsCompare = ColDef[] | ColGroupDef[] | undefined | null;

// colDefs returned by the ag-grid api are very fun to work with.
// In fact, if your initial colDef has headerComponentParams that is undefined,
// the api spits back `headerComponentParams: {}`. This problem happens for nested keys as well.
// Example: headerComponentParams: { a: {b: undefined, c: undefined} } becomes headerComponentParams: {a: {}}.
// So we need to normalize the colDefs before comparing them.
// Amazing.
function parseHeaderComponentParams(colDef: ColDef | ColGroupDef) {
  if (!("field" in colDef) || !("headerComponentParams" in colDef))
    throw new Error(
      "ColDef missing field or headerComponentParams during comparison",
    );

  const { field, headerComponentParams } = colDef;

  const adjustedParams: Record<string, unknown> = {};

  // we want to add the param key value pairs only if the value is not undefined.
  // the check extends to 1 nested level - the nested `for` - as we have top level values that are objects
  for (const k in headerComponentParams) {
    if (headerComponentParams[k]) {
      if (typeof headerComponentParams[k] === "object") {
        adjustedParams[k] = {};

        for (const j in headerComponentParams[k]) {
          if (headerComponentParams[k][j]) {
            // @ts-expect-error - the unknown record value type is making this scream
            adjustedParams[k][j] = headerComponentParams[k][j];
          }
        }
      } else {
        adjustedParams[k] = headerComponentParams[k];
      }
    }
  }

  return {
    field,
    width: colDef.width,
    headerComponentParams: adjustedParams,
  };
}

export function compareGeneratedColDefsToApiColDefs(
  colDefs1: TColDefsCompare,
  colDefs2: TColDefsCompare,
) {
  if (!colDefs1 || !colDefs2) return false;

  const comparisonKeysColDefs1 = colDefs1.map(parseHeaderComponentParams);
  const comparisonKeysColDefs2 = colDefs2.map(parseHeaderComponentParams);

  return deepNonFunctionCompare(comparisonKeysColDefs1, comparisonKeysColDefs2);
}

export function isListOnlyPermissions(
  permissions:
    | ProductFragmentGridFragment["packageByPackage"]["permissions"]
    | undefined
    | null,
) {
  return permissions?.length === 1 && permissions[0].permission === "list";
}

export function isGivenPermission(
  permissions:
    | ProductFragmentGridFragment["packageByPackage"]["permissions"]
    | undefined
    | null,
  permission: User_Right_Enum,
) {
  return permissions?.find((entry) => entry.permission === permission);
}

export function evictNoLongerPermissionedProductsFromCache({
  apolloClient,
  pageProducts,
  products,
}: {
  apolloClient: ReturnType<typeof useApolloClient>;
  pageProducts: TPageProduct[];
  products: readonly ProductFragmentGridFragment[] | undefined | null;
}) {
  for (const pageProduct of pageProducts) {
    const productId = pageProduct.productId;
    const isPermissioned = products?.find(
      (product) => product.id === productId,
    );

    if (!isPermissioned) {
      apolloClient.cache.evict({
        id: `product:${productId}`,
      });
    }
  }
}

export function collisionDetection(args: Parameters<typeof pointerWithin>[0]) {
  // First, let's see if there are any collisions with the pointer
  const pointerCollisions = pointerWithin(args);

  // Collision detection algorithms return an array of collisions
  if (pointerCollisions.length > 0) {
    return pointerCollisions;
  }

  // If there are no collisions with the pointer, return rectangle intersections
  return rectIntersection(args);
}

export function parseLimitRef(limitRef: string) {
  const parts = limitRef.split(":");
  const relativeRow = parts[1];
  const period = relativeRowToRowId[relativeRow];
  const type = relativeRowToRowType(relativeRow);

  if (!period) {
    return { columnId: null, rowId: null };
  }

  return {
    columnId: parts[0],
    rowId: rowStringToCode(period),
    rowType: type,
  } as const;
}
