import Dropdown from "@mui/joy/Dropdown";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import type { SxProps } from "@mui/joy/styles/types/theme";
import { LuMoreVertical } from "react-icons/lu";

export default function IconButtonMenu({
  sx,
  children,
  name,
}: {
  sx?: SxProps;
  children?: React.ReactNode;
  name?: string;
}) {
  return (
    <Dropdown>
      <MenuButton
        sx={sx}
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: "plain", color: "neutral", name: name } }}
      >
        <LuMoreVertical />
      </MenuButton>
      <Menu
        sx={{
          overflow: "visible",
        }}
      >
        {children}
      </Menu>
    </Dropdown>
  );
}
