import * as R from "remeda";
import { months } from "../globals";

export const monthCodeLookup = {
  JAN: "F",
  FEB: "G",
  MAR: "H",
  APR: "J",
  MAY: "K",
  JUN: "M",
  JUL: "N",
  AUG: "Q",
  SEP: "U",
  OCT: "V",
  NOV: "X",
  DEC: "Z",
} as const;

export const orderedMonthCodes = [
  "F",
  "G",
  "H",
  "J",
  "K",
  "M",
  "N",
  "Q",
  "U",
  "V",
  "X",
  "Z",
] as const;

type TRelativeRowIdLookup = {
  [key: string]: string;
};

const date = new Date();

const monthCodes = Object.keys(monthCodeLookup);
const quarterMap = ["Q1", "Q2", "Q3", "Q4"];
const halfMap = ["H1", "H2"];

const currentMonthNum = date.getMonth();
const currentYearNum = date.getFullYear() % 100;

type LookupParams = {
  periods: number;
  prefix: string;
  getIndex: (i: number) => number;
  getLabel: (index: number) => string;
  getYear: (i: number) => number;
  spacing?: boolean;
};

function generateSeasonLookups({
  currentMonthNum,
  currentYearNum,
  periods,
}: {
  currentMonthNum: number;
  currentYearNum: number;
  periods: number;
}) {
  const getSeason = (index: number): string => {
    return index % 2 === 0 ? "Sum" : "Win";
  };

  const lookups: { [key: string]: string } = {};

  for (let i = 0; i < periods; i++) {
    const season = getSeason(i);
    const year = currentYearNum + Math.floor(i / 2);
    lookups[`REL-S${i}`] = `${season} ${String(year).slice(-2)}`;
  }

  return lookups;
}

function generateAdhocLookups({ periods }: { periods: number }) {
  const lookups: { [key: string]: string } = {};

  for (let i = 0; i < periods; i++) {
    lookups[`REL-A${i}`] = `Adhoc${i + 1}`;
  }

  return lookups;
}

export function generateLookups({
  periods,
  prefix,
  getIndex,
  getLabel,
  getYear,
  spacing = false,
}: LookupParams) {
  return R.pipe(
    R.range(0, periods),
    R.map((i) => ({
      key: `${prefix}${i}`,
      index: getIndex(i),
      year: getYear(i),
    })),
    R.map(({ key, index, year }) => ({
      [key]: `${getLabel(index)}${spacing ? " " : ""}${String(
        year % 100,
      ).padStart(2, "0")}`,
    })),
    R.mergeAll,
  ) as TRelativeRowIdLookup;
}

export const monthLookups = generateLookups({
  periods: months,
  prefix: "REL-M",
  getIndex: (i) => (currentMonthNum + i) % 12,
  getLabel: (index) => monthCodes[index],
  getYear: (i) => currentYearNum + Math.floor((currentMonthNum + i) / 12),
});

export const quarterLookups = generateLookups({
  periods: 12,
  prefix: "REL-Q",
  getIndex: (i) => Math.floor((currentMonthNum + i * 3) / 3) % 4,
  getLabel: (index) => quarterMap[index],
  getYear: (i) => currentYearNum + Math.floor((currentMonthNum + i * 3) / 12),
  spacing: true,
});

export const halfLookups = generateLookups({
  periods: 8,
  prefix: "REL-H",
  getIndex: (i) => (Math.floor(currentMonthNum / 6) + i) % 2,
  getLabel: (index) => halfMap[index],
  getYear: (i) =>
    currentYearNum + Math.floor((Math.floor(currentMonthNum / 6) + i) / 2),
  spacing: true,
});

export const seasonLookups = generateSeasonLookups({
  currentMonthNum,
  currentYearNum,
  periods: 7,
});
export const yearLookups = generateLookups({
  periods: 4,
  prefix: "REL-C",
  getIndex: (i) => i,
  getLabel: () => "Cal",
  getYear: (i) => currentYearNum + i,
  spacing: true,
});

export const adhocLookups = generateAdhocLookups({ periods: 20 });

export const relativeRowToRowId = {
  ...monthLookups,
  ...quarterLookups,
  ...halfLookups,
  ...seasonLookups,
  ...yearLookups,
  ...adhocLookups,
};
console.log("relativeRowToRowId", relativeRowToRowId);

export const rowIdToRelativeRow = R.invert(relativeRowToRowId);

console.log("rowIdToRelativeRow", rowIdToRelativeRow);
