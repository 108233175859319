import { unique } from "remeda";
import type { TFormatTypes, TRangeSelection } from "./FormatCell";
import { rowIdToRelativeRow, type validColumnSettings } from "..";
import { atom, useSetAtom } from "jotai";
import { client } from "../../../triplit/triplit";
import { useEntity, useQuery } from "@triplit/react";
import type { TConditionalFormattingRules } from "../../../triplit/schema";
import { getPeriodByRowId } from "./ConditionalFormatting/helpers";
import { useGridApi } from "../../../shared/hooks";
import type { GridApi } from "ag-grid-community";
import { useDeepCompareEffect, useDeepCompareMemo } from "@react-hookz/web";
import {
  compressJsonString,
  parsePageFormatting,
  type TPageFormatting,
} from "../../../utils/compressedStringify";
import { useState } from "react";
import { store } from "../../sharedHooks";
import { getProcessedSelectedCellsByRange } from "../../../tableUtils";

export const initialFormattingAndSettingsAtom = atom<{
  formatting: ReturnType<typeof usePageFormatting> | null;
  settings: ReturnType<typeof validColumnSettings> | null;
}>({ formatting: null, settings: null });
initialFormattingAndSettingsAtom.debugLabel =
  "initialFormattingAndSettingsAtom";

export function modalTypeToHighlightType(
  type: string,
): "cellHighlights" | "columnHighlights" | "periodHighlights" {
  switch (type) {
    case "range":
      return "cellHighlights";
    case "column":
      return "columnHighlights";
    case "period":
      return "periodHighlights";
    default:
      return "cellHighlights";
  }
}

export function getCellIds({
  api,
  type,
  ranges,
}: {
  api: GridApi | null;
  type: TFormatTypes;
  ranges: TRangeSelection;
}) {
  const cellIds: string[] = [];

  if (type === "range") {
    if (!ranges.length || !ranges[0].length) return [];

    ranges.map((range) => {
      range.map((cell) => {
        const colId = cell.columnId;
        const period = getPeriodByRowId(api, cell.rowId);

        if (colId && period) {
          const id = `${colId}-${rowIdToRelativeRow[period]}`;
          if (!cellIds.includes(id)) {
            cellIds.push(`${colId}-${rowIdToRelativeRow[period]}`);
          }
        }
      });
    });
  }

  if (type === "column") {
    ranges.map((range) => {
      range.map((cell) => {
        const colId = cell.columnId;
        if (colId && !cellIds.includes(colId)) {
          cellIds.push(colId);
        }
      });
    });
  }

  if (type === "period") {
    ranges.map((range) => {
      range.map((cell) => {
        const id = cell.rowId;
        if (!cellIds.includes(id)) {
          cellIds.push(id);
        }
      });
    });
  }

  return unique(cellIds);
}

export const pageFormattingAtom = atom<ReturnType<
  typeof usePageFormatting
> | null>(null);
pageFormattingAtom.debugLabel = "pageFormattingAtom";

export const conditionalFormattingRulesAtom = atom<
  ReturnType<typeof useConditionalFormattingRules>["formatted"] | null
>(null);
conditionalFormattingRulesAtom.debugLabel = "conditionalFormattingRulesAtom";

export function conditionalFormattingKey(columnId: string, rowId: string) {
  return `${columnId}-${rowId}`;
}

// Create an object where the key is `columnId-rowId` and the value is an array of rules based on the ones that have the same columnId and rowId.
function formatConditionalRules(rules: TConditionalFormattingRules[]) {
  const formatted: Record<string, TConditionalFormattingRules[]> = {};

  for (const rule of rules) {
    const key = conditionalFormattingKey(rule.columnId, rule.rowId);
    if (!formatted[key]) {
      formatted[key] = [];
    }

    formatted[key].push(rule);
  }

  return formatted;
}

export function useConditionalFormattingRules(pageId: string) {
  const setConditionalFormatting = useSetAtom(conditionalFormattingRulesAtom);
  const { results } = useQuery(
    client,
    client
      .query("conditionalFormattingRules")
      .where("pageId", "=", pageId)
      .order("priorityIdx", "ASC"),
  );

  const rules = results || [];
  const formatted = formatConditionalRules(rules);

  const { getApi } = useGridApi();
  const gridApi = getApi();

  useDeepCompareEffect(() => {
    setConditionalFormatting(formatted);
    if (gridApi?.isDestroyed()) return;
    console.log("refreshing cells in useConditionalFormattingRules");
    gridApi?.refreshCells({
      force: true,
    });
  }, [
    formatted,
    setConditionalFormatting,
    gridApi?.refreshCells,
    gridApi?.isDestroyed,
  ]);

  return {
    formatted,
    rules,
  };
}

export function cancelCellFormatting(
  pageId: string,
  initialFormatting: ReturnType<typeof usePageFormatting>,
) {
  setCellFormatting(pageId, "cellHighlights", initialFormatting.cellHighlights);
  setCellFormatting(
    pageId,
    "columnHighlights",
    initialFormatting.columnHighlights,
  );
  setCellFormatting(
    pageId,
    "periodHighlights",
    initialFormatting.periodHighlights,
  );
}

export async function setCellFormatting(
  pageId: string,
  type: "cellHighlights" | "columnHighlights" | "periodHighlights",
  highlights: TPageFormatting,
) {
  const compressedHighlights = await compressJsonString(
    JSON.stringify(highlights),
  );
  client.update("pages", pageId, (p) => {
    p[type] = compressedHighlights;
  });
}

export function usePageFormatting(pageId: string) {
  const { getApi } = useGridApi();
  const gridApi = getApi();

  const setPageFormatting = useSetAtom(pageFormattingAtom);

  const { result: page, fetchingLocal: fetching } = useEntity(
    client,
    "pages",
    pageId,
    {
      localOnly: true,
    },
  );

  const [formatting, setFormatting] = useState<
    Awaited<ReturnType<typeof parsePageFormatting>>
  >({
    cellHighlights: {},
    columnHighlights: {},
    periodHighlights: {},
  });

  useDeepCompareMemo(() => {
    if (page) parsePageFormatting(page).then(setFormatting);
  }, [page]);

  useDeepCompareEffect(() => {
    if (gridApi?.isDestroyed()) return;

    setPageFormatting({
      cellHighlights: formatting.cellHighlights,
      columnHighlights: formatting.columnHighlights,
      periodHighlights: formatting.periodHighlights,
      fetching,
    });

    console.log("refreshing cells in usePageFormatting");
    gridApi?.refreshCells({
      force: true,
    });
  }, [setPageFormatting, gridApi, formatting]);

  return {
    cellHighlights: formatting.cellHighlights,
    columnHighlights: formatting.columnHighlights,
    periodHighlights: formatting.periodHighlights,
    fetching,
  };
}

export function toggleCellBold(api: GridApi | null, pageId: string) {
  if (!api || !pageId) return;

  const pageFormatting = store.get(pageFormattingAtom);
  const selectedRange = getProcessedSelectedCellsByRange({ api });
  const cellIds = getCellIds({ api, ranges: selectedRange, type: "range" });

  const highlightType = modalTypeToHighlightType("range");
  const highlights = pageFormatting?.[highlightType] || {};

  for (const cellId of cellIds) {
    const highlight = highlights[cellId] || {};
    const bold = highlight.boldText || false;

    highlights[cellId] = {
      color: highlight?.color || null,
      boldText: !bold,
      invertTextColor: highlight?.invertTextColor || false,
    };
  }

  setCellFormatting(pageId, "cellHighlights", highlights);
}
