import { client } from "../../triplit/triplit";
import { useQuery } from "@triplit/react";
import toast from "react-hot-toast";
import type { TViewedAnnouncement } from "../../triplit/schema";
import type { NewFeature } from "../../../src/__generated__/sanity/graphql-request";

export function useViewedAnnouncements(userId: string) {
  const emptyResults = new Map<string, TViewedAnnouncement>();

  const { results } = useQuery(
    client,
    client.query("viewedAnnouncements").where("userId", "=", userId),
  );

  return { results: results ?? emptyResults };
}

export async function insertViewedAnnouncement(
  userId: string,
  announcement: TViewedAnnouncement,
) {
  try {
    await client.transact(async (tx) => {
      await tx.insert("viewedAnnouncements", {
        userId: userId,
        announcementId: announcement.announcementId,
        title: announcement.title,
        viewedAt: announcement.viewedAt,
      });
    });

    console.log("Inserted new viewed announcement", announcement.title);
  } catch (error) {
    toast.error(
      "An error occurred while saving the viewed announcement. Please try again.",
      {
        position: "bottom-left",
      },
    );
    throw error;
  }
}

export function hasUnviewedAnnouncements(
  announcements: NewFeature[] = [],
  viewedAnnouncements: Map<string, TViewedAnnouncement> = new Map<
    string,
    TViewedAnnouncement
  >(),
): boolean {
  if (!Array.isArray(announcements) || !(viewedAnnouncements instanceof Map)) {
    console.error(
      "Invalid input: announcements or viewedAnnouncements is not in the expected format",
    );
    return false;
  }

  return announcements.some((announcement) => {
    if (!announcement?._id) {
      console.warn("Announcement has no _id, skipping:", announcement);
      return false;
    }

    return !Array.from(viewedAnnouncements.values()).some(
      (viewed) => viewed?.announcementId === announcement._id,
    );
  });
}
