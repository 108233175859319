import { Outlet, createRootRoute } from "@tanstack/react-router";
import { AuthorizedApolloProvider } from "./auth";
import type { TEnv } from "./globals";
import { ErrorBoundary } from "react-error-boundary";

type SearchParams = {
  env?: TEnv;
};

export const rootRoute = createRootRoute({
  errorComponent: () => (
    <div>
      <h1>Unexpected error!</h1>
    </div>
  ),
  component: () => (
    <AuthorizedApolloProvider>
      <ErrorBoundary
        fallback={
          <div>
            <h1>Something went wrong...</h1>
          </div>
        }
      >
        <Outlet />
      </ErrorBoundary>
    </AuthorizedApolloProvider>
  ),
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    // these are auth0 params that we don't want to keep in the url
    search.code = undefined;
    search.state = undefined;
    return {
      ...search,
    };
  },
});
