import Link, { type LinkProps } from "@mui/joy/Link";
import { Link as RouterLink, createLink } from "@tanstack/react-router";
import { forwardRef } from "react";

const JoyLink = forwardRef<
  HTMLAnchorElement,
  LinkProps & { children: React.ReactNode }
>(({ children, ...props }, ref) => {
  return (
    <Link component={RouterLink} {...props} ref={ref}>
      {children}
    </Link>
  );
});

JoyLink.displayName = "JoyLink";

const JoyRouterLink = createLink(JoyLink);

export default JoyRouterLink;
