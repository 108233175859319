import type { ColDef, Column, GridApi, IRowNode } from "ag-grid-community";
import type { TRangeSelection } from "./webapp/sharedHooks";
import type { TCell } from "./webapp/calculations-worker/sharedStores";

export function colDefId<T>(colDef: ColDef<T>): ColDef<T> {
  return {
    ...colDef,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  };
}

export function redrawRowsByIds(api: GridApi | null, ids: string[]) {
  if (!api) throw new Error("No api passed to redrawRowsByIds");
  const rowNodes = ids.map((id) => api.getRowNode(id)).filter(Boolean);
  api.redrawRows({ rowNodes });
}

export type TRangeCell = {
  productId?: string | "";
  columnId: string;
  rowId: string;
  rowIndex: number;
  headerName: string;
};

export function getCurrentCellValue(cell: TRangeCell, api: GridApi) {
  const rowNode = api.getRowNode(cell.rowId);
  if (!rowNode) return;
  return api.getCellValue<TCell>({ rowNode, colKey: cell.columnId });
}

export function getCurrentCellValueById(
  columnId: string | Column<TCell>,
  rowId: string,
  api: GridApi,
) {
  const rowNode = api.getRowNode(rowId);
  if (!rowNode) return;
  return api.getCellValue<TCell>({ rowNode, colKey: columnId });
}

export function selectedCellsByRange<T, S>(
  api: GridApi<T>,
  store: S,
  processCell: (params: {
    column: Column<T>;
    cellValue: string | number | undefined;
    rowNode: IRowNode<T>;
    rangeIdx: number;
    store: S;
  }) => unknown,
) {
  const cellRanges = api.getCellRanges();
  if (!cellRanges) return store; // Handle case where ranges are null or undefined

  for (const [idx, cellRange] of cellRanges.entries()) {
    if (cellRange.columns.length === 0) {
      throw new Error("No columns found in range");
    }

    const { startRow, endRow } = cellRange;
    if (!startRow || !endRow) {
      throw new Error("No rows found in range");
    }

    const startRowIndex = Math.min(startRow.rowIndex, endRow.rowIndex);
    const endRowIndex = Math.max(startRow.rowIndex, endRow.rowIndex);

    for (const rowIndex of Array.from(
      { length: endRowIndex - startRowIndex + 1 },
      (_, i) => startRowIndex + i,
    )) {
      for (const column of cellRange.columns) {
        const rowNode = api.getDisplayedRowAtIndex(rowIndex);
        if (!rowNode) continue;

        const columnId = column.getColId();
        const cellValue = api.getCellValue({ rowNode, colKey: columnId });

        processCell({
          column,
          cellValue,
          rowNode,
          store,
          rangeIdx: idx,
        });
      }
    }
  }

  return store;
}

export function getProcessedSelectedCellsByRange({
  api,
}: {
  api: GridApi | null;
}) {
  if (!api) return [];

  return selectedCellsByRange(
    api,
    [] as TRangeSelection,
    ({ column, rowNode, store, rangeIdx }) => {
      if (
        !column ||
        !rowNode.id ||
        rowNode?.rowIndex === undefined ||
        rowNode?.rowIndex === null
      )
        return;
      store[rangeIdx] = store[rangeIdx] ?? [];
      store[rangeIdx].push({
        columnId: column.getId(),
        productId: column?.getColDef().headerComponentParams.productId,
        rowId: rowNode.id,
        rowIndex: rowNode.rowIndex,
        headerName: column?.getColDef().headerName || column.getId(),
      });
    },
  );
}
