import { useAuth0 } from "@auth0/auth0-react";
import { PWBHost } from "promise-worker-bi";
import { useState, useCallback, useEffect, useRef } from "react";
import { getHasuraName } from "./globals";
import { useEnv } from "./envUtils";
import { atom, useAtom } from "jotai";

export type TWorkerPayload = {
  type: string;
  data?: Record<string, unknown>;
};

const workerAtom = atom<PWBHost | null>(null);
workerAtom.debugLabel = "workerAtom";

export function useInstantiateWorker() {
  const [worker, setWorker] = useAtom(workerAtom);

  const settingWorker = useRef(false);

  useEffect(() => {
    if (!worker && !settingWorker.current) {
      settingWorker.current = true;
      const isDev = import.meta.env.VITE_CLJS_DEV;
      const workerUrl = isDev ? "./cljs/worker.js" : "/cljs-prod/worker.js";
      console.log("instantiating worker...");
      const workerInstance = new Worker(new URL(workerUrl, import.meta.url));
      const promiseWorker = new PWBHost(workerInstance);
      setWorker(promiseWorker);
      console.log("worker instantiated", { workerInstance, promiseWorker });
      return () => {
        console.log("unmount", {
          worker,
          settingWorker: settingWorker.current,
        });
        if (worker) {
          console.log("terminating worker...");
          workerInstance.terminate();
        }
      };
    }
  }, [worker, setWorker]);
}

export function useWorker() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [worker] = useAtom(workerAtom);

  const sendMsgToWorker = useCallback(
    async (payload: TWorkerPayload) => {
      const token = await getAccessTokenSilently();
      if (!user || !worker || !token) {
        console.warn("no user, token or worker", { user, token, worker });
        return;
      }

      const workerUser = { id: user.sub, jwt: token };
      const payloadWithUser = {
        ...payload,
        data: { ...payload.data, user: workerUser },
      };
      //logger.debug('sending msg to worker', { payloadWithUser });
      return await worker.postMessage(payloadWithUser);
      //logger.debug('worker response', { res });
    },
    [getAccessTokenSilently, user, worker],
  );

  return {
    sendMsgToWorker,
  };
}

export function useWorkerStates<T, B>({
  type,
  callback,
}: {
  type: TWorkerPayload["type"];
  callback?: (res: B) => T;
}) {
  const { sendMsgToWorker } = useWorker();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>();
  const [data, setData] = useState<T>();
  const [env] = useEnv();
  const hasuraName = getHasuraName(env);

  async function handleWorkerMsg(data: TWorkerPayload["data"]) {
    setIsLoading(true);
    setError(null);

    try {
      const res = await sendMsgToWorker({
        type,
        data: { hasuraName, ...data },
      });
      setData(callback?.(res) || res);
      setIsLoading(false);
      return res;
    } catch (error) {
      const stringifiedError = error?.toString() || "Unknown error";
      setError(stringifiedError);
      setIsLoading(false);
      return Promise.reject(stringifiedError);
    }
  }

  const memoizedHandleWorkerMsg = useCallback(handleWorkerMsg, []);

  return { execute: memoizedHandleWorkerMsg, isLoading, error, data };
}
