import type {
  CellKeyDownEvent,
  RedoStartedEvent,
  SuppressKeyboardEventParams,
  UndoStartedEvent,
} from "ag-grid-community";
import { copyWithHeadersAndMonths } from "./copyRangeSelectionHelpers";
import { toggleCellBold } from "./modals/formatCellHelpers";
import { useCallback, useEffect, useMemo } from "react";
import {
  currentPageProductsAtom,
  pageIdAtom,
  usePageId,
} from "../market-pages";
import { store } from "../sharedHooks";
import { useThrottledCallback } from "@react-hookz/web";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { gridSettingsAtom, statusMapAtom } from "../grid-settings/atomStore";
import { calcWorker } from "../calculations-worker/hooks";
import { monthCodeToMonthString, monthCodeToOffset } from "./periodHelpers";
import type {
  TGridDataColumn,
  TGridDataRowId,
} from "../calculations-worker/sharedStores";
import { Product_Artis_Type_EnumSchema } from "../../__generated__/gql-validation/schemas";
import { getProcessedSelectedCellsByRange } from "../../tableUtils";
import { defaultSelector } from "./contextMenuHelpers";
import { parse } from "../numbers";
import { getManualInstrumentType } from "./statuses/statusLogic";
import { atom, useSetAtom } from "jotai";
import type { TOptimisticCell } from "../calculations-worker/subscriptionHandlers";
import { defaultColumnSettings } from ".";
import { colParams } from "../utils";
import { initGridSettings } from "./defaultSettings";
import { useQuery } from "@triplit/react";
import { client } from "../../triplit/triplit";
import type { TQuickAccess } from "../../triplit/schema";
import { useNavigate } from "@tanstack/react-router";
import { useGridApi } from "../../shared/hooks";
import { useProductsByIds } from "../curve-management";

dayjs.extend(utc);
dayjs.extend(timezone);

export type TUndoRedo = TOptimisticCell & {
  pasted: boolean;
};

export const undoRedoAtom = atom<{
  undo: TUndoRedo[];
  redo: TUndoRedo[];
  time: number;
}>({
  undo: [],
  redo: [],

  // Handles an edge case where the user might paste multiple times in a row. This way we can differentiate between modifying multiple cells in 1 paste vs. multiple pastes.
  time: new Date().getTime(),
});

export type TQuickAccessAtom = Array<TQuickAccess & { productName: string }>;

export const quickAccessAtom = atom<TQuickAccessAtom>([]);

type TGridKeyboardShortcut = {
  name: string;
  description: string;
  shortcuts: string[];
  sequenceTimeout?: number;
  action: (params: {
    params: CellKeyDownEvent;
    pageId?: string | null;
  }) => void;
};

export const gridKeyboardShortcuts = [
  {
    name: "Increment Column",
    description: "Increment the selected column(s)",
    shortcuts: [
      "ctrl+alt+ArrowUp",
      "cmd+alt+ArrowUp",
      "ctrl+shift+i",
      "cmd+shift+i",
    ],
    action: ({ params }) =>
      updateColumnFromCellByValueAndOperation({ params, operation: "+" }),
  },
  {
    name: "Decrement Column",
    description: "Decrement the selected column(s)",
    shortcuts: [
      "ctrl+alt+ArrowDown",
      "cmd+alt+ArrowDown",
      "ctrl+shift+d",
      "cmd+shift+d",
    ],
    action: ({ params }) =>
      updateColumnFromCellByValueAndOperation({ params, operation: "-" }),
  },
  {
    name: "Increment Cell",
    description: "Increment the selected cell(s)",
    shortcuts: ["ctrl+ArrowUp", "cmd+ArrowUp", "ctrl+i", "cmd+i"],
    action: ({ params }) => {
      updateCellsByValueAndOperation({ params, operation: "+" });
    },
  },
  {
    name: "Decrement Cell",
    description: "Decrement the selected cell(s)",
    shortcuts: ["ctrl+ArrowDown", "cmd+ArrowDown", "ctrl+d", "cmd+d"],
    action: ({ params }) => {
      updateCellsByValueAndOperation({ params, operation: "-" });
    },
  },
  {
    name: "Copy with Headers and Months",
    description: "Copy the selected range with headers and months",
    shortcuts: ["ctrl+shift+c", "cmd+shift+c"],
    action: ({ params }) => copyWithHeadersAndMonths(params),
  },
  {
    name: "Make Cell Bold",
    description: "Format the selected cell to be bold",
    shortcuts: ["ctrl+b", "cmd+b"],
    action: ({ params, pageId }) => toggleCellBold(params.api, pageId || ""),
  },
] satisfies TGridKeyboardShortcut[];

type TGlobalKeyboardShortcut = TGridKeyboardShortcut & { action: () => void };

export const globalKeyboardShortcuts = [
  {
    name: "Shortcut Menu",
    description: "Open the shortcut menu",
    shortcuts: ["shift+?"],
    action: () => {
      alert("Shortcut Menu");
    },
  },
] satisfies TGlobalKeyboardShortcut[];

function undoRedo(action: "undo" | "redo") {
  const worker = calcWorker?.()?.proxy;
  const undoRedoValue = store.get(undoRedoAtom);
  if (action === "undo") {
    worker?.optimisticCellEdit(undoRedoValue.undo);
  } else {
    worker?.optimisticCellEdit(undoRedoValue.redo);
  }
}

export function handleUndo(_: UndoStartedEvent) {
  undoRedo("undo");
}

export function handleRedo(_: RedoStartedEvent) {
  undoRedo("redo");
}

function ctrl(event: KeyboardEvent) {
  return event.ctrlKey || event.metaKey;
}

type TModifierKey = "ctrl" | "alt" | "shift";

function modifierKeyPressed(event: KeyboardEvent, key: TModifierKey) {
  if (key === "ctrl") return ctrl(event);
  return `${key}Key` in event && event[`${key}Key`];
}

function keyPressed(event: KeyboardEvent, key: string) {
  return event.key?.toLowerCase() === key?.toLowerCase();
}

function keysMatch(event: KeyboardEvent, shortcutKeys: string) {
  const keys = shortcutKeys.split("+");
  const matches = keys.reduce((keyAcc, key) => {
    ["ctrl", "alt", "shift"].includes(key)
      ? keyAcc.push(modifierKeyPressed(event, key as TModifierKey))
      : keyAcc.push(keyPressed(event, key));
    return keyAcc;
  }, [] as boolean[]);

  return matches.every((m) => m);
}

export function matchGridShortcut(
  event: KeyboardEvent,
): TGridKeyboardShortcut | null {
  for (const shortcut of gridKeyboardShortcuts) {
    const shortcutMatch = shortcut.shortcuts.reduce((acc, sc) => {
      if (keysMatch(event, sc)) {
        acc.push(shortcut);
      }
      return acc;
    }, [] as TGridKeyboardShortcut[])?.[0];

    if (shortcutMatch) {
      return shortcutMatch;
    }
  }

  return null;
}

export function matchGlobalShortcut(
  event: KeyboardEvent,
): TGlobalKeyboardShortcut | null {
  for (const shortcut of globalKeyboardShortcuts) {
    const shortcutMatch = shortcut.shortcuts.reduce((acc, sc) => {
      if (keysMatch(event, sc)) {
        acc.push(shortcut);
      }
      return acc;
    }, [] as TGlobalKeyboardShortcut[])?.[0];

    if (shortcutMatch) {
      return shortcutMatch;
    }
  }

  return null;
}

export function handleCellKeyDown(params: CellKeyDownEvent) {
  const pageId = store.get(pageIdAtom);
  if (!params || !pageId || !("column" in params)) return;

  // CellKeyDownEvent's type has "Event" as the type of the event property, but it is actually a KeyboardEvent. We need to cast it to the correct type.
  const event = params.event as KeyboardEvent;

  if (!("key" in event)) return;

  event.preventDefault();
  event.stopPropagation();

  if (modifierKeyPressed(event, "ctrl") || modifierKeyPressed(event, "alt")) {
    const shortcutMatch = matchGridShortcut(event);
    if (shortcutMatch) {
      return shortcutMatch.action({ params, pageId });
    }
  }
}

export function suppressKeyboardEvent(params: SuppressKeyboardEventParams) {
  const event = params.event;

  return (
    ((modifierKeyPressed(event, "ctrl") || modifierKeyPressed(event, "alt")) &&
      keyPressed(event, "ArrowUp")) ||
    keyPressed(event, "ArrowDown")
  );
}

function matchQuickAccessShortcut(
  quickAccess: TQuickAccess[],
  event: KeyboardEvent,
) {
  if (
    (!ctrl(event) && !modifierKeyPressed(event, "alt")) ||
    !modifierKeyPressed(event, "shift")
  ) {
    return false;
  }

  const key = event.code.includes("Digit")
    ? event.code.replace("Digit", "")
    : event.key;

  const shortcut = quickAccess.find(
    (qa) => qa.shortcutNumber.toString() === key.toString(),
  );

  return shortcut;
}

function getQuickAccessProductNames(
  quickAccess: TQuickAccess[],
  products: ReturnType<typeof useProductsByIds>["data"],
) {
  return quickAccess.reduce(
    (acc, qa) => {
      const product = products?.find((p) => p?.id === qa.productId);
      if (!product) return acc;
      acc[qa.shortcutNumber] = product.description || product.name || "";
      return acc;
    },
    {} as Record<number, string>,
  );
}

function useQuickAccess() {
  const pageId = usePageId();
  const setQuickAccessAtom = useSetAtom(quickAccessAtom);

  const { getApi } = useGridApi();
  const api = getApi();

  const navigate = useNavigate({
    from: "/app/market/$id",
  });

  const { results, fetchingLocal } = useQuery(
    client,
    client
      .query("quickAccess")
      .where("userId", "=", "$session.SESSION_USER_ID"),
  );

  const values = results || [];

  const products = useProductsByIds(
    values.map((qa) => qa?.productId).filter(Boolean) || [],
  );

  const quickAccess =
    useMemo(() => {
      const names = getQuickAccessProductNames(values, products?.data);
      return values.map((qa) => {
        return {
          ...qa,
          productName: names?.[qa.shortcutNumber] || "",
        };
      });
    }, [values, products]) || [];

  useEffect(() => {
    setQuickAccessAtom(quickAccess);
  }, [quickAccess, setQuickAccessAtom]);

  const showQuickAccessColumn = useCallback(
    ({
      columnId,
      switchPage = false,
      attempt = 0,
    }: {
      columnId: string;
      switchPage?: boolean;
      attempt?: number;
    }) => {
      if (!api) return;

      const flashDuration = switchPage ? 2000 : 500;

      console.log("Checking for Quick Access Column");

      const column = api.getColumn(columnId);
      console.log("Quick Access Column", column);

      if (!column) {
        console.log("Column not found, retrying...");

        if (attempt > 3) {
          console.error("Failed to find column after 3 attempts");
          return;
        }

        setTimeout(() => {
          showQuickAccessColumn({
            columnId,
            switchPage,
            attempt: attempt + 1,
          });
        }, 500);
        return;
      }

      api.ensureColumnVisible(column);

      // Flash sometimes doesn't work immediately after ensuring column is visible.
      setTimeout(() => {
        api.flashCells({
          columns: [column],
          flashDuration,
          fadeDuration: flashDuration,
        });
      }, 100);
    },
    [api],
  );

  const handleQuickAccess = useCallback(
    (qa: TQuickAccess) => {
      console.log("Quick Access Shortcut: ", qa);

      const switchPage = pageId !== qa.pageId;

      if (switchPage) {
        navigate({ to: "/app/market/$id", params: { id: qa.pageId } });
      }

      showQuickAccessColumn({ columnId: qa.columnId, switchPage });
    },
    [navigate, pageId, showQuickAccessColumn],
  );

  return { quickAccess, handleQuickAccess, fetchingLocal };
}

export function useShortcuts(debounce?: number) {
  const { quickAccess, handleQuickAccess } = useQuickAccess();

  const debouncedAction = useThrottledCallback(
    (shortcut: TGlobalKeyboardShortcut) => {
      shortcut.action();
    },
    [],
    debounce ?? 1000,
    true,
  );

  const listenerKeydown = useCallback(
    (event: KeyboardEvent) => {
      const qaShortcut = matchQuickAccessShortcut(quickAccess, event);

      if (qaShortcut) {
        handleQuickAccess(qaShortcut);
        return;
      }

      const shortcut = matchGlobalShortcut(event);

      if (shortcut) {
        debouncedAction(shortcut);
      }
    },
    [debouncedAction, quickAccess, handleQuickAccess],
  );

  useEffect(() => {
    window.addEventListener("keydown", listenerKeydown);

    return () => {
      window.removeEventListener("keydown", listenerKeydown);
    };
  }, [listenerKeydown]);
}

export async function updateCellsByValueAndOperation({
  params,
  operation,
  clearSelection,
}: {
  params: CellKeyDownEvent;
  operation: "+" | "-";
  clearSelection?: boolean;
}) {
  const api = params.api;
  const worker = calcWorker?.()?.proxy;

  const cells = getProcessedSelectedCellsByRange({ api }).flat();

  if (clearSelection) {
    api.clearRangeSelection();
  }

  if (!cells?.length || !worker) return;

  const sharedCells = cells.filter((cell) => {
    const columnParams = colParams(api.getColumn(cell.columnId));
    const shared = columnParams?.sharedCellOffsets?.includes(
      cell.rowIndex?.toString(),
    );
    return shared;
  });

  const gridSettings = store.get(gridSettingsAtom);

  const statusMap = store.get(statusMapAtom);
  const incrementMap = gridSettings?.incrementMap || {};
  const pageProducts = store.get(currentPageProductsAtom);

  const gridCells = sharedCells.reduce(
    (
      acc: {
        rowIds: TGridDataRowId[];
        columns: TGridDataColumn[];
        gridIds: string[];
      },
      selectedCell,
    ) => {
      const columnId = selectedCell.columnId;
      const productId = selectedCell.productId;
      const monthCode = selectedCell.rowId;

      if (!productId || !worker) return acc;

      const product = pageProducts.find((p) => p.productId === productId);

      if (!product) return acc;

      const status = statusMap?.[productId];

      const rowIdx = monthCode && monthCodeToOffset(monthCode);
      const rowId =
        rowIdx && (["mth", monthCode, rowIdx] satisfies TGridDataRowId);

      if (!rowId || !status) return acc;

      const columnParams = colParams(api.getColumn(columnId));

      const artisType = Product_Artis_Type_EnumSchema.Values.customer_curve;

      const column = {
        columnId,
        productId,
        artisType,
        hasSharedCell: true,
        eodId: null,
        selector: product.columnFieldSelector || defaultSelector(artisType),
        status: status,
        isPermissioned: true,
      } satisfies TGridDataColumn;

      acc.rowIds.push(rowId);
      acc.columns.push(column);
      acc.gridIds.push(columnParams?.gridId || "");
      return acc;
    },
    { rowIds: [], columns: [], gridIds: [] },
  );

  const gridData = await worker.getGridData({
    columns: gridCells.columns,
    rowIds: gridCells.rowIds,
  });

  if (!gridData?.length) return;

  const edits = gridData
    .map((data, idx) => {
      const selectedCell = sharedCells[idx];
      const columnIncrement =
        incrementMap?.[selectedCell.columnId] ||
        defaultColumnSettings.increment;
      const monthCode = selectedCell.rowId;
      const gridId = gridCells.gridIds[idx];
      const cellValue = data?.[gridId]?.Ok;

      const currentValue = (typeof cellValue === "number" ? cellValue : 0) || 0;

      const newValue =
        operation === "+"
          ? currentValue + columnIncrement
          : currentValue - columnIncrement;

      const offset = selectedCell.rowIndex;
      const monthString = monthCodeToMonthString(monthCode);

      const offsetIsNotSet = offset !== 0 && !offset;

      if (offsetIsNotSet || newValue === currentValue) {
        console.warn("Invalid cell edit request", {
          offset,
          oldValue: currentValue,
        });
        return;
      }
      const value = parse(newValue);

      if (!selectedCell.productId) return;

      const status = statusMap?.[selectedCell.productId];

      const storageType = getManualInstrumentType(status);

      if (!storageType) return;

      return {
        product: selectedCell.productId,
        offset,
        field: "value",
        result: value ?? undefined,
        currentValue,
        storageType,
        month: monthString,
        rowId: monthCode,
      } satisfies TOptimisticCell & { currentValue: number | undefined };
    })
    .filter(Boolean);

  store.set(undoRedoAtom, {
    undo: edits.map((edit) => ({
      ...edit,
      pasted: false,
      result: edit.currentValue,
    })),
    redo: edits.map((edit) => ({ ...edit, pasted: false })),
    time: new Date().getTime(),
  });

  await worker.optimisticCellEdit(edits);
}

export function updateColumnFromCellByValueAndOperation({
  params,
  operation,
}: {
  params: CellKeyDownEvent;
  operation: "+" | "-";
}) {
  const api = params.api;

  if (!api) return;

  const selectedColumn = api.getColumn(params.column.getColId());

  if (!selectedColumn) return;

  const gridSettings = store.get(gridSettingsAtom);
  const months = gridSettings?.months || initGridSettings.months;

  api.addCellRange({
    columns: [selectedColumn],
    rowStartIndex: 0,
    rowEndIndex: months - 1,
  });

  updateCellsByValueAndOperation({ params, operation, clearSelection: true });
}
