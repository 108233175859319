const broadcastChannel = new BroadcastChannel("worker->clients");

export type ClientMessage =
  | {
      type: "logoutRequest";
    }
  | {
      type: "refresh";
    }
  | {
      type: "renewToken";
    };

export function broadcastToClients(data: ClientMessage) {
  broadcastChannel.postMessage(data);
}
