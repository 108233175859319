import type {
  BaselineSeriesPartialOptions,
  ISeriesApi,
  SeriesDataItemTypeMap,
  SeriesMarker,
  Time,
} from "lightweight-charts";
import { type ForwardedRef, forwardRef, memo, type ReactNode } from "react";

import type { BaselineSeriesParams } from "../internal/series.js";
import { createSeriesHook } from "./internal/create-series-hook.js";
import { SeriesContext } from "./internal/series-context.js";

const useBaselineSeriesAction =
  createSeriesHook<BaselineSeriesParams>("Baseline");

export interface BaselineSeriesProps extends BaselineSeriesPartialOptions {
  data: SeriesDataItemTypeMap["Baseline"][];
  markers?: SeriesMarker<Time>[];
  reactive?: boolean;
  children?: ReactNode;
}

export const BaselineSeries = memo(
  forwardRef(
    (props: BaselineSeriesProps, ref: ForwardedRef<ISeriesApi<"Baseline">>) => {
      const { children, ...rest } = props;

      const context = useBaselineSeriesAction(rest, ref);

      return (
        <SeriesContext.Provider value={context.current}>
          {children}
        </SeriesContext.Provider>
      );
    },
  ),
);
