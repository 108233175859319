import { atom, useAtom } from "jotai";
import { environments, useEnv } from "../../envUtils";
import { useEntity } from "@triplit/react";
import { client } from "../../triplit/triplit";
import { useAuth0 } from "@auth0/auth0-react";

export function useEnvLabel() {
  const [env] = useEnv();
  const { collapsedSidebar } = useSideBar();

  const currentEnv = environments.find((e) => e.value === env);
  return collapsedSidebar ? currentEnv?.initials : currentEnv?.label;
}

const sidebarAtom = atom(true);

export function useSideBar() {
  const [sideBarCollapsed, setSideBar] = useAtom(sidebarAtom);
  const sideBarCollpasedWidth = 70;
  const openSideBarWidth = 205;
  return {
    sideBarWidth: sideBarCollapsed ? sideBarCollpasedWidth : openSideBarWidth,
    openSideBarWidth,
    collapsedSidebar: sideBarCollapsed,
    toggleSidebar: (open: boolean) => setSideBar(open),
  };
}

export async function setStarredOrgs(
  userId: string,
  starredOrgs: Array<string>,
) {
  const exists = await client.fetchById("adminUserSettings", userId);

  if (!exists) {
    client.insert("adminUserSettings", {
      id: userId,
      starredOrgs: new Set(starredOrgs),
    });

    return;
  }

  client.update("adminUserSettings", userId, (settings) => {
    if (!settings) {
      client.insert("adminUserSettings", {
        id: userId,
        starredOrgs: new Set(starredOrgs),
      });

      return;
    }

    settings.starredOrgs = new Set(starredOrgs);
  });
}

export function useStarredOrgs() {
  const userId = useAuth0().user?.sub || "";
  const starredOrgs = useEntity(client, "adminUserSettings", userId);
  return {
    fetching: starredOrgs?.fetching,
    starredOrgs: Array.from(starredOrgs?.result?.starredOrgs?.values() || []),
  };
}
