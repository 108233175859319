import { SideNavIconContainer } from "./SideNavIconContainer";

function Icon({ selected }: { selected: boolean }) {
  return (
    <svg
      className={selected ? "nav-icon selected" : "nav-icon"}
      width="42"
      height="42"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{selected ? "Grid Settings Selected" : "Grid Settings"}</title>
      <rect
        className="background"
        opacity="0.15"
        width="42"
        height="42"
        rx="2"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 26C22.866 26 26 22.866 26 19C26 15.134 22.866 12 19 12C15.134 12 12 15.134 12 19C12 22.866 15.134 26 19 26ZM19 28C23.9706 28 28 23.9706 28 19C28 14.0294 23.9706 10 19 10C14.0294 10 10 14.0294 10 19C10 23.9706 14.0294 28 19 28Z"
        fill="white"
      />
      <circle cx="19" cy="19" r="5.5" className="orange" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5286 8.46286L16.5481 8.74121L16.9046 11.2782C17.2234 11.1921 17.5509 11.1248 17.8863 11.0777C18.2217 11.0306 18.5551 11.005 18.8852 10.9998L18.5286 8.46286ZM21.0947 26.7215C20.776 26.8076 20.4484 26.8749 20.1131 26.922C19.7777 26.9691 19.4443 26.9947 19.1142 26.9999L19.4707 29.5368L21.4513 29.2585L21.0947 26.7215Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.11 24.6769L11.3413 26.2529L13.3601 24.6757C13.1262 24.4427 12.9042 24.1926 12.6957 23.9258C12.4872 23.6589 12.2983 23.3829 12.1288 23.0996L10.11 24.6769ZM24.6394 13.3253C24.8733 13.5583 25.0953 13.8083 25.3038 14.0752C25.5123 14.3421 25.7012 14.618 25.8707 14.9013L27.8895 13.324L26.6582 11.748L24.6394 13.3253Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3607 23.8612L29.1099 22.0068L26.7345 21.0471C26.6497 21.3662 26.5442 21.6835 26.4173 21.9975C26.2904 22.3115 26.1459 22.6131 25.9853 22.9015L28.3607 23.8612ZM11.2651 16.9542C11.35 16.6351 11.4555 16.3178 11.5824 16.0038C11.7092 15.6898 11.8538 15.3882 12.0143 15.0998L9.639 14.1401L8.88979 15.9945L11.2651 16.9542Z"
        fill="white"
      />
    </svg>
  );
}

export function GridSettingsIcon({ selected = false }: { selected?: boolean }) {
  return (
    <SideNavIconContainer>
      <Icon selected={selected} />
    </SideNavIconContainer>
  );
}
