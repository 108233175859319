import { memo } from "react";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import type { TUserRow } from "../users";
import type { CustomCellEditorProps } from "ag-grid-react";
import { GroupsSelect } from "./GroupsSelect";
import { Stack } from "@mui/joy";
import { FaChevronDown } from "react-icons/fa";
import { useGridCustomSelect } from "../hooks/gridHooks";

export const UserGroupsMultiSelectAgGridEditor = memo(
  (props: CustomCellEditorProps<TUserRow, TUserRow["groups"]>) => {
    const { anchorRef, id } = useGridCustomSelect(props);
    const userId = props.data.id;

    return (
      <>
        <Stack
          ref={anchorRef}
          id={id}
          width="100%"
          height="100%"
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
          sx={{
            borderRadius: 0,
            pl: 1,
            pr: 1,
          }}
        >
          Select or create groups
          <FaChevronDown />
        </Stack>

        {anchorRef?.current && (
          <BasePopup open={true} anchor={anchorRef?.current}>
            <GroupsSelect
              sx={{
                width: props.column.getActualWidth(),
              }}
              autoFocus
              userId={userId}
            />
          </BasePopup>
        )}
      </>
    );
  },
);
