import { createFilterOptions } from "@mui/joy";
import type { roles } from "../../globals";

export type TOptionType = {
  id: string;
  inputValue?: string;
  title: string;
};

export function formatOptionLabel(option: TOptionType) {
  return option?.title;
}

export function formatValues(value: TOptionType[]) {
  return value.map((x) => {
    if (x.inputValue) {
      return {
        id: x.id,
        title: x.inputValue,
      };
    }
    return x;
  });
}

export const optionFilter = createFilterOptions<TOptionType>({
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
});

// Return "Artis Lite" for "read-only" role, otherwise replace "-" with " " and capitalize each word in Title Case.
export function roleToLabel(role: (typeof roles)[number]) {
  if (role === "read-only") {
    return "Artis Lite";
  }

  return role
    .replace(/-/g, " ")
    .replace(/\b\w/g, (c) => c.toUpperCase())
    .replace("Umi", "UMI");
}
