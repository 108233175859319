import type {
  HistogramSeriesPartialOptions,
  ISeriesApi,
  SeriesDataItemTypeMap,
  SeriesMarker,
  Time,
} from "lightweight-charts";
import { type ForwardedRef, forwardRef, memo, type ReactNode } from "react";

import type { HistogramSeriesParams } from "../internal/series.js";
import { createSeriesHook } from "./internal/create-series-hook.js";
import { SeriesContext } from "./internal/series-context.js";

const useHistogramSeriesAction =
  createSeriesHook<HistogramSeriesParams>("Histogram");

export interface HistogramSeriesProps extends HistogramSeriesPartialOptions {
  data: SeriesDataItemTypeMap["Histogram"][];
  markers?: SeriesMarker<Time>[];
  reactive?: boolean;
  children?: ReactNode;
}

export const HistogramSeries = memo(
  forwardRef(
    (
      props: HistogramSeriesProps,
      ref: ForwardedRef<ISeriesApi<"Histogram">>,
    ) => {
      const { children, ...rest } = props;

      const context = useHistogramSeriesAction(rest, ref);

      return (
        <SeriesContext.Provider value={context.current}>
          {children}
        </SeriesContext.Provider>
      );
    },
  ),
);
