import { atom, useSetAtom } from "jotai";
import { useState } from "react";

export const isSavingAtom = atom(false);

export function useFloatingSave() {
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>();
  const setIsSaving = useSetAtom(isSavingAtom);

  const floatingSaveFn = (callback: () => unknown) => {
    if (!timeoutId) {
      const timeout = setTimeout(() => {
        callback();
        setIsSaving(true);
        setTimeout(() => {
          setIsSaving(false);
        }, 3000);
        setTimeoutId(undefined);
      }, 500);

      setTimeoutId(timeout);
    }
  };

  const cancelSaveFn = () => {
    clearTimeout(timeoutId);
    setTimeoutId(undefined);
  };

  const isWaiting = Boolean(timeoutId);

  return {
    isWaiting,
    floatingSaveFn,
    cancelSaveFn,
  };
}
