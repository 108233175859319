import type { ColumnMovedEvent, ColumnResizedEvent } from "ag-grid-community";
import { generateKeyBetween } from "fractional-indexing";
import { client } from "../../triplit/triplit";
import { debounce } from "remeda";
import { mainMonthColumnId } from ".";
import { gridSettingsUpsert } from "../grid-settings";
import { colParams } from "../utils";
import type { TPageProduct } from "../../triplit/schema";

async function fetchProductById(columnId: string) {
  try {
    return await client.fetchById("pageProducts", columnId, {
      policy: "local-only",
    });
  } catch (error) {
    console.error("Error in fetchProductById:", error);
  }
}

async function updatePageProducts(
  columnId: string,
  updateFn: (entry: Partial<TPageProduct>) => void,
) {
  try {
    await client.transact(async (tx) => {
      try {
        await tx.update("pageProducts", columnId, (e) => {
          console.log("updatePageProducts", e);
          updateFn(e);
        });
      } catch (error) {
        console.error("Error in updatePageProducts tx:", error);
      }
    });
  } catch (error) {
    console.error("Error in updatePageProducts:", error, client);
  }
}

async function onColumnMoved(params: ColumnMovedEvent) {
  if (params.source === "gridOptionsChanged" || !params.finished) return;

  const api = params.api;
  const columnId =
    colParams(params.column)?.gridId ?? params.column?.getColId();
  const toIndex = params.toIndex;

  console.log("params", params);

  // Ensure columnId and toIndex are valid
  if (!columnId) {
    console.error("Invalid column ID");
    return;
  }
  if (toIndex === undefined || toIndex === null) {
    console.error("Invalid target index");
    return;
  }

  const gridColumns = api.getAllGridColumns();

  // Ensure gridColumns are available
  if (!gridColumns || gridColumns.length === 0) {
    console.error("Grid columns are not available");
    return;
  }

  const prevColumn = gridColumns[toIndex - 1];
  const nextColumn = gridColumns[toIndex + 1];

  try {
    // EDGE CASE 1: target position is first
    // index 0 is month column
    if (toIndex === 1) {
      const nextColumnId = colParams(nextColumn)?.gridId || "";

      if (!nextColumnId) {
        console.error("Next column ID is not available");
        return;
      }

      const nextProduct = await fetchProductById(nextColumnId);
      if (!nextProduct) {
        console.error("Next product is not available");
        return;
      }

      console.log("in debouncedOnColumnMoved INSERT FIRST");
      const newIdx = generateKeyBetween(null, nextProduct.idx);
      await updatePageProducts(columnId, (entry) => {
        entry.idx = newIdx;
      });
      return;
    }

    // EDGE CASE 2: target position is last
    if (toIndex === gridColumns.length - 1) {
      const prevColumnId = colParams(prevColumn)?.gridId || "";

      if (!prevColumnId) {
        console.error("Previous column ID is not available");
        return;
      }

      const prevProduct = await fetchProductById(prevColumnId);
      if (!prevProduct) {
        console.error("Previous product is not available");
        return;
      }

      console.log("in debouncedOnColumnMoved INSERT LAST");
      const newIdx = generateKeyBetween(prevProduct.idx, null);
      await updatePageProducts(columnId, (entry) => {
        entry.idx = newIdx;
      });
      return;
    }

    // BASE CASE
    const prevColumnId = colParams(prevColumn)?.gridId || "";
    const nextColumnId = colParams(nextColumn)?.gridId || "";

    if (!prevColumnId || !nextColumnId) {
      return;
    }

    const [prevProduct, nextProduct] = await Promise.all([
      fetchProductById(prevColumnId),
      fetchProductById(nextColumnId),
    ]);

    if (!prevProduct || !nextProduct) {
      return;
    }

    if (prevProduct.idx >= nextProduct.idx) {
      return;
    }

    console.log("in debouncedOnColumnMoved BASE CASE");
    const newIdx = generateKeyBetween(prevProduct.idx, nextProduct.idx);
    await updatePageProducts(columnId, (entry) => {
      entry.idx = newIdx;
    });
  } catch (error) {
    console.error("Error in debouncedOnColumnMoved:", error);
  }
}

export function onColumnMovedCallback(params: ColumnMovedEvent) {
  onColumnMoved(params);
}

function onColumnResized(params: ColumnResizedEvent, userId: string) {
  if (params.source === "api") return;

  const width = params?.column?.getActualWidth();
  if (!width) {
    return;
  }

  // handle main month column resizing
  const columnFieldId = params?.column?.getColId();

  if (columnFieldId === mainMonthColumnId) {
    return gridSettingsUpsert(userId, "mainMonthColumnWidth", width);
  }

  // resizing any other column
  const columnId = colParams(params.column)?.gridId;

  if (!columnId) throw new Error("columnId is undefined");

  return updatePageProducts(columnId, (entry) => {
    entry.columnWidth = width;
  });
}

const onColumnResizedDebouncer = debounce(onColumnResized, {
  waitMs: 300,
});

export function onColumnResizedCallback(
  params: ColumnResizedEvent,
  userId: string,
) {
  onColumnResizedDebouncer.call(params, userId);
}
