export type TExchangeCurvesUomSource = keyof typeof exchangeCurvesUom;

export const defaultCurvesUom: Record<string, string> = {
  ask: "Ask",
  asksize: "Ask lots",
  bid: "Bid",
  bidsize: "Bid lots",
  chgexch: "ChgEx",
  "chg%exch": "ChgEx%",
  chgfv: "ChgFV",
  "chgfv-mid": "ChgMid",
  chgtick: "ChgTk",
  close: "Cls",
  "days-before": "DaysBfr",
  "days-end": "DateEnd",
  "days-start": "DateStart",
  "days-total": "DaysTotal",
  expiry: "Expiry",
  "first-effective": "1st days",
  "first-used": "FirstUsed",
  "fv-mid": "Mid",
  high: "High",
  last: "Last",
  lastsize: "Last lots",
  lasttime: "LastTime",
  low: "Low",
  "last-used": "LastUsed",
  "pricing-days": "Px days",
  "second-effective": "2nd days",
  "settle-chg": "Settle Chg",
  "settle-prior": "Settle -1",
  "settle-today": "Settle",
  "total-pricing-days": "Tot px days",
  oi: "OI",
  oidate: "OIDate",
  oichg: "OIChg",
  volm: "Volm",
  volmprior: "Vol -1",
  "value-before": "ValBfr",
  "value-after": "ValAft",
  wav: "WAv",
};

export const exchangeCurvesUom: Record<
  string,
  Record<string, Record<string, string>>
> = {
  "CME NYMEX": {
    "60a58576-8deb-44fb-8cf8-8f7827f64153": {
      fv: "c/gal",
    },
    "3a8543bf-9dce-405d-8733-068c87271792": {
      fv: "c/gal",
    },
    "abc88939-cf10-453e-a808-a2a3a6ce8711": {
      fv: "$/bbl",
    },
    "af3a49b5-a864-44cf-b25f-f60f4d18b65f": {
      fv: "$/bbl",
    },
    "7cb6b463-217e-4c97-ab79-eed6bda12627": {
      fv: "$/bbl",
    },
    "3d237b99-96a9-41f3-881a-e29b87da5345": {
      fv: "$/bbl",
    },
    "a0c700a0-2eab-465c-bbb7-09bc33d00eeb": {
      fv: "$/MMBtu",
    },
    "9b6a9b6a-4457-4408-a2d4-2c40b5ac7ad8": {
      fv: "c/gal",
    },
    "ab58810d-ccd5-4e47-8958-e2919abacd60": {
      fv: "$/bbl",
    },
    "eda3d857-a7a9-41b9-aa45-494decf8b927": {
      fv: "c/gal",
    },
    "83f8f80b-8b3a-47a9-8cb9-1c7b7466bf71": {
      fv: "$/bbl",
    },
    "32977c10-5e93-4dcb-ab5f-69ba1478942b": {
      fv: "$/MMBtu",
    },
    "830b58e5-78b9-454c-9ed2-af98472b8239": {
      fv: "c/gal",
    },
    "1f41c7d8-b597-4b80-b1e2-02faf421c090": {
      fv: "c/gal",
    },
    "488da924-8377-461c-b312-1e2eb0a8a749": {
      fv: "$/MMBtu",
    },
    "4d2de6d1-72f9-46d0-91fc-fa73f366064a": {
      fv: "$/MMBtu",
    },
  },
  "ICE FUTURES EUROPE": {
    "a64658cc-3081-46e1-b20f-3325dd0082de": {
      fv: "c/gal",
    },
    "69993417-5bb5-4532-b3d8-424f2e7ccba2": {
      fv: "$/ton",
    },
    "00e9655d-3243-4225-a0a3-d43864727f9b": {
      fv: "$/bbl",
    },
    "ae992df4-0b80-4b32-b203-ada580c6c494": {
      fv: "p/thm",
    },
    "a79b77a0-2267-4f86-8c75-c9248ac9d4b3": {
      fv: "$/mt",
    },
    "65c0741c-7ed5-47d3-9987-2fa3b6b39b86": {
      fv: "$/mt",
    },
    "73ef9108-6c95-4978-900a-5a845594b839": {
      fv: "c/gal",
    },
    "d66746e5-8e18-402b-9e78-3be5831854d0": {
      fv: "$/bbl",
    },
    "f49c81e1-6371-4bb7-a449-cb5dd113fe80": {
      fv: "$/bbl",
    },
    "a78b6d84-1017-4958-8875-1e0c1a23406f": {
      fv: "c/gal",
    },
    "cdc2ee85-7fa0-40a7-90bd-17b610aa4309": {
      fv: "$/bbl",
    },
    "e48c187b-1416-4508-9810-f57094dc3949": {
      fv: "$bbl",
    },
    "92801246-4587-46e5-825e-5b2a93c61bbf": {
      fv: "$/bbl",
    },
    "80894118-3325-4849-b164-1c0a65bef2bb": {
      fv: "p/thm",
    },
    "003998e1-975a-48e7-aef2-e711efbe4e8c": {
      fv: "c/gal",
    },
    "ad8965ce-bc7e-45d2-9ace-cc3c90798852": {
      fv: "c/gal",
    },
    "fe5ec999-3e87-44b8-8bb8-634f6547cd0c": {
      fv: "c/gal",
    },
  },
  "ICE ENDEX": {
    "6f8f1fc3-2899-4798-b94e-a378e13bb3d8": {
      fv: "€/MWh",
    },
    "6363e14e-3750-42d3-892e-f81532bd7c4c": {
      fv: "€/MWh",
    },
  },
  IDS: {
    "46a81843-2ef3-470e-a7f8-4b452102b625": {
      fv: "$/€",
    },
    "6c772c57-3604-4222-8f7e-3f3ebf453eaf": {
      fv: "$/£",
    },
    "8df15c9d-d971-43c6-bb18-d808a7af0fd3": {
      fv: "€/£",
      "fv-mid": "€",
    },
    "b8973c43-540b-477c-aa48-5dcd68139eba": {
      fv: "$/€",
    },
  },
};
