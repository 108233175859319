import { atom, useAtomValue } from "jotai";
import { statusMapAtom } from "../../grid-settings/atomStore";
import { client } from "../../../triplit/triplit";
import {
  getManualInstrumentType,
  knownStatusList,
  type TStatus,
} from "./statusLogic";
import { useUserId } from "../../../auth";
import { useCallback } from "react";
import { calcWorker, type TWorker } from "../../calculations-worker/hooks";
import { monthRowIds, offsetToRow } from "..";
import type { TOptimisticCell } from "../../calculations-worker/subscriptionHandlers";
import type { ProductFragmentGridFragment } from "../../../__generated__/gql/graphql";
import { objectEntries } from "../../../shared/utils";
import {
  knownStatusOptions,
  knownStatusRules,
  statusTransitions,
  type TKnownStatusRules,
  type TStatusOption,
} from "./consts";
import { gridSettingsOptions } from "../../grid-settings/settingsStore";
import { store } from "../../sharedHooks";

export const changeAllHoverAtom = atom<boolean>(false);

export function useStatusMap() {
  const statusMap = useAtomValue(statusMapAtom);
  return statusMap as Record<string, TStatus>;
}

async function updateColumnStatus(
  toStatus: TStatus,
  productIds: string[],
  userId: string,
  currentStatusMap: Record<string, TStatus>,
) {
  if (!userId)
    throw new Error("userId is undefined when updating column status");
  const currentGridSettings = await client.fetchById("gridSettings", userId, {
    policy: "local-only",
  });
  if (currentGridSettings) {
    for (const productId of productIds) {
      currentStatusMap[productId] = toStatus;
    }

    return client.update("gridSettings", userId, (data) => {
      data.statusMap = JSON.stringify(currentStatusMap);
    });
  }

  const statusMap = productIds.reduce(
    (acc, productId) => {
      acc[productId] = toStatus;
      return acc;
    },
    {} as Record<string, TStatus>,
  );

  return client.insert("gridSettings", {
    id: userId,
    statusMap: JSON.stringify(statusMap),
  });
}

export function useUpdateColumnStatus() {
  const statusMap = useStatusMap();
  const userId = useUserId();
  return useCallback(
    async (toStatus: TStatus, productIds: string[]) => {
      await updateColumnStatus(toStatus, productIds, userId, statusMap);
    },
    [statusMap, userId],
  );
}

async function writeStatusInstruments(
  {
    to,
    from,
    curveId,
    gridId,
  }: {
    to: TStatus;
    from: TStatus;
    curveId: string;
    gridId: string;
  },
  getData: TWorker["getGridData"],
  updateCell: TWorker["optimisticCellEdit"],
) {
  const storageType = getManualInstrumentType(to);
  if (!storageType || !knownStatusList.includes(to)) {
    console.error(`can't write when going to status: ${to}`);
    return;
  }
  const rowIds = monthRowIds.map((month, idx) => ["mth", month, idx] as const);
  const rawCellValues = await getData({
    rowIds,
    columns: [
      {
        eodId: undefined,
        columnId: gridId,
        productId: curveId,
        artisType: "customer_curve",
        hasSharedCell: true,
        selector: "value",
        status: from,
        isPermissioned: true,
      },
    ],
  });
  if (!rawCellValues) return;
  const cells: TOptimisticCell[] = [];
  for (const rowId of rowIds) {
    const rowIdx = rowId[2];
    const cell = rawCellValues[rowIdx];
    const month = offsetToRow[rowIdx];
    const result = cell?.[gridId]?.Ok ?? undefined;
    if (typeof result === "string") {
      console.error(`Result is string: ${result}`);
      continue;
    }
    cells.push({
      result,
      month,
      storageType,
      offset: rowIdx,
      product: curveId,
      field: "value",
      rowId: rowId[1],
    });
  }
  updateCell(cells);
}

export function useWriteStatusInstruments() {
  const worker = calcWorker?.().proxy;
  return useCallback(
    async (params: Parameters<typeof writeStatusInstruments>[0]) => {
      if (!worker) {
        throw new Error("Worker not available");
      }
      await writeStatusInstruments(
        { ...params },
        worker.getGridData,
        worker.optimisticCellEdit,
      );
    },
    [worker],
  );
}

export function checkCanBroadcast(
  permissions:
    | ProductFragmentGridFragment["packageByPackage"]["permissions"]
    | null
    | undefined,
) {
  return permissions?.some(
    (perm) => perm.permission === "broadcast" || perm.permission === "write",
  );
}

export function verifyKnownStatusRules(
  rules: TKnownStatusRules | undefined,
  resolvedRules: Required<TKnownStatusRules>,
) {
  return (
    !rules ||
    objectEntries(rules).every(([rule, bool]) => bool === resolvedRules[rule])
  );
}

export function genGlobalDependencyOptions(
  product: ProductFragmentGridFragment | null | undefined,
) {
  return (product?.product_global_dependencies.map((dep) => ({
    value: dep.global_product,
    label: dep.global_product,
    style: undefined,
    icon: undefined,
    rules: undefined,
    image: false,
  })) || []) satisfies TStatusOption[];
}

export function genSelectStatusOptions({
  resolvedRules,
  globalOptions,
}: {
  resolvedRules: Required<TKnownStatusRules>;
  globalOptions: ReturnType<typeof genGlobalDependencyOptions>;
}) {
  const { hideEOD, hideGlobal, hidePrivate, hideBroadcast } =
    store.get(gridSettingsOptions);
  return [
    ...knownStatusOptions
      // to compare the hard coded rules in statusOptions with the resolved rules
      // belonging to the product in question.
      .filter(({ value }) => {
        let userDisabled = false;
        switch (value) {
          case "eod":
            userDisabled = !!hideEOD;
            break;
          case "private":
            userDisabled = !!hidePrivate;
            break;
          case "broadcast":
            userDisabled = !!hideBroadcast;
            break;
        }
        return (
          !userDisabled &&
          verifyKnownStatusRules(knownStatusRules[value], resolvedRules)
        );
      }),
    ...(hideGlobal ? [] : globalOptions),
  ] satisfies TStatusOption[];
}

export function genStatusTransitionActions({
  from,
  to,
}: {
  from: string | undefined;
  to: string;
}) {
  return statusTransitions.find(
    (entry) => (entry.from === "*" || entry.from === from) && entry.to === to,
  );
}
