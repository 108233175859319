import { NO_COLOR } from "../globals";

const removeHash = (hex: string): string => {
  return hex.charAt(0) === "#" ? hex.slice(1) : hex;
};

interface HexObject {
  r: string;
  g: string;
  b: string;
  a: string;
}

function parseHex(nakedHex: string): HexObject {
  const isShort = nakedHex.length === 3 || nakedHex.length === 4;

  const twoDigitHexR = isShort
    ? `${nakedHex.slice(0, 1)}${nakedHex.slice(0, 1)}`
    : nakedHex.slice(0, 2);
  const twoDigitHexG = isShort
    ? `${nakedHex.slice(1, 2)}${nakedHex.slice(1, 2)}`
    : nakedHex.slice(2, 4);
  const twoDigitHexB = isShort
    ? `${nakedHex.slice(2, 3)}${nakedHex.slice(2, 3)}`
    : nakedHex.slice(4, 6);
  const twoDigitHexA =
    (isShort
      ? `${nakedHex.slice(3, 4)}${nakedHex.slice(3, 4)}`
      : nakedHex.slice(6, 8)) || "ff";

  return {
    r: twoDigitHexR,
    g: twoDigitHexG,
    b: twoDigitHexB,
    a: twoDigitHexA,
  };
}

function hexToDecimal(hex: string): number {
  return Number.parseInt(hex, 16);
}

interface DecimalObject {
  r: number;
  g: number;
  b: number;
  a: number;
}

function hexesToDecimals(hexObject: HexObject): DecimalObject {
  const { r, g, b, a } = hexObject;

  return {
    r: hexToDecimal(r),
    g: hexToDecimal(g),
    b: hexToDecimal(b),
    a: Number.parseFloat((hexToDecimal(a) / 255).toFixed(2)),
  };
}

export function hexToRgba(hex: string, a?: string) {
  const hashlessHex = removeHash(hex);
  const hexObject = parseHex(hashlessHex);
  const decimalObject = hexesToDecimals(hexObject);
  return { ...decimalObject, a };
}

export function brightColor(color: string) {
  const { r, g, b } = hexToRgba(color);
  return (r * 299 + g * 587 + b * 114) / 1000 > 128;
}

const white = "#fff";
const black = "#000";

export function calculateTextColor(
  backgroundColor: string | null | undefined,
  invert: boolean,
) {
  if (!backgroundColor || backgroundColor === "inherit") return "inherit";
  const darkMode =
    document.documentElement.getAttribute("data-joy-color-scheme") === "dark";

  if (backgroundColor === NO_COLOR) {
    return darkMode ? white : black;
  }

  const backgroundColorToUse =
    backgroundColor !== "none" ? backgroundColor : darkMode ? black : white;

  const isBright = brightColor(backgroundColorToUse);

  if (invert) {
    return isBright ? white : black;
  }

  return isBright ? black : white;
}
