import type { CustomCellEditorProps } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import type { RequiredTableFields } from "../../utils";

export function useGridCustomSelect<T extends RequiredTableFields, V>(
  props: CustomCellEditorProps<T, V>,
) {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [id, setId] = useState("");
  // this is a bit of a hack to ensure the ref is actually
  // attached to the dom due to the way ag grid renders this component
  // - without it the basepopup will not be able to position itself
  useEffect(() => {
    setId(props.data?.id.toString());
  }, [props.data?.id]);
  return {
    anchorRef,
    id,
  };
}
