// can't use css vars here, as the logo is also drawn in the live charts canvas
function style(mode: "dark" | "light") {
  const isDark = mode === "dark";
  return `
  .st0{fill: ${isDark ? "#E3E3E3" : "#A6A8AB"};}
  .st5 {fill: ${isDark ? "#FFFFFF" : "#424243"};}
	.st1{fill:#FF7400;}
	.st2{opacity:0.8;}
	.st3{opacity:0.6;}
	.st4{opacity:0.4;}`;
}

export function ArtisSmallLogo({
  mode = "light",
}: { mode?: "dark" | "light" }) {
  return (
    <svg
      id="artisLogoSmall"
      viewBox="0 53.674 246.169 133.426"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        {mode === "dark" ? "Artis Logo Dark Mode" : "Artis Logo Light Mode"}
      </title>
      <style
        type="text/css"
        // biome-ignore lint/security/noDangerouslySetInnerHtml: safe as mode is validated
        dangerouslySetInnerHTML={{
          __html: style(mode),
        }}
      />
      <g>
        <g transform="matrix(1, 0, 0, 1, -31.668497, 28.337118)">
          <path
            className="st0 st5"
            d="M34.6,108c3-3.1,6.3-5.4,9.8-6.9s7.4-2.3,11.6-2.3c3.1,0,5.8,0.5,8.1,1.6c2.4,1.1,4.4,2.5,6,4.4
    s2.8,4.2,3.7,6.9c0.8,2.7,1.2,5.6,1.2,8.9v36.8h-4.3c-0.9,0-1.7-0.2-2.2-0.5s-0.9-1-1.2-1.9l-1.1-5.5c-1.4,1.4-2.8,2.6-4.2,3.7
    c-1.4,1.1-2.8,2-4.3,2.7s-3.1,1.3-4.8,1.7s-3.6,0.6-5.7,0.6c-2.1,0-4.1-0.3-6-0.9c-1.9-0.6-3.5-1.6-4.9-2.8
    c-1.4-1.3-2.5-2.8-3.3-4.7s-1.2-4.2-1.2-6.8c0-2.3,0.6-4.5,1.8-6.6s3.1-4,5.8-5.6s6.1-3,10.4-4s9.5-1.6,15.8-1.8v-4.5
    c0-4.5-0.9-7.9-2.8-10.1c-1.8-2.3-4.5-3.4-8-3.4c-2.4,0-4.4,0.3-6,0.9s-3,1.3-4.2,2.1s-2.2,1.5-3,2.1s-1.7,0.9-2.5,0.9
    c-0.6,0-1.2-0.2-1.7-0.5c-0.5-0.4-0.9-0.8-1.2-1.3L34.6,108z M65.6,131.5c-4.4,0.2-8.2,0.5-11.3,1.1s-5.6,1.4-7.6,2.3
    c-2,0.9-3.4,2.1-4.3,3.4c-0.9,1.3-1.3,2.7-1.3,4.3c0,1.5,0.2,2.8,0.7,3.9c0.5,1.1,1.1,2,1.9,2.7s1.8,1.2,2.9,1.5
    c1.1,0.3,2.3,0.5,3.5,0.5c1.7,0,3.2-0.2,4.6-0.5c1.4-0.4,2.7-0.9,4-1.6c1.2-0.7,2.4-1.5,3.6-2.4c1.1-0.9,2.2-2,3.3-3.2
    C65.6,143.5,65.6,131.5,65.6,131.5z"
          />
          <path
            className="st0 st5"
            d="M94.4,111.4c1.7-3.9,3.8-7,6.4-9.2c2.5-2.2,5.6-3.3,9.2-3.3c1.2,0,2.3,0.1,3.3,0.4s2,0.7,2.8,1.2l-0.7,7.6
    c-0.2,0.9-0.8,1.4-1.6,1.4c-0.5,0-1.2-0.1-2.2-0.3c-1-0.2-2.1-0.3-3.3-0.3c-1.7,0-3.3,0.3-4.6,0.8c-1.3,0.5-2.6,1.3-3.6,2.4
    s-2,2.3-2.9,3.9c-0.8,1.5-1.6,3.3-2.3,5.3v36.4h-9.7V99.9h5.5c1,0,1.8,0.2,2.2,0.6s0.7,1.1,0.8,2.2L94.4,111.4z"
          />
          <path
            className="st0 st5"
            d="M147.8,158.4c-4.3,0-7.6-1.3-10-3.8c-2.3-2.5-3.5-6.2-3.5-11v-35.2h-6.6c-0.6,0-1.1-0.2-1.5-0.5
    c-0.4-0.4-0.6-0.9-0.6-1.7v-4l9-1.2l2.2-17.8c0.1-0.6,0.4-1,0.7-1.4c0.4-0.4,0.9-0.5,1.5-0.5h4.9V101h15.8v7.3H144v34.5
    c0,2.4,0.6,4.2,1.7,5.4c1.1,1.2,2.6,1.8,4.3,1.8c1,0,1.9-0.1,2.6-0.4c0.7-0.3,1.4-0.6,1.9-0.9c0.5-0.3,1-0.7,1.4-0.9
    s0.7-0.4,1-0.4c0.5,0,1,0.3,1.3,1l2.8,4.8c-1.7,1.6-3.7,2.9-6,3.8C152.7,157.9,150.3,158.4,147.8,158.4z"
          />
          <path
            className="st0 st5"
            d="M184,81.8c0,1-0.2,1.9-0.6,2.8c-0.4,0.9-0.9,1.6-1.5,2.3c-0.6,0.7-1.4,1.2-2.2,1.6s-1.7,0.6-2.7,0.6
    c-0.9,0-1.8-0.2-2.6-0.6s-1.5-0.9-2.1-1.6s-1.1-1.4-1.5-2.3s-0.5-1.8-0.5-2.8s0.2-1.9,0.5-2.8c0.4-0.9,0.8-1.7,1.5-2.3
    c0.6-0.7,1.3-1.2,2.1-1.6s1.7-0.6,2.6-0.6c0.9,0,1.8,0.2,2.7,0.6c0.8,0.4,1.6,0.9,2.2,1.6c0.6,0.7,1.1,1.4,1.5,2.3
    C183.8,79.9,184,80.8,184,81.8z M182,99.9v57.6h-9.6V99.9H182z"
          />
          <path
            className="st0 st5"
            d="M227.4,109.4c-0.4,0.8-1.1,1.3-2,1.3c-0.5,0-1.2-0.2-1.8-0.6c-0.7-0.4-1.5-0.9-2.5-1.4s-2.2-1-3.5-1.4
    c-1.4-0.4-3-0.7-4.9-0.7c-1.6,0-3.1,0.2-4.4,0.7c-1.3,0.4-2.4,1-3.3,1.8c-0.9,0.8-1.6,1.6-2.1,2.6s-0.7,2.1-0.7,3.3
    c0,1.5,0.4,2.7,1.2,3.7s1.9,1.8,3.2,2.6c1.3,0.7,2.8,1.4,4.5,1.9s3.4,1.1,5.2,1.8c1.8,0.6,3.5,1.3,5.2,2.1s3.2,1.7,4.5,2.8
    s2.4,2.5,3.2,4.2c0.8,1.6,1.2,3.6,1.2,5.9c0,2.7-0.5,5.1-1.3,7.4c-0.9,2.3-2.2,4.2-4,5.9c-1.8,1.6-3.9,2.9-6.5,3.9
    c-2.6,0.9-5.5,1.4-8.8,1.4c-3.8,0-7.3-0.7-10.4-2c-3.1-1.3-5.7-3-7.9-5l2.3-3.9c0.3-0.5,0.6-0.9,1-1.1c0.4-0.3,0.9-0.4,1.5-0.4
    s1.3,0.3,2,0.8s1.6,1.1,2.6,1.8c1,0.6,2.3,1.2,3.7,1.8c1.5,0.5,3.3,0.8,5.5,0.8c1.9,0,3.5-0.3,4.9-0.8s2.6-1.2,3.5-2.1
    c0.9-0.9,1.6-1.9,2.1-3s0.7-2.3,0.7-3.6c0-1.6-0.4-2.9-1.2-3.9s-1.9-1.9-3.2-2.7c-1.3-0.7-2.9-1.4-4.6-1.9
    c-1.7-0.5-3.5-1.1-5.2-1.7c-1.8-0.6-3.5-1.3-5.2-2.1s-3.2-1.8-4.6-2.9c-1.3-1.2-2.4-2.6-3.2-4.3c-0.8-1.7-1.2-3.8-1.2-6.3
    c0-2.2,0.4-4.3,1.3-6.3c0.9-2,2.1-3.8,3.8-5.3s3.7-2.8,6.1-3.7c2.4-0.9,5.2-1.4,8.3-1.4c3.6,0,6.8,0.6,9.7,1.8s5.3,2.8,7.4,4.9
    L227.4,109.4z"
          />
        </g>
        <g>
          <g transform="matrix(1, 0, 0, 1, -317.685364, 27.65449)">
            <polygon
              className="st1"
              points="560.9,28.7 549.6,79.2 496.5,79.2"
            />
            <polygon
              className="st2 st1"
              points="541.5,112 549.6,79.2 534.9,91.5"
            />
            <polygon
              className="st3 st1"
              points="531.1,79.2 541,86.5 549.6,79.2"
            />
            <polygon
              className="st4 st1"
              points="537.3,83.7 534.9,91.5 541,86.5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
