import { useDeepCompareMemo } from "@react-hookz/web";
import { useEffect, useRef, useState } from "react";
import { pageSettingsAtom } from "../sharedHooks";
import { useAtom, useSetAtom } from "jotai";
import { Subscription_Tier_EnumSchema } from "../../__generated__/gql-validation/schemas";
import { useCurrentUser } from "../layout";
import { useUserByUserId } from "../../admin/users";
import { useAuthToken, userAtom } from "../../auth/hooks";
import { closeLiveprices, connectToLiveprices } from "../calculations-worker";
import { refreshTimeoutSet } from "../grid-settings/atomStore";
import { usePages } from "./pagesHooks";

export function useScrollableTabs() {
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);

  useEffect(() => {
    const tabContainer = tabContainerRef.current;
    if (!tabContainer) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setIsScrollable(entry.target.scrollWidth > entry.target.clientWidth);
      }
    });

    resizeObserver.observe(tabContainer);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const scrollToNextTab = () => {
    if (!tabContainerRef.current) return;
    tabContainerRef.current.scrollBy({
      left: 100, // Adjust the scroll distance as needed
      behavior: "smooth",
    });
  };

  const scrollToPreviousTab = () => {
    if (!tabContainerRef.current) return;
    tabContainerRef.current.scrollBy({
      left: -100, // Adjust the scroll distance as needed
      behavior: "smooth",
    });
  };

  const scrollToBeginning = () => {
    if (!tabContainerRef.current) return;
    tabContainerRef.current.scrollTo({
      left: 0,
      behavior: "smooth",
    });
  };

  const scrollToEnd = () => {
    if (!tabContainerRef.current) return;
    tabContainerRef.current.scrollTo({
      left: tabContainerRef.current.scrollWidth,
      behavior: "smooth",
    });
  };

  return {
    tabContainerRef,
    isScrollable,
    scrollToNextTab,
    scrollToPreviousTab,
    scrollToBeginning,
    scrollToEnd,
  };
}

export function usePageSettings() {
  const { results: pages, fetching, error } = usePages();

  const noProducts = Boolean(!fetching && pages?.length === 0);

  const setPageSettings = useSetAtom(pageSettingsAtom);

  useEffect(() => {
    setPageSettings(pages || null);
  }, [pages, setPageSettings]);

  return {
    fetching,
    noProducts,
    results: pages,
    error,
  };
}

export function useUserSubscriptionTierByUserId(id: string) {
  const { user } = useUserByUserId(id);
  const setUser = useSetAtom(userAtom);
  useEffect(() => {
    if (user) {
      setUser(user);
    }
  }, [user, setUser]);
}

export function useUserSubscriptionTier() {
  const { user } = useCurrentUser();
  const subscriptionTier = user?.subscription_tier || "artis_lite";

  const liteUser =
    subscriptionTier === Subscription_Tier_EnumSchema.enum.artis_lite;
  const enhancedUser =
    subscriptionTier === Subscription_Tier_EnumSchema.enum.artis_enhanced;
  // const enhancedNoExchUser =
  //   subscriptionTier === Subscription_Tier_EnumSchema.enum.artis_enhanced_no_exch;

  // Based on: https://artis.works/#pricing
  const disabledFeatures =
    !liteUser && !enhancedUser
      ? undefined
      : {
          spreader: liteUser,
          charts: liteUser,
          createPage: liteUser,
          createCurve: liteUser || enhancedUser,
          editCurve: liteUser || enhancedUser,
          insertCurve: liteUser,
          removeCurve: liteUser,
          priceAlerts: liteUser || enhancedUser,
        };

  return useDeepCompareMemo(
    () => ({
      liteUser,
      enhancedUser,
      // enhancedNoExchUser,
      disabledFeatures,
    }),
    [liteUser, enhancedUser, disabledFeatures],
  );
}

export function useRefreshTimeout({
  hour = 1,
  minute = 0,
  offset = 30,
}: { hour?: number; minute?: number; offset?: number }) {
  const [timeoutSet, setTimeoutSet] = useAtom(refreshTimeoutSet);
  const { session, isLoading: tokenLoading } = useAuthToken();

  useEffect(() => {
    if (timeoutSet) {
      console.log("timeout already set");
      return;
    }

    if (session && !tokenLoading) {
      const today = new Date();

      // 1 AM based on the user's local time.
      const refreshDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1,
        hour,
        minute,
        0,
      );

      // 1 AM based on GMT, adjusted for the user's local time.
      const timeDiff = today.getTimezoneOffset() / 60;
      const livePricesResetDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1,
        hour - timeDiff,
        minute,
        0,
      );

      const offsetInMs = offset * 60 * 1000;
      const randomOffset =
        Math.floor(Math.random() * (offsetInMs * 2)) - offsetInMs;

      const timeUntilRefresh =
        refreshDate.getTime() - today.getTime() + randomOffset;

      const timeUntilLivePricesReset =
        livePricesResetDate.getTime() - today.getTime() + randomOffset;

      const timeoutRefresh = timeUntilRefresh < 0 ? 0 : timeUntilRefresh;

      const timeoutLivePricesReset =
        timeUntilLivePricesReset < 0 ? 0 : timeUntilLivePricesReset;

      const partsRefresh = new Date(timeUntilRefresh)
        .toISOString()
        .substring(11, 19)
        .split(":");

      const partsLivePricesReset = new Date(timeUntilLivePricesReset)
        .toISOString()
        .substring(11, 19)
        .split(":");

      console.log(
        "Time until refresh:",
        `${partsRefresh[0]} hour(s), ${partsRefresh[1]} minute(s), ${partsRefresh[2]} second(s) - offset: ${offset} minutes - ${refreshDate.toISOString()} (User's local time - without offset)`,
      );

      console.log(
        "Time until live prices reset:",
        `${partsLivePricesReset[0]} hour(s), ${partsLivePricesReset[1]} minute(s), ${partsLivePricesReset[2]} second(s)- offset: ${offset} minutes - ${livePricesResetDate.toISOString()} (GMT - without offset)`,
      );

      setTimeout(() => {
        console.log("doing scheduled page reload");
        window.location.reload();
      }, timeoutRefresh);

      setTimeout(() => {
        console.log("doing scheduled live prices reset");
        closeLiveprices();
        connectToLiveprices({ jwt: session.jwt });
      }, timeoutLivePricesReset);

      setTimeoutSet(true);
    }
  }, [session, tokenLoading, hour, minute, offset, timeoutSet, setTimeoutSet]);
}
