import { useEffect } from "react";
import { useGridSettingsColors } from "../grid-settings";
import { useCssVar } from "../sharedHooks";

export function useDynamicCssClassRules() {
  const {
    alternatingRowColours,
    indicatorColour,
    headerColumnColour,
    subheaderColumnColour,
    listenColour,
    localColour,
    hybridColour,
    broadcastColour,
    eodColour,
    globalColour,
  } = useGridSettingsColors();

  const setVar = useCssVar();

  useEffect(() => {
    // https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
    // 60% opacity
    const opacityInHex = "99";

    setVar(
      "--rowOddColour",
      alternatingRowColours ? "var(--rowOddColourOn)" : "transparent",
    );

    setVar("--indicatorColour", `${indicatorColour}${opacityInHex}`);

    setVar("--headerColumnColour", headerColumnColour || "transparent");
    setVar("--subheaderColumnColour", subheaderColumnColour || "transparent");
    setVar("--listenColour", listenColour || "transparent");
    setVar("--localColour", localColour || "transparent");
    setVar("--hybridColour", hybridColour || "transparent");
    setVar("--broadcastColour", broadcastColour || "transparent");
    setVar("--eodColour", eodColour || "transparent");
    setVar("--globalColour", globalColour || "transparent");
  }, [
    indicatorColour,
    headerColumnColour,
    subheaderColumnColour,
    alternatingRowColours,
    listenColour,
    localColour,
    hybridColour,
    broadcastColour,
    eodColour,
    globalColour,
    setVar,
  ]);
}
