import { Box, Button, MenuItem, useColorScheme } from "@mui/joy";
import { AgCharts } from "ag-charts-react";
import { memo, useCallback, useMemo, useState } from "react";
import { deepEqual } from "@tanstack/react-router";
import { monthCodeToShortMonth } from "../market-grid/periodHelpers";
import { bottomPanelHeight } from "../globals";
import {
  Autocomplete,
  type TOption,
} from "../components/Autocomplete/Autocomplete";
import { useAutocomplete } from "../components/Autocomplete/hooks";
import type {
  ProductFragmentGridFragment,
  Product_Artis_Type_Enum,
} from "../../__generated__/gql/graphql";
import { defaultFieldNameSelector, isListOnlyPermissions } from "../utils";
import {
  fieldNameOverrides,
  getSelectors,
  validateSelectorExchange,
} from "../market-grid/contextMenuHelpers";
import { client } from "../../triplit/triplit";
import ThreeDotMenu from "../components/ThreeDotMenu";

const periodsOptions: TOption[] = Array.from({ length: 24 }, (_, i) => {
  const val = (i + 1).toString();
  return { label: val, value: val, groupValue: "period" } satisfies TOption;
});

function genSelectorOptions(
  product: ProductFragmentGridFragment | undefined,
): TOption[] {
  const selectedExchange =
    product?.packageByPackage?.sourceBySource?.exchange?.code;
  const selectedMaturity = product?.maturity;
  const defaultFieldNameSelectorOption = genDefaultOption(product?.artis_type);

  if (product && selectedExchange && selectedMaturity) {
    return getSelectors({
      productId: product.id,
      exchange: validateSelectorExchange(selectedExchange),
      maturity: selectedMaturity,
    });
  }
  return [defaultFieldNameSelectorOption];
}

interface ContextMenuContentProps {
  id: string;
  idx: number;
  userId: string;
  productId?: string;
  selector?: string;
  period?: string;
  allProductOptions: TOption[] | undefined;
}

const ContextMenuContent: React.FC<ContextMenuContentProps> = memo(
  ({ id, idx, userId, productId, selector, period, allProductOptions }) => {
    const productOptions = allProductOptions;
    const defaultOption = productOptions?.find((p) => p.value === productId);

    const useProductAutocompleteProps = useAutocomplete({
      multiple: false,
      defaultValue: defaultOption,
    });

    const [selectedProduct] = useProductAutocompleteProps.singleState;

    const selectorOptions = useMemo(
      () => genSelectorOptions(selectedProduct?.metadata),
      [selectedProduct],
    );

    const defaultSelector = selectorOptions?.find((s) => s.value === selector);

    const useSelectorAutocompleteProps = useAutocomplete({
      multiple: false,
      defaultValue: defaultSelector,
    });

    const [selectedSelector, setSelectedSelector] =
      useSelectorAutocompleteProps.singleState;

    const defaultPeriod = periodsOptions.find((p) => p.value === period);

    const usePeriodAutocompleteProps = useAutocomplete({
      multiple: false,
      defaultValue: defaultPeriod,
    });

    const [selectedPeriod, setSelectedPeriod] =
      usePeriodAutocompleteProps.singleState;

    const [formState, setFormState] = useState({
      productId,
      selector,
      period,
      metadata: selectedProduct?.metadata,
    });

    const isAnyFieldEmpty =
      !selectedProduct || !selectedSelector || !selectedPeriod;

    const idxFromId = id.split("-")[1];
    const handleSave = useCallback(async () => {
      await client.insert("gridCharts", {
        id,
        idx: Number.parseInt(idxFromId),
        productId: formState.productId,
        selector: formState.selector,
        period: Number.parseInt(formState.period || "12"),
        userId,
      });
    }, [
      formState.period,
      formState.productId,
      formState.selector,
      id,
      idxFromId,
      userId,
    ]);

    const handleRemove = useCallback(async () => {
      await client.delete("gridCharts", id);
    }, [id]);

    const containerWidth = 350;

    // takes into account padding
    const halfWidth = containerWidth / 2 - 12;

    return (
      <Box
        sx={{
          borderRadius: "sm",
          p: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: containerWidth,
        }}
      >
        <Autocomplete
          {...useProductAutocompleteProps}
          multiple={false}
          placeholder="Curve"
          options={productOptions || []}
          onSelectValue={async (v: TOption) => {
            const value = v.value;
            const defaultOption = genDefaultOption(v?.metadata?.artis_type);

            setSelectedSelector(defaultOption);

            setFormState((prev) => {
              const newPeriod = prev.period || "12";
              setSelectedPeriod({
                label: newPeriod,
                value: newPeriod,
                groupValue: "period",
              });
              return {
                ...prev,
                productId: value,
                selector: value
                  ? defaultFieldNameSelector(
                      v?.metadata?.artis_type || "customer_curve",
                    )
                  : undefined,
                period: newPeriod,
              };
            });
          }}
        />
        <Box sx={{ display: "flex", gap: 1 }}>
          <Autocomplete
            {...useSelectorAutocompleteProps}
            multiple={false}
            disabled={!selectedProduct || selectorOptions?.length === 1}
            sx={{ width: halfWidth }}
            defaultValue={
              selectorOptions?.length === 1 ? selectorOptions[0] : undefined
            }
            placeholder="Field"
            options={selectorOptions || []}
            onSelectValue={(v: TOption) => {
              setFormState((prev) => ({
                ...prev,
                selector: v.value,
              }));
            }}
          />
          <Autocomplete
            {...usePeriodAutocompleteProps}
            disableFilter
            multiple={false}
            sx={{ width: halfWidth }}
            disabled={!selectedProduct}
            placeholder="No of Months"
            options={periodsOptions}
            onSelectValue={(v: TOption) => {
              setFormState((prev) => ({
                ...prev,
                period: v.value,
              }));
            }}
          />
        </Box>
        <MenuItem
          sx={{
            display: "flex",
            gap: 1,
            padding: 0,
            margin: 0,
            // remove default menu button styles
            cursor: "initial",
            "&:focus": {
              outline: "none",
            },
            "&:focus-visible": {
              outline: "none",
            },
            "&:focus-within": {
              outline: "none",
            },
            "&:active": {
              backgroundColor: "transparent !important",
            },
            "&:hover": {
              backgroundColor: "transparent !important",
            },
            backgroundColor: "transparent",
          }}
        >
          <Button
            variant="outlined"
            size="sm"
            sx={{ flex: 1, width: halfWidth }}
            disabled={isAnyFieldEmpty}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="danger"
            size="sm"
            disabled={!id || idx <= 1}
            sx={{ flex: 1, width: halfWidth }}
            onClick={handleRemove}
          >
            Remove
          </Button>
        </MenuItem>
      </Box>
    );
  },
);

function genDefaultOption(
  artisType: Product_Artis_Type_Enum | undefined,
): TOption {
  const defaultFieldNameSelectorValue = defaultFieldNameSelector(
    artisType || "customer_curve",
  );

  const selectorLabel =
    fieldNameOverrides[defaultFieldNameSelectorValue] ||
    defaultFieldNameSelectorValue;

  return {
    label: selectorLabel.toUpperCase(),
    value: defaultFieldNameSelectorValue,
    groupValue: "selector",
  };
}

const ChartRenderer = memo(
  ({
    id,
    idx,
    productId,
    selector,
    period,
    userId,
    allProductOptions,
    data,
    chartsWidth,
    fetchingAllProducts,
  }: {
    id: string;
    idx: number;
    productId?: string;
    selector?: string;
    period?: number;
    userId: string;
    allProductOptions?: TOption[];
    data: { type: string; value: number | undefined }[];
    chartsWidth: number;
    fetchingAllProducts: boolean;
  }) => {
    const { mode } = useColorScheme();

    const product = allProductOptions?.find((p) => p.value === productId);

    const productName = product?.label;

    const productNameLabel = productName
      ? `${productName}${
          selector !== "fv" && selector !== "value"
            ? ` - ${selector?.toUpperCase()}`
            : ""
        }`
      : "";

    const permissions = product?.metadata?.packageByPackage.permissions;
    const isListOnly = isListOnlyPermissions(permissions);

    const isPermissioned = permissions?.length && !isListOnly;
    const overlayText = isListOnly
      ? "List only permissions"
      : !permissions?.length
        ? "No permissions"
        : productId
          ? "No data to show"
          : "";

    return (
      <Box
        key={id}
        sx={{
          width: chartsWidth,
          border: `1px solid ${mode === "dark" ? "#2D2D2D" : "#E5E5E5"}`,
          borderBottom: "none",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <ThreeDotMenu
          name={`ThreeDotMenu-${idx}`}
          sx={{
            zIndex: 10,
            position: "absolute",
            top: 3,
            right: 2,
          }}
        >
          <ContextMenuContent
            id={id}
            idx={idx}
            productId={productId}
            selector={selector}
            period={period?.toString()}
            userId={userId}
            allProductOptions={allProductOptions}
          />
        </ThreeDotMenu>

        {productId && !fetchingAllProducts ? (
          <AgCharts
            options={{
              data: isPermissioned ? data : [],
              theme: mode === "dark" ? "ag-default-dark" : "ag-default",
              overlays: {
                noData: { text: overlayText },
              },
              padding: { top: 15, right: 5, bottom: 5, left: 5 },
              width: chartsWidth,
              height: bottomPanelHeight - 20,
              title: {
                text: productNameLabel,
                fontSize: 12,
                maxWidth: chartsWidth - 100,
              },
              series: [
                {
                  type: "bar",
                  xKey: "type",
                  yKey: "value",
                  yName: "Value",
                  itemStyler: (params) => {
                    const posColor = "#337A3B";
                    const negColor = "#9B3636";
                    return {
                      fill: params.datum[params.yKey] < 0 ? negColor : posColor,
                      stroke:
                        params.datum[params.yKey] < 0 ? negColor : posColor,
                      strokeWidth: 0,
                    };
                  },
                },
              ],
              axes: [
                {
                  type: "category",
                  position: "bottom",
                  label: {
                    fontSize: 10,
                    formatter: (params) =>
                      monthCodeToShortMonth(params.value as string),
                    rotation: -90,
                  },
                  tick: { size: 4 },
                },
                { type: "number", position: "left", tick: { size: 0 } },
              ],
            }}
          />
        ) : null}
      </Box>
    );
  },
  deepEqual,
);

export default ChartRenderer;
