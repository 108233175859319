import { type ReactElement, useRef } from "react";
import * as ContextMenuRadix from "@radix-ui/react-context-menu";
import { contextMenuZIndex } from "../globals";
import { nanoid } from "nanoid";

type TContextMenu = {
  id?: string;
  trigger: ReactElement;
  openOnLeftClick?: boolean;
  children: ReactElement;
  onOpenChange?: (open: boolean) => void;
  isArtisLite?: boolean;
};

export function ContextMenu({
  id,
  trigger,
  openOnLeftClick,
  children,
  onOpenChange,
  isArtisLite,
}: TContextMenu) {
  const elId = useRef(id || nanoid());
  return (
    <ContextMenuRadix.Root onOpenChange={onOpenChange}>
      <ContextMenuRadix.Trigger
        title={
          isArtisLite
            ? "Please upgrade your subscription to access this feature"
            : undefined
        }
        disabled={isArtisLite}
        id={elId.current}
        onClick={() => {
          if (openOnLeftClick) {
            const button = document.getElementById(elId.current);
            button?.dispatchEvent(
              new MouseEvent("contextmenu", {
                bubbles: true,
                clientX: button?.getBoundingClientRect().x,
                clientY: button?.getBoundingClientRect().y,
              }),
            );
          }
        }}
      >
        {trigger}
      </ContextMenuRadix.Trigger>
      <ContextMenuRadix.Portal>
        <ContextMenuRadix.Content style={{ zIndex: contextMenuZIndex }}>
          {children}
        </ContextMenuRadix.Content>
      </ContextMenuRadix.Portal>
    </ContextMenuRadix.Root>
  );
}
