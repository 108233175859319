import type { SxProps } from "@mui/joy/styles/types";

export const sideBarLinkStyle: SxProps = {
  textDecoration: "none",
  color: "inherit",
  "&:hover": {
    textDecoration: "none",
  },
  width: "100%",
};
