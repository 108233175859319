import { headerHeight, months } from "../globals";
import type { TGridSettings } from "../../triplit/schema";

export const initGridSettings = {
  id: "",
  updatedAt: new Date(),
  extraHeaderHeight: headerHeight,
  statusMap: undefined,
  incrementMap: undefined,

  commodityParentGroup: true,
  geographicalRegion: true,
  commodityGroup: true,
  package: false,
  description: false,
  source: false,
  fieldName: false,
  mainMonthColumnWidth: 70,

  adhocSpreadsRows: 5,
  flashCellUpdates: false,
  enableFullMonthRowFeature: false,
  qtrSwitch: true,
  hlvSwitch: true,
  seasonSwitch: true,
  calSwitch: true,
  adhocSpreadsSwitch: true,
  months: months,
  hlvs: 7,
  seasons: 6,
  seasonCurrent: false,
  hlvCurrent: false,
  cals: 4,
  qtrCurrent: true,
  calCurrent: false,
  qtrs: 12,
  alternatingRowColours: true,
  hideStatusRow: false,
  hideEOD: true,
  hideBroadcast: true,
  hidePrivate: false,
  hideGlobal: false,
  gridScratchpadSwitch: false,
  scratchpadMaxCols: 1,
  scratchpadMaxRows: 1,
  quarterBorders: true,
  sound: false,
  indicatorColour: "#ff0000",
  headerColumnColour: "#b5c7e8",
  subheaderColumnColour: "#d9e1f2",
  broadcastColour: "#ffffcc",
  hybridColour: "#fce3e3",
  listenColour: "#b7d7f5",
  eodColour: "#c6e0b4",
  localColour: "#ededed",
  globalColour: "#33AA44",
} satisfies TGridSettings;
