import type { DockviewApi } from "dockview";
import { atom } from "jotai";
import type { CandlestickData, UTCTimestamp } from "lightweight-charts";
import { z } from "zod";
import type { TOption } from "../components/Autocomplete/Autocomplete";
import dayjs, { type Dayjs } from "dayjs";
import type { DurationUnitType } from "dayjs/plugin/duration";
import duration from "dayjs/plugin/duration";
import type { NextState } from "@react-hookz/web/util/resolveHookState.js";
import type { useHistoricalChartData } from "./hooks";
dayjs.extend(duration);

export const rightOffset = 4;

export const products: TOption[] = [
  {
    label: "BRT SW",
    value: "d66746e5-8e18-402b-9e78-3be5831854d0",
    groupValue: "ICE",
  },
  {
    label: "RB SW",
    value: "a64658cc-3081-46e1-b20f-3325dd0082de",
    groupValue: "ICE",
  },
  {
    label: "GO TS",
    value: "65c0741c-7ed5-47d3-9987-2fa3b6b39b86",
    groupValue: "ICE",
  },
  {
    label: "PLATTS BRT SW",
    value: "410f1a70-e117-4aaa-a2e0-556d1a15f4a4",
    groupValue: "ICE",
  },
  {
    label: "RB TS",
    value: "73ef9108-6c95-4978-900a-5a845594b839",
    groupValue: "ICE",
  },
  {
    label: "WTI TS",
    value: "00e9655d-3243-4225-a0a3-d43864727f9b",
    groupValue: "ICE",
  },
  {
    label: "WTI SW",
    value: "e48c187b-1416-4508-9810-f57094dc3949",
    groupValue: "ICE",
  },
  {
    label: "BRT FT",
    value: "cdc2ee85-7fa0-40a7-90bd-17b610aa4309",
    groupValue: "ICE",
  },
  {
    label: "GO SW",
    value: "69993417-5bb5-4532-b3d8-424f2e7ccba2",
    groupValue: "ICE",
  },
  {
    label: "GO FT",
    value: "a79b77a0-2267-4f86-8c75-c9248ac9d4b3",
    groupValue: "ICE",
  },
  {
    label: "BRT TS",
    value: "92801246-4587-46e5-825e-5b2a93c61bbf",
    groupValue: "ICE",
  },
  {
    label: "RB FT",
    value: "a78b6d84-1017-4958-8875-1e0c1a23406f",
    groupValue: "ICE",
  },
  {
    label: "WTI FT",
    value: "f49c81e1-6371-4bb7-a449-cb5dd113fe80",
    groupValue: "ICE",
  },
  {
    label: "UKA FT",
    value: "46555d30-8fdd-4e85-918c-d748ea1b5bbd",
    groupValue: "ICE",
  },
  {
    label: "HO TS",
    value: "ad8965ce-bc7e-45d2-9ace-cc3c90798852",
    groupValue: "ICE",
  },
  {
    label: "HO FT",
    value: "003998e1-975a-48e7-aef2-e711efbe4e8c",
    groupValue: "ICE",
  },
  {
    label: "HO SW",
    value: "fe5ec999-3e87-44b8-8bb8-634f6547cd0c",
    groupValue: "ICE",
  },
  {
    label: "BRT TS",
    value: "83f8f80b-8b3a-47a9-8cb9-1c7b7466bf71",
    groupValue: "NYMEX",
  },
  {
    label: "BRT SW",
    value: "abc88939-cf10-453e-a808-a2a3a6ce8711",
    groupValue: "NYMEX",
  },
  {
    label: "HO FT",
    value: "1f41c7d8-b597-4b80-b1e2-02faf421c090",
    groupValue: "NYMEX",
  },
  {
    label: "RB SW",
    value: "9b6a9b6a-4457-4408-a2d4-2c40b5ac7ad8",
    groupValue: "NYMEX",
  },
  {
    label: "WTI SW",
    value: "3d237b99-96a9-41f3-881a-e29b87da5345",
    groupValue: "NYMEX",
  },
  {
    label: "WTI TS",
    value: "af3a49b5-a864-44cf-b25f-f60f4d18b65f",
    groupValue: "NYMEX",
  },
  {
    label: "WTI FT",
    value: "ab58810d-ccd5-4e47-8958-e2919abacd60",
    groupValue: "NYMEX",
  },
  {
    label: "HO SW",
    value: "830b58e5-78b9-454c-9ed2-af98472b8239",
    groupValue: "NYMEX",
  },
  {
    label: "HO TS",
    value: "3a8543bf-9dce-405d-8733-068c87271792",
    groupValue: "NYMEX",
  },
  {
    label: "BRT FT",
    value: "7cb6b463-217e-4c97-ab79-eed6bda12627",
    groupValue: "NYMEX",
  },
  {
    label: "RB TS",
    value: "60a58576-8deb-44fb-8cf8-8f7827f64153",
    groupValue: "NYMEX",
  },
  {
    label: "RB FT",
    value: "eda3d857-a7a9-41b9-aa45-494decf8b927",
    groupValue: "NYMEX",
  },
  {
    label: "HHUB TS",
    value: "a0c700a0-2eab-465c-bbb7-09bc33d00eeb",
    groupValue: "Natural Gas",
  },
  {
    label: "JKM",
    value: "488da924-8377-461c-b312-1e2eb0a8a749",
    groupValue: "Natural Gas",
  },
  {
    label: "HHUB FT",
    value: "4d2de6d1-72f9-46d0-91fc-fa73f366064a",
    groupValue: "Natural Gas",
  },
  {
    label: "JKM TS",
    value: "32977c10-5e93-4dcb-ab5f-69ba1478942b",
    groupValue: "Natural Gas",
  },
  {
    label: "NBP TS",
    value: "80894118-3325-4849-b164-1c0a65bef2bb",
    groupValue: "Natural Gas",
  },
  {
    label: "NBP FT",
    value: "ae992df4-0b80-4b32-b203-ada580c6c494",
    groupValue: "Natural Gas",
  },
  {
    label: "TTF FT",
    value: "6f8f1fc3-2899-4798-b94e-a378e13bb3d8",
    groupValue: "ICE ENDEX",
  },
  {
    label: "TTF TS",
    value: "6363e14e-3750-42d3-892e-f81532bd7c4c",
    groupValue: "ICE ENDEX",
  },
  {
    label: "EUA FT",
    value: "0ab9f625-9071-46b5-a042-f2c42ff53a63",
    groupValue: "ICE ENDEX",
  },
];
export const sampleConfig = {
  "10s": {
    label: "10s",
    round: 10,
    unit: "second" as DurationUnitType,
    maxItems: 200,
    seconds: 10,
    minBars: 3000,
  },
  "1m": {
    label: "1m",
    round: 1,
    unit: "minute" as DurationUnitType,
    maxItems: 200,
    seconds: 60,
    minBars: 300,
  },
  "5m": {
    label: "5m",
    round: 5,
    unit: "minute" as DurationUnitType,
    maxItems: 200,
    seconds: 300,
    minBars: 100,
  },
  "1h": {
    label: "1h",
    round: 1,
    unit: "hour" as DurationUnitType,
    maxItems: 200,
    seconds: 3600,
    minBars: 50,
  },
  "1d": {
    label: "1d",
    round: 1,
    unit: "day" as DurationUnitType,
    maxItems: 200,
    seconds: 86400,
    minBars: 10,
  },
} as const;

export const sampleByQuestOptions = Object.keys(sampleConfig).map((key) => ({
  label: sampleConfig[key as keyof typeof sampleConfig].label,
  value: key,
}));

export const sampleRoundingIndex = Object.fromEntries(
  Object.entries(sampleConfig).map(([key, { round, unit }]) => [
    key,
    { round, unit },
  ]),
) as Record<TSample, { round: number; unit: DurationUnitType }>;

export type TProduct = (typeof products)[number]["value"];
export type TSample = keyof typeof sampleConfig;
export type TMovingAverage = {
  stateType: "MovingAverage";
  id: string;
  show: boolean;
  period: number;
  field: string;
  type: "Simple" | "Exponential" | "Weighted";
  offset: number;
  color: string;
};

export type TIndicatorsStateTypes = TMovingAverage; // Add other indicators here as union

export type TIndicatorsState = Array<TIndicatorsStateTypes>;

export const defaultIndicatorsState: TIndicatorsState = [
  {
    id: "ma1",
    stateType: "MovingAverage",
    show: true,
    period: 8,
    field: "close",
    type: "Simple",
    offset: 0,
    color: "#E65100",
  },
  {
    id: "ma2",
    stateType: "MovingAverage",
    show: true,
    period: 21,
    field: "close",
    type: "Simple",
    offset: 0,
    color: "#1565C0",
  },
];

const TSampleEnum = z.enum(Object.keys(sampleConfig) as [string, ...string[]]);

export const validSampleOrFirst = (sample?: string) => {
  try {
    TSampleEnum.parse(sample);
    return sample as TSample;
  } catch (e) {
    return sampleByQuestOptions[0].label;
  }
};

export type TMarketData = {
  [key in TProduct]?: {
    [key in TSample]?: CandlestickData[];
  };
};

export const dockviewAtom = atom<DockviewApi | null>(null);
dockviewAtom.debugLabel = "dockviewAtom";
export const refetchInterval = 250;

export const liveChartsOpenAtom = atom<{
  isLiveChartsOpen: boolean | undefined;
  setIsLiveChartsOpen: (value: NextState<boolean, boolean | undefined>) => void;
}>({
  isLiveChartsOpen: undefined,
  setIsLiveChartsOpen: () => undefined,
});

export function getUnixNow() {
  return Math.floor(Date.now() / 1000) as UTCTimestamp;
}

export function getUTCNow() {
  return new Date().toISOString();
}

export function fromUTCToUnix(d: string) {
  return Math.floor(new Date(d).getTime() / 1000) as UTCTimestamp;
}

export function fromUnixToUTC(n: UTCTimestamp) {
  return new Date(n * 1000).toISOString();
}

export function parseQuestDBData(
  data: [string, number, number, number, number][],
) {
  return data.map(([timestamp, open, close, min, max]) => {
    const isEmptyEntry = !open;
    const time = fromUTCToUnix(timestamp);
    return isEmptyEntry ? { time } : { time, open, close, low: min, high: max };
  });
}

export const oneHourInSeconds = 60 * 60;
export const oneDayInSeconds = oneHourInSeconds * 24;
export const oneWeekInSeconds = oneDayInSeconds * 7;
export const oneMonthInSeconds = oneWeekInSeconds * 5;
export const oneYearInSeconds = oneMonthInSeconds * 12;

export const maxItemsInOneFetch = Object.fromEntries(
  Object.entries(sampleConfig).map(([key, { maxItems }]) => [key, maxItems]),
) as Record<TSample, number>;

export const sampleTimeToSeconds = Object.fromEntries(
  Object.entries(sampleConfig).map(([key, { seconds }]) => [key, seconds]),
) as Record<TSample, number>;

export function getSecondsToSubtract(sampleTime: TSample | undefined): number {
  if (!sampleTime) throw new Error("sampleTime is required");
  return (
    maxItemsInOneFetch[sampleTime] * (sampleTimeToSeconds[sampleTime] ?? 0)
  );
}

export function isMidnight(unixTimestamp: number) {
  return unixTimestamp % oneDayInSeconds === 0;
}

export const bottomBarHeight = "30px";

export const maxChartsAllowed = 4;

export function genChartId({
  chartIdx,
  userId,
}: {
  chartIdx: string;
  userId: string;
}) {
  return `${chartIdx}-chart-${userId}`;
}

export function fromChartIdToIdx(chartId: string) {
  return chartId.split("-")[0];
}

export const getComplementaryColorInRGBA = (
  hex: string,
  alpha = 0.1,
): string => {
  const removeHash = (hex: string) => hex.replace("#", "");

  const hexToRgb = (hex: string) => ({
    r: Number.parseInt(hex.slice(0, 2), 16),
    g: Number.parseInt(hex.slice(2, 4), 16),
    b: Number.parseInt(hex.slice(4, 6), 16),
  });

  const getComplementary = (rgb: { r: number; g: number; b: number }) => ({
    r: 255 - rgb.r,
    g: 255 - rgb.g,
    b: 255 - rgb.b,
  });

  const rgbToRgbaString = (
    rgb: { r: number; g: number; b: number },
    alpha: number,
  ) => `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;

  return rgbToRgbaString(getComplementary(hexToRgb(removeHash(hex))), alpha);
};

// Chunk size must be at least 1 hour and in multiples of an hour
const chunkSizeHours = 4;
const chunkTimeSeconds = dayjs.duration(chunkSizeHours, "hours").asSeconds();

export function startOfCurrentChunk(nowDayJs?: Dayjs) {
  const now = nowDayJs || dayjs();

  // Calculate the number of chunks that have passed since the start of the day
  const chunksSinceStartOfDay = Math.floor(now.hour() / chunkSizeHours);

  // Calculate the start of the current chunk
  const startOfChunk = now
    .startOf("day")
    .add(chunksSinceStartOfDay * chunkSizeHours, "hours");

  return startOfChunk;
}

export function isDateRounded(isoString: string): boolean {
  const date = new Date(isoString);

  const seconds = date.getUTCSeconds();
  const milliseconds = date.getUTCMilliseconds();

  return seconds === 0 && milliseconds === 0;
}

export function getPreviousFromTo(lastPageTime: Dayjs) {
  const to = lastPageTime.utc().toISOString();
  const isRaw = !isDateRounded(to);
  const from = isRaw
    ? lastPageTime.subtract(10, "m")
    : lastPageTime.subtract(chunkTimeSeconds, "s");
  const fromRoundedToHour = from.startOf("hour");
  return {
    from: fromRoundedToHour.utc().toISOString(),
    to,
  };
}

export const specificPeriods = [
  { label: "1d", value: 1 },
  { label: "7d", value: 7 },
  { label: "28d", value: 28 },
];

export function getSpecificHistoricalDataDates(
  latestVisibleCandleTime: number,
) {
  const oneDaySeconds = 60 * 60 * 24;

  const periodDates = specificPeriods.map((p) => {
    const from = latestVisibleCandleTime - oneDaySeconds * p.value;
    const fromUTC = dayjs(from * 1000)
      .utc()
      .toISOString();
    const to = from + 3600;
    const toUTC = dayjs(to * 1000)
      .utc()
      .toISOString();
    return { from: fromUTC, to: toUTC, label: p.label };
  });

  return periodDates;
}

export function genDatesBasedOnBars(bars: number, sample: TSample) {
  const sampleTimeSeconds = sampleTimeToSeconds[sample];
  const { round, unit } = sampleRoundingIndex[sample];
  const toDate = dayjs().utc().round(round, unit);
  const fromDate = toDate
    .subtract(sampleTimeSeconds * bars, "seconds")
    .round(round, unit);
  return { fromDate: fromDate.toISOString(), toDate: toDate.toISOString() };
}

export function findClosestPrice(
  targetTime: number,
  data: ReturnType<typeof useHistoricalChartData>["data"],
) {
  try {
    return data?.reduce((prev, curr) =>
      Math.abs(curr.time - targetTime) < Math.abs(prev.time - targetTime)
        ? curr
        : prev,
    );
  } catch (e) {
    return undefined;
  }
}
