import { Box } from "@mui/joy";
import type { ReactNode } from "react";

export function SideNavIconContainer({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
}
