import { Box, Button, type ButtonProps } from "@mui/joy";
import { FaPlusCircle } from "react-icons/fa";

export function CreateEntityButton({
  onClick,
  entityName,
  entityIcon = <FaPlusCircle />,
}: {
  onClick: () => void;
  entityName: string;
  entityIcon?: React.ReactNode;
}) {
  const props = {
    size: "sm",
    variant: "outlined",
    color: "success",
    onClick,
  } satisfies ButtonProps;

  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "flex",
        },
      }}
    >
      <Button
        sx={{
          display: {
            sm: "none",
            md: "flex",
          },
        }}
        {...props}
      >
        Create New {entityName}
      </Button>
      <Button
        sx={{
          display: {
            sm: "flex",
            md: "none",
          },
        }}
        endDecorator={entityIcon}
        {...props}
      >
        Create
      </Button>
    </Box>
  );
}
